<section class="stc-legales list-part">
    <div class="container">
        <div class="row">  

            <div class="col-md-12 "  data-aos="zoom-in">
                <div class="container-legal">

                    <div class="card-body ">
                        <h1 class="card-title text-center mt-3">Lista de participantes Sorteo Egresados 2023</h1>
                        <p>
                            <strong>¡Consulta las listas de participantes aquí! </strong>
                        </p>
                        <p>
                            Encuentra a los afortunados participantes que forman parte de estos emocionantes sorteos. 
                        </p>
                        <p>
                            ¡Suerte a todos!
                        </p>
                        
                    </div>


                    <div class="row">

                        <div class="col-md-4 text-center mb-3">
                            <h5>
                                SORTEO EGRESADOS <span class="d-block">
                                    ANÁHUAC 2023
                                </span>
                            </h5>
                            <a href="/assets/pdf/participantes-sorteo-egresado-2023.pdf" target="_blank" class="btn-stack" >Consultar </a>
                        </div>

                        <div class="col-md-4 text-center mb-3">
                            <h5>
                                Sorteo Amigo <span class="d-block">Egresado 2023</span>
                                
                            </h5>
                            <a href="/assets/pdf/participantes-amigo-egresado-2023.pdf" target="_blank" class="btn-stack" >Consultar </a>
                        </div>
                        
                        <div class="col-md-4 text-center  mb-5">
                            <h5>
                                SORTEO EGRESADOS <span class="d-block">
                                    LEALTAD 2023
                                </span>
                            </h5>
                            <a href="/assets/pdf/participantes-sorteo-egresados-lealtad-2023.pdf" target="_blank" class="btn-stack" >Consultar </a>
                        </div>
                    </div>
                  </div>
            </div>

        </div>
    </div>
</section>

