import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  title = 'Blog del Sorteo Anáhuac Séptima Edición';
  description = '¡Bienvenido al Blog del Sorteo Anáhuac 8ª Edición! Encuentra información sobre las ventajas de participar en el sorteo y cómo ganar increíbles premios.';

  blogs = 
	[

		{
			src: "/assets/images/sorteo2023/blogs/blog1.jpg", 
      alt: "Consejos para ahorrar para una casa", 
      title: 'Consejos para ahorrar para una casa' , 
      description:'Para los recién casados, los que quieren empezar su camino fuera del núcleo familiar o los que buscan realizar una inversión a largo plazo, adquirir una casa es el primer paso para emprender un sueño posible...',
      class: 'orange',
      link: '#'
		},

    {
			src: "/assets/images/sorteo2023/blogs/blog2.jpg", 
      alt: "Ganadores del Sorteo Anáhuac Sexta Edición", 
      title: 'Ganadores del Sorteo Anáhuac Sexta Edición' , 
      description:'Apoyar a la educación mediante un Sorteo y además ganar uno de los maravillosos premios que ofrece, debe ser el anhelo de gran parte de los mexicanos en la actualidad...',
      class: 'purple',
      link: '#'
		},

    {
			src: "/assets/images/sorteo2023/blogs/blog3.jpg", 
      alt: "¿Dónde debes participar y cuántas posibilidades hay de ganarse una casa en un Sorteo?", 
      title: '¿Dónde debes participar y cuántas posibilidades hay de ganarse una casa en un Sorteo?' , 
      description:'Como te hemos mencionado en otras entradas de nuestro blog, hay diferentes formas en las que puedes ahorrar dinero, alcanzar la...',
      class: 'orange',
      link: '#'
		}
    



    
  ];
  constructor( private titleService: Title, private metaTagService: Meta,private router:Router) { 
  

  }

  ngOnInit(): void {
      this.titleService.setTitle(this.title);
      this.metaTagService.updateTag(
        { name: 'title', content: this.title}
      );
      this.metaTagService.updateTag(
        { name: 'description', content: this.description}
      );



      this.metaTagService.updateTag(
      { property: 'og:title', content: this.title}
      );

      this.metaTagService.updateTag(
        { property: 'keywords', content: 'Sorteo Anáhuac, Blog Sorteo Anáhuac' }
      );

      this.metaTagService.updateTag(
        { property: 'og:description', content: this.description }
      );

      this.metaTagService.updateTag(
        { property: 'og:url', content: 'https://www.sorteoanahuac.mx/blog' }
      );

      this.metaTagService.updateTag(
        { property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/pasa-la-voz/logo-anahuac.png' }
        );
        
      this.metaTagService.updateTag(
        { name: 'twitter:title', content: this.title}
      );
      this.metaTagService.updateTag(
        { name: 'twitter:description', content: this.description}
      );
  }

  selectedPost(postslug){
    //console.log('blog slg ', postslug);
      this.router.navigate(['blog',postslug]);
  }
}
