import { Component, OnInit } from '@angular/core';
import { PasaLavozService } from '../../../services/pasa-lavoz.service';
import { Contact } from '../../../models/contact';
import { NgForm } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  dataConact: Contact = new Contact();
  valid_email = false;
  touchInp = false;
  flag = false;
  mesagge = false;
  title = 'Contacto | Sorteo Anáhuac 2023 - 8ª Edición';
  description = 'Contacto Sorteo Anáhuac 8ª Edición. ¿Tienes alguna duda o tienes alguna sugerencia? Ponte en contacto con nosotros aquí.';
  constructor(private readonly _pasaServive: PasaLavozService, private titleService: Title, private metaTagService: Meta,) {
    
   }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
		this.metaTagService.updateTag(
			{ name: 'title', content: this.title}
		);
		this.metaTagService.updateTag(
			{ name: 'description', content: this.description }
		);

		this.metaTagService.updateTag(
			{ property: 'og:title', content: this.title}
		);

    this.metaTagService.updateTag(
			{ property: 'keywords', content: ''}
		);

    this.metaTagService.updateTag(
      		{ property: 'og:description', content: this.description }
		);

    this.metaTagService.updateTag(
      { property: 'og:url', content: 'https://www.sorteoanahuac.mx/contacto' }
    );

		this.metaTagService.updateTag(
			{ property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/bg-contact.jpg' }
		  );
		  
		this.metaTagService.updateTag(
			{ name: 'twitter:title', content: this.title}
		);
		this.metaTagService.updateTag(
			{ name: 'twitter:description', content: this.description }
		);
  }

  validateDataAll(form: NgForm){ 
    if (form.invalid ||  !this.valid_email ) { 
      (<any>$('#modalError')).modal('show');
      return;
    }
    //console.log(form);
    (<any>$('#modalSend')).modal({backdrop: 'static', keyboard: false});
    (<any>$('#modalSend')).modal('show');
    this.flag = true;

    this._pasaServive.getToken().subscribe(a => {
      console.log(a);
    }, err => {
    
      if (err.status == 200) {
        this._pasaServive.sendConact(this.dataConact, err.error.text).subscribe(a =>{
          //console.log(a);
          this.flag = false;
          this.mesagge = true;
        
        },err => {
            //console.log(err);
            this.flag = false;
            this.mesagge = true;
    
        });
      }
    });
    

   
    
  }

  inputValidateEmail(event: any) {
    //////console.log(event.target.value);
    this.touchInp = true;
    const pattern = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;   
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      // invalid character, prevent input
      this.valid_email = false;
    }else{
      this.valid_email = true;
     
    }
    //console.log("res:" + this.valid_email);
  }

  inputValidator(event: any) {
    //////console.log(event.target.value);
    const pattern = /^[0-9]*$/;   
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/, "");
      // invalid character, prevent input

    }
  }

  reload(){
    window.scroll(0,0);
    window.location.reload();
  }

  scrollTo(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

}
