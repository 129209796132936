<section id="premios-principales">
    <div class="container" data-aos="fade-right" data-aos-duration="3000">
        <div class="row">
            <div class="col-12 text-center my-auto">
                <img src="/assets/images/sorteo2024/premio-p.png" alt="Premios Sorteo Anáhuac" class="img-fluid">
            </div>
        </div>
    </div>
</section>

<section class="premios-text">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h2>DÉJATE SORPRENDER</h2>
            </div>
            <div class="col-md-5 my-auto">
                <h3>SORTEO MAGNO</h3>
                <small>Primer premio</small>
                <p>Departamento
                    en Cancún
                </p>
                <span>
                    Valor + de 10 millones de pesos
                </span>
                <span class="d-block mt-4"><strong>21 de noviembre 2024</strong> | 17:00 hrs.</span>
                <small>IEST Anáhuac</small>

                <h5 class="mt-3">Precio del
                    boleto $450
                </h5>


            </div>

            <div class="col-md-7 my-auto text-center">
                <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/" target="_blank">
                    <img src="/assets/images/sorteo2024/premios.png" class="img-fluid">
                </a>
            </div>

            <div class="col-md-12 text-center">
                <h6>
                    Descubre nuestras sorpresas:
                </h6>
                <p class="paragraph">
                    <strong>¡Más de 850 premios,</strong> desde un <strong> departamento en Cancún</strong> hasta una
                    impresionante casa en Mérida!

                    Además, increíbles autos como el nuevo <strong>KIA K3</strong> y el icónico <strong>Mini
                        Cooper.</strong>
                </p>
            </div>
        </div>
    </div>
</section>

<section id="content-prem">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 offset-lg-4 text-center my-auto">
                <h3>
                    ¡Súmate a
                    <span class="d-block">
                        Sorteo Anáhuac!
                    </span>
                </h3>
                <p>
                    Y gana apoyando la educación de miles de alumnos de la Red de Universidades Anáhuac y
                    creemos un mejor futuro para nuestro país.
                </p>
            </div>

        </div>
    </div>
</section>



<section class="premios-principal">
    <div class="container">
        <div class="row ">

            <div class="col-md-9 my-auto">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let slide of cancun; let i = index" class="slide">


                        <div class="container" id="premio1" (scroll)="onScroll($event)">
                            <div class="row">
                                <div class="col-md-12 my-auto">

                                    <img src="{{slide.src}}" alt="Primer Premio" class="w-100">

                                </div>
                            </div>
                        </div>



                    </div>
                </ngx-slick-carousel>
                <h2>
                    Depto. en cancún
                </h2>
                <p>
                    Valor + de 10 millones
                </p>
                <small>
                    Brezza Towers Mar
                </small>
            </div>
            <div class="col-md-3">
                <div class="content-btn">
                    <a (click)="scrollPage('#premio1')" class="cta" [ngClass]="{'active': isPremio1Active}">
                        1º premio
                    </a>
                    <a (click)="scrollPage('#premio2')" class="cta">
                        2º premio
                    </a>
                    <a (click)="scrollPage('#premio3')" class="cta">
                        3º premio
                    </a>
                    <a (click)="scrollPage('#otros')" class="cta">
                        Ver más premios
                    </a>

                    <p>
                        Precio del boleto
                        <span class="d-block">
                            $450
                        </span>
                    </p>
                    <a href="https://www.youtube.com/watch?v=BRste7e9Ozw" target="_blank"
                        class="btn-stack mb-4 btn-yt w-100 text-center">
                        ¡Ver video!
                    </a>
                    <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/" target="_blank" class="btn-stack w-100 text-center">
                        ¡compra ya!
                    </a>
                </div>
            </div>

            <div class="col-md-9 my-auto">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let slide of mexico; let i = index" class="slide">


                        <div class="container" id="premio1" (scroll)="onScroll($event)">
                            <div class="row">
                                <div class="col-md-12 my-auto">

                                    <img src="{{slide.src}}" alt="Primer Premio" class="w-100">

                                </div>
                            </div>
                        </div>



                    </div>
                </ngx-slick-carousel>
                    
                <h2>
                    Depto. en Edo. de México
                </h2>
                <p>
                    Valor + de 8 millones
                </p>
                <small>
                    Bosque Real Five
                </small>

            </div>
            <div class="col-md-3 my-auto">
                <div class="content-btn">
                    <a (click)="scrollPage('#premio1')" class="cta">
                        1º premio
                    </a>
                    <a (click)="scrollPage('#premio2')" class="cta" [ngClass]="{'active': isPremio2Active}">
                        2º premio
                    </a>
                    <a (click)="scrollPage('#premio3')" class="cta">
                        3º premio
                    </a>
                    <a (click)="scrollPage('#otros')" class="cta">
                        Ver más premios
                    </a>

                    <p>
                        Precio del boleto
                        <span class="d-block">
                            $450
                        </span>
                    </p>


                    <a href="https://www.youtube.com/watch?v=sURdqu-mp70" target="_blank"
                        class="btn-stack mb-4 btn-yt w-100 text-center">
                        ¡Ver video!
                    </a>
                    <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/" target="_blank" class="btn-stack w-100 text-center">
                        ¡compra ya!
                    </a>
                </div>
            </div>


            <div class="col-md-9 my-auto">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let slide of merida; let i = index" class="slide">


                        <div class="container" id="premio3" (scroll)="onScroll($event)">
                            <div class="row">
                                <div class="col-md-12 my-auto">

                                    <img src="{{slide.src}}" class="w-100">

                                </div>
                            </div>
                        </div>



                    </div>
                </ngx-slick-carousel>

                <h2>
                    Casa en mérida
                </h2>
                <p>
                    Valor + de 6 millones
                </p>
                <small>
                    El Origen
                </small>
            </div>
            <div class="col-md-3 my-auto">
                <div class="content-btn">
                    <a (click)="scrollPage('#premio1')" class="cta">
                        1º premio
                    </a>
                    <a (click)="scrollPage('#premio2')" class="cta">
                        2º premio
                    </a>
                    <a (click)="scrollPage('#premio3')" class="cta" [ngClass]="{'active': isPremio3Active}">
                        3º premio
                    </a>
                    <a (click)="scrollPage('#otros')" class="cta">
                        Ver más premios
                    </a>

                    <p>
                        Precio del boleto
                        <span class="d-block">
                            $450
                        </span>
                    </p>

                    <a href="https://www.youtube.com/watch?v=izupRfzaqIM" target="_blank"
                        class="btn-stack mb-4 btn-yt w-100 text-center">
                        ¡Ver video!
                    </a>
                    <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/" target="_blank" class="btn-stack w-100 text-center">
                        ¡compra ya!
                    </a>
                </div>
            </div>


            <div class="col-md-9 my-auto" id="otros" (scroll)="onScroll($event)">
                <div class="row">

                    <div class="col-lg-4 video-container">
                        <img src="/assets/images/sorteo2024/otros1.jpg" class="video-thumbnail" />
                        <iframe class="video-iframe" width="255" height="194" src="https://www.youtube.com/embed/u8TEvB322pU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                    <div class="col-lg-4 video-container">
                        <img src="/assets/images/sorteo2024/otros2.jpg" class="video-thumbnail" />
                        <iframe class="video-iframe" width="255" height="194" src="https://www.youtube.com/embed/36YPZErIEfM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                    
                    <div class="col-lg-4 video-container">
                        <img src="/assets/images/sorteo2024/otros3.jpg" class="video-thumbnail" />
                        <iframe class="video-iframe" width="255" height="194" src="https://www.youtube.com/embed/N8aP3hH5fEw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                    
                    <div class="col-lg-4 video-container">
                        <img src="/assets/images/sorteo2024/otros4.jpg" class="video-thumbnail" />
                        <iframe class="video-iframe" width="255" height="194" src="https://www.youtube.com/embed/YMwZuG3kljs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                    
                    <div class="col-lg-4 video-container">
                        <img src="/assets/images/sorteo2024/otros5.jpg" class="video-thumbnail" />
                        <iframe class="video-iframe" width="255" height="194" src="https://www.youtube.com/embed/KKE3_M4tO7s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                    <div class="col-lg-4 video-container">
                        <img src="/assets/images/sorteo2024/otros6.jpg" class="video-thumbnail" />
                        <iframe class="video-iframe" width="255" height="194" src="https://www.youtube.com/embed/nqw64B53YwI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                    <div class="col-lg-4 video-container">
                        <img src="/assets/images/sorteo2024/otros7.jpg" class="video-thumbnail" />
                        <iframe class="video-iframe" width="255" height="194" src="https://www.youtube.com/embed/kpqYDtL6jg8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                    <div class="col-lg-4 video-container">
                        <img src="/assets/images/sorteo2024/otros8.jpg" class="video-thumbnail" />
                        <iframe class="video-iframe" width="255" height="194" src="https://www.youtube.com/embed/diU7S396Hu0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>



                </div>

            </div>
            
            <div class="col-md-3 my-auto">
                <div class="content-btn">
                    <a (click)="scrollPage('#premio1')" class="cta">
                        1º premio
                    </a>
                    <a (click)="scrollPage('#premio2')" class="cta">
                        2º premio
                    </a>
                    <a (click)="scrollPage('#premio3')" class="cta">
                        3º premio
                    </a>
                    <a (click)="scrollPage('#otros')" class="cta" [ngClass]="{'active': isPremio4Active}">
                        Ver más premios
                    </a>

                    <p>
                        Precio del boleto
                        <span class="d-block">
                            $450
                        </span>
                    </p>


                    <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/" target="_blank" class="btn-stack w-100 text-center">
                        ¡compra ya!
                    </a>
                </div>
            </div>
            <div class="col-12 mt-5">
                <p style="font-size: 14px;">
                    *Todas las imágenes son con fines ilustrativas
                </p>
            </div>

        </div>
    </div>

</section>