<section class="stc-legales">
    <div class="container">
        <div class="row">

            <div class="col-12 my-auto" data-aos="zoom-in-down" data-aos-easing="ease" data-aos-duration="3000" style="padding-top: 100px;">
                <h1>
                    Mecánica <br>del Sorteo
                </h1>
            </div>

        </div>
    </div>
</section>

<section class="stc-legal">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">

                <div class="container-legal" data-aos="zoom-in-down" data-aos-easing="ease" data-aos-duration="1500">
                    <p>
                        <strong>MECÁNICA DEL SORTEO PARA CADA UNO DE LOS SORTEOS ACUMULADOS ESPECIALES PARA LOS
                            COMPRADORES Y COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC NOVENA EDICIÓN.</strong>
                    </p>
                    <p>
                        <strong>La mecánica para el PRIMER, SEGUNDO y TERCER SORTEO ACUMULADO ESPECIAL PARA LOS COMPRADORES Y COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC NOVENA EDICIÓN será por formación de números de conformidad a lo siguiente: </strong>
                    </p>
                    <p>
                        Se presentarán 3 (tres) mesas que cuentan con 5 (cinco) ánforas unidas por un eje horizontal de izquierda a derecha. En 4 (cuatro) de las 5 (cinco) ánforas, se colocarán 10 (diez) esferas con la numeración del 0 (cero) al 9 (nueve). Estas 4 (cuatro) primeras ánforas representan las unidades, decenas, centenas y unidades de millar. El ánfora 5 (cinco), que representa las decenas de millar y centenas de millar, contendrá 37 (treinta y siete) esferas con numeración del 0 (cero) al 36 (treinta y seis). En donde de cada ánfora saldrá una esfera formando el número ganador.  Cabe señalar que la formación de los números ganadores se hará de derecha a izquierda y su lectura de izquierda a derecha. 

                    </p>

                    <p>
                        Los premios se irán otorgando en orden de mayor a menor valor, comenzando con el premio número 1 (uno) y terminando en el premio 5 (cinco). Lo cual corresponde al total de premios que se sortearán en cada Sorteo Acumulado Especial para Compradores y Colaboradores Oportunos del SORTEO ANÁHUAC NOVENA EDICIÓN. Debido al número de premios, se irán obteniendo de las mesas los números premiados, previo anuncio del premio correspondiente para cada número de boleto ganador. En caso de que, al momento en que estén corriendo las esferas, alguna se atore, se repetirá el procedimiento. Si se generara más de una vez el mismo número ganador, la persona recibirá el premio de mayor valor al cual se haya hecho acreedor, toda vez que posteriormente se generarán números para premios de menor valor. Adicionalmente se otorgará un premio al colaborador que hayan vendido el boleto ganador del primero, segundo, tercero, cuarto y quinto premio.


                    </p>

                    <p>
                        Por el sistema de control con el que contamos, de manera inmediata, podemos determinar si el número de boleto digital o físico que se ha formado es vendido (y por lo tanto ganador) o no vendido.
                    </p>

                    <ul>
                        <li>
                            Si el boleto es vendido (físico o digital), en ese momento se procederá a dar a conocer el nombre del ganador.
                        </li>
                        <li>
                            Si el boleto es no vendido (físico o digital) en ese momento se procederá a mencionar que el número de boleto es no vendido y se procederá a formar y extraer un nuevo número de boleto y así sucesivamente hasta que haya un ganador
                        </li>
                    </ul>

                    <p>
                        Con respecto a los comprobantes de los boletos ganadores:
                    </p>

                    <ul>
                        <li>
                            Para el caso de los boletos físicos, se imprimirá en una impresora de oficina el talón físico del archivo escaneado en formato PDF proveniente de la sede en donde se haya concentrado el talón físico, esta impresión, llevará la rúbrica del inspector asignado mismos que se mostrarán a la entrega del premio. El talón original quedará en resguardo del Sorteo Anáhuac ya que ese mismo talón, participará en los otros sorteos oportunos y en el sorteo principal denominado SORTEO ANÁHUAC NOVENA EDICIÓN.
                        </li>
                        <li>
                            Para el caso de los boletos digitales, se imprimirá en una impresora de oficina, el boleto digital que consta de una hoja y que servirá de comprobante llevando la rúbrica del inspector asignado mismo que se mostrará a la entrega del premio. 
                        </li>
                    </ul>

                    <p>
                        Al finalizar el evento y al elaborar las actas correspondientes, el Inspector designado por la Dirección General de Juegos y Sorteos, anexará a dichas actas copia simple de la impresión del talón físico del archivo de PDF concentrado en alguna de nuestras sedes y que contiene la rúbrica del inspector asignado en el caso de que haya sido ganador, también anexará copia simple de la impresión (realizada en impresora de oficina) del boleto digital y que contiene la firma del inspector designado.
                    </p>
                  
                </div>
            </div>
        </div>
    </div>
</section>