import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thanks-egresado',
  templateUrl: './thanks-egresado.component.html',
  styleUrls: ['./thanks-egresado.component.scss']
})
export class ThanksEgresadoComponent implements OnInit {
  folio: string;
  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.folio = params['folio'];
    });
  }
  ngOnInit(): void {
  }

}
