import { Component } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  pdfSource = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf "; 
  constructor( private titleService: Title, private metaTagService: Meta) { }
  title = 'Home - Sorteo Anáhuac';
  ngOnInit() {
    window.addEventListener('popstate', (event) => {
    if($("body").hasClass("modal-open")) {
      window.location.reload();
    }

    }, false);
  }

  onActivate(event) {
    window.scroll(0,0);


  }

  

}
