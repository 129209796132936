import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-listaegresados',
  templateUrl: './listaegresados.component.html',
  styleUrls: ['./listaegresados.component.scss']
})
export class ListaegresadosComponent implements OnInit {
//   title = 'Página de registro de Egresados Anáhuac | Sorteo Anáhuac 2022';
//   description = 'Página de registro para Egresados de Universidades Anáhuac. Registrate aquí con matricula y campus. ';
  title = '';
  description = ' ';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    	this.titleService.setTitle(this.title);
		this.metaTagService.updateTag(
			{ name: 'title', content: this.title}
		);
		this.metaTagService.updateTag(
			{ name: 'description', content:  this.description}
		);

		this.metaTagService.updateTag(
				{ property: 'keywords', content: 'Nosotros'}
			);

			this.metaTagService.updateTag(
				{ property: 'og:title', content: this.title}
			);

		this.metaTagService.updateTag(
				{ property: 'og:description', content: this.description}
			);



		this.metaTagService.updateTag(
			{ property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/banner2_about.png' }
		  );
		  
		this.metaTagService.updateTag(
			{ name: 'twitter:title', content: this.title}
		);
		this.metaTagService.updateTag(
			{ name: 'twitter:description', content: this.description}
		);

    
  }
}
