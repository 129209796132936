import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Formulario } from '../models/formulario';
import { Mat } from 'src/models/mat';
import { SorteoOportuno } from 'src/models/sorteoOportuno';
import { Registrouni } from 'src/models/registrouni';
import { GanadoresSorteo } from 'src/models/ganadoresSorteo';
import { LoginGanador } from 'src/models/loginGanador';
import { data } from 'jquery';



@Injectable({
  providedIn: 'root'
})

export class FormEgrService {
  constructor(private http: HttpClient) { }
  
 // private  apiURLSO =   'https://serviciosapp.sorteoanahuac.mx/api/';
  /* TEST   */
  private  apiURL_2 = 'https://serviciossitioapp.sorteoanahuac.mx/api';
  private api = 'https://serviciosapp.sorteoanahuac.mx/api/';
  private  apiURL =   'https://serviciosapp.sorteoanahuac.mx/api/BoletosWeb';
  private urlGandores = 'https://serviciosapp.sorteoanahuac.mx/';

  /*local  
  private apiURL =   'http://localhost:2393/api/BoletosWeb';
  private urlGandores = 'http://localhost:2393/';
  private api = 'http://localhost:2393/api/';
  private  apiURL_2 = 'http://localhost:3000/api';
  */ 

    validaEgresado(mat:Mat) {
      return this.http.post(`${this.api}/Egresado/ValidaEgresado/`,mat);
    }

    getDataStudent(mat:Mat) {
      return this.http.post(`${this.api}/Egresado/GetEgresado/`,mat);
    }
 
    getCampus() {
      return this.http.get(`${this.apiURL}/GetCampus`);
    }
    getCountry() {
      return this.http.get(`${this.apiURL}/Paises`);
    }

    getCanales() {
      return this.http.get(`${this.api}/Colaborador/ObtenerCanales`);
    }
    getCpostal(codP:string) {
      return this.http.get(`${this.apiURL}/Buscar/` + codP);
    }

    
      
    getDataStudentDate(mat:Mat) {
      return this.http.post(`${this.apiURL}/getEgresadosFecha_Nac/`,mat);
    }

    //STEPS
    sendStep(formulario:Formulario,step:string) {
      return this.http.post(`https://serviciossitioapp.sorteoanahuac.mx/api/validateForm/` + step,formulario);
      //return this.http.post(`${this.apiURL_2}/validateForm/` + step,formulario);
    }
    

    //ALL DATA 
    sendDataStudent(formulario:Formulario) {
      //console.log("---- 1");
      //console.log(formulario);
      //return this.http.get(`${this.apiURL}/Paises`);
      return this.http.post(`${this.apiURL}/ActualizacionEgresados/`,formulario);
    }

    getAwards() {
      return this.http.get(`${this.api}/Egresados/premios`);
    }

    /* EGRESADOS */
    getPkEgr( email:String ) {
      return this.http.get(`${this.api}Egresado/ObtienePkEgr?correo=`+email);
    }

    getFoliosEgr( num: number) {
      return this.http.get(`${this.api}Egresado/ObtieneBoletoEgresado?num=`+num);
    }

    blockFoliosEgr(pk_egresado: number, folio: string) {
      return this.http.post(`${this.api}Egresado/RegistroBoletoEgresado`, { pk_egresado: pk_egresado, folio: folio });
    }


    /* FIN EGRESADOS */

    sendSorteoOpt(formulario: SorteoOportuno, tokenS: string) {
      //console.log("---- 2");
      //console.log(formulario);

      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + tokenS
      });
      return this.http.post(`${this.api}/Sorteo/SorteosParticipantes/`,formulario, { headers });
      //return "enviada";
    }

    // sendParticiapntesUniversitarios(formulario: Registrouni){
    //   return this.http.post(`${this.apiURLSO}Colaborador/RegistroSorteosUniversitarios`, formulario);
    // }

    //FORMULARIO GANADORES SORTEO
    token( correo: String, tipo_ganador: string, tipo_seguridad:string  ){
      return this.http.post(`${this.urlGandores}api/usuariosganadores/token`, {"correo":correo,"tipo_ganador":"0","tipo_seguridad":"0"});
    }

    sendDataGanadoresSorteo( dataGanadores: GanadoresSorteo, bearerToken){
      //console.log("AQUI");
      //console.log(dataGanadores);
      //console.log(bearerToken);
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + bearerToken
      });
      return this.http.post(`${this.urlGandores}api/usuariosganadores/registro`, dataGanadores, { headers });
    }

    loginGandores(dataLogin: LoginGanador, bearerToken ) {
      // console.log(dataLogin);
      // console.log(bearerToken);
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + bearerToken
      });
      return this.http.post(`${this.urlGandores}api/usuariosganadores/login`, dataLogin, { headers });
    }


    logOutGanadores(token: string){
      console.log(token);
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + token
        });

        return this.http.post(`${this.urlGandores}api/usuariosganadores/logout`,"", { headers });

      }


      //FORMULARIO SUBIR ARCHIVOS
      sendArchive( file, idArchivo: string){
        let token = sessionStorage.getItem('tokken');


        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + token
        });
        //console.log(httpOptions);
        //console.log(file);
        return this.http.post(`${this.urlGandores}api/usuariosganadores/subirdocumentos?idArchivo=`+ idArchivo, file ,{ headers });
      }

      
  

      //FORMULARIO SUBIR ARCHIVOS
      getArchivosGandor(){
        let token = sessionStorage.getItem('tokken');


        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + token
        });

        return this.http.post(`${this.urlGandores}api/usuariosganadores/GetArchivosGanador`, '' ,{ headers });
      }

      getArchivosGandorUnico(ruta:string){
        let token = sessionStorage.getItem('tokken');
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + token
        });
    
        return this.http.get(`${this.urlGandores}/api/usuariosganadores/obtenerArchivo?fileName=${ruta}`, {
          headers,
          responseType: 'blob' // Esto indica que la respuesta es un archivo binario
        });
      }


      
}
