import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thanks-colaborador',
  templateUrl: './thanks-colaborador.component.html',
  styleUrls: ['./thanks-colaborador.component.scss']
})
export class ThanksColaboradorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
