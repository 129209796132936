export class Direccion_colabora{
    calle: string;
    codigo_postal:string;
    colonia:string;
    estado: string;
    municipio: string;
    pais: string;
    telefono: string;
    telefono_alternativo: string;
    numero_exterior: string;    
    numero_interior: string;    

}