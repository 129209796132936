import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-remanentes',
  templateUrl: './remanentes.component.html',
  styleUrls: ['./remanentes.component.scss']
})
export class RemanentesComponent implements OnInit {
  link;
  linkShare;
	select = false;
  session = false;
  mensaje="";
	mensaje2="";
  title = 'Remanentes del Sorteo Anáhuac 2023 - 8ª Edición';
  description = 'Descubre aquí cómo se usarán los remanentes del Sorteo Anáhuac 8ª Edición.';
  constructor(private titleService: Title, private metaTagService: Meta) { 
    
    if(localStorage.getItem('idColaborador') === null || localStorage.getItem('idColaborador') === ""){
		}else{
			this.session = true;
		}
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'description', content: this.description }
    );
  
    this.metaTagService.updateTag(
      { property: 'keywords', content: ''}
    );
  
    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title}
    );
  
    this.metaTagService.updateTag(
      { property: 'og:description', content: this.description}
    );
  
    this.metaTagService.updateTag(
    { property: 'og:url', content: 'https://www.sorteoanahuac.mx/remanentes' }
    );
  
    this.metaTagService.updateTag(
      { property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/home-n.png' }
    );
      
    this.metaTagService.updateTag(
      { name: 'twitter:title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: this.description }
    );

  }

  sharedLink(mensaje:string){
    this.linkShare = document.location.href;
    this.link = document.location.href;
    this.link = this.link.replace("#", '%23');
    this.mensaje = mensaje;
    this.mensaje2 = mensaje.replace(/ /g, "%20");
	}

	clickbtn(){
		$("#link").select();
		document.execCommand("copy");
		//console.log("texto copiado");
		this.select = true;
	}
	more(){
		window.location.href = "/comparte-contenido";
	}

}
