import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { GanadoresSorteo } from 'src/models/ganadoresSorteo';
import { FormEgrService } from 'src/services/form-egr.service';
import {Md5} from 'ts-md5/dist/md5';


@Component({
  selector: 'app-formwinners',
  templateUrl: './formwinners.component.html',
  styleUrls: ['./formwinners.component.scss']
})
export class FormwinnersComponent implements OnInit {
  dataSorteo: GanadoresSorteo = new GanadoresSorteo();
  codigoPostalVal;
  dataCountry:any = [];
  dataEmptyCP = false;
  selectCP = true;
  isClickCol = true;
  seleccionCol: string = '0';
  flagLastName = false;
  flag = false;
  success = false; 
  mensaje = "";
  country:any = [];
  touchPais: boolean;
  touchColonia: boolean;
  touchGanador: boolean;
  cmpPass: boolean;
  extrajero: string;
  errorG: number;
  //md5 = new Md5();

  constructor(private readonly _formService: FormEgrService, private router: Router,) { }
  
  ngOnInit(): void {
    // this.dataCountry = [
    //   {
    //       "colonia": " ",
    //       "estado": " ",
    //       "municipio": " "
    //   }
    // ];
   (<any>$('#modal-slide')).modal({backdrop: 'static', keyboard: false});
   (<any>$('#modal-slide')).modal('show');

   this.touchPais = false;
   this.touchColonia = false;
   this.touchGanador = false;
   this.cmpPass = false;

    this._formService.getCountry().subscribe( a => {
      this.country = a;
      //console.log(this.country)
    });
    this.dataSorteo.estado = null;
    this.dataSorteo.municipio = null;
  
  }

  changeVal(id: number) {
    if(id == 1) { 
      this.touchPais = true;
      this.extrajero = $("#pais option:selected").text();
      if(this.extrajero == 'México') {
        this.dataSorteo.cp = null;
        this.dataSorteo.estado = null;
        this.dataSorteo.municipio = null;
        this.dataSorteo.colonia = null;
      }
    }
    if(id == 2)  this.touchColonia = true;
    if(id == 3)  this.touchGanador = true;
  }

  validaPass(event: any) {
    let password =  $("#password").val().toString();
    let confPass =  $("#validaPass").val().toString();


    if(password  != confPass ) {
      $("#validaPass").addClass('is-invalid');
      $("#validaPass").removeClass('is-valid');
      this.cmpPass = false;
    }else {
      $("#validaPass").addClass('is-valid');
      $("#validaPass").removeClass('is-invalid');
      this.cmpPass = true;
    }

  }

  sendData(form: NgForm) {
    //console.log(this.dataSorteo);
    if (!this.touchPais) {
      $("#pais").addClass("is-invalid");
    }

    if (!this.touchGanador) {
      $("#tipo_ganador").addClass("is-invalid");
    }


    if(this.extrajero == "México") {
     
      if (!this.touchColonia) {
        $("#colonia").addClass("is-invalid");
      }
     
    }else {
      this.dataSorteo.cp = "00000";
      this.dataSorteo.estado = "Extranjero";
      this.dataSorteo.municipio = "Extranjero";
      this.dataSorteo.colonia = "Extranjero";
    }
    
  
    
    if (form.invalid || !this.cmpPass) {
      (<any>$('#modal-error')).modal('show');
      $("input.ng-invalid, textarea.ng-invalid").addClass("is-invalid");
     
      return ;
    }
    this.dataSorteo.password = String(new Md5().appendStr(this.dataSorteo.password).end());
    
    this.flag = true;
    (<any>$('#modal-registro')).modal('show');

    this._formService.token(this.dataSorteo.correo, "0", "0").subscribe(a => {
      console.log(a);
    },
    
    error => {
      //console.log(error);
      //console.log(error.status);

        if(error.status == 200) {
          //console.log(error.error.text);
          
            this._formService.sendDataGanadoresSorteo(this.dataSorteo,error.error.text).subscribe( a => {
              if(a) {
                this.mensaje = "Registro Exitoso";
                //console.log("se inserto");
                this.flag = false;
                this.success = true;
                this.errorG = 200;
              }
            },
            err => {
              console.log(err);
              if(err.status === 200){
                this.errorG = 200;
                this.mensaje = "Registro Exitoso";
                //console.log("se inserto");
                //this._formService.logOutGanadores(error.error.text).subscribe(a => {console.log(a);});
              }else {
                this.mensaje = err.error;
                //console.log("here");
                this.dataSorteo.password = null;
                $("#validaPass").val("");
                //this._formService.logOutGanadores(error.error.text).subscribe(a => {console.log(a);});
               // location.reload();
              }

            });
        }


        
    });


    this.flag = false;
    this.success = true;
    //console.log(this.dataSorteo);
  }


  getCp(){
    var valor = $('#cp').val();
    this.codigoPostalVal = valor;
    this._formService.getCpostal(valor.toString()).subscribe( a => {
      this.dataCountry = a;
      //console.log( this.dataCountry);
      if( this.dataCountry.length < 1){
          //CP
          jQuery('#cp').addClass('is-invalid');
          jQuery('#cp').removeClass('is-valid');
          //Estado
          jQuery('#estado').addClass('is-invalid');
          jQuery('#estado').removeClass('is-valid');
          //Municipio
          jQuery('#municipio').addClass('is-invalid');
          jQuery('#municipio').removeClass('is-valid');
          this.dataSorteo.estado = null;
          this.dataSorteo.municipio = null;
      }else{
          //CP
          jQuery('#cp').removeClass('is-invalid');
          jQuery('#cp').addClass('is-valid');
          //Estado
          jQuery('#estado').removeClass('is-invalid');
          jQuery('#estado').addClass('is-valid');
          //Municipio
          jQuery('#municipio').removeClass('is-invalid');
          jQuery('#municipio').addClass('is-valid');
          this.dataSorteo.estado = this.dataCountry[0].estado;
          this.dataSorteo.municipio = this.dataCountry[0].municipio;
      }
      this.selectCP = false;
    });
  }


  reloadCurrentPage() {
    window.location.reload();
  }



}
