  <!-- <a (click)="logout();" class="btn-stack ">
                    Cerrar Sesión
                </a> -->
         <!-- <div class="col-md-3 text-center"> 
                        <img *ngIf="!doc1"src="/assets/images/sorteo2022/form/pdf.png" class="img-fluid">
                        <iframe  *ngIf="doc1" [src]="sanitizer.bypassSecurityTrustResourceUrl(selectedPdf1)" width="130px" height="130px" scrolling="no" style="overflow:hidden"></iframe>

                    </div> -->
<section class="banner-docs">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1>Documentación</h1>
            </div>
        </div>
    </div>
</section>


<section class="content-docs">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <nav>
                    <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                        <a class="nav-item nav-link btn-stack  active" id="nav-home-tab" data-toggle="tab" href="#nav-archivos" role="tab" aria-controls="nav-home" aria-selected="true">
                            Envio de archivos
                        </a>
                        <a class="nav-item btn-stack  nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-archivosenviados" role="tab" aria-controls="nav-profile" aria-selected="false" (click)="getDoc()">
                            Archivos enviados
                        </a>
                      
                    </div>
                </nav>
                <div class="tab-content " id="nav-tabContent">
                    <div class="tab-pane fade show active table-responsive" id="nav-archivos" role="tabpanel" aria-labelledby="nav-home-tab ">
                        
                        <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col-3">DOCUMENTO</th>
                                <th scope="col-6">ARCHIVO</th>
                                <th scope="col-3">ENVIAR</th>
                              </tr>
                            </thead>
                            <tbody>
                                <!--documentos -->
                                <tr *ngFor="let doc of docs; let i = index;">
                                    <th class="col-3" >{{ doc.nombreArchivo }}</th>
                                    <td class="col-6" >
                                
                                        <form class="was-validated" *ngIf="doc.required && archivosNewEstruc[i]?.estadoArchivo <= 1">
                                            <input type="file" class="form-control" (change)="capturarFile($event, i)" accept="application/pdf" required />
                                          </form>
                                          <input *ngIf="!doc.required && archivosNewEstruc[i]?.estadoArchivo <= 1" type="file" class="form-control" (change)="capturarFile($event, i)" accept="application/pdf" />
                                        <div *ngIf="flags[i];" class="spinner-border text-primary mt-2 mb-2" role="status" style="color: #f35f44 !important;">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <p  *ngIf=" archivosNewEstruc[i]?.estadoArchivo > 1">
                                            Archivo en revisión no se puede reenviar
                                        </p>
                                        <!-- {{archivosNewEstruc[i].estadoArchivo}} -->
                                    </td>
                                    <td class="col-3" >
                                        <button  class="btn-stack " (click)="subirArchivo(i, doc.idArchivo);"  
                                        [className]="!archivos[i] || flags[i] ? 'disabled' : 'btn-docs '" 
                                        [disabled]="!archivos[i]">
                                           
                                            <span *ngIf="docs[i].send " >Enviado</span>
                                            <span *ngIf=" !docs[i].send " >Enviar documento</span>
                                        </button>
        
                                    </td>
                                </tr>
        
              
        
                            </tbody>
                        </table>

                    </div>


                    <div class="tab-pane fade table-responsive" id="nav-archivosenviados" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col-4">DOCUMENTO</th>
                                <th scope="col-4">ARCHIVO</th>
                                <th scope="col-4">Estado archivo</th>
                              </tr>
                            </thead>
                            <tbody>
                                <!--documentos -->
                               
                                <tr *ngFor="let archivos of archivosNewEstruc; ">
                                    <th class="col-4" >{{ archivos.nombreArchivo }}</th>
                                    <td class="col-4" >
                                        <a (click)="descargarArchivo(archivos.ruta)" target="_blank">
                                     
                                            {{ archivos.archivo }}
                                        </a>
                                    </td>
                                    <td class="col-4" >
                                    <!-- <ng-container *ngIf="archivos.archivo ">
                                        <ul class="list-unstyled multi-steps">
                                            <li [ngClass]="{ 'completed': archivos.estadoArchivo >= 1,'': archivos.estadoArchivo <= 0}">
                                                Envío de archivo
                                            </li>
                                            <li [ngClass]="{ 'completed': archivos.estadoArchivo >= 1,  'active': archivos.estadoArchivo == 1}">
                                                Archivo pendiente de evaluar
                                            </li>
                                            <li [ngClass]="{ 'completed': archivos.estadoArchivo >= 2,  'active': archivos.estadoArchivo == 2}">
                                                Archivo aprobado
                                            </li>
                                            <ng-container *ngIf=" archivos.estadoArchivo == -1">
                                                <li>error</li>
                                            </ng-container>
                                           
                                        </ul>     
                                    </ng-container>
                                                             -->
                
                                    <ul class="list-unstyled multi-steps">
                                        <li [ngClass]="{ 'completed': archivos.estadoArchivo >= 1 || archivos.estadoArchivo == -1,'': archivos.estadoArchivo <= 0}">
                                            Envío de archivo
                                        </li>
                                        <li [ngClass]="{ 'completed': archivos.estadoArchivo > 1 || archivos.estadoArchivo == -1,  'active': archivos.estadoArchivo == 1}">
                                            Archivo recibido
                                        </li>
                                        <li [ngClass]="{ 'completed': archivos.estadoArchivo >= 2 || archivos.estadoArchivo == -1,  'active': archivos.estadoArchivo == 2 }">
                                            Archivo aprobado
                                        </li>
                                        <ng-container *ngIf=" archivos.estadoArchivo == -1">
                                            <li class="error">Error</li>
                                        </ng-container>
                                        
                                    </ul> 
                                        <!-- <img *ngIf="archivos.archivo;" src="/assets/images/sorteo2022/form/comprobado.png" width="30"> -->
        
                                    </td>
                                </tr>
        
              
        
                            </tbody>
                           
                        </table>
                        <div class="w-100 text-center">
                            <div *ngIf="flagArchives" class="spinner-border text-primary mt-2 mb-2" role="status" style="color: #f35f44 !important; margin: auto;">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div *ngIf="flagError" class="alert alert-danger" role="alert">
                            Aún no has subido archivos.
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</section>




<!-- Modal send-->
<div class="modal fade" id="modal-warning" tabindex="-1" role="dialog" aria-labelledby="modal-warning" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-4">

           
            <div class="modal-body text-center">
                <h3>IMPORTANTE</h3>
                <p>Los documentos que cuenten con un contorno rojo SON obligatorios.</p>
                <form class="was-validated">
                    <input  type="file" class="form-control" accept="application/pdf"  required  />
                </form> 

                <p>Los documentos que no cuenten con un contorno rojo NO SON obligatorios.</p>
                <input  type="file" class="form-control" accept="application/pdf"  disabled  />
            </div>
            <div class="modal-footer text-center border-0 justify-content-center">
                <a data-dismiss="modal" class="btn-stack  mb-" >Cerrar</a>
            </div>
        </div>
    </div>
</div>



<div class="content-btn-login">
    <a (click)="logout()"  class="btn-close-login">
        <span class="text">Cerrar Sesión</span>
    </a>
</div>
