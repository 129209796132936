<section class="stc-manu-login">
    <div class="container">
        <div class="row">

            <div class="col-lg-6  my-auto">
                <h1>
                    <span>
                        ¡PASA LA VOZ
                    </span>
                    Y GANA!
                </h1>
            </div>
                <div class="col-lg-6 my-auto">
                    <img src="/assets/images/sorteo2024/pasalavoz/img-banner.jpg" class="img-fluid">
                </div>
            </div>
        </div>
    
</section>


<section class="stc-banner" data-aos="fade-up" data-aos-easing="ease"  data-aos-duration="3000">
    <div class="container">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-center flex-column text-center">
                <p>
                    Te invitamos a pasar la voz e invitar a tus amigos y familiares a ganar con Sorteo Anáhuac, al hacerlo apoyas al desarrollo de la educación en México y favoreces a que nuestro programa de becas siga apoyando a nuestros futuros líderes.
                </p>

                <h2>
                    ¡Entre más compartas más
                    posibilidades tienes de ganar!
                </h2>

                <h3>
                    ¡COMPARTE Y GANA!
                </h3>

            </div>
        </div>
    </div>
</section>


<section class="stc-menu" data-aos="fade-up" data-aos-easing="ease"  data-aos-duration="3000">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 p-0">
                <a [routerLink]="['/postal']">
                    <img src="/assets/images/sorteo2024/pasalavoz/enviopostales.jpg" class="img-fluid">
                 </a>
            </div>
            <div class="col-lg-6 p-0">
                <a [routerLink]="['/comparte']">
                    <img src="/assets/images/sorteo2024/pasalavoz/redes.jpg" alt="">
                 </a>
            
            </div>
                
            <div class="col-lg-6 p-0">
                <a [routerLink]="['/comparte-contenido']">
                    <img src="/assets/images/sorteo2024/pasalavoz/compartirliga.jpg" alt="">
                 </a>
            </div>
            
            <div class="col-lg-6 p-0">
                <a [routerLink]="['/consulta-boletos']">
                    <img src="/assets/images/sorteo2024/pasalavoz/boleto.jpg" alt="">
                 </a>
            </div>
        </div>
    </div>
</section>


