

export class formularioNewEgr{
    IDBanner: string;
    Alumno: string;
    Campus: string;
    DescripcionPrograma: string;
    Generaciones: string;
    Nivel: string;
    LadaTelPrincipal: string;
    TelefonoPrincipal: string;
    Apellidos: string;
    SegundoNombre: string;
    NombreDePila: string;


    Ocupaciones: any[];
    Telefonos: any[];
    Direcciones: any[];
    Redes: any[];
    FechaNacimiento: string;
}