
import { Fechas_importantes } from 'src/models/fechas_importantes';
export class Formulario{
    nacimiento: Date;

    red_anahuac= false
    red_semper_altius: boolean ;
    hijos= false

    extranjero:boolean;
    premios:number;
    graduacion: number;
    fechas_importantes?: Fechas_importantes[];
    egresado_referido?:string;
    generacion:string;
    otras_escuelas:string;
    empresa:string;
    ocupacion:string;
    celular:string;
    area_celular: string;
    telefono_casa:string;
    telefonoprincipal: string;
    ladatelprincipal: string; 
    area_telefono: string;
    calle:string;
    colonia:string;
    cp:string;
    municipio:string;
    estado?:string;
    pais:string;
    instagram:string;
    twitter:string;
    facebook:string;
    linkedin:string;
    email:string;
    nombrecompleto:string;
    nombrepila: string;
    segundonombre: string;
    apellidos: string;
    carrera:string;
    codigocampus: string;
    campus:string;
    matricula:string;
    yes?:boolean;
    
    fecha:string;
    descripcion_fecha:string;

    ciudad:string;
    
    nivel:string;
    
    recurrente: number;

    constructor() {
        this.red_anahuac= false
        this.red_semper_altius = false
        this.hijos = false

        this.extranjero = false;
        this.premios= 0;
        this.graduacion= 0;

        this.generacion= "";
        this.otras_escuelas= "";
        this.empresa= "";
        this.ocupacion= "";
        this.celular= "";
        this.area_celular= "";
        this.telefono_casa= "";
        this.telefonoprincipal= "";
        this.ladatelprincipal= ""; 
        this.area_telefono= "";
        this.calle= "";
        this.colonia= "";
        this.cp= "";
        this.municipio= "";

        this.pais= "";
        this.instagram= "";
        this.twitter= "";
        this.facebook= "";
        this.linkedin= "";
        this.email= "";
        this.nombrecompleto= "";
        this.nombrepila= "";
        this.segundonombre= "";
        this.apellidos= "";
        this.carrera= "";
        this.codigocampus= "";
        this.campus= "";
        this.matricula= "";


        this.fecha= "";
        this.descripcion_fecha= "";

        this.ciudad= "";
        this.recurrente= 0;
    }
}






