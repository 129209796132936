<section class="stc-msi">
    <div class="container">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-center flex-column " data-aos="fade-left" data-aos-easing="ease"  data-aos-duration="3000">
                <img src="/assets/images/sorteo2024/msi.png" alt="MSI" class="img-fluid">
            </div>
        </div>
    </div>
</section>


<section class="container-msi">
    <div class="container">
        <div class="row"> 
            <div class="col-md-12 text-center">
                <p>
                    Compra ahora y paga tus boletos a meses sin intereses. Esta promoción es exclusiva para tarjetas participantes y en compras directas en la <strong>máquina de la suerte</strong> de nuestra página web.
                </p>


                <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/compra-en-linea-boletos-sorteo-anahuac{{utmUrl}}" target="_blank" class="btn-stack">
                    ¡Compra aquí a
                    meses sin intereses!
                </a>


                <p>
                    Vigencia de la promoción del 27 de marzo al 21 de noviembre 2024.
                </p>
            </div>
        </div>
    </div>
</section>


<section class="stc-cards">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center">

                <img src="/assets/images/sorteo2023/santander-msi.png" class="img-fluid" alt="Santander">
                
                <img src="/assets/images/sorteo2023/banorte.png" class="img-fluid" alt="Banorte">

                <img src="/assets/images/sorteo2023/scotiabank-msi.png" class="img-fluid" alt="Scotiabank">
                <img src="/assets/images/sorteo2023/hsbc.png" class="img-fluid" alt="HSBC">
                <img src="/assets/images/sorteo2023/banjercito.png" class="img-fluid" alt="Banjercito">
                <img src="/assets/images/sorteo2023/liverpool.png" class="img-fluid" alt="Liverpool">
                <img src="/assets/images/sorteo2023/banco-azteca.png" class="img-fluid" alt="Banco Azteca">

                
            </div>
        </div>
    </div>
</section>


<section class="stc-time">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h2>
                    ¡meses sin intereses!
                </h2>
            </div>

            <div class="col-lg-3 col-md-6 text-center">
                <img src="/assets/images/sorteo2023/meses1.jpg" class="img-fluid" alt="MSI">
                <p>
                    $450.00
                </p>
            </div>

            <div class="col-lg-3 col-md-6 text-center">
                <img src="/assets/images/sorteo2023/meses2.jpg" class="img-fluid" alt="MSI">
                <p>
                    $900.00
                </p>
            </div>

            <div class="col-lg-3 col-md-6 text-center">
                <img src="/assets/images/sorteo2023/meses3.jpg" class="img-fluid" alt="MSI">
                <p>
                    $1350.00
                </p>
            </div>

            <div class="col-lg-3 col-md-6 text-center">
                <img src="/assets/images/sorteo2023/meses4.jpg" class="img-fluid" alt="MSI">
                <p>
                    $1800.00
                </p>
            </div>

        </div>
    </div>
</section>


<section class="msi-privacidad">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3> TÉRMINOS Y CONDICIONES DE USO DE LOS MESES SIN INTERESES.</h3>
                <ul>
                    <li>
                        Únicamente boletos comprados en máquina de la suerte. 
                    </li>
                    <li>
                        Compras a través de la App de Sorteo Anáhuac y boletos físicos no participan en este promoción.  
                    </li>
                    <li>
                        CAT 0% Informativo.
                    </li>
                    <li>
                        Consulta bancos participantes antes de finalizar tu pago.
                    </li>
                    <li>
                        Reclamaciones: Cualquier reclamación por no aplicar bien los meses sin intereses deberá ser reportada al banco emisor de la tarjeta.
                        Promoción está sujeta a cambio sin previo aviso.
                    </li>
                </ul>
                <h3>
                    INFORMACIÓN RELEVANTE
                </h3>
                <p>
                    Es requisito necesario para la adquisición de los Boletos del Sorteo Anáhuac, que lea y acepte los siguientes Términos y Condiciones que a continuación se redactan. 
                </p>
                <p>
                    La compra de los Boletos implicará que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento. 
                    Todas las compras de los Boletos que se lleven a cabo por medio del sitio web https://sorteoanahuaccompra.sorteoanahuac.mx/carrito-compra  están sujetas a un proceso de confirmación y verificación, el cual se realiza de forma inmediata. 
                    Los precios de los Boletos y los meses sin intereses ofrecidos son válidos solamente en las compras realizadas en el sitio web https://sorteoanahuaccompra.sorteoanahuac.mx/carrito-compra  
                </p>
                <h3>
                    AVISO DE PRIVACIDAD
                </h3>
                <p>
                    Este sitio web https://sorteoanahuaccompra.sorteoanahuac.mx/carrito-compra, garantiza que la información personal que usted envía cuenta con la seguridad necesaria. Para más información consulte: https://www.sorteoanahuac.mx/aviso-de-privacidad
                </p>
            </div>
        </div>
    </div>
</section>
