import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comparte-text',
  templateUrl: './comparte-text.component.html',
  styleUrls: ['./comparte-text.component.scss']
})
export class ComparteTextComponent implements OnInit {

  constructor() {
    if(localStorage.getItem('idColaborador') === null || localStorage.getItem('idColaborador') === ""){
      window.location.href = "/home";
    }
   
   
   }

  ngOnInit(): void {
    (<any>$('#modal-thank')).modal('hide');
  }

}
