<section id="stc-egresados">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <img src="/assets/images/sorteo2024/texto-egresados.png" class="img-fluid">
      </div>
    </div>
  </div>
</section>


<section class="conteainer-form form-val">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 ">
        <div class="content-fall content-egresado">

          <ul class="nav nav-tabs list-form">
            <li><a data-toggle="tab" class="active border-init" href="#academico">Datos Académicos</a></li>
            <li><a data-toggle="tab" class="dis-menu" href="#general">Datos Personales</a></li>
            <li><a data-toggle="tab" class="dis-menu" href="#ocupacion">Ocupación</a></li>
            <!-- <li><a data-toggle="tab" class="dis-menu " href="#fecha">Otra información</a></li> -->
          </ul>
          <div class="text-center mt-4 mb-5">
            <p style="font-size: 20px;line-height: 1; color: #ff4700;"><strong class="f-bold">Nota: Los campos en fondo
                gris no pueden ser modificados.</strong></p>
          </div>
          <form #dataEgrAll="ngForm" (ngSubmit)="validateDataAll(dataEgrAll)" class="form-all" id="f"
            (keydown.enter)="$event.preventDefault()">
            <div class="tab-content mt-5">
              <div id="academico" class="tab-pane fade active show">

                <div class="form-row">
                  <div class="form-group col-lg-6 col-md-12 ">
                    <label class="nombre-label" for="nombrecompleto">Nombre Completo:</label>
                    <input type="text" class="form-control is-data-req f-bold" nombrecompleto ngModel
                      id="nombrecompleto" name="nombrecompleto" placeholder="{{formulario.nombrecompleto}}"
                      #nombrecompleto="ngModel" [(ngModel)]="formulario.nombrecompleto" readonly disabled>

                  </div>
                  <div class="form-group col-md-6  ">
                    <label class="calendar-label" for="nacimiento">Fecha de Nacimiento:</label>
                    <input style="display: none;" type="text" class="form-control" id="nacimiento"
                      placeholder="{{formulario.nacimiento  }}" nacimiento ngModel #nacimiento="ngModel"
                      name="nacimiento" [(ngModel)]="formulario.nacimiento" readonly disabled>
                    <p style="font-size: 18px;padding:6px 12px; background: #e6e6e6 !important;
                                        color: #9e9e9e !important;
                                        cursor: no-drop !important; border-radius: 100px;">
                      {{localTime | date:'dd/MM/yyyy' }}</p>
                  </div>

                  <!--IDreferido-->
                  <div class="form-group col-md-6 d-none">
                    <!-- <label for="egresado_referido">IdReferido</label > -->
                    <input type="text" class="form-control is-data-req f-bold " id="egresado_referido"
                      placeholder="{{formulario.egresado_referido }} " egresado_referido ngModel
                      #egresado_referido="ngModel" name="egresado_referido" [(ngModel)]="formulario.egresado_referido"
                      readonly disabled>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="matricula">Matrícula</label>
                    <input type="text" class="form-control is-data-req f-bold" id="matricula"
                      placeholder="{{formulario.matricula }} " matricula ngModel #matricula="ngModel" name="matricula"
                      [(ngModel)]="formulario.matricula" readonly disabled>
                   
                  </div>
                  <div class="form-group col-md-6">
                    <label for="campus">Campus</label>
                    <input type="text" class="form-control is-data-req f-bold" id="campus"
                      placeholder="{{formulario.campus }} " campus ngModel #campus="ngModel" name="campus"
                      [(ngModel)]="formulario.campus" readonly disabled>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="carrera">Carrera del primer programa cursado en tu alma mater.</label>
                    <input type="text" class="form-control is-data-req f-bold" id="carrera"
                      placeholder="{{formulario.carrera }} " carrera ngModel #carrera="ngModel" name="carrera"
                      [(ngModel)]="formulario.carrera" readonly disabled>
                      <small>
                        En caso que no aparezca tu programa de estudios cursado, esto no será un impedimento para completar tu registro. 
                      </small>
                  </div>
                  <!-- <div class="form-group col-lg-6 col-md-6">
                                <label for="generacion">Año Graduación </label >
                                <input type="text" class="form-control is-data-req f-bold"  id="generacion" placeholder="{{formulario.generacion }} "  generacion ngModel #generacion="ngModel" #generacion name="generacion" [(ngModel)]="formulario.generacion" readonly disabled> 
                              </div> -->

                </div>

                <div class="form-row">
                  <div class="form-group col-lg-12 col-md-12">
                    <label for="email">Correo en donde llegará tu folio de participación y en donde recibirás
                      información de tu campus.</label>
                    <input (input)="validaCorreo($event)" type="email" class="form-control" id="email"
                      placeholder="Ingrese correo " email ngModel #email="ngModel" name="email"
                      [(ngModel)]="formulario.email">
                    <div *ngIf="!formulario.email" class="alert alert-danger">
                      Dato requerido
                    </div>

                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-lg-12 col-md-12">
                    <label for="carrera">Confirmar nuevo correo</label>
                    <input (input)="validaCorreo($event)" type="email" class="form-control" id="email2"
                      placeholder="Confirma tu correo ">
                    <div *ngIf="!validateEmail2" class="alert alert-danger">
                      Correos diferentes
                    </div>
                    <div *ngIf="validateEmail2" class="alert alert-success" role="alert">
                      Correos iguales
                    </div>
                  </div>
                </div>


                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="linkedin">LinkedIn</label>
                    <input type="text" class="form-control" id="linkedin" placeholder="{{formulario.linkedin }} "
                      linkedin ngModel #linkedin="ngModel" name="linkedin" [(ngModel)]="formulario.linkedin" required
                      (input)="validaLinkdln($event)">
                    <div *ngIf="!formulario.linkedin" class="alert alert-danger">
                      Dato requerido (en caso de no tener colocar NA)
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="facebook">Facebook</label>
                    <input type="text" class="form-control" id="facebook" placeholder="{{formulario.facebook }} "
                      facebook ngModel #facebook="ngModel" name="facebook" [(ngModel)]="formulario.facebook">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="twitter">Twitter</label>
                    <input type="text" class="form-control" id="twitter" placeholder="{{formulario.twitter }} " twitter
                      ngModel #twitter="ngModel" name="twitter" [(ngModel)]="formulario.twitter">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="instagram">Instagram</label>
                    <input type="text" class="form-control" instagram ngModel id="instagram" name="instagram"
                      placeholder="{{formulario.instagram }} " #instagram="ngModel" [(ngModel)]="formulario.instagram">
                  </div>
                </div>

                <div class="conten-btn text-center pt-4">
                  <button class="btn-d mb-2" type="button" data-toggle="modal" data-target="#modalInfo">Mis datos son
                    incorrectos/ No soy yo</button><br>
                  <a class="btn-d etp1 " (click)="step1();">Siguiente</a>
                </div>


              </div>
              <!--end tab 1-->
              <!--tab 2-->

              <div id="general" class="tab-pane fade ">
                <!--tab 3-->

                <div class="form-row pt-3">
                  <div class="form-group col-md-12 mb-0">
                    <label>Dirección de Casa</label>
                  </div>
                </div>
                <div class="form-row pt-3">
                  <div class="form-group col-md-6">
                    <label class="pt-2 is-visible" for="pais">País</label>
                    <select class="form-control " required pais ngModel id="pais" name="pais" #pais="ngModel"
                      [(ngModel)]="formulario.pais">
                      <option *ngFor="let item of country" value="{{item}}">{{ item}}</option>
                    </select>

                    <div *ngIf="!formulario.pais" class="alert alert-danger">
                      Dato requerido
                    </div>
                    <label class="pt-2 sub-l " for="pais">País</label>
                  </div>
                  <ng-container *ngIf="formulario.pais === 'México' || formulario.pais === 'Mexico'; else ext ">

                    <div class="form-group col-md-4">
                      <label class="pt-2 is-visible">Código Postal</label>

                      <div class="input-group ">
                        <input type="text" class="form-control" required id="cp" placeholder="{{formulario.cp }} " cp
                          ngModel #cp="ngModel" name="cp" [(ngModel)]="formulario.cp">
                        <div class="input-group-prepend">
                          <span (click)="getCp();" class="input-group-text"
                            style=" background: linear-gradient(to right, #ff4700 0%, #ff4700 100%);color: #fff;cursor:pointer;z-index: 5; margin-left: -40px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-search" viewBox="0 0 16 16">
                              <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z">
                              </path>
                            </svg>
                          </span>
                        </div>

                      </div>

                      <label class="pt-2 sub-l" for="cp">Código Postal</label>
                      <div *ngIf="!formulario.cp " class="alert alert-danger">
                        Dato requerido
                      </div>
                    </div>
                    <div class="form-group col-md-2">
                      <a class="btn btn-active" (click)="getCp();">Buscar</a>
                    </div>





                    <div class="form-group col-md-12">
                      <ng-container *ngIf="dataEmptyCP || bandCp">
                        <div class="alert alert-danger">
                          Codigo postal incorrecto
                        </div>
                      </ng-container>
                    </div>
                    <!-- <ng-container *ngIf="selectCP"> -->
                    <ng-container *ngIf="selectCP">
                      <div class="form-group col-md-4">
                        <label class="pt-2 is-visible">Estado</label>
                        <input type="text" class="form-control dis-menu" required id="estado"
                          value="{{formulario.estado }}" estado ngModel #estado="ngModel" name="estado"
                          [(ngModel)]="formulario.estado" disabled readonly>
                        <label class="pt-2 sub-l" for="estado">Estado </label>
                        <!-- <div *ngIf="!formulario.estado " class="alert alert-danger">
                                            Dato requerido
                                        </div> -->
                      </div>

                      <div class="form-group col-md-4">
                        <label class="pt-2 is-visible">Municipio</label>
                        <input type="text" class="form-control dis-menu" required id="municipio"
                          value="{{formulario.municipio }} " municipio ngModel #municipio="ngModel" name="municipio"
                          [(ngModel)]="formulario.municipio" disabled readonly>
                        <label class="pt-2 sub-l" for="municipio">Municipio</label>
                        <!-- <div *ngIf="!formulario.municipio " class="alert alert-danger">
                                            Dato requerido
                                        </div> -->
                      </div>
                    </ng-container>

                    <ng-container *ngIf="!selectCP">
                      <div class="form-group col-md-4">
                        <label class="pt-2 is-visible">Estado</label>
                        <input type="text" class="form-control dis-menu" required id="estado"
                          placeholder="{{dataCountry[0].estado }}" estado ngModel #estado="ngModel" name="estado"
                          [(ngModel)]="dataCountry[0].estado" disabled readonly>
                        <label class="pt-2 sub-l" for="estado">Estado </label>
                        <!-- <div *ngIf="!dataCountry.estado" class="alert alert-danger">
                                                Dato requerido
                                            </div> -->
                      </div>

                      <div class="form-group col-md-4">
                        <label class="pt-2 is-visible">Municipio</label>
                        <input type="text" class="form-control dis-menu" required d="municipio"
                          placeholder="{{dataCountry[0].municipio }} " municipio ngModel #municipio="ngModel"
                          name="municipio" [(ngModel)]="dataCountry[0].municipio" disabled readonly>
                        <label class="pt-2 sub-l" for="municipio">Municipio</label>
                        <!-- <div *ngIf="!dataCountry.municipio " class="alert alert-danger">
                                                Dato requerido
                                            </div> -->
                      </div>
                    </ng-container>

                    <div class="form-group col-md-4">
                      <label class="pt-2 is-visible">Colonia</label>
                      <ng-container *ngIf="isClickCol && selectCP">
                        <input (click)="isClickInp()" type="text" class="form-control" required id="colonia"
                          placeholder="{{formulario.colonia }} " colonia ngModel #colonia="ngModel" name="colonia"
                          [(ngModel)]="formulario.colonia">
                      </ng-container>
                      <ng-container *ngIf="!isClickCol || !selectCP">
                        <select class="form-control" required colonia ngModel id="colonia" name="colonia"
                          #colonia="ngModel" [(ngModel)]="formulario.colonia">
                          <option value="0">Seleccionar</option>
                          <option *ngFor="let item of dataCountry" value="{{item.colonia}}">{{ item.colonia}}</option>
                        </select>
                      </ng-container>
                      <label class="pt-2 sub-l " for="pais">Colonia</label>
                      <div *ngIf="!formulario.colonia || formulario.colonia === '0'" class="alert alert-danger">
                        Dato requerido
                      </div>

                    </div>

                    <!-- <div class="form-group col-md-12">
                      <label class="pt-2 is-visible">Calle y número</label>

                      <input type="text" class="form-control" required id="calle" placeholder="{{formulario.calle }} "
                        required calle ngModel #calle="ngModel" name="calle" [(ngModel)]="formulario.calle">
                      <label class="pt-2 sub-l" for="calle">Calle y número</label>
                      <div *ngIf="!formulario.calle " class="alert alert-danger">
                        Dato requerido
                      </div>
                    </div> -->

                    <div class="form-row pt-3">
                      <div class="col-lg-6 form-group">
                        <label class="tel-label" for="celular">Número celular</label>
                        <div class="row">
                          <div class="form-group col-md-4">
                            <label class="pt-2 is-visible">Código de país</label>
                            <input maxlength="3" (input)="inputValidator($event)" type="text"
                              class="form-control d-none" id="area_celular" placeholder="{{formulario.area_celular }} "
                              area_celular ngModel #area_celular="ngModel" name="area_celular"
                              [(ngModel)]="formulario.area_celular" autocomplete="new-calle">
                            <input maxlength="3" (input)="inputValidator($event)" type="text" class="form-control"
                              required id="area_celular" placeholder="{{formulario.area_celular }} " area_celular
                              ngModel #area_celular="ngModel" name="area_celular" [(ngModel)]="formulario.area_celular"
                              autocomplete="new-calle">
                            <label class="pt-2 sub-l" for="lada">Código de país</label>
                            <div *ngIf="!formulario.area_celular || area_celular.invalid" class="alert alert-danger">
                              Dato requerido
                            </div>
                          </div>
                          <div class="form-group col-md-8">
                            <label class="pt-2 is-visible">Número a 10 dígitos</label>
                            <input minlength="10" maxlength="10" (input)="inputValidator($event)" type="text"
                              class="form-control" required id="celular" placeholder="{{formulario.celular }} " required
                              celular ngModel #celular="ngModel" name="celular" [(ngModel)]="formulario.celular"
                              autocomplete="new-celular">
                            <label class="pt-2 sub-l" for="number-ten">Número a 10 dígitos</label>
                            <div *ngIf="!formulario.celular || celular.invalid " class="alert alert-danger">
                              Dato requerido ({{formulario.celular.length }}/10)
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-6 form-group">
                        <label class="tel-label" for="telefono_casa">Número casa</label>
                        <div class="row">
                          <div class="form-group col-md-4">
                            <label class="pt-2 is-visible">Código de país</label>
                            <input maxlength="3" (input)="inputValidator($event)" type="text" class="form-control"
                              id="area_telefono" placeholder="{{formulario.area_telefono }} " area_telefono ngModel
                              #area_telefono="ngModel" name="area_telefono" [(ngModel)]="formulario.area_telefono"
                              autocomplete="new-area_telefono">
                            <label class="pt-2 sub-l" for="lada">Código de país</label>
                            <div *ngIf=" area_telefono.invalid" class="alert alert-danger">
                              Formato invalido
                            </div>
                          </div>
                          <div class="form-group col-md-8">
                            <label class="pt-2 is-visible">Número de teléfono</label>
                            <input maxlength="10" (input)="inputValidator($event)" type="text" class="form-control"
                              id="telefono_casa" placeholder="{{formulario.telefono_casa }} " telefono_casa ngModel
                              #telefono_casa="ngModel" name="telefono_casa" [(ngModel)]="formulario.telefono_casa"
                              autocomplete="new-telefono_casa">
                            <label class="pt-2 sub-l" for="number-ten">Número de teléfono</label>
                            <div *ngIf=" telefono_casa.invalid" class="alert alert-danger">
                              Formato invalido
                            </div>
                          </div>
                        </div>
                      </div>



                      <div class="col-lg-12 form-group">
                        <label class="tel-label" for="ladatelprincipal">Número preferido </label>
                        <div class="row">
                          <div class="form-group col-md-2">
                            <label class="pt-2 is-visible">Código de país</label>
                            <input maxlength="3" (input)="inputValidator($event)" type="text" class="form-control"
                              id="ladatelprincipal" placeholder="{{formulario.ladatelprincipal }} " ladatelprincipal
                              ngModel #ladatelprincipal="ngModel" name="ladatelprincipal"
                              [(ngModel)]="formulario.ladatelprincipal" autocomplete="new-ladatelprincipal">
                            <label class="pt-2 sub-l" for="lada">Código de país</label>
                            <div *ngIf="!formulario.ladatelprincipal || ladatelprincipal.invalid"
                              class="alert alert-danger">
                              Dato requerido
                            </div>
                          </div>
                          <!-- mex -->
                          <div class="form-group col-md-4">
                            <label class="pt-2 is-visible">Número de teléfono</label>
                            <input minlength="10" maxlength="10" (input)="inputValidator($event)" type="text"
                              class="form-control" id="telefonoprincipal"
                              placeholder="{{formulario.telefonoprincipal }} " telefonoprincipal ngModel
                              #telefonoprincipal="ngModel" name="telefonoprincipal"
                              [(ngModel)]="formulario.telefonoprincipal" autocomplete="new-telefonoprincipal">
                            <label class="pt-2 sub-l" for="number-ten">Número a 10 dígitos</label>
                            <div *ngIf="!formulario.telefonoprincipal || telefonoprincipal.invalid "
                              class="alert alert-danger">
                              Dato requerido ({{formulario.telefonoprincipal.length }}/10 )
                            </div>
                          </div>
                        </div>
                      </div>




                    </div>



                  </ng-container>

                  <ng-template #ext>
                    <div class="form-group col-md-12">
                      <label class="pt-2 is-visible">Ingrese dirección</label>
                      <input type="text" class="form-control" required id="calle" placeholder="{{formulario.calle }} "
                        required calle ngModel #calle="ngModel" name="calle" [(ngModel)]="formulario.calle"
                        autocomplete="new-calle">
                      <label class="pt-2 sub-l" for="calle">Ingrese dirección</label>
                      <div *ngIf="!formulario.calle " class="alert alert-danger">
                        Dato requerido
                      </div>
                    </div>

                    <div class="form-row pt-3">
                      <div class="col-lg-6 form-group">
                        <label class="tel-label" for="celular">Número celular</label>
                        <div class="row">
                          <div class="form-group col-md-4">
                            <label class="pt-2 is-visible">Código de país</label>
                            <input maxlength="3" (input)="inputValidator($event)" type="text"
                              class="form-control d-none" id="area_celular" placeholder="{{formulario.area_celular }} "
                              area_celular ngModel #area_celular="ngModel" name="area_celular"
                              [(ngModel)]="formulario.area_celular" autocomplete="new-calle">
                            <input maxlength="3" (input)="inputValidator($event)" type="text" class="form-control"
                              required id="area_celular" placeholder="{{formulario.area_celular }} " area_celular
                              ngModel #area_celular="ngModel" name="area_celular" [(ngModel)]="formulario.area_celular"
                              autocomplete="new-calle">
                            <label class="pt-2 sub-l" for="lada">Código de país</label>
                            <div *ngIf="!formulario.area_celular || area_celular.invalid" class="alert alert-danger">
                              Dato requerido
                            </div>
                          </div>
                          <div class="form-group col-md-8">
                            <label class="pt-2 is-visible">Ingrese su número </label>
                            <input minlength="5" maxlength="20" (input)="inputValidator($event)" type="text"
                              class="form-control" required id="celular" placeholder="{{formulario.celular }} " required
                              celular ngModel #celular="ngModel" name="celular" [(ngModel)]="formulario.celular"
                              autocomplete="new-celular">
                            <label class="pt-2 sub-l" for="number-ten">Ingrese su número</label>
                            <div *ngIf="!formulario.celular || celular.invalid " class="alert alert-danger">
                              Dato requerido
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 form-group">
                        <label class="tel-label" for="telefono_casa">Número casa</label>
                        <div class="row">
                          <div class="form-group col-md-4">
                            <label class="pt-2 is-visible">Código de país</label>
                            <input maxlength="3" (input)="inputValidator($event)" type="text" class="form-control"
                              id="area_telefono" placeholder="{{formulario.area_telefono }} " area_telefono ngModel
                              #area_telefono="ngModel" name="area_telefono" [(ngModel)]="formulario.area_telefono"
                              autocomplete="new-area_telefono">
                            <label class="pt-2 sub-l" for="lada">Código de país</label>
                            <div *ngIf=" area_telefono.invalid" class="alert alert-danger">
                              Formato invalido
                            </div>
                          </div>
                          <div class="form-group col-md-8">
                            <label class="pt-2 is-visible">Número de teléfono</label>
                            <input maxlength="20" (input)="inputValidator($event)" type="text" class="form-control"
                              id="telefono_casa" placeholder="{{formulario.telefono_casa }} " telefono_casa ngModel
                              #telefono_casa="ngModel" name="telefono_casa" [(ngModel)]="formulario.telefono_casa"
                              autocomplete="new-telefono_casa">
                            <label class="pt-2 sub-l" for="number-ten">Número de teléfono </label>
                            <div *ngIf=" telefono_casa.invalid" class="alert alert-danger">
                              Formato invalido
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-12 form-group">
                        <label class="tel-label" for="ladatelprincipal">Número preferido</label>
                        <div class="row">
                          <div class="form-group col-md-2">
                            <label class="pt-2 is-visible">Código de país</label>
                            <input maxlength="3" (input)="inputValidator($event)" type="text" class="form-control"
                              id="ladatelprincipal" placeholder="{{formulario.ladatelprincipal }} " ladatelprincipal
                              ngModel #ladatelprincipal="ngModel" name="ladatelprincipal"
                              [(ngModel)]="formulario.ladatelprincipal" autocomplete="new-ladatelprincipal">
                            <label class="pt-2 sub-l" for="lada">Código de país</label>
                            <div *ngIf="!formulario.ladatelprincipal || ladatelprincipal.invalid"
                              class="alert alert-danger">
                              Dato requerido
                            </div>
                          </div>
                          <!-- primer telefono -->
                          <div class="form-group col-md-4">
                            <label class="pt-2 is-visible">Número de teléfono</label>
                            <input maxlength="20" (input)="inputValidator($event)" type="text" class="form-control"
                              id="telefonoprincipal" placeholder="{{formulario.telefonoprincipal }} " telefonoprincipal
                              ngModel #telefonoprincipal="ngModel" name="telefonoprincipal"
                              [(ngModel)]="formulario.telefonoprincipal" autocomplete="new-telefonoprincipal">
                            <label class="pt-2 sub-l" for="number-ten">Número de teléfono</label>
                            <div *ngIf="!formulario.telefonoprincipal || telefonoprincipal.invalid "
                              class="alert alert-danger">
                              Dato requerido
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </ng-template>

                  <!--estart info country
                            <ng-container *ngIf="flagCountry">-->

                  <!--   </ng-container>
                             end  info country-->

                </div>
                <!--End row-->




                <div class="conten-btn text-center">
                  <a (click)="step2();" class="btn-d etp2 tabs-all">Siguiente</a>
                </div>
              </div>
              <!--end tab 2-->
              <div id="ocupacion" class="tab-pane fade ">
                <div class="form-row pt-3">
                  <div class="form-group col-md-6">
                    <label for="ocupacion">Ocupación:</label>
                    <input type="text" class="form-control" id="ocupacion" placeholder="{{formulario.ocupacion }} "
                      required ocupacion ngModel #ocupacion="ngModel" name="ocupacion"
                      [(ngModel)]="formulario.ocupacion" autocomplete="new-ocupacion">
                    <div *ngIf="!formulario.ocupacion " class="alert alert-danger">
                      Dato requerido
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="empresa">Nombre de la empresa</label>
                    <input type="text" class="form-control" id="empresa" placeholder="{{formulario.empresa }} " required
                      empresa ngModel #empresa="ngModel" name="empresa" [(ngModel)]="formulario.empresa"
                      autocomplete="new-empresa">
                    <div *ngIf="!formulario.empresa" class="alert alert-danger">
                      Dato requerido
                    </div>
                  </div>


                  <div class="form-group col-md-12 col-sm-12 col-12  my-auto pt-4 pb-4">
                    <div class="check-title">
                      <label>Aviso de Privacidad: Sorteo Anáhuac A.C., con domicilio en Ave. Universidad Anáhuac no. 46
                        Huixquilucan, Edo. de México C.P. 52786, utilizará sus datos personales aquí
                        recabados para actualizar la base de datos de los egresados de la Red de Universidades Anáhuac.
                        Para más información acerca del tratamiento de sus datos personales y de los derechos que puede
                        hacer valer, favor de consultar nuestro Aviso de Privacidad integral.
                        <br>* Conozco el <a target="_blank" class="site" [routerLink]="['/aviso-de-privacidad']">Aviso
                          de
                          Privacidad</a> y otorgo mi Consentimiento para el Tratamiento de mis Datos Personales.
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input (click)="disabledCheck();" class="form-check-input" type="checkbox" id="yes" required yes
                        ngModel #yes="ngModel" name="yes">
                      <label for="yes-con">Aceptar</label>

                    </div>
                    <div *ngIf="checkError" class="alert alert-danger">
                      Dato requerido
                    </div>
                  </div>

                  <div class="conten-btn text-center pt-4 w-100">
                    <a class="btn-d etp1 pt-2 mt-2" (click)="step3();">Enviar</a>
                    <!-- <button type="submit" class="btn btn-primary mb-2 btn-send-all btn-main">Enviar</button> -->
                  </div>
                </div>
                <!-- <div class="conten-btn text-center">
                  <a (click)="step3();" class="btn-d etp3">Siguiente</a>
                </div> -->
              </div>
              <!--end tab 3-->


              <!-- <div id="fecha" class="tab-pane fade ">
                <div class="form-row pt-3 date-all-sl">
                  <div class="col-12 pb-3">
                    <label>Compártenos una fecha importante para ti y después da clic en <span
                        class="f-bold">Confirmar</span></label>

                  </div>
                  <div class="form-group col-md-3">
                    <label class="pt-2 is-visible">Evento</label>
                    <input (input)="activeHtml($event)" type="text" class="form-control df" id="descripcion_fecha"
                      descripcion_fecha ngModel #descripcion_fecha="ngModel" name="descripcion_fecha"
                      [(ngModel)]="formulario.descripcion_fecha" autocomplete="new-descripcion_fecha">
                    <label class="pt-2 sub-l" for="descripcion_fecha">Evento</label>

                  </div>

                  <div class="form-group col-md-4">
                    <label class="pt-2 is-visible">Fecha</label>
                    <input (input)="activeHtml($event)" type="date" placeholder="dd/mm/yyyy"
                      pattern="^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$" class="form-control f"
                      id="fecha" fecha ngModel #fecha="ngModel" name="fecha" [(ngModel)]="formulario.fecha">
                    <label class="pt-2 sub-l" for="date-2">Fecha</label>
                  </div>
                  <div class="form-group col-md-5">
                    <div *ngIf="activeAdd === false">
                      <a class="btn btn-disabled">Confirmar</a>
                    </div>
                    <div *ngIf="activeAdd === true">
                      <a class="btn btn-active" (click)="addHtml();">Confirmar</a>
                    </div>

                  </div>
                  <div class="form-group col-md-12">
                    <div *ngIf="dataDateImp == true" class="alert alert-danger">
                      Ambos datos son requeridos. La fecha debe llevar el siguiente formato 01/01/1900
                    </div>

                  </div>
                </div>

                <ng-container *ngIf="!dataDateImp">
                  <div class="col-12 pb-3"> <label>Mis fechas Importantes</label></div>
                  <div *ngFor="let item of formulario.fechas_importantes;let i = index">
                    <div class="form-row pt-3 date-all-sl">

                      <div class="form-group col-md-4">
                        <label class="pt-2 is-visible">Fecha</label>
                        <input type="text" class="form-control" placeholder="{{item.descripcion_fecha}}" readonly
                          disabled>
                        <label class="pt-2 sub-l" for="date-2">Fecha</label>
                      </div>
                      <div class="form-group col-md-3">
                        <label class="pt-2 is-visible">Evento</label>
                        <input type="text" class="form-control" placeholder="{{item.fecha}}" readonly disabled>
                        <label class="pt-2 sub-l" for="descripcion_fecha">Evento</label>
                      </div>
                      <div class="form-group col-md-5">
                        <a class="btn" (click)="deleteDate( i );">Eliminar</a>
                      </div>
                    </div>
                  </div>

                </ng-container>

                <div class="form-group col-md-9 ">
                  <label class="pt-2 " for="premios">Al ganar los 200 mil pesos ¿Qué premios elegirías?</label>
                  <select class="form-control customº-select custom-select-lg " required premios ngModel id="premios"
                    name="premios" #premios="ngModel" [(ngModel)]="formulario.premios">
                    <option value="-1">Seleccionar</option>
                    <option *ngFor="let item of awards2" value="{{item.id}}">{{ item.nombre}}</option>
                  </select>
                  <div *ngIf="!formulario.premios || formulario.premios == -1 " class="alert alert-danger">
                    Dato requerido
                  </div>

                </div>

              </div> -->
              <!--end tab 4-->
              <div class="class text-center mt-5">
                <label for="amper-no">Tus datos están seguros con nosotros. Revisa nuestro <a target="_blank"
                    style="color:#ff4700;" class="link" [routerLink]="['/aviso-de-privacidad']">Aviso de privacidad</a>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade pr-0" id="modalenrollment" tabindex="-1" role="dialog" aria-labelledby="modalenrollment"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content text-center">
      <div class="modal-body text-center no-padding">
        <form id="form-egr" #dataEgr="ngForm" novalidate (ngSubmit)="validateData(dataEgr)"
          (keydown.enter)="$event.preventDefault()">


          <div class="form-group">
            <label for="ua_idbanner" class="col-form-label pb-0">Ingresa tu matrícula</label>
            <label class="text"><strong class="f-bold"> NOTA: Debes proporcionar la matrícula y campus del primer
                programa cursado en tu alma mater <span class="d-block">(en caso de error, si tu matrícula cuenta con
                  ceros al inicio deberás ingresarlos)</span></strong></label>
            <input type="text" class="form-control" required ua_idbanner ngModel id="ua_idbanner" name="ua_idbanner"
              #ua_idbanner="ngModel" [(ngModel)]="mat.ua_idbanner" requiered>

            <div *ngIf="!mat.ua_idbanner" class="alert alert-danger">
              Dato requerido
            </div>
          </div>

          <div class="form-group">
            <label for="ua_campus" class="col-form-label">Selecciona campus</label>
            <select class="form-control" required campus ngModel id="ua_campus" name="ua_campus" #ua_campus="ngModel"
              [(ngModel)]="mat.ua_campus" (change)="capturar()">
              <option value="0">Seleccionar</option>
              <option *ngFor="let item of camp" value="{{item.Codigo}}">{{ item.campus}}</option>
            </select>
            <div *ngIf="!mat.ua_campus || seleccionCampus === '0'" class="alert alert-danger">
              Dato requerido
            </div>
          </div>



          <ng-container *ngIf="total">
            <div class="form-group">
              <label for="FechaNacimiento" class="col-form-label">Selecciona tu Fecha de nacimiento</label>
              <input type="date" class="form-control" id="FechaNacimiento" required FechaNacimiento ngModel
                #FechaNacimiento="ngModel" name="FechaNacimiento" [(ngModel)]="mat.FechaNacimiento">
              <div *ngIf="!mat.FechaNacimiento && dataEgr.submitted" class="alert alert-danger">
                Dato requerido
              </div>
            </div>

          </ng-container>

          <ng-container *ngIf="dataEmpty === 401">
            <p>Lo sentimos la fecha para actualizar tus datos ha expirado</p>
          </ng-container>
          <ng-container *ngIf="dataEmpty === 404  && this.seleccionCampus != 'JPII'">
            <p class="no-margin"> No existe la matrícula en este campus</p>
            <p>Si tienes alguna duda con tu matrícula o campus ponte en contacto al correo <a
                href="mailto:egresados.sorteoan@anahuac.mx" style="color: #f35f44;"> egresados.sorteoan@anahuac.mx </a>
            </p>
          </ng-container>
          <ng-container *ngIf="dataEmpty === 404 && this.seleccionCampus == 'JPII'">
            <p class="no-margin"> No existe la matrícula en este campus</p>
            <p>Si eres egresados de JPII y no esta tu matrícula por favor de dirigirte a: <a
                href="https://mkt.familia.edu.mx/sorteo-egresados" target="_blank" style="color: #f35f44;"> ingresa aquí. </a>
            </p>
          </ng-container>
          <ng-container *ngIf="dataEmpty === 406 || !flagValidaEgr">
            <p>Ya tiene un boleto de participación si aún desea actualizar sus datos pónganse en contacto con el equipo
              de sorteo al
              siguiente correo <a href="mailto:egresados.sorteoan@anahuac.mx" style="color: #f35f44;">
                egresados.sorteoan@anahuac.mx </a> </p>
          </ng-container>



          <ng-container *ngIf="progress;">
            <div class="lds-hourglass"></div>
          </ng-container>

          <div *ngIf="!progress;" class="modal-footer text-center" style="width: fit-content;">
            <div class="row">
              <div class="col-md-6 col-6">
                <a class="btn-modal  " [routerLink]="['/colabora']" data-dismiss="modal">Cancelar</a>
              </div>
              <div class="col-md-6 col-6">
                <button type="submit" class="btn-modal">Enviar</button>
              </div>
            </div>


          </div>
        </form>
      </div>

    </div>
  </div>
</div>



<!-- Modal Info -->
<div class="modal fade" id="modalInfo" tabindex="-1" role="dialog" aria-labelledby="modalInfo" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content  border-gradient-all ">

      <div class="modal-body text-center no-padding">
        <p>Si tus datos son incorrectos, comunícate al siguiente correo electrónico <a class="link"
            href="mailto:egresados.sorteoan@anahuac.mx" target="_blank">egresados.sorteoan@anahuac.mx</a> </p>
      </div>
      <div class="modal-footer  border-0">
        <a class="btn-modal text-center mb-2 " [routerLink]="['/colabora']" data-dismiss="modal">Cancelar</a>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalFolio" tabindex="-1" role="dialog" aria-labelledby="modalFolio" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content" style="border-radius: 25px;">

      <div class="modal-body text-center pt-5 pb-5">

        <ng-container *ngIf="bandera;">
          <div class="lds-hourglass"></div>
        </ng-container>
        <ng-container *ngIf="isNumber === true">
          <p style=" color:#ff5900; font-size: 28px !important; font-weight: 900;"><strong>¡Muchas felicidades!</strong></p>
          <p>
            Su folio de participación es
            <span style="color:#ff5900;font-size: 20px;">{{folio}}</span>
            mismo que llegará al correo que proporcionó, es importante revisar en la bandeja de Spam.
          </p>

          <div class="row" id="folio-egr">
            <div class="col-md-12">
              <p  style=" color:#662380; font-size: 28px !important; margin: 0; "> 
                <strong>Participa en el Sorteo Magno</strong>
              </p>
              <h5 class="mb-4">
                ¡Conoce tus números de la suerte! 
              </h5>
           
            </div>
            <div class="col-lg-3">
              <input class="form-check-input" type="checkbox" id="folio0" (click)="confirmSelection(0)">
              <label for="yes-con">{{boletosEgresado[0].folio}}</label>
            </div>
            <div class="col-lg-3">
              <input class="form-check-input" type="checkbox" id="folio1" (click)="confirmSelection(1)">
              <label for="yes-con">{{boletosEgresado[1].folio}}</label>
            </div>
            <div class="col-lg-3">
              <input class="form-check-input" type="checkbox" id="folio2" (click)="confirmSelection(2)">
              <label for="yes-con">{{boletosEgresado[2].folio}}</label>
            </div>
  
            <div class="col-lg-3">
  
              <button (click)="nuevosFolios();"> Actualizar </button>
            </div>
  
            <div class="col-12">
              <a (click)="validarFolios();" class="btn-modal mt-3">Continuar</a>
            </div>
  
            <div class="col-12 mt-3" role="alert" *ngIf="BoletoBloqueado">
              <p>Los boleto que acabas de seleccionar ya no están disponible, vuelve a generar otro.</p>
              <ul class="list-unstyled">
                <li *ngIf="folios[0] != ''"> <strong style="font-size: 20px;">{{folios[0]}}</strong> </li>
                <li *ngIf="folios[1] != ''"> <strong style="font-size: 20px;">{{folios[1]}}</strong> </li>
                <li *ngIf="folios[2] != ''"> <strong style="font-size: 20px;">{{folios[2]}}</strong> </li>
              </ul>
            </div>
          </div>

          <!-- <p>
            <strong class=" f-bold">
              NOTA: este correo puede tardar hasta 24 horas en llegar, a partir de este correo, podrán empezar a
              compartir una liga con sus conocidos egresados para participar en el “Sorteo Amigo Egresado”.
            </strong>
            <br>
            ¡Mucha suerte!
          </p> -->

         

        </ng-container>

      

      <ng-container *ngIf="isNumber === false">

          <p> {{folio}}</p>
          <a class="btn-modal text-center  mb-2" data-dismiss="modal">Cerrar</a>
        </ng-container>



      </div>
      <div class="modal-footer  border-0">
        <!-- <ng-container *ngIf="isNumber === true">

          <a class="btn-modal text-center  mb-2" [routerLink]="['/#']" data-dismiss="modal">Cerrar</a>

        </ng-container> -->
      </div>



    </div>
  </div>
</div>

<div class="modal fade" id="modalValid" tabindex="-1" role="dialog" aria-labelledby="modalValid" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content border-gradient-all">

      <div class="modal-body text-center no-padding">
        <p>Por favor verifica tus datos, hay datos incompletos.
        </p>
      </div>
      <div class="modal-footer border-0">
        <a class="btn-modal text-center mb-2" data-dismiss="modal">Cerrar</a>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="modalSteps" tabindex="-1" role="dialog" aria-labelledby="modalSteps" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content border-gradient-all">

      <div class="modal-body text-center no-padding">
        <ng-container *ngIf="stepMesagge; else elseBlock;">
          <p>{{stepMesagge}}</p>
        </ng-container>
        <ng-template #elseBlock>
          <p>Por favor revisa tu información.</p>
        </ng-template>

      </div>
      <div class="modal-footer  border-0">
        <a class="mb-2 btn-modal text-center" data-dismiss="modal">Cerrar</a>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="modalAccept" tabindex="-1" role="dialog" aria-labelledby="modalAccept" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content border-gradient-all">

      <div class="modal-body text-center no-padding">
        <p>¿Estás seguro que los datos son correctos?</p>
      </div>
      <div class="modal-footer ">
        <a class=" mb-2 btn-modal text-center" data-dismiss="modal">Cerrar</a>
        <a class=" mb-2 btn-modal text-center" (click)="validateDataAll(dataEgrAll);">Enviar</a>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="modalBack" tabindex="-1" role="dialog" aria-labelledby="modalBack" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content border-gradient-all">

      <div class="modal-body text-center no-padding">
        <p>Ya has enviado tus datos.</p>
      </div>
      <div class="modal-footer border-0">
        <a class="btn-modal  mb-2 text-center" data-dismiss="modal" [routerLink]="['/']">Regresar al home</a>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalFechas" tabindex="-1" role="dialog" aria-labelledby="modalFechas" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content border-gradient-all">
      <div class="modal-header"></div>
      <div class="modal-body text-center no-padding">
        <p>Aún no has agregado las fechas importantes, da clic en el botón confirmar.</p>
      </div>
      <div class="modal-footer ">
        <a class="btn-modal text-center mb-2" data-dismiss="modal">Cerrar</a>
      </div>
    </div>
  </div>
</div>