import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ventamesessinintereses',
  templateUrl: './ventamesessinintereses.component.html',
  styleUrls: ['./ventamesessinintereses.component.scss']
})
export class VentamesessininteresesComponent implements OnInit {
  title = 'Compra boletos del Sorteo Anáhuac 2023 a meses sin intereses';
  description = 'Venta de boletos del Sorteo Anáhuac 2023 a meses sin intereses. Consulta las fechas y los bancos participantes aquí.';
  utmUrl =  "";
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
	/*utm */
	let utmTem= localStorage.getItem('urlUtm');
	if(utmTem)this.utmUrl =utmTem;
	/* fin utm */
    this.titleService.setTitle(this.title);
		this.metaTagService.updateTag(
			{ name: 'title', content: this.title}
		);
		this.metaTagService.updateTag(
			{ name: 'description', content:  this.description}
		);

		this.metaTagService.updateTag(
				{ property: 'keywords', content: 'Nosotros'}
			);

			this.metaTagService.updateTag(
				{ property: 'og:title', content: this.title}
			);

		this.metaTagService.updateTag(
				{ property: 'og:description', content: this.description}
			);

		this.metaTagService.updateTag(
		{ property: 'og:url', content: 'https://www.sorteoanahuac.mx/nosotros' }
		);

		this.metaTagService.updateTag(
			{ property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/banner2_about.png' }
		  );
		  
		this.metaTagService.updateTag(
			{ name: 'twitter:title', content: this.title}
		);
		this.metaTagService.updateTag(
			{ name: 'twitter:description', content: this.description}
		);

    
  }
}
