import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ganadorresegresados',
  templateUrl: './ganadorresegresados.component.html',
  styleUrls: ['./ganadorresegresados.component.scss']
})
export class GanadorresegresadosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
