import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginGanador } from 'src/models/loginGanador';
import { FormEgrService } from 'src/services/form-egr.service';
import {Md5} from 'ts-md5/dist/md5';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  dataLogin: LoginGanador = new LoginGanador();
  message: string | undefined;
  touchGanador: boolean;
  md5 = new Md5();
  tokken: any;
  constructor(private router: Router, private readonly _formService: FormEgrService) { }

  ngOnInit(): void {
   
  }


  changeVal(id: number) {
    if(id == 1)  this.touchGanador = true;
  }

  validateData(form: NgForm) {
    if (form.invalid ) {
      $("input.ng-invalid, textarea.ng-invalid, .ng-invalid").addClass("is-invalid");
      this.message = "Datos incorrectos"

      return ;
    }
   // console.log( $("#password").val().toString() );
    let tem = String(new Md5().appendStr($("#password").val().toString().trim()).end());
    //console.log(tem);
    this.dataLogin.password = tem;
    //console.log(this.dataLogin);

    this._formService.token(this.dataLogin.correo, this.dataLogin.tipo_ganador, "0").subscribe(a => {
      //console.log(a);
    },
    
    error => {
     // console.log(error);
      //console.log(error.status);
        if(error.status == 200) {
         // console.log(error.error.text);
          this._formService.loginGandores(this.dataLogin,error.error.text).subscribe( a => {
           // console.log(a);
          },
          err => {
           
            //console.log(err);
            if(err.status === 200){
              console.log(err.error.text);
              sessionStorage.setItem('tokken', err.error.text);
              //this._formService.logOutGanadores(error.error.text).subscribe(a => { });
              this.router.navigate(['formulario-documentos']);
            }else {
              this.message = err.error;
              this.dataLogin.password = "";
              //window.location.reload();
            }

          });
       
        }

    });

    

   
  }

}
