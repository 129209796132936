<section class="thanks">
    <div class="container">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-center flex-column" data-aos="fade-top" data-aos-easing="ease"  data-aos-duration="3000">
                <h1>
                    ¡Gracias, tenemos tus datos!
                </h1>
                <p>
                    en breve nos comunicaremos contigo.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="stc-text">
    <div class="container">
        <div class="row" data-aos="fade-left" data-aos-easing="ease"  data-aos-duration="3000">
           
            <div class="col-md-4" >
                <p>
                    ¿Quieres ganar grandes premios con la compra de <strong>un solo boleto? </strong>
                    Compra 1 boleto por solo $450 pesos y participa en nuestros 3 sorteos.
                </p>
                <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/" target="_blank" class="btn-stack"> Da clic aquí </a>
            </div>

            <div class="col-md-4" >

                <p>
                    <strong>¡Más de 400 premios</strong> te esperan en nuestro Sorteo Magno! 
                    ¡Participa ya y gana apoyando la educación!
                    
                </p>
                <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/" target="_blank" class="btn-stack"> Da clic aquí </a>

            </div>

            <div class="col-md-4" >
                <p>
                    ¿Eres <strong>EGRESADO</strong> de la Universidad Anáhuac?
                    ¡Participa GRATIS actualizando tus datos y gana <strong>200 mil pesos!</strong>
                </p>
                <a  class="btn-stack"> Da clic aquí </a>
            </div>

        </div>
    </div>
</section>