import { Component, OnInit } from '@angular/core';
import { PasaLavozService } from '../../../services/pasa-lavoz.service';

@Component({
  selector: 'app-comparte',
  templateUrl: './comparte.component.html',
  styleUrls: ['./comparte.component.scss']
})
export class ComparteComponent implements OnInit {
  id = localStorage.getItem('idColaborador');
  nombre = localStorage.getItem('nombre');
  link ;
  select = false;
  temp ;
  constructor(private readonly _pasaServive: PasaLavozService) {
    if(localStorage.getItem('idColaborador') === null || localStorage.getItem('idColaborador') === ""){
      window.location.href = "/home";
    }
    
    this._pasaServive.getLiga(this.id , this.nombre).subscribe( a => {
        ////console.log(a);
        this.temp = a;
        this.temp = this.temp.split(" ");
        this.link = this.temp[0];
    },
    err => {

    });

  }
  ngOnInit(): void {
    
     
  }

  clickbtn(){
    $("#link").select();
    document.execCommand("copy");
    ////console.log("texto copiado");
    this.select = true;
  }


}
