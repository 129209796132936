import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basesegresados',
  templateUrl: './basesegresados.component.html',
  styleUrls: ['./basesegresados.component.scss']
})
export class BasesegresadosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
