import { Component, OnInit } from '@angular/core';

import { Formulario } from 'src/models/formulario';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Mat } from 'src/models/mat';
import { Campus } from 'src/models/campus';
import { Fechas_importantes } from 'src/models/fechas_importantes';
import { AwarsEgr } from 'src/models/awardsEgr';
import { formularioNewEgr } from 'src/models/formularioNewEgr';
import { FormEgrService } from 'src/services/form-egr.service';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  dataStudent: any = [];
  camp: any = [];
  country: any = [];
  dataCountry: any = [];

  boletosEgresado: any = [];
  isCp = 0;
  flagCountry = false;
  mat: Mat = new Mat();
  folio: any = [];
  total = false;
  dataEmpty = 0;
  localTime: Date = new Date();
  index = 0;
  fechas_importantes: Fechas_importantes = new Fechas_importantes();
  fecha: string;
  descripcion_fecha: string;
  valid_email = false;
  idReferido = "";
  isClickCol = true;
  selectCP = true;
  errorCP = false;
  dataEmptyCP = false;
  dataDateImp = false;
  dataDateImpF = true;
  hideAll = false;
  checkError = true;
  fecha_imp = false;
  validateEmail2 = false;
  isNumber = false;
  seleccionCampus: string = '0';
  progress = false;
  progress_main_form = 1;
  bandera = true;
  errorFl = false;
  bandCp = false;
  globalFlag = false;
  flagStep1: any = [];
  flagStep2: any = [];
  flagStep3: any = [];
  flagStep4: any = [];
  step_1 = false;
  step_2 = false;
  step_3 = false;
  step_4 = false;
  stepMesagge = "";
  sendOne = false;
  activeAdd = false;

  awards: AwarsEgr = new AwarsEgr();
  awards2: any = [];
  dataNewEgr: any = [];

  flagTemp = true;

  flagValidaEgr = true;

  validaLink = false;
  public fechasImp: any = [];

  public pk_egr;
  public BoletoBloqueado = false;
  public folios: string[] = ["", "", ""];
  public folioSelected = false;

  public redirectPago = true;
  constructor(private readonly _formService: FormEgrService, private router: Router, private route: ActivatedRoute) {
    this.mat.ua_campus = "0";

    //url refereido
    this.route.queryParams.subscribe(params => {
      this.idReferido = params['IDEgresado'];
      if (this.idReferido === undefined) {
        this.idReferido = "";
        ////console.log("referido vacio= "  + this.idReferido);
      }
      ////console.log("referido = "  + this.idReferido);
    });
  }

  // links tabs
  // href="#academico"  
  // href="#general" 
  // href="#ocupacion"
  // href="#fecha"


  formulario: Formulario = new Formulario();
  formularioNewEgr: formularioNewEgr = new formularioNewEgr();
  ngOnInit(): void {

    this._formService.getCampus().subscribe(a => {
      this.camp = a;
      ////console.log(this.camp);
    });

    this._formService.getCountry().subscribe(a => {
      this.country = a;
      ////console.log(a);
    });

    $(document).on('change', '#pais', function (event) {
      let ct = $("#pais option:selected").text();
      if (ct == "México" || ct == "Mexico") {
        this.flagCountry = true;
        ////console.log("entro"+ this.flagCountry);
      }
    });

    //awards
    this._formService.getAwards().subscribe(a => {
      this.awards2 = a;
      //console.log(this.awards2);
    });

    this.boletosEgresado = [
      {
        "folio": "000000"
      },
      {
        "folio": "000000"
      }
      ,
      {
        "folio": "000000"
      }
    ];
    this.pk_egr =  10261;
    //(<any>$('#modalFolio')).modal('show');
    $('#main-header .btn-buy').css('display', 'block');
    $(document).ready(function () {
      (<any>$('#modalenrollment')).modal({ backdrop: 'static', keyboard: false });
      (<any>$('#modalenrollment')).modal('show');

      $('ul.list-form li a').addClass("disabled no-click");
      $("#main-header .main-nav .site-navigation li .last-child").removeClass("latido-egr");


    });
  }



  async confirmSelection(index) {
    
    const checkboxes_f: HTMLInputElement[] = [];

    // Obtener los checkboxes y almacenarlos en el array
    for (let i = 0; i < 3; i++) {
      const checkbox = document.getElementById(`folio${i}`) as HTMLInputElement;
      checkboxes_f.push(checkbox);
    }

    var checkbox = document.getElementById('folio' + index) as HTMLInputElement;
   // console.log(checkbox);
    if (checkbox) {

      if (!this.folioSelected) {
        var confirmMsg = confirm("¿Estás seguro de que quieres seleccionar este folio porque no podras deseleccionarlo?");
        if (confirmMsg) {
          this.folioSelected = true;
          checkbox.checked = true;
          try {
            const result = await this._formService.blockFoliosEgr(this.pk_egr, this.boletosEgresado[index].folio).toPromise();
            //console.log(result);
            checkbox.disabled = true;
            checkbox.style.backgroundColor = '#ff5900';
          } catch (error) {
            if (error.status !== 200) {
              this.folios[index] = this.boletosEgresado[index].folio;
              this.BoletoBloqueado = true;
             // console.log("Error: Estado de respuesta no es 200");
             // console.log(error);
              //this.redirectPago = false;
              checkbox.checked = false; 
            }

            
          }
        } else {
          checkbox.checked = false;
        }
      } else {
        checkbox.checked = true;
        try {
          const result = await this._formService.blockFoliosEgr(this.pk_egr, this.boletosEgresado[index].folio).toPromise();
          //console.log(result);
          checkbox.disabled = true;
            checkbox.style.backgroundColor = '#ff5900';
        } catch (error) {
          if (error.status !== 200) {
            this.folios[index] = this.boletosEgresado[index].folio;
            this.BoletoBloqueado = true;
           // console.log("Error: Estado de respuesta no es 200");
           // console.log(error);
            //this.redirectPago = false;
            checkbox.checked = false; 
          }


        }
      }

      // Contar cuántos checkboxes están seleccionados
      var checkboxes = document.querySelectorAll('.form-check-input');
      var selectedCount = 0;
      checkboxes.forEach(function (checkbox) {
        if ((checkbox as HTMLInputElement).checked) {
          selectedCount++;
        }
      });

      // Desactivar el botón si los tres checkboxes están seleccionados
      var button = document.querySelector("#folio-egr > div:nth-child(5) > button") as HTMLButtonElement;
      if (button) {
        if (selectedCount === 3) {
          //button.disabled = true;
        } else {
         // button.disabled = false;
        }
      } else {
        console.error("No se pudo encontrar el botón.");
      }

      // Reiniciar this.folioSelected si todos los checkboxes están deseleccionados
      if (selectedCount === 0) {
        this.folioSelected = false;
      }
    } else {
      console.error("No se pudo encontrar el checkbox con el id folio" + index);
    }
  }








  //  disableCheckboxes() {
  //   var checkboxes = document.querySelectorAll('.form-check-input');
  //   checkboxes.forEach(function(checkbox) {
  //       (checkbox as HTMLInputElement).disabled = true;
  //   });
  // }


  nuevosFolios() {
    this.BoletoBloqueado = false;
    $("#folio-egr > div > button").addClass("rotar");
    $("#folio-egr > div > button").prop("disabled", true);
    const checkboxes: HTMLInputElement[] = [];

    // Obtener los checkboxes y almacenarlos en el array
    for (let i = 0; i < 3; i++) {
      const checkbox = document.getElementById(`folio${i}`) as HTMLInputElement;
      checkboxes.push(checkbox);
    }

    // Obtener el estado de selección de cada checkbox y almacenarlo en otro array
    const estados: boolean[] = checkboxes.map(checkbox => checkbox.checked);
    //console.log(estados);
    let total = 3;
    if (estados.length != 0) total = 3 - estados.length;

   // console.log(total);
    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    sleep(1000) // Espera 1 segundo (1000 milisegundos)
      .then(() => {
        return this._formService.getFoliosEgr(3).toPromise();
      })
      .then(a => {

        //console.log(this.boletosEgresado);
        let count = 0;
        for (let i = 0; i < 3; i++) {
          if (estados[i] == false) {
            this.boletosEgresado[i].folio = a[count].folio;
            count++;
          }
        }

        $("#folio-egr > div > button").removeClass("rotar");
        $("#folio-egr > div > button").prop("disabled", false);
      })
      .catch(err => {
        console.log(err);
      });
  }

  async validarFolios() {

    this.BoletoBloqueado = false;
    const checkboxes: HTMLInputElement[] = [];

    // Obtener los checkboxes y almacenarlos en el array
    for (let i = 0; i < 3; i++) {
      const checkbox = document.getElementById(`folio${i}`) as HTMLInputElement;
      checkboxes.push(checkbox);
    }

    const estados: boolean[] = checkboxes.map(checkbox => checkbox.checked).filter(checked => checked === true);

    //console.log(estados);
    if (estados.length > 0) {


      //console.log("termino");

      //if (this.redirectPago) {
        window.open('https://sorteoanahuaccompra.sorteoanahuac.mx/pagar/20/' + this.pk_egr, '_blank');

        //console.log("rederigir");
    
    } else {
      alert("Debes seleccionar algún boleto")
    }
  }



  step1() {
    let bandera = false;
    if (this.validateEmail2 && this.globalFlag === true) {
      //linkedin distonto de na
      if (this.formulario.linkedin && !this.validaLink) {
        bandera = true;
      } else {
        bandera = false;
      }

      if (this.validaLink && (this.formulario.facebook || this.formulario.twitter || this.formulario.instagram)) {
        bandera = true;
      }

      //console.log(bandera);

      this._formService.sendStep(this.formulario, "1").subscribe(a => {
        this.flagStep1 = a;
        ////console.log(a);
        if (this.flagStep1["validationStep1"] === true && bandera) {
          this.step_1 = true;
          $("ul.list-form li:nth-child(2) a").addClass("active");
          $("ul.list-form li:nth-child(1) a").removeClass('active');
          $("#academico").removeClass(" active show");
          $("#general").addClass(" active show");
          $("#ocupacion").removeClass(" active show");
          $("#fecha").removeClass(" active show");
          $('html, body').animate({ scrollTop: 0 }, 'slow');
          $('ul.list-form li:nth-child(1) a').removeClass("disabled no-click");
          $('ul.list-form li:nth-child(2) a').removeClass("disabled no-click  dis-menu");




        } else {
          this.step_1 = false;
          this.stepMesagge = this.flagStep1["mesagge"];

          if (this.validaLink) {
            this.stepMesagge = "Favor de llenar una red social Facebook, Instagram o twitter.";
          }
          (<any>$('#modalSteps')).modal('show');
        }

      },
        err => {
          ////console.log(err.status);
        });

    } else {
      this.stepMesagge = "Completa los datos obligatorios.";
      (<any>$('#modalSteps')).modal('show');
    }

  }

  step2() {
    if (this.step_1) {
      ////console.log(this.formulario);
      if (this.formulario.pais === 'México' && this.formulario.colonia === '0') {
        this.stepMesagge = "Por favor revisa tu información.";
        (<any>$('#modalSteps')).modal('show');
      } else {
        if (this.selectCP == false) {
          this.formulario.estado = this.dataCountry[0].estado;
          this.formulario.municipio = this.dataCountry[0].municipio;
        }
        this._formService.sendStep(this.formulario, "2").subscribe(a => {
          this.flagStep2 = a;
          ////console.log(a);

          if (this.flagStep2["validationStep2"] === true && this.formulario.ladatelprincipal !== "" && this.formulario.ladatelprincipal !== null && this.formulario.telefonoprincipal !== "" && this.formulario.telefonoprincipal !== null) {
            this.step_2 = true;
            $("ul.list-form li:nth-child(3) a").addClass("active");
            $("ul.list-form li:nth-child(2) a").removeClass('active');

            $("#academico").removeClass(" active show");
            $("#general").removeClass(" active show");
            $("#ocupacion").addClass(" active show");
            $("#fecha").removeClass(" active show");
            $('html, body').animate({ scrollTop: 0 }, 'slow');
            $('ul.list-form li:nth-child(2) a').removeClass("disabled no-click");
            $('ul.list-form li:nth-child(3) a').removeClass("disabled no-click dis-menu");

          } else {
            this.step_2 = false;
            this.stepMesagge = this.flagStep2["mesagge"];
            (<any>$('#modalSteps')).modal('show');
          }

        },
          err => {
            ////console.log(err.status);
          });
      }


    } else {
      this.stepMesagge = "Por favor revisa tu información.";
      (<any>$('#modalSteps')).modal('show');
    }

  }

  step3() {
    if (this.step_1 && this.step_2) {
      this._formService.sendStep(this.formulario, "3").subscribe(a => {
        this.flagStep3 = a;
        ////console.log(a);
        if (this.flagStep3["validationStep3"] === true) {
          this.step_3 = true;
          this.step_4 = true;
          // $( "ul.list-form li:nth-child(4) a" ).addClass( "active" );
          // $( "ul.list-form li:nth-child(3) a" ).removeClass('active');

          // $( "#academico" ).removeClass( " active show" );
          // $( "#general" ).removeClass( " active show" );
          // $( "#ocupacion" ).removeClass( " active show" );
          // $( "#fecha" ).addClass( " active show" );
          // $('html, body').animate({scrollTop:0}, 'slow');
          // $('ul.list-form li:nth-child(4) a').removeClass("disabled no-click dis-menu");

          if (!this.sendOne) {
            (<any>$('#modalAccept')).modal('show');
            //  $('ul.list-form li:nth-child(4) a').removeClass("disabled no-click");
          } else {
            (<any>$('#modalBack')).modal('show');
          }
        } else {
          this.step_3 = false;
          this.stepMesagge = this.flagStep3["mesagge"];
          (<any>$('#modalSteps')).modal('show');
        }

      },
        err => {
          ////console.log(err.status);
        });

    } else {
      this.stepMesagge = "Por favor revisa tu información.";
      (<any>$('#modalSteps')).modal('show');
    }

  }

  step4() {
    if (this.step_1 && this.step_2 && this.step_3) {
      this.formulario.yes = this.checkError;
      this._formService.sendStep(this.formulario, "4").subscribe(a => {
        this.flagStep4 = a;
        ////console.log(a);
        if (this.flagStep4["validationStep4"] === true) {
          this.step_4 = true;
          if (!this.sendOne) {
            (<any>$('#modalAccept')).modal('show');
            //$('ul.list-form li:nth-child(4) a').removeClass("disabled no-click");
          } else {
            (<any>$('#modalBack')).modal('show');
          }
        } else {
          this.step_4 = false;
          this.stepMesagge = this.flagStep4["mesagge"];
          (<any>$('#modalSteps')).modal('show');
        }

      },
        err => {
          ////console.log(err.status);
        });

    } else {
      this.stepMesagge = "Por favor revisa tu información.";
      (<any>$('#modalSteps')).modal('show');
    }

  }



  validateData(form: NgForm) {
    if (form.invalid) { return; }
    this.globalFlag = true;
    this.progress = true;

    if (this.mat.FechaNacimiento) {
      this._formService.getDataStudentDate(this.mat).subscribe(a => {
        this.dataStudent = a;

        this.localTime = new Date(this.dataStudent[0].FechaNacimiento.replace(/-/g, '\/').replace(/T.+/, ''));
        this.dataStudent[0].email = this.dataStudent[0].email.replace(/ /g, "");
        this.formulario = this.dataStudent[0];
        let f = new Date(this.formulario.nacimiento);
        //obtenr id referido
        this.formulario.egresado_referido = this.idReferido;
        //obtenemos el estado colina,.. con el cp, es important que el codigo postal no este vacio
        if (this.formulario.cp) {
          this.getCpPrimero(this.formulario.cp);
        }

        ////console.log( this.formulario);
        (<any>$('#modalenrollment')).modal('hide');

      },
        err => {
          ////console.log(err);
          if (err.status === 401) {
            this.dataEmpty = 401;

          }
          if (err.status === 404) {
            this.dataEmpty = 404;
            this.globalFlag = false;
          }
          if (err.status === 406) {
            this.dataEmpty = 406;
          }
        });
      this.formulario.email = null;
    } else {

      this._formService.validaEgresado(this.mat).subscribe(a => {
        //console.log(a);
        if (a == 0) {
          this.flagValidaEgr = true;
          /*******************************/
          this._formService.getDataStudent(this.mat).subscribe(a => {

            this.dataStudent = a;

            //console.log(this.dataStudent);

            this.localTime = new Date(this.dataStudent.FechaNacimiento.replace(/-/g, '\/').replace(/T.+/, ''));
            //asignar nuevo arreglo al que ya teniamos


            //step 1
            this.formulario.nombrecompleto = this.dataStudent.Alumno;

            this.formulario.nombrepila = this.dataStudent.NombreDePila;
            this.formulario.segundonombre = this.dataStudent.SegundoNombre;
            this.formulario.apellidos = this.dataStudent.Apellidos;



            this.formulario.nacimiento = this.dataStudent.FechaNacimiento;
            this.formulario.matricula = this.dataStudent.IDBanner;
            this.formulario.campus = this.dataStudent.Campus;
            this.formulario.codigocampus = this.dataStudent.Campus;
            this.formulario.carrera = this.dataStudent.DescripcionPrograma;
            this.formulario.nivel = this.dataStudent.Nivel;

            if (this.dataStudent.Redes.lenght > 0) {
              this.formulario.linkedin = this.dataStudent.Redes[0].LinkedIn;
              this.formulario.facebook = this.dataStudent.Redes[0].Facebook;
              this.formulario.twitter = this.dataStudent.Redes[0].Twitter;
              this.formulario.instagram = this.dataStudent.Redes[0].Instagram;
            } else {
              this.formulario.linkedin = "";
              this.formulario.facebook = "";
              this.formulario.twitter = "";
              this.formulario.instagram = "";
            }

            //recurrenete 
            this.formulario.recurrente = this.dataStudent.recurrente;
            //console.log(this.formulario);
            //console.log(this.dataStudent.Redes.lenght);
            //console.log("here");
            //console.log( this.formulario);


            //step2
            if (this.dataStudent.Direcciones.length > 0) {
              // //console.log("entre direcciones");
              this.formulario.pais = this.dataStudent.Direcciones[0].Pais;
              //console.log(this.formulario.pais);


              this.formulario.cp = this.dataStudent.Direcciones[0].CodigoPostal;
              this.formulario.estado = this.dataStudent.Direcciones[0].Estado;
              this.formulario.municipio = this.dataStudent.Direcciones[0].DelegacionMunicipio;
              this.formulario.colonia = this.dataStudent.Direcciones[0].Colonia;
              this.formulario.calle = "1";
            } else {
              this.formulario.pais = "";
              this.formulario.cp = "";
              this.formulario.estado = "";
              this.formulario.municipio = "";
              this.formulario.colonia = "";
              this.formulario.calle = "1";
            }


            //console.log(this.dataStudent.Telefonos);
            if (this.dataStudent.Telefonos.length == 1) {
              this.formulario.area_celular = this.dataStudent.Telefonos[0].Area.substr(0, 2);
              this.formulario.celular = this.dataStudent.Telefonos[0].Numero.substr(0, 10);
            } else if (this.dataStudent.Telefonos.length == 2) {
              this.formulario.area_celular = this.dataStudent.Telefonos[0].Area.substr(0, 2);
              this.formulario.celular = this.dataStudent.Telefonos[0].Numero.substr(0, 10);
              this.formulario.area_telefono = this.dataStudent.Telefonos[1].Area.substr(0, 2);
              this.formulario.telefono_casa = this.dataStudent.Telefonos[1].Numero.substr(0, 10);
            }


            else {
              this.formulario.area_celular = "";
              this.formulario.celular = "";
              this.formulario.area_telefono = "";
              this.formulario.telefono_casa = "";
            }




            if (this.dataStudent.LadaTelPrincipal) {
              this.formulario.ladatelprincipal = this.dataStudent.LadaTelPrincipal.substr(0, 2);
            } else {
              this.formulario.ladatelprincipal = ""
            }

            if (this.dataStudent.telefonoprincipal) {
              this.formulario.telefonoprincipal = this.dataStudent.TelefonoPrincipal.substr(0, 10);
            } else {
              this.formulario.telefonoprincipal = ""
            }
            //datos corregidos
            this.formulario.graduacion = 0;
            this.formulario.generacion = this.dataStudent.Generaciones;
            this.formulario.ciudad = "";

            //step3
            if (this.dataStudent.Ocupaciones.length > 0) {
              this.formulario.ocupacion = this.dataStudent.Ocupaciones[0].Puesto;
              this.formulario.empresa = this.dataStudent.Ocupaciones[0].Empresa;
            } else {
              this.formulario.ocupacion = "";
              this.formulario.empresa = "";
            }

            if (this.formulario.pais === "México") {
              this.formulario.area_celular = "52";
              this.formulario.area_telefono = "52";
              this.formulario.ladatelprincipal = "52";
            }

            //step4
            //fechas importantes no vienen

            //console.log( this.formulario);

            //hacer modificaciones en caso de que traiga mas de un alumno por lo mientras trabajaremos con un solo alumno


            if (this.dataStudent.length > 1) {
              this.total = true;


              //console.log("entro aqui");

              //obtenr id referido
              this.formulario.egresado_referido = this.idReferido;
              //codigo postal
              if (this.formulario.cp) {
                this.getCpPrimero(this.formulario.cp);
              }


            } else if (this.flagTemp) { //validar si solo es uno
              //apartado donde se modifico las nuevas APIS 28 feb 2021
              //console.log("solo uno");
              if (this.dataStudent.Direcciones.lenght > 0 && this.dataStudent.Direcciones[0].Pais === "Mexico") {
                this.dataStudent.Direcciones[0].Pais = this.dataStudent.Direcciones[0].Pais.replace("Mexico", "México");
              }


              //this.formulario = this.dataStudent[0];
              //this.dataStudent[0].email = this.dataStudent[0].email.replace(/ /g, "");
              //obtenr id referido
              this.formulario.egresado_referido = this.idReferido;
              //codigo postal
              if (this.formulario.cp) {
                this.getCpPrimero(this.formulario.cp);
              }

              ////console.log("aqui");
              ////console.log(this.formulario );
              (<any>$('#modalenrollment')).modal('hide');
            }
            this.progress = false;
            // this.formulario.celular = "112233445566";
            // this.formulario.area_telefono = "1234444";
            // this.formulario.telefono_casa = "5456455645654654654645";
            this.formulario.email = null;
            this.formulario.premios = -1;
          },
            err => {
              ////console.log(err);
              if (err.status === 401) {
                this.dataEmpty = 401;
              }
              if (err.status === 404) {
                this.dataEmpty = 404;
                this.globalFlag = false;
              }
              if (err.status === 406) {
                this.dataEmpty = 406;
              }
              this.progress = false;
            });



          /***************************** */
        }

      },
        err => {
          if (err.status === 404) {
            this.flagValidaEgr = false;
            this.progress = false;
            this.dataEmpty = 0;

          }
          //console.log(err.error);
          if (err.status === 401) {
            this.dataEmpty = 401;

          }

          this.progress = false;

        }
      );



    }

  }

  isInteger(str) {
    var regInteger = /^\d+$/;
    return regInteger.test(str);
  }


  validateDataAll(form: NgForm) {
    //console.log(form);
    (<any>$('#modalAccept')).modal('hide');
    let dat = Date.parse(this.formulario.fecha) || 0;
    if (($("#descripcion_fecha").val() != "" && dat != 0)) { (<any>$('#modalFechas')).modal('show'); return; }
    if ((this.step_1 && this.step_2 && this.step_3 && this.step_4)) {


      if (this.checkError || !this.validateEmail2) {
        //console.log("here123");
        (<any>$('#modalValid')).modal('show');
        return;
      }

      this.sendOne = true;
      this.isNumber = false;
      this.bandera = true;
      this.folio = [];
      $(".btn-main").prop("disabled", true);
      $(".btn-main").addClass("btn-disabled");
      this.progress_main_form = 1;
      ////console.log("Envio formulario");
      ////console.log(form);

      if (this.selectCP == false) {
        this.formulario.estado = this.dataCountry[0].estado;
        this.formulario.municipio = this.dataCountry[0].municipio;
      }
      if (this.formulario.pais === 'México' || this.formulario.pais === 'Mexico') {
        ////console.log("SI ES igual a mexico");
        this.formulario.extranjero = false;
      } else {
        ////console.log("NO ES igual a mexico");
        this.formulario.cp = "000000";
        this.formulario.colonia = "Extranjero";
        this.formulario.estado = "Extranjero";
        this.formulario.municipio = "Extranjero";
        this.formulario.extranjero = true;
      }

      ////console.log(form);


      (<any>$('#modalFolio')).modal({ backdrop: 'static', keyboard: false });
      (<any>$('#modalFolio')).modal('show');
      this.progress_main_form = 1;
      ////console.log(this.progress_main_form);
      this.formulario.premios = Number(this.formulario.premios);
      this.formulario.fecha = null;
      console.log(this.formulario);
      this._formService.sendDataStudent(this.formulario).subscribe(a => {
        this.folio = a;
        //console.log(a);

        // funcion numero
        if (this.isInteger(this.folio)) {
         // console.log('Integer');
          this.isNumber = true;
          //debugger;
          //window.location.href = '/sorteo-egresado-universidad-anahuac-gracias?folio='+this.folio;
          /* traer pk */
          this._formService.getPkEgr(this.formulario.email).subscribe(a => {
            this.pk_egr = a;
            //console.log(this.pk_egr);
            //llamar numero
            if (this.isInteger(this.pk_egr)) {
              this._formService.getFoliosEgr(3).subscribe(a => {
                this.boletosEgresado = a;
                //console.log(this.boletosEgresado);
              },
                err => {
                  console.log(err);
                });
            }
          },
            err => {
              console.log(err);
            });

        } else {
          ////console.log('Non Integer'); 
          this.isNumber = false;
          this.sendOne = false;
          this.progress_main_form = 1;
          this.bandera = true;
        }

        ////console.log(this.isNumber );

        this.progress_main_form = 0;
        this.bandera = false;
        $(".btn-main").prop("disabled", false);
        $(".btn-main").removeClass("btn-disabled");
      }
        ,
        err => {
          this.errorFl = true;
          if (err.status === 404) {
            this.sendOne = false;
          }
        });

    } else {
      (<any>$('#modalValid')).modal('show');
    }

    // if (form.invalid ||  this.checkError || !this.validateEmail2 ||  !this.globalFlag) { 
    //   (<any>$('#modalValid')).modal('show');
    //   return;
    //  }





  }

  getCp() {
    var valor = $('#cp').val();

    this._formService.getCpostal(valor.toString()).subscribe(a => {
      ////console.log("codigo postal");
      ////console.log(a);
      this.dataCountry = a;
      if (this.dataCountry.length < 1) {
        this.dataEmptyCP = true;
        this.bandCp = true;
        this.formulario.colonia = '0';
      } else {
        this.dataEmptyCP = false;
        this.bandCp = false;
      }
      this.isCp = 1;
      this.selectCP = false;

    },
      err => {
        ////console.log(err);
        this.errorCP = true;
      });
  }

  getCpPrimero(codigoP: string) {
    var valor = codigoP;
    this._formService.getCpostal(valor.toString()).subscribe(a => {
      ////console.log("codigo postal primero");
      ////console.log(a);
      this.dataCountry = a;
      ////console.log(this.dataCountry);
      this.isCp = 1;
      if (this.dataCountry.length < 1) {
        this.bandCp = true;
        this.formulario.colonia = '0';
      } else {
        this.bandCp = false;
      }
    });


  }

  inputValidator(event: any) {
    //////console.log(event.target.value);
    const pattern = /^[0-9]*$/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/, "");
      // invalid character, prevent input

    }
  }

  inputValidateEmail(event: any) {
    //////console.log(event.target.value);

    const pattern = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      // invalid character, prevent input

      this.valid_email = false;
    } else {
      this.valid_email = true;

    }
  }

  //saber si dio click sobre el input de colonia 
  isClickInp() {
    this.isClickCol = false;
  }

  activeHtml(event: any) {
    let dat = Date.parse(this.formulario.fecha) || 0;
    if ($("#descripcion_fecha").val() != "" && dat != 0) { this.activeAdd = true; } else { this.activeAdd = false; }
  }


  addHtml() {
    let dat = Date.parse(this.formulario.fecha) || 0;

    //console.log("fecha = " + this.formulario.fecha);  
    //console.log("descripcion"+ $("#descripcion_fecha").val());  
    //console.log(this.index);
    if ($("#descripcion_fecha").val() != "" && dat != 0 && this.index <= 3) {
      this.dataNewEgr[this.index] = ({
        fecha: this.formulario.fecha + "", descripcion_fecha: this.formulario.descripcion_fecha
      });
      //console.log(this.dataNewEgr);
      if (this.index <= 2) {
        this.formulario.fechas_importantes = this.dataNewEgr;
        this.index = this.index + 1;
        ////console.log(this.formulario.fechas_importantes + "----"+ this.index);
        this.formulario.fecha = "";
        this.formulario.descripcion_fecha = "";
        this.dataDateImp = false;
        this.dataDateImpF = false;
        this.fecha_imp = false;
      } else {
        this.fecha_imp = true;
      }
    } else {
      this.dataDateImp = true;

      ////console.log(this.formulario.fechas_importantes + "vacio"+ this.index);
    }

    this.activeAdd = false;
  }
  //deleteDate
  deleteDate(pos: number) {
    var post = pos;
    ////console.log(this.formulario.fechas_importantes.length+"--"+post);

    if (post == 0 && this.formulario.fechas_importantes.length > 1) {
      this.formulario.fechas_importantes.shift();
      this.index = this.index - 1;
      this.hideAll = false;
      ////console.log("uno");
    } else if (post == 0 && this.formulario.fechas_importantes.length == 1) {
      this.hideAll = true;
      this.formulario.fechas_importantes.shift();
      this.index = 0;
      ////console.log(this.formulario.fechas_importantes + "borrar"+ this.index);
      ////console.log("dos");
    } else {
      this.formulario.fechas_importantes.splice(post, post);
      this.index = this.index - 1;
      this.hideAll = false;
      ////console.log("tres");
    }

  }

  disabledCheck() {

    $("#yes").is(':checked') ? this.checkError = false : this.checkError = true;
    ////console.log(this.checkError);
  }


  //validar correo

  validaCorreo(event: any) {

    if ($("#email").val() != $("#email2").val()) {
      this.validateEmail2 = false;
    } else if ($("#email").val() != "" && $("#email2").val() != "") {
      ////console.log("son iguales");
      this.validateEmail2 = true;
    }
  }

  capturar() {
    this.seleccionCampus = this.mat.ua_campus;
    console.log(this.seleccionCampus);
  }

  validaLinkdln(event: any) {
    //console.log(event.target.value);
    if (event.target.value.toLowerCase() === "na" || event.target.value.toLowerCase() === "n/a") {
      this.validaLink = true;
    } else {
      this.validaLink = false;
    }
  }
}