import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-mecanica',
  templateUrl: './mecanica.component.html',
  styleUrls: ['./mecanica.component.scss']
})
export class MecanicaComponent implements OnInit {
  title = 'Conoce la mecánica del Sorteo Anáhuac 2023 - 8ª Edición';
  description = 'Conoce la mecánica del Sorteo Anáhuac 8ª Edición. Toda la información disponible aquí.';
  constructor(private titleService: Title, private metaTagService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'description', content: this.description }
    );

    this.metaTagService.updateTag(
      { property: 'keywords', content: ''}
    );

    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title}
    );

    this.metaTagService.updateTag(
      { property: 'og:description', content: this.description  }
    );

    this.metaTagService.updateTag(
    { property: 'og:url', content: 'https://www.sorteoanahuac.mx/mecanica' }
    );

    this.metaTagService.updateTag(
      { property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/bg-btn.jpg' }
    );
      
    this.metaTagService.updateTag(
      { name: 'twitter:title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: this.description  }
    );
  }

}
