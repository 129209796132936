<section class="stc-ganadores-opt">
    <div class="container">
        <div class="row">

            <div class="col-md-6 my-auto" data-aos="zoom-in">
                <h1>
                    CONOCE
                    <span class="text d-block">
                         A NUESTROS
                    </span>
                    GANADORES 
                    <span class="paragraph d-block">
                        1º SORTEO OPORTUNO
                    </span>
                </h1>
            </div>

            <div class="col-md-6 my-auto">
                <img src="/assets/images/sorteo2024/img-sopt.png"  class="img-fluid">
            </div>

        </div>
    </div>
</section>


<section class="stc-winners-opt">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h2>
                    LISTA OFICIAL DE GANADORES
                    <span class="d-block">
                        1° SORTEO OPORTuNO 2024
                    </span>
                </h2>
            </div>
            <div class="col-md-12 ">
                <div class=" text-center"  style="overflow-x: auto;">
                    
                    <table class="table table-striped ">
                       
                        <thead class="text-center">
                            <tr class="text-center">
                                <th class = "border-none" colspan="4">Compradores</th>
                                <th class = "border-none" colspan="3">Colaboradores</th>
                            </tr>
                            <tr class="text-center cols">
                                <th scope="col"><span>No. DE PREMIO</span></th>
                                <th scope="col"><span>nombre</span></th>
                                <th scope="col"><span>VALOR</span></th>
                                <th scope="col"><span>NÚMERO DE BOLETO</span></th>
                                <th scope="col"><span>NOMBRE</span></th>
                                <th scope="col"><span>VALOR</span></th>
                                <th scope="col"><span>NÚMERO DE BOLETO </span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center first">
                                <td>1</td>
                                <td>Miriam</td>
                                <td> $250,000.00 </td>
                                <td>045380</td>
                                <td>Estefanía </td>
                                <td>$100,000.00</td>
                                <td>POR VENDER EL BOLETO 045380</td>
                            </tr>
                            <tr class="text-center second">
                                <td>2</td>
                                <td>Roberto</td>
                                <td> $150,000.00</td>
                                <td>206169</td>
                                <td>Yazmín Michelle  </td>
                                <td>$50,000.00</td>
                                <td>POR VENDER EL BOLETO 206169</td>
                            </tr>

                            <tr class="text-center first">
                                <td>3</td>
                                <td>Jose</td>
                                <td>$100,000.00</td>
                                <td>348083</td>
                                <td>Maria</td>
                                <td>$25,000.00</td>
                                <td>POR VENDER EL BOLETO 348083</td>
                            </tr>

                            <tr class="text-center second">
                                <td>4</td>
                                <td>Rodolfo</td>
                                <td>$50,000.00</td>
                                <td>217594</td>
                                <td>Pablo</td>
                                <td>$15,000.00</td>
                                <td>POR VENDER EL BOLETO 217594</td>
                            </tr>

                            <tr class="text-center first">
                                <td>5</td>
                                <td>Valeria Renata</td>
                                <td>$25,000.00</td>
                                <td>102464</td>
                                <td>Valeria Renata</td>
                                <td>$5,000.00</td>
                                <td>POR VENDER EL BOLETO 102464</td>
                            </tr>


                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-12">
                <p class="font-bold">
                    SORTEO ANÁHUAC NOVENA EDICIÓN
                </p>
                <p>
                   
                    El Sorteo Anáhuac celebró el día viernes 24 de mayo de 2024, a las 11:15 horas, el Primer Sorteo Acumulado Especial para Compradores y Colaboradores Oportunos del Sorteo Anáhuac Novena Edición en la Prepa Anáhuac México
                    Campus Oxford con domicilio en Torre Prepa Anáhuac, Av. de las Torres 131, Torres de Potrero, Álvaro Obregón, 01780 Álvaro Obregón, CDMX con la presencia del C. Gerardo Roaro Marín, Inspector de la Secretaría de Gobernación,
                    para dar fe de la legalidad del evento.

                    De acuerdo con lo establecido en el <strong>Permiso No. 20240048PS07 otorgado por la Secretaría de Gobernación </strong> a Sorteo Anáhuac, A.C., los resultados de los ganadores, así como los nombres de los colaboradores que se han hecho
                    acreedores a un premio por haber vendido los boletos del premio uno al cinco, han de ser publicados de forma posterior al evento del sorteo en la página de internet https://www.sorteoanahuac.mx/ y nuestras redes sociales a partir del día 25 de mayo de 2024 y de acuerdo a lo registrado ante SEGOB, sólo aparecerán los números de los boletos ganadores, el nombre propio de los ganadores, y la ciudad de residencia de cada un o de ellos, adicionalmente, se
                    publicará la lista impresa con los nombres completos de los ganadores en las Instalaciones de la Universidad Anáhuac México Campus Norte, ubicada en la Av. Universidad no. 46 Colonia Lomas de Anáhuac, CP. 52786, Huixquilucan
                    Estado de México.
                    Los premios mayores a 1,500 (UMA). $162,855.00 (Ciento Sesenta y dos mil ochocientos cincuenta y cinco pesos 00/100 m.n.), se solicitará la presencia de un inspector de la DGJS para la entrega de los mismos, conforme al siguiente
                    calendario de fechas: 07 de junio de 2024 a las 12:00 horas Entrega del Premio 1 de Comprador del Primer Sorteo Acumulado Especial de Compradores y Colaboradores Oportunos del SORTEO ANAHUAC NOVENA EDICIÓN. En las
                    instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786.
                    Los premios serán pagados y entregados de conformidad con lo establecido en las bases de Sorteo, los premios menores a 1,500 (UMA), $162,855.00 (Ciento Sesenta y dos mil ochocientos cincuenta y cinco pesos 00/100 m.n.) se
                    realizará dentro de los 20 (veinte) días hábiles contados a partir de la celebración del sorteo, en Avenida Universidad Anáhuac N° 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan, Estado de México, piso 6 del edificio de Biblioteca,
                    en horario de lunes a jueves de 10:00 a 18:00 hrs. y los viernes de 10:00 a 14:00 hrs.
                    Para más información sobre dónde reclamar la entrega de los premios, documentos necesarios para recibirlos diríjase a: www.sorteoanahuac.mx, o a la oficina del Sorteo Anáhuac, A.C. ubicada en la calle “Av. Universidad Anáhuac
                    no. 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan, Estado de México o al teléfono: 01(55) 5328-8096 ext 2137.
                    Las personas que resulten ganadoras deberán presentar una identificación oficial vigente con fotografía (credencial INE, pasaporte), Constancia de situación Fiscal de RFC, comprobante de domicilio (no mayor a tres meses) y CURP;
                    el nombre completo escrito en el boleto debe corresponder exactamente con el del talón o con el registro en la APP del Sorteo Anáhuac y su identificación oficial.
                    En caso de queja derivada de este sorteo, la autoridad competente es la Dirección General de Juegos y Sorteos de la Secretaría de Gobernación ubicada en la calle de Versalles 49, piso 2, Col. Juárez, Del. Cuauhtémoc, C.P. 06600,
                    Ciudad de México o bien comunicarse al teléfono: 01(55) 5209 8800.
                </p>

                
            </div>
            
        </div>
    </div>
</section>