import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormEgrService } from 'src/services/form-egr.service';

@Component({
  selector: 'app-formulario-documentos',
  templateUrl: './formulario-documentos.component.html',
  styleUrls: ['./formulario-documentos.component.scss']
})
export class FormularioDocumentosComponent implements OnInit {
  fileName = '';
  //archivos: any = [];
  archivos =   [];
  imageToUpload: File = null;
  imageSource;
  flags: any = [];
  flagError= false;
  archivosGanador: any = [];
  archivosNewEstruc: any = [];
  flagArchives = false;
  docs = [
      { nombreArchivo:"Boleto", idArchivo: "DOC_BOLETO", required: true, send: false },
      { nombreArchivo:"Identificación",  idArchivo: "IDENTIFICACION", required: true, send: false },
      { nombreArchivo:"Comprobante de domicilio",  idArchivo: "COMP_DOMICILIO", required: true, send: false },
      { nombreArchivo:"Curp",  idArchivo: "CURP", required: true, send: false },
      { nombreArchivo:"Contancia de situacion fiscal",  idArchivo: "RFC_DOCUMENTO", required: true, send: false },
      { nombreArchivo:"Acta de nacimiento",  idArchivo: "ACTA_NACIMIENTO", required: false, send: false },
      { nombreArchivo:"Caratula del estado de cuenta bancaria",  idArchivo: "NUMERO_CTA_BANCARIO", required: false, send: false },
      { nombreArchivo:"Acta de matrimonio de padres",  idArchivo: "ACTA_MATRIMONIO_PAPAS", required: false, send: false} ,
      { nombreArchivo:"Identificación del padre o tutor",  idArchivo: "ID_PAPA_TUTOR", required: false, send: false },
      { nombreArchivo:"Carta poder",  idArchivo: "CARTA_PODER", required: false, send: false },
      { nombreArchivo:"Identificación de quien recibe carta poder",  idArchivo: "ID_RECIBE_PODER", required: false, send: false },
      { nombreArchivo:"Identificación quien otorga carta poder",  idArchivo: "ID_OTORGA_PODER", required: false, send: false },
      { nombreArchivo:"Identificación testigo 1",  idArchivo: "ID_TESTIGO1", required: false, send: false },
      { nombreArchivo:"Identificación testigo 2",  idArchivo: "ID_TESTIGO2", required: false, send: false },
      { nombreArchivo:"Acta de defunción",  idArchivo: "ACTA_DEFUNCION", required: false, send: false },
  ]
  constructor(private router: Router, private readonly _formService: FormEgrService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if(!sessionStorage.getItem('tokken')){
      window.location.href = '/login-ganadores'
    } 
    this.getDoc();
     //(<any>$('#modal-warning')).modal('show');
    // this.flagArchives = true;
    // this.flagError = false;
    // this._formService.getArchivosGandor().subscribe( a => {
    //   this.archivosGanador = a;
    //   if( this.archivosGanador.Valida_rfc_documento >= 0 ) {
    //     this.archivosNewEstruc.push(
    //       { nombreArchivo:"Boleto",                           archivo:  this.archivosGanador.Doc_boleto},
    //       { nombreArchivo:"Identificación",                   archivo: this.archivosGanador.Identificacion},
    //       { nombreArchivo:"Comprobante de domicilio",         archivo: this.archivosGanador.Comp_domicilio},
    //       { nombreArchivo:"Curp",                             archivo: this.archivosGanador.Curp},
    //       { nombreArchivo:"RFC",                              archivo: this.archivosGanador.Rfc_documento},
    //       { nombreArchivo:"Acta de nacimiento",               archivo: this.archivosGanador.Acta_nacimiento},
    //       { nombreArchivo:"Caratula del estado de cuenta",    archivo: this.archivosGanador.Numero_cta_bancario},
    //       { nombreArchivo:"Acta de matrimonio de padres",     archivo: this.archivosGanador.Acta_matrimonio_papas},
    //       { nombreArchivo:"Identificación del padre o tutor", archivo: this.archivosGanador.Id_papa_tutor},
    //       { nombreArchivo:"Carta poder",                      archivo: this.archivosGanador.Carta_poder},
    //       { nombreArchivo:"Identificación de quien recibe carta poder",   archivo: this.archivosGanador.Id_recibe_poder},
    //       { nombreArchivo:"Identificación quien otorga carta poder",  archivo: this.archivosGanador.Id_otorga_poder},
    //       { nombreArchivo:"Identificación testigo 1",  archivo: this.archivosGanador.Id_testigo1},
    //       { nombreArchivo:"Identificación testigo 2",  archivo: this.archivosGanador.Id_testigo2},
    //       { nombreArchivo:"Acta de defunción",  archivo: this.archivosGanador.Acta_defuncion},
    //     );
    //   }else {
    //     this.flagError = true;
    //   }
      
    //   console.log(this.archivosNewEstruc);
    //   this.flagArchives = false;
    // },
    // err => {
    //   this.flagArchives = false;
    //     console.log(err);
    //     this.flagError = false;
    // });

   
  }


  logout() {
    this._formService.logOutGanadores(sessionStorage.getItem("tokken")).subscribe( a => {
      //console.log(a)
    },
    err => {
        //console.log(err);
        sessionStorage.removeItem('tokken');
        window.location.href = "/login-ganadores";

    });
  }


  // onFileSelected(event) {
  //   console.log(event.target.files.mozFullPath);
  //   const archivoCaprturado = event.target.files[0];
  //   let idArchivo = "CURP";
  
  
  //   const formData = new FormData();

  //   formData.append("file", archivoCaprturado);
    

  //   console.log(archivoCaprturado);

  //   this._formService.sendArchive(formData, idArchivo).subscribe( a => {
  //     console.log(a)
  //   },
  //   err => {
  //       console.log(err);
  //   });
    
  // }

  capturarFile(event, id) {
    const archivosCapturados = event.target.files[0];
    this.archivos[id] = archivosCapturados;
    //console.log(this.archivos);
    this.flags[id] = false;
    //console.log(this.flags);
    this.docs[id].send = false;
  }

  subirArchivo(id, idArchivo) {
    const formularioDatos = new FormData();
    //console.log(idArchivo);
    //console.log(this.archivos[id]);
    formularioDatos.append("file",this.archivos[id]);
    //console.log(formularioDatos);
    //console.log(formularioDatos.get("file"));
    
    this.flags[id] = true;
    this._formService.sendArchive(formularioDatos, idArchivo).subscribe( a => {
      //console.log(a);
      this.flags[id] = false;
      this.docs[id].send = true;
    },
    err => {
      this.flags[id] = false;
       // console.log(err);
    });
  }


  getDoc() {
    this.archivosNewEstruc = [];
    this.flagArchives = true;
    this.flagError = false;
    this._formService.getArchivosGandor().subscribe( a => {
      this.archivosGanador = a;
      //console.log(this.archivosGanador);
      if( this.archivosGanador.Valida_rfc_documento >= 0 )  {
        this.archivosNewEstruc.push(
          { nombreArchivo:"Boleto",                           archivo: (this.archivosGanador.Doc_boleto.split('/'))[1], ruta:  this.archivosGanador.Doc_boleto, estadoArchivo: this.archivosGanador.Valida_doc_boleto ?? 0},
          { nombreArchivo:"Identificación",                   archivo: (this.archivosGanador.Identificacion.split('/'))[1], ruta: this.archivosGanador.Identificacion, estadoArchivo: this.archivosGanador.Valida_identificacion ?? 0},
          { nombreArchivo:"Comprobante de domicilio",         archivo: (this.archivosGanador.Comp_domicilio.split('/'))[1], ruta: this.archivosGanador.Comp_domicilio, estadoArchivo: this.archivosGanador.Valida_comp_domicilio ?? 0},
          { nombreArchivo:"Curp",                             archivo: (this.archivosGanador.Curp.split('/'))[1], ruta: this.archivosGanador.Curp, estadoArchivo: this.archivosGanador.Valida_curp ?? 0},
          { nombreArchivo:"Contancia de situacion fiscal",                              archivo: (this.archivosGanador.Rfc_documento.split('/'))[1], ruta: this.archivosGanador.Rfc_documento, estadoArchivo: this.archivosGanador.Valida_rfc_documento ?? 0},
          { nombreArchivo:"Acta de nacimiento",               archivo: (this.archivosGanador.Acta_nacimiento.split('/'))[1], ruta: this.archivosGanador.Acta_nacimiento, estadoArchivo: this.archivosGanador.Valida_acta_nacimiento ?? 0},
          { nombreArchivo:"Caratula del estado de cuenta bancaria",    archivo: (this.archivosGanador.Numero_cta_bancario.split('/'))[1], ruta: this.archivosGanador.Numero_cta_bancario, estadoArchivo: this.archivosGanador.Valida_numero_cta_bancario ?? 0},
          { nombreArchivo:"Acta de matrimonio de padres",     archivo: (this.archivosGanador.Acta_matrimonio_papas.split('/'))[1], ruta: this.archivosGanador.Acta_matrimonio_papas, estadoArchivo: this.archivosGanador.Valida_acta_matrimonio_papas ?? 0},
          { nombreArchivo:"Identificación del padre o tutor", archivo: (this.archivosGanador.Id_papa_tutor.split('/'))[1], ruta: this.archivosGanador.Id_papa_tutor, estadoArchivo: this.archivosGanador.Valida_id_papa_tutor ?? 0},
          { nombreArchivo:"Carta poder",                      archivo: (this.archivosGanador.Carta_poder.split('/'))[1], ruta: this.archivosGanador.Carta_poder, estadoArchivo: this.archivosGanador.Valida_carta_poder ?? 0},
          { nombreArchivo:"Identificación de quien recibe carta poder",   archivo: (this.archivosGanador.Id_recibe_poder.split('/'))[1], ruta: this.archivosGanador.Id_recibe_poder, estadoArchivo: this.archivosGanador.Valida_id_recibe_poder ?? 0},
          { nombreArchivo:"Identificación quien otorga carta poder",  archivo: (this.archivosGanador.Id_otorga_poder.split('/'))[1], ruta: this.archivosGanador.Id_otorga_poder, estadoArchivo: this.archivosGanador.Valida_id_otorga_poder ?? 0},
          { nombreArchivo:"Identificación testigo 1",  archivo: (this.archivosGanador.Id_testigo1.split('/'))[1], ruta: this.archivosGanador.Id_testigo1, estadoArchivo: this.archivosGanador.Valida_id_testigo1 ?? 0},
          { nombreArchivo:"Identificación testigo 2",  archivo: (this.archivosGanador.Id_testigo2.split('/'))[1] , ruta: this.archivosGanador.Id_testigo2, estadoArchivo: this.archivosGanador.Valida_id_testigo2 ?? 0},
          { nombreArchivo:"Acta de defunción",  archivo: (this.archivosGanador.Acta_defuncion.split('/'))[1], ruta: this.archivosGanador.Acta_defuncion, estadoArchivo: this.archivosGanador.Valida_acta_defuncion ?? 0},
        );

    
        // this.archivosNewEstruc.push(
        //   { nombreArchivo:"Boleto",                           archivo: (this.archivosGanador.Doc_boleto.split('/'))[1], ruta:  this.archivosGanador.Doc_boleto, estadoArchivo: 0},
        //   { nombreArchivo:"Identificación",                   archivo: (this.archivosGanador.Identificacion.split('/'))[1], ruta: this.archivosGanador.Identificacion, estadoArchivo: 1},
        //   { nombreArchivo:"Comprobante de domicilio",         archivo: (this.archivosGanador.Comp_domicilio.split('/'))[1], ruta: this.archivosGanador.Comp_domicilio, estadoArchivo: 2},
        //   { nombreArchivo:"Curp",                             archivo: (this.archivosGanador.Curp.split('/'))[1], ruta: this.archivosGanador.Curp, estadoArchivo: 3},
        //   { nombreArchivo:"RFC",                              archivo: (this.archivosGanador.Rfc_documento.split('/'))[1], ruta: this.archivosGanador.Rfc_documento, estadoArchivo:-1},
        //   { nombreArchivo:"Acta de nacimiento",               archivo: (this.archivosGanador.Acta_nacimiento.split('/'))[1], ruta: this.archivosGanador.Acta_nacimiento, estadoArchivo: 1},
        //   { nombreArchivo:"Caratula del estado de cuenta",    archivo: (this.archivosGanador.Numero_cta_bancario.split('/'))[1], ruta: this.archivosGanador.Numero_cta_bancario, estadoArchivo: 2},
        //   { nombreArchivo:"Acta de matrimonio de padres",     archivo: (this.archivosGanador.Acta_matrimonio_papas.split('/'))[1], ruta: this.archivosGanador.Acta_matrimonio_papas, estadoArchivo: 0},
        //   { nombreArchivo:"Identificación del padre o tutor", archivo: (this.archivosGanador.Id_papa_tutor.split('/'))[1], ruta: this.archivosGanador.Id_papa_tutor, estadoArchivo: 0},
        //   { nombreArchivo:"Carta poder",                      archivo: (this.archivosGanador.Carta_poder.split('/'))[1], ruta: this.archivosGanador.Carta_poder, estadoArchivo:0},
        //   { nombreArchivo:"Identificación de quien recibe carta poder",   archivo: (this.archivosGanador.Id_recibe_poder.split('/'))[1], ruta: this.archivosGanador.Id_recibe_poder, estadoArchivo: 0},
        //   { nombreArchivo:"Identificación quien otorga carta poder",  archivo: (this.archivosGanador.Id_otorga_poder.split('/'))[1], ruta: this.archivosGanador.Id_otorga_poder, estadoArchivo: 0},
        //   { nombreArchivo:"Identificación testigo 1",  archivo: (this.archivosGanador.Id_testigo1.split('/'))[1], ruta: this.archivosGanador.Id_testigo1, estadoArchivo: 0},
        //   { nombreArchivo:"Identificación testigo 2",  archivo: (this.archivosGanador.Id_testigo2.split('/'))[1] , ruta: this.archivosGanador.Id_testigo2, estadoArchivo: 0},
        //   { nombreArchivo:"Acta de defunción",  archivo: (this.archivosGanador.Acta_defuncion.split('/'))[1], ruta: this.archivosGanador.Acta_defuncion, estadoArchivo:0},
        // );
      }else {
        this.flagError = true;
      }
      //console.log(this.archivosNewEstruc);
      this.flagArchives = false;
    },
    err => {
     // console.log("AQUIIII");
      this.archivosNewEstruc.push(
        { nombreArchivo: "Boleto", archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo: "Identificación", archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"Comprobante de domicilio",  archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"Curp",  archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"RFC",   archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"Acta de nacimiento", archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"Caratula del estado de cuenta",  archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"Acta de matrimonio de padres", archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"Identificación del padre o tutor", archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"Carta poder", archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"Identificación de quien recibe carta poder", archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"Identificación quien otorga carta poder", archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"Identificación testigo 1",  archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"Identificación testigo 2", archivo: "", ruta: "", estadoArchivo: 0 },
        { nombreArchivo:"Acta de defunción",  archivo: "", ruta: "", estadoArchivo: 0 },
      );
        console.log(err);
        this.flagArchives = false;
        this.flagError = true;
    });
  }

  descargarArchivo(ruta: string) {
    const nombreArchivo = ruta; // Reemplaza con el nombre de tu archivo
    this._formService.getArchivosGandorUnico(nombreArchivo).subscribe((blob: Blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = nombreArchivo;
      link.click();
    }, error => {
      console.log('Error al descargar el archivo:', error);
    });
   // window.location.href = 'http://localhost:2393/api/usuariosganadores/obtenerArchivo?fileName='  + ruta;
    
  }

}
