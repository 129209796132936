<section id="faqs" >
    <div class="container">
        <div class="row">
            <div class="col-lg-12 my-auto" data-aos="fade-left"  data-aos-easing="ease"  data-aos-duration="3000">
                <img src="/assets/images/sorteo2024/faqs.png" alt="" class="img-fluid">
            </div>
        </div>
    </div>
</section>

<section class="faqs" data-aos="fade-up" data-aos-easing="ease"  data-aos-duration="3000">
    <div class="container">
        <div class="row">

            <div class="col-md-12 ">

                <div id="accordion">

                    <div class="card">
                        <div class="card-header w-100" id="faq2">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                <span class="f-bold">
                                    1. Información general Sorteo Anáhuac Novena Edición
                                </span>
                            </a>
                        </div>

                        <div id="collapse2" class="collapse " aria-labelledby="faq2" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    ¡Hola! El costo de nuestro boleto de la Novena Edición es de $450 pesos. Con la compra de un boleto apoyas a miles de líderes para que tengan la oportunidad de estudiar una carrera profesional en la Red de Universidades Anáhuac.
                                </p>
                                <p>
                                    ¡Tenemos más de 46 millones de pesos en premios a repartir! El sorteo se llevará a cabo el día 21 de noviembre de 2024 a las 17:00, en el I.E.S.T Anáhuac y se transmitirá vía Facebook Live y YouTube.
                                </p>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq3">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                                <span class="f-bold">
                                    2. ¿Qué hago si estoy en el extranjero y no puedo descargar la APP de Sorteo Anáhuac?
                                </span>
                            </a>
                        </div>

                        <div id="collapse3" class="collapse " aria-labelledby="faq3" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    La APP está habilitada para ser descargada en el extranjero. Si existe algún problema para hacer la descarga, te pedimos revisar las restricciones del país en donde radicas, o bien:
                                </p>
                                <p>
                                    1. Contactar por correo electrónico al Enlace Operativo del Sorteo de tu Universidad para informarle de esta situación.
                                </p>
                                <p>
                                    2. Proporcionarle al Enlace Operativo del Sorteo tu correo y contraseña de la APP para que pueda acceder a ella y te pueda compartir la liga de tus boletos por medio de WhatsApp.
                                </p>
                                <p>
                                    3. Comparte la liga por redes sociales a tus conocidos, amigos y familiares para empezar la venta de tus boletos.
                                </p>
                            </div>
                        </div> 
                    </div>


                   

                 

                    <div class="card">
                        <div class="card-header " id="faq6">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                                <span class="f-bold">
                                    3. ¿Cuáles son las fechas límite del Sorteo Anáhuac para pagar los boletos?
                                </span>
                            </a>
                        </div>

                        <div id="collapse6" class="collapse" aria-labelledby="faq6" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    <strong>Alumnos Becarios de Reingreso:</strong> 28 de agosto de 2024.
                                </p>
                                <p>
                                    <strong>Alumnos de Nuevo Ingreso:</strong> 15 de octubre de 2024 
                                </p>
                                <p> 
                                    <strong>Voluntarios de comunidad Universitaria:</strong> 07 de octubre de 2024
                                </p>
                                <p>
                                    <strong>Voluntarios comunidad externa:</strong> 15 de mayo de 2024
                                </p>
                                <p>
                                   <strong> Alumnos Becarios de Reingreso 1er. Corte de Pago:</strong> 10 de mayo de 2024.
                                </p>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq7">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                                <span class="f-bold"> 4. ¿Qué hacer si me equivoco al escribir el nombre del comprador? </span>
                            </a>
                        </div>

                        <div id="collapse7" class="collapse" aria-labelledby="faq7" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    <strong>Boletos Físicos:</strong> ¡No lo borres, taches o pongas corrector líquido! Acércate a la Oficina del Sorteo Anáhuac de tu Universidad y ahí te guiarán en el proceso que debes realizar para su corrección.
                                </p>
                                <p>
                                    <strong>Boletos Digitales:</strong> Acércate a la Oficina del Sorteo Anáhuac de tu Universidad y te daremos la solución a tu problema.
                                </p>

                            </div>
                        </div> 
                    </div>


                    <div class="card">
                        <div class="card-header " id="faq8">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                                <span class="f-bold">5. ¿Qué pasa si gano un premio?</span>
                            </a>
                        </div>

                        <div id="collapse8" class="collapse" aria-labelledby="faq8" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    En el Sorteo Anáhuac te buscaremos para coordinar la entrega de documentos por medio de nuestra página web y la presentación del boleto ganador; o bien, debes comunicarte inmediatamente a las oficinas del Sorteo de tu universidad o bien llamarnos al 5328 8096 ext. 2137.
                                </p>
                                <p>
                                    Te recordamos que es importante que presentes el boleto ganador y que éste no tenga raspaduras o enmendaduras.
                                </p>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq9">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                                <span class="f-bold">6. Pasos para uso de la App</span>
                            </a>
                        </div>

                        <div id="collapse9" class="collapse" aria-labelledby="faq9" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    1. Puedes descargar y actualizar la App del Sorteo Anáhuac desde cualquier dispositivo móvil. Los boletos son 100% digitales para que puedas vender, distribuir y cobrar tus boletos desde casa.
                                </p>
                                <p>
                                    2. Si estás en el extranjero, la App ya se encuentra habilitada para poder descargarla o bien, escríbenos a <a href="mailto:sorteoanahuac@anahuac.mx">sorteoanahuac@anahuac.mx</a> para que te proporcionemos soporte técnico y puedas vender los boletos de forma digital.
                                </p>
                                <p>
                                    3. Ingresa con tu correo Institucional (@anahuac.mx). En caso de que tengas dificultades, valida tus datos con el área de TI de tu universidad.
                                </p>
                                <p>
                                    4. Verifica que ya tengas tus boletos asignados. Si no te aparecen, contacta al Enlace Operativo del Sorteo en tu Universidad o escríbenos al mail: <a href="mailto:sorteoanahuac@anahuac.mx">sorteoanahuac@anahuac.mx</a>
                                </p>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq10">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                                <span class="f-bold">7. ¿Puedo tener varias sesiones abiertas de mi cuenta en varios dispositivos móviles?</span>
                            </a>
                        </div>

                        <div id="collapse10" class="collapse" aria-labelledby="faq10" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    Sí puedes tener varias sesiones al mismo tiempo, sin embargo, no te recomendamos hacerlo ya que tendrías que proporcionar tu contraseña. Recuerda que tanto el usuario y la contraseña de la aplicación es completamente tu responsabilidad.
                                </p>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq11">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                                <span class="f-bold"> 8. ¿Qué hago si el boleto no le llega al comprador a su correo electrónico?</span>
                            </a>
                        </div>

                        <div id="collapse11" class="collapse" aria-labelledby="faq11" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    Deberás verificar el correo electrónico al que enviaste el boleto. Esto lo puedes ver al abrir tu talonario asignado en donde se encuentra el boleto y pulsas la palabra consulta. Si el correo es correcto, puedes reenviarlo pulsando el icono que dice compartir a comprador de color naranja que aparece en la parte debajo de la pantalla. En caso contrario deberás acudir a las oficinas del Sorteo para solicitar la corrección correspondiente (correo correcto) y en un plazo que se te indicará podrás reenviar el correo que contiene el boleto electrónico.
                                </p>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq12">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapse12">
                                <span class="f-bold"> 9. Dejé mi sesión abierta y alguien puso los boletos a su nombre ¿Cómo puedo corregir los boletos?</span>
                            </a>
                        </div>

                        <div id="collapse12" class="collapse" aria-labelledby="faq12" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    Dejar los boletos o talonarios tal y como ocurrieron los hechos y acudir inmediatamente con el enlace operativo del Sorteo de tu universidad para que te den orientación personalizada y poder proceder.
                                </p>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq13">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse13" aria-expanded="true" aria-controls="collapse13">
                                <span class="f-bold">10. La APP sufrió un crash y el boleto me aparece como vendido, pero sin información. </span>
                            </a>
                        </div>

                        <div id="collapse13" class="collapse" aria-labelledby="faq13" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    Acude a las oficinas del Sorteo para la corrección del problema y continuar con la venta de tus boletos.
                                </p>
                            </div>
                        </div> 
                    </div>


                    <div class="card">
                        <div class="card-header " id="faq14">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse14" aria-expanded="true" aria-controls="collapse14">
                                <span class="f-bold">
                                    11. ¿Qué pasa si extravío o me roban mi boleto físico o el talonario de boletos físicos?    
                                </span>
                            </a>
                        </div>

                        <div id="collapse14" class="collapse" aria-labelledby="faq14" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    Deberás levantar una constancia de hechos en el caso de extravío, o un acta de denuncia para el caso de robo en el Ministerio Público donde te encuentres, señalando el número de boleto o boletos que se extraviaron o robaron, señalar el nombre del dueño de los boletos que estaban ya vendidos y presentar el Acta del Ministerio Público en la oficina del Sorteo Anáhuac de tu universidad para que la entregues y a su vez, Sorteo Anáhuac haga el reporte respectivo ante la Dirección General de Juegos y Sorteo.
                                </p>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq15">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse15" aria-expanded="true" aria-controls="collapse15">
                                <span class="f-bold">
                                    12. ¿Qué pasa si se dañan o manchan mis boletos físicos? 
                                </span>
                            </a>
                        </div>

                        <div id="collapse15" class="collapse" aria-labelledby="faq15" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    Dejar los boletos o talonarios tal y como ocurrieron los hechos y acudir inmediatamente con el enlace operativo del Sorteo de tu universidad para que te den orientación personalizada y poder proceder.
                                </p>
                            </div>
                        </div> 
                    </div>



                    <div class="card">
                        <div class="card-header " id="faq17">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse17" aria-expanded="true" aria-controls="collapse17">
                                <span class="f-bold">13. ¿Cuál es la cuenta de depósito?</span>
                            </a>
                        </div>

                        <div id="collapse17" class="collapse" aria-labelledby="faq17" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    Para pagos de boletos digitales, se puede hacer mediante la App o por la página web del Sorteo, hay opciones de pago con tarjeta de crédito, débito y pagos referenciados. Para pagos de boletos físicos, la cuenta es Banco Banamex a nombre de “Sorteo Anáhuac, A.C.” con número de cuenta: 1130708, Sucursal 7012, más tu número de referencia bancaria que te proporcionaron en la entrega de los boletos.                  
                                </p>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq18">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse18" aria-expanded="true" aria-controls="collapse18">
                                <span class="f-bold">14. ¿Qué hago si pierdo mi número de referencia personal?</span>
                            </a>
                        </div>

                        <div id="collapse18" class="collapse" aria-labelledby="faq18" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    Para boletos digitales por medio de la App: Ahora el sistema de pago será diferente, por lo que no tendrás una sola referencia ya que por cada operación de pago que realices se te generará automáticamente una nueva, ya sea que pagues con tarjeta de crédito, débito o en ventanilla bancaria.                     
                                </p>
                                <p>
                                    Para boletos físicos: Contacta al enlace operativo de tu universidad en donde te podrán proporcionar nuevamente tu referencia de pago para Banamex.
                                </p>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq19">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse19" aria-expanded="true" aria-controls="collapse19">
                                <span class="f-bold">15. ¿Cuándo, dónde y a qué hora es el Sorteo Anáhuac?</span>
                            </a>
                        </div>

                        <div id="collapse19" class="collapse" aria-labelledby="faq19" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    Jueves 21 de noviembre de 2024, 17:00 hrs en las instalaciones del I.E.S.T. ANÁHUAC. El acceso al evento es libre y gratuito.                     
                                </p>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq20">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse20" aria-expanded="true" aria-controls="collapse20">
                                <span class="f-bold">16. ¿Dónde y cuándo serán entregados los premios?</span>
                            </a>
                        </div>

                        <div id="collapse20" class="collapse" aria-labelledby="faq20" data-parent="#accordion">
                            <div class="card-body">
                                <p>
                                    Los premios se pagarán y entregarán según lo establecido en las bases de Sorteo, los premios menores a 1.500 (UMA). $155,610.00 (Ciento Cincuenta y cinco mil seiscientos diez pesos 00/100 m.n.), se realizará dentro de los 20 (veinte) días hábiles contados a partir de la celebración del sorteo, en Avenida Universidad Anáhuac N° 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan, Estado de México, piso 7 del edificio de Biblioteca, en horario de lunes a jueves de 10:00 a 18:00 horas y los viernes de 10:00 a 14:00 horas.
                                </p>
                                <p>
                                    Referente a los premios de colaborador, el valor neto de los premios del 5° al 433° que consisten en un cheque nominativo, previo acuerdo con los ganadores, éste tendrá tres opciones:
                                </p>
                                <ul>
                                    <li>
                                        Se procederá a realizar el depósito del cheque del premio a una cuenta bancaria a nombre del ganador fungiendo la ficha de depósito como acuse de recibo por parte del ganador misma que se anexará al formato de acuse de recibo.
                                    </li>
                                    <li>
                                        Por otro lado, en caso de que el ganador prefiera una transferencia bancaria se hará el depósito y se adjuntará en el acuse de recibo el comprobante bancario de la transferencia a nombre del ganador.
                                    </li>
                                    <li>
                                        En caso de que el ganador así lo prefiera, se le entregará el cheque nominativo con acuse de recibo. 
                                    </li>
                                </ul>
                                <p>
                                    En los tres casos, para la comprobación de entrega de premios a los ganadores, se realizará conforme a los lineamientos de ley con un acuse de recibo en donde se describe el premio al ganador.
                                </p>
                                <p>
                                    Los premios mayores a 1,500 (UMA) $162,855.00 (Ciento Sesenta y dos mil ochocientos cincuenta y cinco pesos 00/100 m.n.), se solicitará la presencia de un inspector de la DGJYS para la entrega de los mismos, conforme al siguiente calendario de fechas:
                                </p>
                                <ul>
                                    <li>
                                        10 de diciembre de 2024 a las 11:00 horas: Entrega de los premios 4° al 24° directos y del 1° al 4° de colaborador, en las instalaciones de la universidad Anáhuac México Campus Norte con domicilio en Avenida Universidad Anáhuac no. 46 col. Lomas de Anáhuac c.p.52786 en Huixquilucan, Estado de México, México.
                                    </li>
                                    <li>
                                        14 de diciembre de 2024 a las 11:00 horas: Entrega del premio 1 ubicado en Avenida Antonio Enriquez Savignac, Torre Aire Int: UPE 2-30 Condominio Residencial Brezza Lote 4C-4 Manzana 01 localidad Cancún Supermanzana 4-A, Municipio Benito Juárez, Cancún, Quintana Roo, México.
                                    </li>
                                    <li>
                                        11 de diciembre de 2024 a las 11:00 horas: del premio 2 en Boulevard Bosque Real número 180 torre A lote 8 de la manzana v departamento 2402 colonia Bosque Real C.P. 52774 Huixquilucan, Estado de México, México.
                                    </li>
                                    <li>
                                        13 de diciembre de 2024 a las 11:00 horas: Del premio 3 ubicado en Condominio El Origen Lote 16 localidad Komchen, Mérida, Yucatán, México C.P. 97302.
                                    </li>
                                </ul>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq21">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse21" aria-expanded="true" aria-controls="collapse21">
                                <span class="f-bold">
                                    17. Documentos a preparar en caso de ser ganador:
                                </span>
                            </a>
                        </div>

                        <div id="collapse21" class="collapse" aria-labelledby="faq21" data-parent="#accordion">
                            <div class="card-body">
                                <ul>
                                    <li>
                                        Boleto ganador: Si el Boleto es digital, es boleto impreso del correo electrónico que les llegó.
                                    </li>
                                    <li>
                                        Si el Boleto es Físico deberán escanear el boleto y el original entregarlo en la Oficina Central de Sorteo Anáhuac o entregarlo en cualquiera de nuestras oficinas sedes de nuestras universidades.
                                    </li>
                                    <li>
                                        Identificación oficial vigente por ambos lados. (Credencial del INE o IFE, Pasaporte)
                                    </li>
                                    <li>
                                        Comprobante de domicilio no mayor a 3 meses de antigüedad, aunque no esté a su nombre. (luz, agua, predial o teléfono fijo)
                                    </li>
                                    <li>
                                        CURP (si no la tienen díganles que lo pueden imprimir en las páginas oficiales del gobierno)
                                    </li>
                                    <li>
                                        RFC (Si aún no tienen la constancia, generarla en la página web del SAT o generar una cita para que los atiendan personalmente en dichas oficinas en caso de requerirlo)
                                    </li>
                                </ul>
                                <p>
                                    Todos estos documentos son OBLIGATORIOS presentarlos para el proceso de entrega de tu premio.
                                </p>
                            </div>
                        </div> 
                    </div>

                    <div class="card">
                        <div class="card-header " id="faq22">
                            <a class="btn-link" data-toggle="collapse" data-target="#collapse22" aria-expanded="true" aria-controls="collapse22">
                                <span class="f-bold">
                                    18. ¿Qué documentos debo de llevar para recoger un premio?
                                </span>
                            </a>
                        </div>

                        <div id="collapse22" class="collapse" aria-labelledby="faq22" data-parent="#accordion">
                            <div class="card-body">
                              <p>
                                Para recibir sus premios, independientemente de los documentos enviados con anterioridad a las Oficinas Centrales del Sorteo previa verificación, los ganadores deberán mostrar en caso de tener un boleto físico, la parte del contra talón (boleto original) con el número ganador e identificación oficial vigente con fotografía. Para los boletos digitales, el ganador solo deberá mostrar mediante su aparato móvil la imagen del boleto digital con el número ganador por correo electrónico al comprarlo y su identificación oficial vigente con fotografía.
                              </p>
                            </div>
                        </div> 
                    </div>

                    
                </div>
               
            </div>
          
        </div>
    </div>
</section>

