<section class="stc-legales">
    <div class="container">
        <div class="row">
            <div class="col-md-12 my-auto" >
                <h1>
                    CARTA DE CONFIDENCIALIDAD
                </h1>

            </div>
        </div>
    </div>
</section>


<section class="stc-legal">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="container-legal"  data-aos="fade-up" data-aos-easing="ease"data-aos-duration="3000">
                   
                    <p class="m-0">
                        SORTEO ANÁHUAC, A.C.
                    </p>
                    <p class="m-0">
                        Avenida Universidad Anáhuac número 46, 
                    </p>
                    <p  class="m-0">
                        Edificio de biblioteca, piso 7,
                    </p>
                    <p  class="m-0">
                        Oficina del Sorteo Anáhuac,
                    </p>
                    <p  class="m-0">
                        Colonia Lomas Anáhuac, 
                    </p>
                    <p  class="m-0">
                        Huixquilucan Estado de México, C.P. 52786.
                    </p>

                    <p >
                        Atn’ Lic. Adán Macario Maldonado Martínez.
                        Director único.                        
                    </p>

                    <p>
                        El suscrito reconoce que he tenido y seguiremos teniendo acceso a diversa información y/o documentación comercial, jurídica, técnica, administrativa y contable proporcionada por Sorteo Anáhuac, A.C. (en lo sucesivo “SORTEO ANÁHUAC”) relacionada con el Sorteo Anáhuac (en lo sucesivo “EL SORTEO”) y que dicha información tiene carácter de confidencial, independientemente de que haya estado o no identificada como tal al momento de su entrega.
                    </p>
                    <p>
                        Asimismo, manifiesto que la información confidencial (en lo sucesivo la “INFORMACIÓN”) a la que he tenido acceso previo a la firma de la presente carta de confidencialidad, la he mantenido en carácter estrictamente confidencial.
                    </p>
                    <p>
                        Adicionalmente, de conformidad con el artículo 83 bis de la Ley Federal del Derecho de Autor, estoy de acuerdo en que la totalidad de la información o elementos que se lleguen a desarrollar en relación con EL SORTEO serán de la propiedad exclusiva de SORTEO ANÁHUAC y serán considerados como parte de la INFORMACIÓN por lo que, en caso de que SORTEO ANÁHUAC así lo solicite, me obligo a llevar a cabo las acciones y a firmar los documentos pertinentes para que la información o elementos mencionados queden en propiedad de SORTEO ANÁHUAC o de quien esta última designe.
                    </p>
                    <p>
                        En virtud de lo anterior, me obligo a no divulgar la INFORMACIÓN a terceras personas sin el consentimiento previo y por escrito de SORTEO ANÁHUAC, a no usarla en beneficio propio o en beneficio de terceras personas, a no llevar a cabo ninguna acción que pueda llegar a comprometer o poner en riesgo los derechos de propiedad intelectual contenidos o derivados de la INFORMACIÓN, incluyendo la validez y vigencia de patentes, marcas, derechos de autor o cualquier derecho de propiedad industrial o intelectual que sea propiedad o que utilice SORTEO ANÁHUAC y a hacer del conocimiento de esta última cualquier divulgación que sobre la INFORMACIÓN se haga o pudiera llegar a hacerse por cualesquier tercero y sobre la que tuviere conocimiento. 
                    </p>
                    <p>
                        De la misma manera, me obligo a informar oportunamente a SORTEO ANÁHUAC en caso de que alguna autoridad que esté legítimamente autorizada solicite la INFORMACIÓN, a fin de que tome las medidas que considere pertinentes.
                    </p>
                    <p>
                        Igualmente me comprometo a no sacar copias ni reproducir por cualquier medio la INFORMACIÓN sin el consentimiento previo y por escrito por parte de SORTEO ANÁHUAC y a devolverla, en forma inmediata en el momento en que ésta se lo solicite, así como a destruir las copias que por cualquier medio hubiere sacado.
                    </p>
                    <p>
                        De igual forma reconozco que toda la información que me ha proporcionado SORTEO ANÁHUAC, constituye un Secreto Industrial, de conformidad con lo establecido en el Artículo 82 de la Ley de la Propiedad Industrial, por lo que me obligo a manejarla bajo la más estricta confidencialidad, sometiéndome a las disposiciones y sanciones previstas en dicho ordenamiento.
                    </p>
                    <p>
                        La presente carta no implica obligación alguna por parte de SORTEO ANÁHUAC de celebrar ningún contrato o convenio, ni al pago de ninguna contraprestación.
                    </p>
                    <p>
                        Atentamente
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

