<section class="postal">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 d-flex justify-content-center flex-column text-center" data-aos="fade-left" data-aos-easing="ease"  data-aos-duration="3000">
                <img src="/assets/images/sorteo2024/pasalavoz/texto-postal.png" class="img-fluid">
            </div>
        </div>
    </div>
</section>

<section class="form-postal" data-aos="zoom-in" data-aos-easing="ease"  data-aos-duration="3000">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="content-form ">
                    <div class="content-fall">
                        <form class="form-all" #dataPostal="ngForm" (ngSubmit)="validateDataAll(dataPostal)" autocomplete="off">
                            <div class="form-row  pt-3">
                                <div class="form-group  col-md-12">
                                    <label for="titulo">Título:</label>
                                    <input (input)="countLetters($event)" maxlength="100" type="text" class="form-control is-data-req" Titulo ngModel id="Titulo" name="Titulo" #Titulo="ngModel" [(ngModel)]="dataPos.Titulo" required>

                                    <p> {{count}} / 100 Caracteres</p>

                                    <div *ngIf="!dataPos.Titulo " class="alert alert-danger">
                                        Ingrese título
                                    </div>

                                </div>
                                <div class="content-img text-center">
                                    <label class="mb-3 " style="font-size: 18px;text-align: left !important;">Selecciona la postal que será enviada:</label>

                                    
                                    <div class="icon-img2 ">
                                        <div class="row">
                                            <ng-container *ngFor="let item of postales;let i = index">
                                                <div class="col-md-3  col-12">
                                                    <img id="border{{i+1}}" (click)="imageModal(item.liga,(i+1),item.Idpostal);" src="{{item.liga}}" class="border-image">
                                                    <p id="select{{i+1}}" class="text-image-1">Seleccionado</p>

                                                </div>
                                            </ng-container>

                                        </div>
                                    </div>
                                    <ng-container *ngIf="IdPostal == 0">
                                        <div class="alert alert-danger mt-3" style="text-align: left !important;">
                                            Selecciona una postal
                                        </div>
                                    </ng-container>
                                </div>
                                
                                

                                
                                <div class="form-group  col-md-12">
                                    <label for="correos">Direcciones de correo (Separados por comas):</label>
                                    <textarea (input)="empty($event)" class="form-control" id="correos" rows="3"></textarea>
                                    <div *ngIf="!dataEmail" class="alert alert-danger">
                                        Ingrese correos
                                    </div>
                                </div>

                                <div class="form-group  col-md-12">
                                    <label for="cuerpo">¿Qué mensaje quieres enviar a tus amigos?</label>
                                    <textarea maxlength="500" (input)="countLetters2($event)" rows="4" type="text" class="form-control is-data-req " cuerpo ngModel id="cuerpo" name="cuerpo" #cuerpo="ngModel" [(ngModel)]="dataPos.cuerpo" required></textarea>
                                    <p> {{count2}} / 500 Caracteres</p>
                                    <div *ngIf="!dataPos.cuerpo " class="alert alert-danger">
                                        Ingrese mensaje
                                    </div>
                                    <div *ngIf="send " class="alert alert-success  mt-4" role="alert">
                                        Postal envíada
                                    </div>
                                </div>

                                <div class="form-group  col-md-6 col-12 col-md-6 col-12 text-center mt-4">
                                    <a class="btn-stack mb-2 prev" (click)="preview(dataPostal);"> Vista previa</a>
                                </div>

                                <div class="form-group  col-md-6 col-12 text-center mt-4">
                                    <button type="submit" class="btn-stack mb-2 pl-4 pr-4 send">Enviar postal</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- Modal para compartir -->
<div class="modal fade" id="modal-postal" tabindex="-1" role="dialog" aria-labelledby="modal-postal" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered" role="document">
        <div class="modal-content border-gradient-all " >
            <div class="modal-body text-center no-padding">
                <img id="image-modal" class="img-fluid" src="https://serviciosapp.sorteoanahuac.mx/Content/Correos/Postales/imagenes-postales/postal1.jpg" alt="Postal" style="width:100%;">
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modalCom" tabindex="-1" role="dialog" aria-labelledby="modalCom" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content border-gradient-all ">
            <div class="modal-header border-0">
            </div>
            <div class="modal-body text-center ">
                <p>Rellena todos los campos. </p>
            </div>
            <div class="modal-footer w-100 justify-content-center border-0">
                <a  class="btn-modal text-center mb-2 " data-dismiss="modal">Cerrar</a>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modalPreview" tabindex="-1" role="dialog" aria-labelledby="modalPreview" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content border-gradient-all ">
            <div class="modal-header border-0">
            </div>
            <div class="modal-body text-center " style="  word-break: break-all !important;">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="mt-3 mb-3 text-left" style="color: #333;">{{dataPos.Titulo}}</h3>
                            <p class="mt-3 mb-3">{{dataPos.cuerpo}}</p>
                            <a href="{{link}}" target="_blank"><img class="mt-3 mb-3" src="{{namePostalg}}" class="img-fluid"></a>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer w-100 justify-content-center border-0">
                <a  class="btn-modal text-center mb-2" data-dismiss="modal">Cerrar</a>
            </div>
        </div>
    </div>
</div>



<!-- Modal enviar Postal-->
<div class="modal fade" id="sendPost" tabindex="-1" role="dialog" aria-labelledby="sendPost" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content border-gradient-all ">
            <div class="modal-header border-0">
            </div>
            <div class="modal-body text-center ">
                <p> Se ha enviado la postal.</p>
            </div>
            <div class="modal-footer text-center w-100 justify-content-center border-0">
                <a (click)="fReload();" id="close-thank" data-dismiss="modal" class="btn-modal text-center mb-2" >Cerrar</a>
            </div>
        </div>
    </div>
</div>


<!-- Modal error-->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="errorModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content border-gradient-all ">
            <div class="modal-header">
            </div>
            <div class="modal-body text-center ">
                <p> No existen postales.</p>
            </div>
            <div class="modal-footer text-center">
                <a data-dismiss="modal" class="btn-modal mb-2 text-center" style="color:#fff;">Cerrar</a>
            </div>
        </div>
    </div>
</div>