<section id="remanentes">
    <div class="container">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-center flex-column text-center" data-aos="zoom-in-right" data-aos-easing="ease"data-aos-duration="3000">
              <img src="/assets/images/sorteo2024/rema.png"  class="img-fluid">
            </div>
        </div>
    </div>
</section>

<section class="stc-person">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center"  data-aos="fade-down" data-aos-easing="ease"data-aos-duration="3000">
                <h2>
                    Las Universidades han destinado parte
                    de sus remanentes a diferentes proyectos:
                </h2>
                <div class="container-img">
                    <img src="/assets/images/sorteo2023/slide-remanente.jpg"  >
                </div>
                <p>
                    Gracias a tu apoyo, los recursos que se obtienen del Sorteo Anáhuac se destinan al Programa de Becas, infraestructura académica, deportiva y vial de la Universidad Anáhuac.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="box-remanente">
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-6 text-lg-right  pt-5 pb-5 pr-lg-5 my-auto order-lg-1 order-1" data-aos="fade-right" data-aos-easing="ease"data-aos-duration="3000">
                <h3>
                    PROGRAMA DE BECAS
                </h3>

                <h4>
                    Las Universidades que han 
                    destinado parte de
                    sus 
                    remanentes al programa de
                    becas son:
                </h4>

                <p>
                    Universidad Anáhuac Querétaro.
                </p>
                <p>
                    Universidad Anáhuac Mayab.
                </p>
                <p>
                    Universidad Anáhuac México Norte
                </p>
                <p>
                    Universidad Anáhuac México Sur
                </p>
            </div>

            <div class="col-lg-6 p-0 order-lg-2 order-2">
                <img src="/assets/images/sorteo2023/remanente1.jpg"  class="w-100" data-aos="fade-left" data-aos-easing="ease"data-aos-duration="3000">
            </div>

            <div class="col-lg-6 p-0 order-lg-3 order-4">
                <img src="/assets/images/sorteo2023/remanente2.jpg"  class="w-100" data-aos="fade-right" data-aos-easing="ease"data-aos-duration="3000">
            </div>

            <div class="col-lg-6 text-lg-left pt-5 pb-5 pl-lg-5 my-auto order-lg-4 order-3" data-aos="fade-left" data-aos-easing="ease" data-aos-duration="3000">
                <h3>
                    INFRAESTRUCTURA
                DEPORTIVA Y VIAL
                </h3>

                <h4>
                    Las Universidades que han sido beneficiadas en proyectos deportivos con el Sorteo Anáhuac son:
                </h4>
                <p>
                    Universidad Anáhuac Mayab
                </p>
                <p>
                    Universidad Anáhuac Puebla
                </p>
                <p>
                    Universidad Anáhuac México Norte
                </p>
            </div>

            <div class="col-lg-6 text-lg-right  pt-5 pb-5 pr-lg-5 my-auto order-lg-5 order-5" data-aos="fade-right" data-aos-easing="ease"data-aos-duration="2000">
                <h3>
                    INFRAESTRUCTURA
                    DEPORTIVA Y VIAL
                    
                </h3>

                <h4>
                    Las Universidades que han sido beneficiadas en infraestructura académica con el Sorteo Anáhuac son:

                </h4>
                <p>
                    Instituto de Estudios Superiores de Tamaulipas.
                </p>
                <p>
                    Universidad Anáhuac Oaxaca.</p>
                <p>
                    Universidad Anáhuac Querétaro.
                </p>

            </div>

            <div class="col-lg-6 p-0 order-lg-6 order-6" data-aos="fade-left" data-aos-easing="ease"data-aos-duration="2000">
                <img src="/assets/images/sorteo2023/remanente3.jpg"  class="w-100">
            </div>

            <div class="col-lg-6 p-0 order-lg-7 order-8" data-aos="fade-right" data-aos-easing="ease"data-aos-duration="2000">
                <img src="/assets/images/sorteo2023/remanente4.jpg"  class="w-100">
            </div>

            <div class="col-lg-6 text-lg-left pl-lg-5 pt-5 pb-5 my-auto order-lg-8 order-7" data-aos="fade-left" data-aos-easing="ease"data-aos-duration="2000">
                <h3>
                    INFRAESTRUCTURA VIDA UNIVERSITARIA

                </h3>

                <h4>
                    Las Universidades que han destinado parte de sus remanentes a proyectos de vida universitaria son:
                </h4>
                <p>
                    Universidad Anáhuac Oaxaca.
                </p>
                <p>
                    Universidad Anáhuac Mayab.
                </p>
                <p>
                    Universidad Anáhuac Cancún.
                </p>
                <p>
                    Universidad Anáhuac México Sur.
                </p>
            </div>

        </div>
    </div>
</section>

<section class="remanente-text">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h5>
                    CONSOLIDACIÓN ACADÉMICA
                </h5>
                <p>
                    El Pontificio Instituto Juan Pablo II ha decidido invertir sus remanentes del Sorteo Anáhuac en la educación y equipamiento de las aulas en sus sedes de Guadalajara y Monterrey.
                </p>
            </div>
        </div>
    </div>
</section>