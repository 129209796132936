import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})


export class FooterComponent implements OnInit {
  //public phone = "525540525533";
  // phone = "525531332409"
  //public title = "";
  utmUrl = "";
  constructor(private router: Router, private route: ActivatedRoute,) {
    this.route.queryParams.subscribe((params) => {
      if (params['utm_campaign']) {
        this.utmUrl = document.location.href;
        let urlVar = this.utmUrl.split('?');
        this.utmUrl = "?" + urlVar[1];
        console.log(this.utmUrl);

        localStorage.setItem('urlUtm', this.utmUrl);
      }
    });

  }
  ngOnInit(): void {

    document.addEventListener("DOMContentLoaded", function () {
      // Cuando la página se cargue completamente, agregar la clase .show al #info
      var infoSection = document.getElementById('info');
     
      if(localStorage.getItem('saveCookie') != "1") {
        infoSection.classList.add('show');
      }

    });
    this.router.events.subscribe(() => {
      //console.log(this.router.url) 
      if (this.router.url === "/registro-sorteo-universitario") {
        $("body").addClass("bg-sorteo-uni");
        $(".genral-footer").addClass("d-none");
        $(".sorteo-uni").removeClass("d-none");
      } else {
        $("body").removeClass("bg-sorteo-uni");
        $(".genral-footer").removeClass("d-none");
        $(".sorteo-unir").addClass("d-none");
      }
    });

    //   jQuery( document ).ready(function() {
    //       // if ((<any>jQuery(document)).width() < 1200 ) {
    //       //   (<any>jQuery('.toast')).toast('hide');
    //       //   (<any>jQuery('img.icon-form')).removeClass('show');
    //       // }else {
    //       //   (<any>jQuery('.toast')).toast('show');
    //       //   (<any>jQuery('img.icon-form')).addClass('show');
    //       // }
    //       console.log(window.location.pathname);
    //       if(window.location.pathname == "/registro-sorteo-universitario") {
    //         $("body").addClass("bg-sorteo-uni");
    //       }else {
    //         $("body").removeClass("bg-sorteo-uni");
    //       }
    //  });
  }

  // showIcon() {
  //   (<any>jQuery('img.icon-form')).removeClass('show');
  // }

  // showToast() {
  //   (<any>jQuery('.toast')).toast('show');
  //   (<any>jQuery('img.icon-form')).addClass('show');
  // }

  acceptInfo() {
    $('#info').removeClass('show');
    localStorage.setItem('saveCookie', "1");
  }

  closeModal() {
    $('#info').removeClass('show');
  }
}
