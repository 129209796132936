import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-participantes-lealtad',
  templateUrl: './participantes-lealtad.component.html',
  styleUrls: ['./participantes-lealtad.component.scss']
})
export class ParticipantesLealtadComponent implements OnInit {
  dataNuevos: any[] = [];
  filteredData: any[] = [];
  searchText: string = '';
  p: number = 1;

  constructor() {
    this.loadExcel();
  }

  ngOnInit(): void {}

  loadExcel() {
    const url = 'assets/pdf/participantes.xlsx';
    fetch(url)
      .then(response => response.arrayBuffer())
      .then(buffer => {
        const workbook = XLSX.read(buffer, { type: 'array' });

        const sheetNuevos = workbook.Sheets['Recurrentes'];

        this.dataNuevos = XLSX.utils.sheet_to_json(sheetNuevos);

        // Inicializa el filtro con los datos de Nuevos
        this.filteredData = this.dataNuevos;
      })
      .catch(error => console.error('Error loading Excel file:', error));
  }

  filterData() {
    const term = this.searchText.toLowerCase();
    this.filteredData = this.dataNuevos.filter(participante =>
      participante.folio_recurrente.toString().toLowerCase().includes(term) ||
      participante.nombre_pila.toLowerCase().includes(term) ||
      participante.matricula.toLowerCase().includes(term)
    );
  }

  getFirstName(fullName: string): string {
    if (!fullName) return '';
    const nameParts = fullName.split(' ');
    return nameParts[0];
    
  }

}
