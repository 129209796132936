<section id="about">
    <div class="container">
        <div class="row">   
            <div class="col-lg-5 my-auto" data-aos="fade-right" data-aos-easing="ease"data-aos-duration="3000">
               <img src="/assets/images/sorteo2024/girl.png"  class="img-fluid">
            </div>

            <div class="col-md-7 my-auto  text-center">
                <h1>
                    ¡NOSOTROS SOMOS <br>
                    SORTEO ANÁHUAC!
                </h1>
                <p>
                    #SORTEOANÁHUACSOMOSTODOS
                </p>
            </div>
        </div>
    </div>
</section>




<section class="content-about">
    <div class="container-fluid">
        <div class="row">

            <div class="col-md-3 bg-img" data-aos="fade-right" data-aos-easing="ease"data-aos-duration="3000" >

            </div>
            <div class="col-md-3 bg-img justify-content-center flex-column" data-aos="fade-right" data-aos-easing="ease"data-aos-duration="3000" >
                <p>
                    Sorteo Anáhuac tiene como objetivo generar recursos que se destinen de forma íntegra al programa de becas y desarrollo de infraestructura de la Red de Universidades Anáhuac
                </p>
            </div>
            <div class="col-md-3 bg-img" data-aos="fade-left" data-aos-easing="ease"data-aos-duration="3000">
                <p>
                  
                </p>
            </div>
            <div class="col-md-3 bg-img" data-aos="fade-left" data-aos-easing="ease"data-aos-duration="3000">
              
            </div>

            <div class="col-md-3 bg-img" data-aos="fade-right" data-aos-easing="ease"data-aos-duration="3000">
            
            </div>
            <div class="col-md-3 bg-img" data-aos="fade-right" data-aos-easing="ease"data-aos-duration="3000">
             
            </div>
            <div class="col-md-3 bg-img justify-content-center flex-column" data-aos="fade-left" data-aos-easing="ease"data-aos-duration="3000">
                <p>
                    La misión fundamental de nuestra institución, ser una comunidad universitaria que contribuye e impulsa el proceso de formación integral de las personas.
                </p>
            </div>
            <div class="col-md-3 bg-img justify-content-center flex-column" data-aos="fade-left" data-aos-easing="ease"data-aos-duration="3000">
                <p>
                    Por su profunda formación humana y moral inspirada en los valores perennes del humanismo cristiano, y por su genuina conciencia social sean líderes de acción positiva que promuevan el desarrollo del ser humano y de la sociedad.
                </p>
            </div>

        </div>
    </div>
</section>


<section class="stc-valores">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-5 col-12 my-auto text-center" >
                <h2>
                    NUESTROS 
                    <span class="d-md-block">
                    VALORES
                    </span>
                </h2>
            </div>
            <div class="col-xl-7 col-12 text-center my-auto"  id="slider-valores" >
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig2">
                    <div ngxSlickItem *ngFor="let slide of slides; let i = index" class="slide">
                
                        <div>
                                <div class="container">
                                    <div class="row">    
                                        <div class="col-md-12 my-auto">
                                           <img src="{{slide.img}}" class="img-fluid" alt="{{slide.place}}">
                                        </div>
                                    </div>
                                </div>
                        </div>
                
                        
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </div>
</section>




