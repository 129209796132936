<section id="ganadores-documentos" >
    <div class="container">
        <div class="row">
            <div class="col-md-12  mx-auto d-flex justify-content-center align-items-center" data-aos="fade-up"  data-aos-easing="ease"  data-aos-duration="3000">
                <img src="/assets/images/sorteo2023/texto-ganadores.png" class="img-fluid"
                    alt="Ganadores Sorteo Anáhuac 2023">
            </div>
        </div>
    </div>
</section>


<section class="ganadores-inner">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                
                <div class="content">
                    <h2>
                        Compradores
                    </h2>
                    <ul>
                        <li>
                            <strong>Constancia de situación fiscal</strong> a noviembre
                        </li>
                        <li>
                            <strong>INE</strong> o pasaporte vigente
                        </li>
                        <li>
                            <strong>Comprobante</strong> de domicilio no mayor
                            a 3 meses de antigüedad
                        </li>
                        <li>
                            <strong>CURP</strong>
                        </li>
                    </ul>
                </div>

                <div class="content">
                    <h2>
                        COLABORADORES
                    </h2>
                    <ul>
                        <li>
                            <strong>Constancia de situación fiscal</strong> a noviembre
                        </li>
                        <li>
                            <strong>INE</strong> o pasaporte vigente
                        </li>
                        <li>
                            <strong>Comprobante</strong> de domicilio no mayor
                            a 3 meses de antigüedad
                        </li>
                        <li>
                            <strong>CURP</strong>
                        </li>
                        <li>Carátula de <strong>estado de cuenta</strong> a depositar (No Fintech)
                        </li>
                    </ul>

                    <div class="container-btn text-center mb-5">
                        <a class="btn-stack" [routerLink]="['/login-ganadores']">
                            haz clic aquí para subir tus documentos
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>