<section class="stc-legales">
    <div class="container">
        <div class="row">
            <div class="col-md-12 my-auto">
                <h1>
                    Aviso de Privacidad
                </h1>
            </div>
        </div>
    </div>
</section>

<section class="stc-legal">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="container-legal" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="3000">
                  

                    <p>
                        En cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los
                        Particulares, de su Reglamento y de los Lineamientos del Aviso de Privacidad publicados por la
                        Secretaría de Economía (en adelante la Ley), Sorteo Anáhuac, A.C. pone a disposición de los
                        Titulares el presente Aviso de Privacidad con el objeto de informarles los alcances y
                        condiciones generales del Tratamiento de sus datos personales, a fin de que estén en posibilidad
                        de tomar decisiones informadas sobre su uso, así como para que mantengan el control y
                        disposición sobre ellos.
                    </p>

                    <h2>
                        ¿Quiénes son los Titulares?
                    </h2>

                    <p>
                        Las personas físicas identificables a quienes corresponden los datos personales. Se considera
                        persona física identificable toda persona cuya identidad pueda determinarse, directa o
                        indirectamente, mediante cualquier información (en adelante el Titular).

                    </p>

                    <h2>
                        ¿Quién es el Responsable del Tratamiento de sus datos personales?
                    </h2>

                    <p>
                        Sorteo Anáhuac, A.C. (en adelante el Responsable), con domicilio en Av. Universidad Anáhuac No.
                        46, Piso 7 de Biblioteca, Col. Lomas Anáhuac, Huixquilucan, Estado de México, Código Postal
                        52786, es responsable del tratamiento y protección de la confidencialidad de sus datos
                        personales, así como de garantizar su seguridad y el derecho a la autodeterminación informativa
                        de cada Titular.
                    </p>

                    <h2>
                        ¿Cuáles son las Finalidades del Tratamiento de sus Datos Personales?
                    </h2>

                    <p>
                        El Responsable recabará datos personales del Titular para las siguientes finalidades:
                        I. Finalidades que dan origen y son necesarias para la existencia, mantenimiento y cumplimiento
                        de la relación jurídica entre el Responsable y Titular:

                    </p>
                    <ul>
                        <li>
                            Identificar al Titular;
                        </li>
                        <li>
                            Informar al Titular sobre el desarrollo y resultado de los sorteos;
                        </li>
                        <li>
                            Incluir el nombre completo del Titular en la lista de ganadores que se publica al finalizar
                            cada sorteo, en caso de resultar ganador;
                        </li>
                    </ul>

                    <h2>
                        II. Finalidades que no son necesarias para la relación jurídica entre el Responsable y Titular:
                    </h2>
                    <ul>
                        <li>
                            Promocionar al Titular en medios de comunicación y redes sociales como ganador del sorteo;
                        </li>
                        <li>
                            Grabar y publicar el testimonio y fotografías del Titular como ganador del sorteo;
                        </li>
                        <li>
                            Invitar al Titular a que participe en otros sorteos promovidos por el Responsable;
                        </li>
                        <li>
                            Enviar al Titular encuestas para la mejora continua de los productos y servicios que ofrece
                            el Responsable;
                        </li>
                        <li>
                            Enviar al Titular publicidad relativa al Sorteo Anáhuac, sus sorteos, a la Red de
                            Universidades Anáhuac y a la Universidad Anáhuac.
                        </li>
                        <li>
                            Realizar estadísticas e investigaciones de mercado sobre los participantes en los sorteos
                            del responsable;
                        </li>
                    </ul>

                    <p>
                        En caso de no estar de acuerdo en el Tratamiento de sus datos personales con respecto a las
                        finalidades que no son necesarias ni dieron origen a la relación jurídica con el Responsable, el
                        Titular puede manifestar su negativa para este tratamiento en cualquier momento enviando un
                        correo electrónico a sorteoanahuac@anahuac.mx . En su caso, esta negativa no será motivo para
                        negarle servicios ni para terminar la relación establecida con el Responsable.
                    </p>

                    <h2>
                        ¿Qué datos personales son objeto de Tratamiento?
                    </h2>
                    <p>
                        Los datos personales del Titular que serán objeto de Tratamiento por parte del Responsable se
                        encuentran en las siguientes categorías:
                    </p>
                    <p>
                        a) Datos de identificación: nombre completo, edad, sexo y fecha de nacimiento.
                    </p>
                    <p>
                        b) Datos de contacto: correo electrónico, domicilio, teléfono particular, ciudad.
                    </p>

                    <p>
                        En caso de no contar con algunos de los datos citados, podría existir la posibilidad de que el
                        Responsable no pueda proporcionar los servicios propios de la relación jurídica que se establece
                        con el Titular.
                    </p>

                    <p>
                        Datos personales sensibles son aquellos que afecten a la esfera más íntima de su Titular, o cuya
                        utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. Se
                        clasifican en las siguientes categorías: datos ideológicos; datos sobre opiniones políticas;
                        datos sobre afiliación sindical; datos de salud; datos sobre vida sexual; datos de origen étnico
                        o racial; datos biométricos. El Responsable no solicita datos sensibles ni datos financieros o
                        patrimoniales del Titular.
                    </p>

                    <p>
                        El Tratamiento de sus datos personales será el que resulte necesario, adecuado y relevante en
                        relación con las finalidades previstas en este Aviso de Privacidad.
                    </p>

                    <h2>
                        ¿Cómo se recaban sus datos personales?
                    </h2>

                    <p>
                        Los datos personales serán recabados por escrito. También podrán ser recabados por medio la
                        página de internet del Responsable, chat en línea, correo electrónico, redes sociales oficiales,
                        medios electrónicos, ópticos, sonoros, visuales, o por cualquier otra tecnología lícita,
                        incluyendo llamadas telefónicas, fax o correo postal.
                    </p>

                    <h2>
                        ¿Se realizan transferencias de datos personales?
                    </h2>

                    <p>
                        El Responsable podrá realizar las transferencias nacionales o internacionales de datos sin
                        solicitar el consentimiento del Titular en los casos previstos en la Ley y en su Reglamento.
                        También podrá realizar transferencias de datos a entidades proveedoras de servicios para el
                        cumplimiento de las obligaciones contractuales acordadas. Los terceros y las entidades
                        receptores de datos personales, asumirán las mismas obligaciones y/o responsabilidades que el
                        Responsable ha asumido con el Titular en el presente Aviso.
                    </p>

                    <p>
                        El Titular de datos personales que establece una relación jurídica con el Responsable acepta que
                        sus datos personales podrían ser transferidos en los términos descritos en la Ley, su Reglamento
                        y el presente Aviso de Privacidad.
                    </p>

                    <p>
                        El Responsable podría transferir los datos personales necesarios a las siguientes organizaciones
                        y para los siguientes fines:
                    </p>

                    <div class="over">
                        <table>
                            <tbody>
                                <tr>
                                    <td><strong >Tercero receptor de los datos personales</strong></td>
                                    <td><strong >Finalidad</strong></td>
                                    <td><strong >Requiere Consentimiento Expreso</strong></td>
                                </tr>
                                <tr>
                                    <td>Proveedores que asistan a Sorteo Anáhuac.</td>
                                    <td>Para el efectivo cumplimiento de los servicios.</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>Instituciones bancarias.</td>
                                    <td>Para realizar las gestiones de pagos necesarias.</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>Afianzadoras y aseguradoras.</td>
                                    <td>Para gestiones de seguros y/o reembolsos.</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>Personas relacionadas con el Responsable.</td>
                                    <td>Para comprobación, revisión y certificación en materia fiscal y administrativa.</td>
                                    <td> No.</td>
                                </tr>
                                <tr>
                                    <td>Sociedades que forman parte del mismo grupo y que operen bajo los mismos procesos y
                                        políticas que el Responsable.</td>
                                    <td>Para la correcta prestación de los servicios propios de relación jurídica que se
                                        establece entre el Responsable y el Titular.</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>Terceros.</td>
                                    <td> Para dar cumplimiento a la legislación aplicable y/o resoluciones judiciales o
                                        administrativas. Cuando requieran información para el ejercicio de un derecho o una
                                        acción. 
                                    </td>
                                    <td>No</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p>
                        El Responsable podrá realizar las transferencias de datos sin solicitar el consentimiento del Titular en los casos previstos en la Ley y en su Reglamento.
                    </p>

                    <p>
                        Si el Titular no desea que el Responsable transfiera sus datos personales, le pedimos que envíe un correo electrónico a sorteoanahuac@anahuac.mx . Su solicitud será analizada y el Responsable le informará sobre la procedencia o improcedencia de la misma.
                    </p>

                    <h2>
                        ¿Tiene el Responsable medidas de seguridad para el Tratamiento de datos personales?
                    </h2>

                    <p>
                        El Responsable cuenta con las medidas de seguridad administrativas, físicas y técnicas necesarias y suficientes para proteger sus datos personales contra daño, pérdida, alteración, destrucción, uso, acceso o Tratamiento no autorizado. 
                    </p>

                    <p>
                        El Responsable se beneficia de las medidas de seguridad y certificaciones de cumplimiento de normas de seguridad que ofrece su proveedor de infraestructura tecnológica.
                    </p>

                    <p>
                        Los datos personales son salvaguardados en bases de datos y equipos de cómputo que cuentan con la seguridad necesaria para prevenir fugas de información. Controles de acceso, controles ambientales, sistemas de protección anti intrusos (IPS, Firewall), herramientas de protección antivirus y filtrado web son algunas de las herramientas utilizadas para mantener la seguridad de los datos en los sistemas del Responsable.  
                    </p>

                    <p>
                        El Responsable se compromete a que los datos personales sean tratados bajo estrictas medidas de seguridad, garantizando su confidencialidad.
                    </p>

                    <h2>
                        COOKIES Y WEB BEACONS
                    </h2>

                    <p>
                        Las cookies son archivos de texto que son descargados automáticamente y almacenados en el disco duro del equipo de cómputo del usuario al navegar en una página de internet específica, que permiten recordar al servidor de internet algunos datos sobre los usuarios que acceden al portal electrónico www.sorteoanahuac.mx, así como para rastrear determinados comportamientos o actividades.
                    </p>

                    <p>
                        Las cookies permiten reconocer a los usuarios, detectar el ancho de banda que han seleccionado, identificar la información más destacada, calcular el tamaño de la audiencia y medir algunos parámetros de tráfico.
                    </p>

                    <p>
                        Por su parte, las web beacons son imágenes insertadas en una página de internet o correo electrónico, que pueden ser utilizados para monitorear el comportamiento de un visitante, como almacenar información sobre su dirección IP, duración del tiempo de interacción en dicha página y el tipo de navegador utilizado, entre otros.
                    </p>

                    <p>
                        El Responsable utiliza las cookies y web beacons para obtener la siguiente información del Titular:
                    </p>

                    <p>
                        -El tipo de navegador y sistema operativo.
                        -Las páginas de internet que visita.<br>
                        -Los vínculos que sigue.<br>
                        -La dirección IP.<br>
                        -El sitio que visitó antes.
                    </p>

                    <p>
                        Estas cookies y otras tecnologías pueden ser deshabilitadas. Para conocer cómo hacerlo, consulte el vínculo o dirección electrónica de su navegador.
                    </p>

                    <h2>
                        ¿Cuáles son las opciones y medios para limitar el uso o la divulgación de los datos personales?
                    </h2>

                    <p>
                        Si el Titular desea solicitar la limitación del uso o divulgación de sus datos personales puede hacerlo en el Departamento de Protección de Datos Personales en el correo electrónico sorteoanahuac@anahuac.mx   o en las oficinas del Responsable.
                    </p>

                    <h2>
                        ¿Cuáles son los derechos de los Titulares de datos personales?
                    </h2>

                    <p>
                        Cualquier Titular o, en su caso, su representante legal, podrá ejercer los derechos de acceso, rectificación, cancelación y oposición sobre sus datos personales, y el Responsable proveerá los medios que le permitan un oportuno ejercicio de sus derechos. 
                    </p>

                    <h2>
                        ¿Cuáles son los medios que tienen los Titulares para ejercer sus derechos?
                    </h2>

                    <p>
                        El ejercicio de los derechos de acceso, rectificación, cancelación, oposición, limitación de uso o la revocación del consentimiento, deberán solicitarse por escrito únicamente ante el Departamento de Protección de Datos Personales en el correo electrónico sorteoanahuac@anahuac.mx o en las oficinas del Responsable. 
                    </p>
                    <h2>
                        ¿Cómo pueden los Titulares ejercer sus derechos? 
                    </h2>

                    <p>
                        La solicitud de acceso, rectificación, cancelación, oposición, limitación de uso o la revocación del consentimiento, deberá presentarse por escrito vía correo electrónico o por escrito en las oficinas del Responsable, y contener y acompañar lo siguiente:
                    </p>

                    <ol type="I">
                        <li>
                            El nombre del Titular y domicilio u otro medio para comunicarle la respuesta a su solicitud.
                        </li>
                        <li>
                            Los documentos que acrediten su identidad o, en su caso, la representación legal del Titular.
                        </li>
                        <li>
                            La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados, y cualquier otro elemento o documento que facilite la localización de los datos personales. 
                        </li>
                        <li>    
                            v.	Para el caso de las solicitudes de rectificación, el Titular deberá indicar las modificaciones a realizarse y aportar la documentación que sustente su petición 
                        </li>
                    </ol>

                    <p>
                        En su caso, el Titular que lo desee puede pedir el formato sugerido de solicitud al correo electrónico sorteoanahuac@anahuac.mx o en las oficinas del Responsable, o enviarla en escrito libre.
                    </p>

                    <p>
                        El Responsable comunicará al Titular, vía correo electrónico, la determinación adoptada dentro de los siguientes veinte días hábiles contados desde la fecha en que se recibió su solicitud, a efecto de que, si resulta procedente, se haga efectiva la misma dentro de los quince días hábiles siguientes a la fecha en que se comunica la respuesta. Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso.
                    </p>

                    <h2>
                        ¿Cómo puede conocer los cambios a este Aviso de Privacidad?
                    </h2>

                    <p>
                        El Responsable se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad para la atención de novedades legislativas o jurisprudenciales, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de los servicios que ofrece el Responsable. 
                        El Aviso de Privacidad actualizado estará siempre disponible a través de la página de internet  <a href="/assets/pdf/aviso-de-privacidad.pdf" target="_blank
                        ">
                        http://www.sorteoanahuac.mx/pdf/aviso-de-privacidad.pdf
                        </a> y en las oficinas del Responsable.
                    </p>

                    <h2>
                        Consentimiento general
                    </h2>

                    <p>
                        Habiéndose puesto a disposición del Titular este Aviso de Privacidad, ya sea personalmente o a través de la página de internet o de otros medios, y no habiendo manifestado inmediatamente oposición alguna, se entenderá que el Titular ha consentido tácitamente el Tratamiento de sus datos personales.
                    </p>

                    <p>
                        Si el Titular proporcionó sus datos verbalmente, por escrito, por medios electrónicos, ópticos o por cualquier otra tecnología, o por signos inequívocos, se entenderá que ha dado su consentimiento expreso.
                    </p>
                    <h2>
                        ¿Cómo puede revocar su consentimiento para el Tratamiento de sus datos personales? 
                    </h2>

                    <p>
                        El Titular de los datos personales podrá revocar su consentimiento para el Tratamiento de sus datos personales en cualquier momento, debiendo enviar una solicitud por escrito para tal efecto al Departamento de Protección de Datos Personales en el correo electrónico sorteoanahuac@anahuac.mx o bien presentarse en las oficinas del Responsable para llenar y firmar la solicitud. 
                    </p>

                    <p>
                        Dentro de los siguientes veinte días hábiles contados desde la fecha en que se recibió la solicitud de revocación, el Responsable informará al Titular sobre la procedencia o improcedencia de la misma, y si resulta procedente la hará efectiva dentro de los quince días hábiles siguientes a la fecha en que se comunique la respuesta al correo electrónico que el Titular proporcione.
                    </p>

                    <p>
                        Última actualización de este Aviso de Privacidad: <strong>26 de marzo de 2024.</strong>
                    </p>

                </div>
            </div>
        </div>
    </div>
</section>