<section id="blog">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 data-aos="zoom-in" data-aos-duration="3000">Blog</h1>
            </div>
        </div>
    </div>
</section>


<section class="blogs" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="500" data-aos-duration="3000">
    <div class="container-fluid">
        <div class="card-columns">


            <ng-container *ngFor="let blog of blogs;"> 
               

                <div class="card">
                    <img   src="{{ blog.src }}" alt="{{ blog.alt }}" class="card-img-top" >
                    <div class="card-t {{ blog.class }}">
                        <h5 class="card-title"> {{ blog.title }} </h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-7 col-md-12">
                                <p >
                                    {{ blog.description }}
                                </p>
                            </div>

                            <div class="col-xl-5  col-md-12 my-auto text-center">
                                <a href="{{ blog.link }}" class="btn-stack">
                                    Ver más
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

             
            </ng-container>

          </div>
    </div>
</section>