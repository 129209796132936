
    <section id="premios-col">
        <div class="container" data-aos="fade-right" data-aos-duration="3000">
            <div class="row">
                <div class="col-12 text-center">
                   <div class="content-img">
                       <img src="/assets/images/sorteo2024/colaborador.png" alt="Premios Colaborador" class="img-fluid">
                   </div>
                </div>
            </div>
        </div>
    </section>

    <section class="premios-colb-cta">
        <div class="container  mb-5">
            <div class="row" >
<!-- 
                <div class="col-12 text-center my-auto">
                    <a (click)="scrollPage('#premios_cta')" class="cta d-xl-block d-none">
                        <img src="/assets/images/sorteo2023/arrow.png" class="img-fluid" >
                    </a>

                    <a (click)="scrollPage('#premios_cta2')" class="cta d-xl-none d-block">
                        <img src="/assets/images/sorteo2023/arrow.png" class="img-fluid" >
                    </a>
                </div> -->

              <div class="col-12 container-h mt-5">
                <h2>
                    Con Sorteo Anáhuac Todos ganan
                </h2>
                <p>
                    y todos nuestros colaboradores lo saben. Conoce nuestros premios para colaborador donde tenemos un acumulado de más de <span> $3.8 millones de pesos</span>.
                </p>
                <!-- <hr class="line"> -->
              </div>
        
        
                <div class="col-lg-5 col-12 my-lg-auto mt-3" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="content-boleto text-center">
                        <p class="m-0 p-0">
                            Precio del boleto <small class="price">$450</small>
                        </p> 
                    </div>
                </div>

                <div class="col-lg-3 col-12 mt-5 my-lg-auto " data-aos="zoom-in" data-aos-duration="3000">
                    <p class="plus m-0 p-0 text-left">
                        + de 850 
                    </p>
                    <span class="d-block p">
                        premios
                    </span>
                </div>

                <div class="col-lg-4 col-12 my-lg-auto mt-5 d-flex flex-column justify-content-between " data-aos="zoom-in" data-aos-duration="3000">
                    <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/compra-en-linea-boletos-sorteo-anahuac{{utmUrl}}" target="_blank" class="btn-stack text-center">
                        ¡compra ya!
                    </a>
                    <span class="text-center or m-0 p-0"> ó </span>
                    <a [routerLink]="['/formulario-registro-colaboradores']" class="btn-stack text-center">
                        Vuélvete colaborador
                    </a>
                </div>


            </div>
        </div>
    </section>



    <section class="premios-colaborador">
        <div class="container">
            <div class="row ">
                <div class="col-md-8 offset-md-2 text-center">
                    <div class="controls ">
                        <a type="button" class="control btn-d" data-filter="all"><span>Todos</span></a>   
                        <a type="button" class="control btn-d" data-filter=".pp"><span>1º premio</span></a>
                        <a type="button" class="control btn-d" data-filter=".sp"><span>2º premio</span></a>
                        <a type="button" class="control btn-d" data-filter=".tp"><span>3º premio</span></a>
                        <a type="button" class="control btn-d" data-filter=".otros"><span>Ver más premios</span></a>
                    </div>

                    
                    <div class="conainter-photos">
                        <div class="row">

                            <div class="col-12">
                                <div class="card-columns-init"  >   
                                    
                                    <img src="/assets/images/sorteo2024/premio-col-1.jpg" class="img-fluid mix pp" >

                                    <img src="/assets/images/sorteo2024/premio-col-2.jpg" class="img-fluid mix sp" >

                                    <img src="/assets/images/sorteo2024/premio-col-3.jpg" class="img-fluid mix tp" >
                                

                                   <div class="row">
                                        <img src="/assets/images/sorteo2024/premio-col-4.jpg" class="img-fluid mix otros col-md-4" >
                                        <img src="/assets/images/sorteo2024/premio-col-5.jpg" class="img-fluid mix otros col-md-4" >
                                        <img src="/assets/images/sorteo2024/premio-col-6.jpg" class="img-fluid mix otros col-md-4" >
                                        <img src="/assets/images/sorteo2024/premio-col-7.jpg" class="img-fluid mix otros col-md-4" >
                                        <img src="/assets/images/sorteo2024/premio-col-8.jpg" class="img-fluid mix otros col-md-4" >
                                        <img src="/assets/images/sorteo2024/premio-col-9.jpg" class="img-fluid mix otros col-md-4" >
                                        <img src="/assets/images/sorteo2024/premio-col-10.jpg" class="img-fluid mix otros col-md-4" >
                                        <img src="/assets/images/sorteo2024/premio-col-11.jpg" class="img-fluid mix otros col-md-4" >
                                   </div>

   
                                    

                                    
                                </div>
                            </div>
                            

                        </div>
            
                        <div class="gap"></div>
                        <div class="gap"></div>
                        <div class="gap"></div>
                        <div class="gap"></div>
                    </div>
                </div>

    
               
            </div>
        </div>
    </section>


    