import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ganadores-principales',
  templateUrl: './ganadores-principales.component.html',
  styleUrls: ['./ganadores-principales.component.scss']
})
export class GanadoresPrincipalesComponent implements OnInit {
  title = 'Comunicado - Sorteo Anáhuac 6ª Edición | Sorteo Anáhuac';
  description = 'Comunicado sobre la realización del Sorteo Anáhuac 6ª Edición llevada a cabo en la Universidad Anáhuac Oaxaca.';
  constructor( private titleService: Title, private metaTagService: Meta) { }

  ngOnInit(): void {
      this.titleService.setTitle(this.title);
      this.metaTagService.updateTag(
        { name: 'title', content: this.title}
      );
      this.metaTagService.updateTag(
        { name: 'description', content: this.description}
      );



      this.metaTagService.updateTag(
      { property: 'og:title', content: this.title}
      );


      this.metaTagService.updateTag(
        { property: 'og:description', content: this.description }
      );

      this.metaTagService.updateTag(
        { property: 'og:url', content: 'https://www.sorteoanahuac.mx/boletin-primer-sorteo-oportuno' }
      );

      this.metaTagService.updateTag(
        { property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/pasa-la-voz/logo-anahuac.png' }
        );
        
      this.metaTagService.updateTag(
        { name: 'twitter:title', content: this.title}
      );
      this.metaTagService.updateTag(
        { name: 'twitter:description', content: this.description }
      );
  }
}
