<section class="stc-legales">
    <div class="container">
        <div class="row">
            <div class="col-md-12 my-auto">
                <h1>
                    REGLAMENTO BECARIO
                </h1>
            </div>
        </div>
    </div>
</section>



<section class="stc-legal">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="container-legal" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="3000">

                    <p>
                        <strong>
                            Reglamento del Sorteo Anáhuac para Becados Colaboradores de preparatoria, Licenciatura y
                            posgrado pertenecientes a la Red de Universidades Anáhuac y al pontificio Instituto Juan
                            pablo II.
                        </strong>
                    </p>

                    <h2>I. GENERALIDADES.</h2>
                    <p>
                        Artículo 1. Este reglamento rige para toda la Red de Universidades Anáhuac en México y del
                        Pontificio Instituto Juan Pablo II con el fin de estandarizar los procesos y por consiguiente
                        que el alumno que recibe un beneficio por algún tipo y porcentaje de beca conozca lo que tiene
                        que cumplir para cubrir el requisito de colaboración en la venta de boletos como lo marca el
                        Compendio Reglamentario Anáhuac 2024, Libro Séptimo, Reglamento de Becas y Financiamiento
                        Educativo para Alumnos de la Universidad Anáhuac, Título I, Capítulo III Art.10 referente a la
                        Conservación de la Beca.
                    </p>
                    <p>
                        Artículo 2. Este documento tiene como propósito definir y regular la entrega de talonarios
                        físicos o digitales con boletos del Sorteo Anáhuac a los alumnos que cuentan con algún
                        porcentaje de beca así como exponer las sanciones en caso de incumplimiento del mismo.
                    </p>
                    <p>
                        Artículo 3. En este reglamento deberá de entenderse como “boletos” a aquellos que distribuye el
                        Sorteo Anáhuac entre sus colaboradores y que en su modalidad son Boletos Digitales; esta
                        distribución dependerá del nicho de mercado al que pertenezca el colaborador.
                    </p>

                    <h2>
                        II. DEL BECADO COLABORADOR Y SU pARTICIpACIÓN EN LA VENTA DE BOLETOS DIGITALES.
                    </h2>
                    <p>
                        Artículo 4. Se entiende como Becado Colaborador a cualquier alumno de nivel Preparatoria,
                        Licenciatura o Posgrado de las instituciones miembros de la Red de Universidades Anáhuac y del
                        Pontificio Instituto Teológico Juan Pablo II que se encuentre gozando de algún tipo de beca que
                        otorga la Institución en la que se encuentre inscrito. NO se consideran en este esquema a las
                        becas SEP.
                    </p>


                    <p>
                        Artículo 5. La colaboración en la venta de boletos digitales, se considera una forma de
                        desarrollo personal y profesional para todos aquellos alumnos que gocen de algún tipo y
                        porcentaje de beca.
                    </p>
                    <p>
                        Artículo 6. Por ningún motivo, el becado colaborador podrá evitar la participación en la venta
                        de boletos digitales del Sorteo Anáhuac por:
                    </p>

                    <ul>
                        <li>
                            Estar realizando su internado (en su caso)
                        </li>
                        <li>
                            Trabajo externo
                        </li>
                        <li>
                            Participar en equipos representativos o grupos estudiantiles
                        </li>
                        <li>
                            Actividades extra-académicas (mesas directivas, simposios y otros) o
                        </li>
                        <li>
                            Por llevar sobre carga académica.
                        </li>
                    </ul>

                    <p>
                        Artículo 7. Ningún becado colaborador podrá devolver boletos digitales sin vender, a excepción
                        del boleto digital de regalo (boleto número once) que podrá ir a su nombre o a nombre de
                        cualquier persona que decida y que no deberá realizar el pago.
                    </p>
                    <h2>
                        III. DE LA ASIGNACIÓN DE BOLETOS DIGITALES A BECADOS COLABORADORES.
                    </h2>

                    <p>
                        Artículo 8. La asignación de talonarios y boletos digitales a vender por parte de los alumnos de
                        nivel PREPARATORIA de la Red de Universidades Anáhuac, que cuentan con algún tipo de beca es
                        como sigue:
                    </p>

                    <ul>
                        <li>
                            Si el alumno se encuentra en el siguiente rango de beca: del 1 al 50%, deberá vender: 1
                            talonario (11 boletos digitales).
                        </li>
                        <li>
                            Si el alumno se encuentra en el siguiente rango de beca: del 51 al 100%, deberá vender 2
                            talonarios (22 boletos digitales).
                        </li>
                    </ul>

                    <p>
                        Es facultad del Comité de la Dirección de cada Preparatoria, evaluar y determinar si el rango de
                        boletos digitales a vender por alumno becado es mayor al establecido en este reglamento. Podrá
                        ser mayor, pero nunca menor al propuesto en este documento.
                    </p>

                    <p>
                        Artículo 9. La asignación de talonarios y boletos digitales a vender por parte de los alumnos de
                        nivel LICENCIATURA de la Red de Universidades Anáhuac, que cuentan con algún tipo de beca es
                        como sigue:
                    </p>

                    <ul>
                        <li>
                            Si el alumno se encuentra en el siguiente rango de beca: del 1 al 50%, deberá vender: 1
                            talonario (11 boletos digitales).
                        </li>
                        <li>
                            Si el alumno se encuentra en el siguiente rango de beca: del 51 al 100%, deberá vender 2
                            talonarios (22 boletos digitales).
                        </li>
                        <li>
                            El becado colaborador debe contar de manera obligatoria con su correo institucional
                            (@anahuac.mx) en donde recibirá información inherente al sorteo y al mismo tiempo será el
                            usuario que lo identificará para el acceso a la APP del Sorteo y realizar su labor de venta.
                        </li>
                    </ul>

                    <p>
                        Es facultad del Comité Rectoral de cada Universidad, evaluar y determinar si el rango de boletos
                        digitales a vender por alumno becado es mayor al establecido en este reglamento. Podrá ser
                        mayor, pero nunca menor al establecido en este documento.
                    </p>
                    <p>
                        Artículo 10. La asignación de talonarios y boletos digitales a vender, por parte de los alumnos
                        de nivel POSGRADO de la Red de Universidades Anáhuac, que cuentan con algún tipo de beca es como
                        sigue:
                    </p>
                    <ul>
                        <li>
                            Si el alumno cuenta con algún porcentaje de beca, deberá vender: 1 talonario (11 boletos
                            digitales).
                        </li>
                        <li>
                            El becado colaborador debe contar de manera obligatoria con su correo institucional
                            (@anahuac.mx) en donde recibirá información inherente al sorteo y al mismo tiempo será el
                            usuario que lo identificará para el acceso a la APP del Sorteo y realizar su labor de venta.
                        </li>
                    </ul>
                    <p>

                        Es facultad del Comité Rectoral de cada Universidad, evaluar y determinar si el rango de boletos
                        digitales a vender por alumno becado es mayor al establecido en este reglamento. Podrá ser
                        mayor, pero nunca menor al establecido en este documento.

                    </p>

                    <p>

                        Artículo 11. La asignación de talonarios y boletos digitales a vender por parte de los alumnos
                        de nivel LICENCIATURA y POSGRADO del Pontificio Instituto Juan Pablo II, que cuentan con algún
                        tipo de beca es como sigue:

                    </p>
                    <p>
                        • Si el alumno cuenta con algún porcentaje de beca, deberá vender: 1 talonario (11 boletos
                        digitales).
                        • El becado colaborador debe contar de manera obligatoria con su correo institucional
                        (@anahuac.mx) en donde recibirá información inherente al sorteo y al mismo tiempo será el
                        usuario que lo identificará para el acceso a la APP del Sorteo y realizar su labor de venta.


                    </p>

                    <p>
                        Es facultad del Comité de la Dirección de cada Instituto, evaluar y determinar si el rango de boletos a vender por alumno becado es mayor al establecido en este reglamento. Podrá ser mayor, pero nunca menor al establecido en este documento.


                    </p>
                    <p>

                        Artículo 12. Las fechas límite para visualizar los boletos digitales en la App del Sorteo Anáhuac (logueo) por parte de todos los alumnos Becados Colaboradores sujetos en este reglamento, serán debidamente informadas a los alumnos por los diferentes medios de comunicación oficiales de la Red de Universidades Anáhuac y en el correo institucional con el que cuenta cada alumno.

                    </p>
                    <p>
                        • Alumnos de reingreso (15 de abril 2024) <br>
                        • Alumnos de nuevo ingreso (23 de septiembre de 2024)

                    </p>

                    <h2>IV. DE LA VENTA DE BOLETOS DIGITALES</h2>
                    <p>
                        Artículo 13. Todos los Becados Colaboradores de la Red de Universidades Anáhuac y del Pontificio Instituto Juan Pablo II, deberán de vender boletos digitales como parte de los requisitos para mantener la beca tal y como está estipulado en el Compendio Reglamentario Anáhuac 2024,  Libro Séptimo, Reglamento de Becas y Financiamiento Educativo para Alumnos de la Universidad Anáhuac, Título I, Capítulo III Art.10: “Haber participado como colaborador en la venta de boletos digitales o físicos del Sorteo Anáhuac, apegado a las políticas del Sorteo Anáhuac de su Universidad”.
                    </p>
                    <p>
                        Artículo 14. Haber participado en la venta de boletos del Sorteo Anáhuac apegado a las políticas del Sorteo Anáhuac de la Universidad. 
                    </p>
                    <p>
                        •	El sorteo es anual, por lo que se debe cumplir con este requisito aún cuando la beca se haya disfrutado por un solo periodo (semestre) en el año del sorteo vigente, es decir, que aunque haya perdido su beca, el becado colaborador deberá concluir con la venta de sus boletos.
                        •	En el caso de que un colaborador becario tenga uno o más hermanos y que también cuenten con un porcentaje de beca, cada uno de ellos tendrá que cubrir la cuota de venta de boletos que le corresponda con base al porcentaje de beca recibido.
                        •	Al momento de egresar, el becario colaborador deberá de haber cumplido con la totalidad de la venta de los boletos que le corresponden por el porcentaje de beca.
                        •	Si el becario colaborador se da de baja durante el semestre, deberá de cumplir con la totalidad de la venta de boletos que le corresponde por el porcentaje de beca.
                        •	Aún cuando el alumno becario se encuentre cursando su último semestre, mientras conserve la beca, deberá cumplir con la venta de boletos en su totalidad. 
                        

                    </p>

                    <p>
                        Ningún becado colaborador podrá devolver boletos digitales sin vender, a excepción del boleto de regalo (el boleto número 11) que podrá ir a su nombre o a nombre de cualquier persona que designe y que no deberá de depositar en el banco. 
                    </p>

                    <p>
                        Artículo 15. Si el Becado Colaborador no cumple con las fechas límites de pago y entrega de sus boletos digitales para cerrar y completar su proceso de venta y sabiendo de antemano que NO puede regresar boletos, estará sujeto a lo siguiente:
                    </p>
                    <p>
                        •	Si el Becado Colaborador tiene en su poder boletos digitales sin concluir el proceso de venta, el sistema automáticamente dos días naturales antes de la celebración del SORTEO (fecha acordada entre la Gerencia de Control y la Dirección del Sorteo) los boletos digitales quedarán catalogados como BOLETOS NO VENDIDOS para términos de reportes a Gobernación, y al mismo tiempo el alumno quedará como deudor por no concluir su proceso de venta completo. La operación que se realiza de manera automática al no concluir el proceso de venta de los boletos digitales que le corresponde al alumno becado, no exime al alumno del pago completo del talonario. Si el alumno no realiza el pago completo que le corresponde quedará como alumno deudor y recibirá la sanción correspondiente. 
                        •	Si el Becado Colaborador tiene en su poder boletos físicos sin concluir el proceso de venta, estarán sujetos a lo dispuesto por la Dirección General de Juegos y Sorteos con respecto a los boletos que no puedan estar dentro del concentrado final especificado en el permiso de gobernación. Por otro lado, el alumno deberá realizar el pago completo del talonario para no tener adeudo con el Sorteo y no recibir la sanción correspondiente.
                        

                    </p>

                    <p>

                        Artículo 16. Las fechas límite para realizar el pago de boletos digitales en la App del Sorteo Anáhuac, o en la página web del sorteo o directamente en los bancos correspondientes por parte de todos los alumnos Becados Colaboradores sujetos en este reglamento, serán debidamente informadas a los alumnos por los diferentes medios de comunicación oficiales de la Red de Universidades Anáhuac y en el correo institucional con el que cuenta cada alumno.


                    </p>

                    <p>
                        • Alumnos que egresan en el periodo de enero-mayo: (15 de mayo de 2024) <br>
                        • Alumnos que ingresan en el periodo de enero-mayo o que hayan recuperado su beca en este mismo
                        periodo: (28 de agosto de 2024)<br>
                        • Alumnos que ingresan en el periodo de agosto-diciembre o que hayan recuperado su beca en este
                        mismo periodo: (15 de octubre de 2024)

                    </p>

                    <h2>V. DE LAS INCIDENCIAS DE BOLETOS FÍSICOS (EN SU CASO)</h2>
                    <p>
                        Artículo 17. Entiéndase por incidencia de boletos físicos a aquel evento que, por causa ajena al colaborador, el boleto físico original sufre un daño físico, maltratando, alterando y/o dañando la imagen y textura inicial del boleto físico para estos casos deberá notificarlo a las oficinas del Sorteo Anáhuac en donde le indicarán el proceso para reportar el suceso y estará sujeto a las disposiciones que la autoridad disponga ante el percance. También en este rubro se considera como incidencia un robo o un extravío de boleto físico ya que su estado inicial ha sido modificado y su tenencia ha cambiado.
                    </p>
                    <p>
                        Artículo 18. Para el caso de incidencias de robo o extravío en boletos físicos, el colaborador tendrá que acudir al Ministerio Público de su localidad para realizar una Declaración de hechos, o en su caso una Denuncia por robo o extravío de sus boletos físicos. En cualquiera de estas modalidades, al colaborador no se le lo exime de la responsabilidad del pago de dichos boletos físicos, debiendo realizar el pago correspondiente de acuerdo a las fechas límite de pago que se le haya notificado.
                    </p>

                    <h2>
                        VI.	SANCIONES AL INCUMPLIMIENTO DEL COMPENDIO REGLAMENTARIO ANÁHUAC 2024, LIBRO SÉPTIMO, REGLAMENTO DE BECAS Y FINANCIAMIENTO EDUCATIVO PARA ALUMNOS DE LA UNIVERSIDAD ANÁHUAC, TÍTULO I, CAPÍTULO III, ART.10
                    </h2>

                    <p>
                        Artículo 19. Este artículo y siguientes, exponen las sanciones que podrán tener los becados colaboradores pertenecientes a la Red de Universidades Anáhuac y el Pontificio Instituto Juan Pablo II ante el incumplimiento del Compendio Reglamentario Anáhuac 2024, Libro Séptimo, Reglamento de Becas y Financiamiento Educativo para Alumnos de la Universidad Anáhuac, Título I, Capítulo III Art.10. Es importante mencionar que estas sanciones serán el último recurso a utilizar. Siempre deberá existir un trabajo previo de concientización, apoyo, seguimiento y motivación a los alumnos para que cumplan con este requisito que al tener una beca les implica.
                    </p>

                    <p>
                        Artículo 20. Sanción a los becados colaboradores de la Red de Universidades Anáhuac y del Pontificio Instituto Juan Pablo II por no visualizar los boletos en la App del Sorteo en los tiempos estipulados:
                    </p>
                    <p>
                        a.	Sabiendo la responsabilidad que tiene con la venta de boletos digitales, quien no visualice sus boletos en la App y hagan caso omiso a los llamados que se le hayan hecho, al becado colaborador se le penalizará con un hold administrativo al siguiente día con respecto a la fecha límite de logueo. En el caso de que el becado colaborador ya haya cumplido con el requisito, previo aviso al enlace operativo del Sorteo de la Universidad, se le quitará en un rango de 24 horas dicha retención.
                    </p>

                    <p>
                        Artículo 21. Sanción a los becados colaboradores de la Red de Universidades Anáhuac y del Pontificio Instituto Juan Pablo II por entregar fuera de las fechas límite establecidas el pago de los boletos digitales y los taloncitos de los boletos físicos vendidos.
                        Deberá entenderse como fecha límite aquel ultimo día en el que el becario colaborador debe cumplir con una disposición previamente aceptada, llámese visualización de boletos en la App o pago de boletos. Por lo que las sanciones se aplicarán como sigue:
                        

                    </p>
                    <p>
                        a.	Al día siguiente de la fecha límite: Hold administrativo.
                        b.	En la 2ª semana de retraso: hold administrativo + disminución de 5% en su porcentaje de beca. Nota: 5 puntos porcentuales, no 5% de la beca que tiene asignada el alumno.
                        c.	En la 3ª semana de retraso: hold administrativo + disminución de 10% extra al 5% de la semana anterior en su porcentaje de beca. Nota: se vuelven 15 puntos porcentuales, no el 15% de la beca que tiene asignada el alumno.
                        

                    </p>
                    <p>
                        Artículo 22. Las sanciones son de carácter inobjetable ya que antes de aplicar la sanción se habrán realizado diversas campañas de aviso a los colaboradores becados ya sea en primera instancia para pasar por sus boletos digitales o para realizar la entrega de los talonarios completamente vendidos.


                    </p>

                    <h2>
                        TRANSITORIOS
                    </h2>

                    <p>
                        Artículo 23. Este reglamento también aplica para aquellos alumnos becarios que elijan vender boletos físicos en lugar de boletos digitales. Con base a las disposiciones de este reglamento, las condiciones se ajustarán a los boletos físicos.
                    </p>

                    <p>

                        Artículo 24. El presente reglamento entrará en vigor desde el primer día hábil del año 2018, y será publicado y actualizado de común acuerdo a las políticas y procedimientos establecidos por la Coordinación de Comunicación y Marketing del Sorteo Anáhuac. 

                    </p>


                    <p>
                        Artículo 25. Es obligación del Sorteo Anáhuac a través de la Gerencia de Control del Sorteo Anáhuac resolver los casos de duda o interpretación lo referente al contenido en este reglamento y se reserva el derecho de realizar modificaciones al propio reglamento salvaguardando la integridad y el beneficio de la comunidad universitaria de la RUA y del Instituto Juan Pablo II.
                    </p>

                    <p>
                        Artículo 26. Cualquier caso no previsto en el presente Reglamento será decisión directa y con carácter de inapelable la resolución que dictamine el Comité Interno de Operación del Sorteo Anáhuac A.C.
                    </p>

                    <p>
                        <strong>Última actualización: 19 de marzo de 2024.</strong>
                    </p>

                </div>
            </div>
        </div>
    </div>
</section>