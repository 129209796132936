import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HomeService } from '../../../../services/home.service';
import { RetrieveEmail } from '../../../../models/retrieveEmail';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from '../../../../models/login';
import { Md5 } from 'ts-md5/dist/md5';
import * as $ from 'jquery';
import * as AOS from 'aos';
import { timer } from 'rxjs';
import { PasaLavozService } from 'src/services/pasa-lavoz.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  login = true;
  valid_email = false;
  token = "";
  successMail = false;
  failMail = false;
  formLogin: Login = new Login();
  md5 = new Md5();
  dataColab: any = [];
  session = false;
  nameSession = "";
  passInval = false;
  linkPb = "";


  //contadores

  //time magno
  _second = 1000;
  _minute = this._second * 60;
  _hour = this._minute * 60;
  _day = this._hour * 24;
  end: any;
  now: any;
  day: any;
  hours: any;
  minutes: any;
  seconds: any;
  source = timer(0, 1000);


  //time oportuno
  _segundos = 1000;
  _minutos = this._segundos * 60;
  _horas = this._minutos * 60;
  _dias = this._horas * 24;
  fin: any;
  ahora: any;
  dias: any;
  horas: any;
  minutos: any;
  segundos: any;
  source1 = timer(0, 1000);

  //time egresado
  _segundos_egr = 1000;
  _minutos_egr = this._segundos_egr * 60;
  _horas_egr = this._minutos_egr * 60;
  _dias_egr = this._horas_egr * 24;
  fin_egr: any;
  ahora_egr: any;
  dias_egr: any;
  horas_egr: any;
  minutos_egr: any;
  segundos_egr: any;
  source2 = timer(0, 1000);

  utmUrl = "";
  constructor(private readonly _homeService: HomeService, private route: ActivatedRoute, private readonly _pasaServive: PasaLavozService) {
    if (localStorage.getItem('idColaborador') === null || localStorage.getItem('idColaborador') === "") {
      this.session = false;
    } else {
      this.session = true;
      let tem = localStorage.getItem('nombre').split(" ");
      this.nameSession = tem[0];
    }
    this.linkPb = localStorage.getItem("payback");


  }
  formEmail: RetrieveEmail = new RetrieveEmail();


  ngOnInit(): void {
    /*utm */
    let utmTem = localStorage.getItem('urlUtm');
    if (utmTem) this.utmUrl = utmTem;
    /* fin utm */
    AOS.init({
      duration: 650,
      once: true
    });


    localStorage.setItem('error1', "f");

    $(document).ready(function () {
      $(".mobile-nav-trigger").click(function () {
        $(this).children(".hamburger").toggleClass('is-active');
        $('.mobile-menu').toggleClass('active-nav');
        $('.overlay').toggleClass('active-overlay');
      });
      $('.main-nav-link').click(function () {
        $('.hamburger').toggleClass('is-active');
        $('.mobile-menu').toggleClass('active-nav');
        $('.overlay').toggleClass('active-overlay');
      });





      //errores
      if (localStorage.getItem('error2') === "t") {
        this.passInval = true;
        (<any>jQuery('#loginmodal')).modal('show');
        localStorage.setItem('error1', "f");
        localStorage.setItem('error2', "f");
      }
    });


    //contador
    //time oportuno
    // this.source1.subscribe(t => {
    //     this.ahora = new Date();
    //     this.fin = new Date('10/13/' + (this.ahora.getFullYear() ) +' 11:00');
    //     this.showDate();
    // });

    //time magno
    this.source.subscribe(t => {
      this.now = new Date();
      this.end = new Date('11/23/' + (this.now.getFullYear()) + ' 17:00');
      this.showDateSorteoMagno();
    });

    //time egresado
    // this.source2.subscribe(t => {
    //   this.ahora_egr = new Date();
    //   this.fin_egr = new Date('06/30/' + (this.ahora_egr.getFullYear() ) +' 12:00');
    //   this.showDateEgr();
    // });

  }


  close() {
    $('.overlay').removeClass('active-overlay');
    $('.hamburger').removeClass('is-active');
    $('.mobile-menu').removeClass('active-nav');

  }

  recoveryEm() {
    this.login = false;
  }

  emailP() {
    this.login = true;
  }

  validateData(form: NgForm) {
    if (form.invalid || !this.valid_email) { return; }
    //console.log(form);
    this._pasaServive.getToken().subscribe(a => {
      console.log(a);
    }, err => {

      if (err.status == 200) {

        this._homeService.recoveryMail(this.formEmail, err.error.text).subscribe(a => {
          ////console.log(a);
        },
          err => {
            ////console.log(err);
            if (err.status === 200) {
              this.successMail = true;
              this.failMail = false;
              //this.login = true;
            }
            if (err.status === 404) {
              this.failMail = true;
              this.successMail = false;
            }
          });
      }
    });


  }

  validateLogin(form: NgForm) {
    if (form.invalid) { return; }
    ////console.log(form);

    this.formLogin.pass = String(this.md5.appendStr(this.formLogin.pass).end());
    //console.log(this.formLogin);
    this._pasaServive.getToken().subscribe(a => {
      console.log(a);
    }, err => {
      console.log(err);
      if (err.status == 200) {
        this._homeService.login(this.formLogin, err.error.text).subscribe(a => {
          this.dataColab = a;

          localStorage.setItem('idColaborador', this.dataColab.IDColaborador);
          localStorage.setItem('nombre', this.dataColab.Nombre);
          this.session = true;
          this.nameSession = this.dataColab.Nombre;
          let tem = localStorage.getItem('nombre').split(" ");
          this.nameSession = tem[0];


          window.location.href = "/menu-login";
        },
          err => {
            ////console.log(err);

            if (err.status === 404) {
              this.passInval = true;
              ////console.log( this.formLogin);
              localStorage.setItem('error1', "t");
              localStorage.setItem('error2', "t");
              location.reload();


            } else {
              ////console.log(this.formLogin);
            }
          });
      }
    });


  }

  inputValidateEmail(event: any) {

    const pattern = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      // invalid character, prevent input
      this.valid_email = false;
      this.failMail = false;
      this.successMail = false;
    } else {
      this.valid_email = true;
    }
  }

  logout() {
    localStorage.removeItem('idColaborador');
    localStorage.removeItem('nombre');
    this.session = false;
    window.location.reload();
    window.location.href = "/home";
  }
  changeVar() {
    this.login = true;
  }


  //contadores
  //sorteos oportunos
  // showDate(){
  // 	let distance = this.fin - this.ahora;
  // 	if(this.fin - this.ahora <= 0) {
  // 		distance = 0;
  //    	}
  // 	this.dias = Math.floor(distance / this._dias);
  // 	if(this.dias < 10) this.dias ="0" + this.dias;

  // 	this.horas = Math.floor((distance % this._dias) / this._horas);
  // 	if(this.horas < 10) this.horas ="0" + this.horas;

  // 	this.minutos = Math.floor((distance % this._horas) / this._minutos);
  // 	if(this.minutos < 10) this.minutos ="0" + this.minutos;

  // 	this.segundos = Math.floor((distance % this._minutos) / this._segundos);
  // 	if(this.segundos < 10) this.segundos ="0" + this.segundos;

  // }

  showDateSorteoMagno() {
    let distance = this.end - this.now;

    if (this.end - this.now <= 0) {
      distance = 0;
    }

    this.day = Math.floor(distance / this._day);
    if (this.day < 10) this.day = "0" + this.day;


    this.hours = Math.floor((distance % this._day) / this._hour);
    if (this.hours < 10) this.hours = "0" + this.hours;

    this.minutes = Math.floor((distance % this._hour) / this._minute);
    if (this.minutes < 10) this.minutes = "0" + this.minutes;

    this.seconds = Math.floor((distance % this._minute) / this._second);
    if (this.seconds < 10) this.seconds = "0" + this.seconds;

  }

  // showDateEgr(){
  // 	let distance = this.fin_egr - this.ahora_egr;
  // 	if(this.fin_egr - this.ahora_egr <= 0) {
  // 		distance = 0;
  //    	}
  // 	this.dias_egr = Math.floor(distance / this._dias_egr);
  // 	if(this.dias_egr < 10) this.dias_egr ="0" + this.dias_egr;

  // 	this.horas_egr = Math.floor((distance % this._dias_egr) / this._horas_egr);
  // 	if(this.horas_egr < 10) this.horas_egr ="0" + this.horas_egr;

  // 	this.minutos_egr = Math.floor((distance % this._horas_egr) / this._minutos_egr);
  // 	if(this.minutos_egr < 10) this.minutos_egr ="0" + this.minutos_egr;

  // 	this.segundos_egr = Math.floor((distance % this._minutos_egr) / this._segundos_egr);
  // 	if(this.segundos_egr < 10) this.segundos_egr ="0" + this.segundos_egr;

  // }

}
