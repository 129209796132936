<section class="stc-galeria">
    <div class="container">
        <div class="row ">
            <div class="col-lg-12  d-flex justify-content-center flex-column text-center" data-aos="fade-left"
                data-aos-easing="ease" data-aos-duration="3000">
                <h1>
                    GALERÍA 
                </h1>

            </div>
        </div>
    </div>
</section>






<section class="container-gallery mb-5">
    <div class="container">
        <div class="row">

            <div class="col-md-12 text-center">

                <div class="controls border-gradient-all">
                    <a *ngIf="btn_2023" class="btn-stack btn-g btn1" (click)="showGalery(1)">2023</a>
                    <a *ngIf="btn_2024" class="btn-stack btn-g btn2" (click)="showGalery(2)">2024</a>

                    <div class="content-2023" >

                        <a type="button" class="control btn-d" data-filter=".sm"><span>1er premio</span></a>
                        <a type="button" class="control btn-d" data-filter=".segundo"><span>2do premio</span></a>
                        <a type="button" class="control btn-d" data-filter=".ter"><span>3er premio</span></a>
                        <a type="button" class="control btn-d" data-filter=".auto"><span>Autos</span></a>
                        <a type="button" class="control btn-d" data-filter=".airpods"><span>Airpods</span></a>
                        <a type="button" class="control btn-d" data-filter=".so1"><span>1º Sorteo oportuno 2023</span></a>
                        <a type="button" class="control btn-d" data-filter=".se"><span>Sorteos de Egresados 2023</span></a>
                        <!-- <a type="button" class="control btn-d" data-filter=".sm"><span>Sorteo Anáhuac Séptima Edición</span></a> -->
                    </div>
                    <div class="content-2024" >

                        <a type="button" class="control btn-d" data-filter=".sop1"><span>1º Sorteo Oportuno - 1er premio</span></a>
                        <a type="button" class="control btn-d" data-filter=".sop2"><span>1º Sorteo Oportuno - 2do premio</span></a>
                        <a type="button" class="control btn-d" data-filter=".sop3"><span>1º Sorteo Oportuno - 3er premio</span></a>
                    </div>
                </div>

                <div class="conainter-photos">
                    <div class="row">

                        <div class="col-12">
                            <div class="card-columns-init">

                                <!-- primer premio -->
                                <div class="img-fluid mix sm">
                                    <p>
                                        PRIMER PREMIO
                                    </p>
                                </div>


                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/premio-1 (1).jpg"
                                    class="img-fluid mix sm">
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/premio-1 (2).jpg"
                                    class="img-fluid mix sm">
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/premio-1 (3).jpg"
                                    class="img-fluid mix sm">
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/premio-1 (4).jpg"
                                    class="img-fluid mix sm">
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/premio-1 (5).jpg"
                                    class="img-fluid mix sm">
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/premio-1 (6).jpg"
                                    class="img-fluid mix sm">
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/premio-1 (7).jpg"
                                    class="img-fluid mix sm">
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/premio-1 (8).jpg"
                                    class="img-fluid mix sm">


                                <!-- segundo premio -->
                                <div class="img-fluid mix segundo">
                                    <p>
                                        SEGUNDO PREMIO
                                    </p>
                                </div>


                                <img src="/assets/images/sorteo2023/galeria/segundo/premio-2-(1).jpg"
                                    class="img-fluid mix segundo">
                                <img src="/assets/images/sorteo2023/galeria/segundo/premio-2-(2).jpg"
                                    class="img-fluid mix segundo">
                                <img src="/assets/images/sorteo2023/galeria/segundo/premio-2-(3).jpg"
                                    class="img-fluid mix segundo">

                                <!-- tercer premio -->
                                <div class="img-fluid mix ter">
                                    <p>
                                        TERCER PREMIO
                                    </p>
                                </div>

                                <img src="/assets/images/sorteo2023/galeria/tercer/premio-3-(1).jpg"
                                    class="img-fluid mix ter">
                                <img src="/assets/images/sorteo2023/galeria/tercer/premio-3-(2).jpg"
                                    class="img-fluid mix tercer">
                                <img src="/assets/images/sorteo2023/galeria/tercer/premio-3-(3).jpg"
                                    class="img-fluid mix tercer">
                                <img src="/assets/images/sorteo2023/galeria/tercer/premio-3-(4).jpg"
                                    class="img-fluid mix tercer">
                                <img src="/assets/images/sorteo2023/galeria/tercer/premio-3-(5).jpg"
                                    class="img-fluid mix tercer">
                                <img src="/assets/images/sorteo2023/galeria/tercer/premio-3-(6).jpg"
                                    class="img-fluid mix tercer">
                                <img src="/assets/images/sorteo2023/galeria/tercer/premio-3-(7).jpg"
                                    class="img-fluid mix tercer">
                                <img src="/assets/images/sorteo2023/galeria/tercer/premio-3-(8).jpg"
                                    class="img-fluid mix tercer">


                                <!-- ganadores autos-p -->
                                <div class="img-fluid mix auto">
                                    <p>
                                        GANADORES AUTOS
                                    </p>
                                </div>


                                <img src="/assets/images/sorteo2023/galeria/autos/autos-p (1).jpg"
                                    class="img-fluid mix auto">
                                <img src="/assets/images/sorteo2023/galeria/autos/autos-p (2).jpg"
                                    class="img-fluid mix auto">
                                <img src="/assets/images/sorteo2023/galeria/autos/autos-p (3).jpg"
                                    class="img-fluid mix auto">
                                <img src="/assets/images/sorteo2023/galeria/autos/autos-p (4).jpg"
                                    class="img-fluid mix auto">
                                <img src="/assets/images/sorteo2023/galeria/autos/autos-p (5).jpg"
                                    class="img-fluid mix auto">
                                <img src="/assets/images/sorteo2023/galeria/autos/autos-p (6).jpg"
                                    class="img-fluid mix auto">
                                <img src="/assets/images/sorteo2023/galeria/autos/autos-p (7).jpg"
                                    class="img-fluid mix auto">
                                <img src="/assets/images/sorteo2023/galeria/autos/autos-p (8).jpg"
                                    class="img-fluid mix auto">
                                <img src="/assets/images/sorteo2023/galeria/autos/autos-p (9).jpg"
                                    class="img-fluid mix auto">
                                <img src="/assets/images/sorteo2023/galeria/autos/autos-p (10).jpg"
                                    class="img-fluid mix auto">


                                <!-- airpods -->
                                <div class="img-fluid mix airpods">
                                    <p>
                                        AIRPODS
                                    </p>
                                </div>



                                <img src="/assets/images/sorteo2023/galeria/airpods/airpods-p-(1).jpg"
                                    class="img-fluid mix airpods">
                                <img src="/assets/images/sorteo2023/galeria/airpods/airpods-p-(2).jpg"
                                    class="img-fluid mix airpods">

                                <img src="/assets/images/sorteo2023/galeria/airpods/airpods-p-(3).jpg"
                                    class="img-fluid mix airpods">
                                <img src="/assets/images/sorteo2023/galeria/airpods/airpods-p-(4).jpg"
                                    class="img-fluid mix airpods">
                                <img src="/assets/images/sorteo2023/galeria/airpods/airpods-p-(5).jpg"
                                    class="img-fluid mix airpods">
                                <img src="/assets/images/sorteo2023/galeria/airpods/airpods-p-(6).jpg"
                                    class="img-fluid mix airpods">
                                <img src="/assets/images/sorteo2023/galeria/airpods/airpods-p-(7).jpg"
                                    class="img-fluid mix airpods">
                                <img src="/assets/images/sorteo2023/galeria/airpods/airpods-p-(8).jpg"
                                    class="img-fluid mix airpods">


                                <!-- SORTEO OPORTUNO -->
                                <div class="img-fluid mix so1">
                                    <p>
                                        ¡Conoce a Roger! Nuestro ganador del 1° lugar del 1° Sorteo Oportuno
                                    </p>
                                    <youtube-player videoId="xNQfPZPgsgw" [height]="500" [width]="800">
                                    </youtube-player>
                                </div>



                                <div class="img-fluid mix so1">
                                    <p>
                                        ¡Conoce a Sergio! Nuestro ganador del 3° lugar del 1° Sorteo Oportuno
                                    </p>
                                    <youtube-player videoId="kySIwO3emj0" [height]="500" [width]="800">
                                    </youtube-player>
                                </div>

                                <!-- SORTEO EGRESADO -->
                                <div class="img-fluid mix se">
                                    <p>
                                        ¡Ganador Sorteo Egresados Anáhuac 2023!
                                    </p>
                                    <youtube-player videoId="ChEg5joVJ9U" [height]="500" [width]="800">
                                    </youtube-player>
                                </div>



                                <div class="img-fluid mix se">
                                    <p>
                                        ¡Ganadora Sorteo Egresados Lealtad 2023!
                                    </p>
                                    <youtube-player videoId="M9H-Q-Sx5sg" [height]="500" [width]="800">
                                    </youtube-player>
                                </div>

                                <div class="img-fluid mix se">
                                    <p>
                                        ¡Ganadora Sorteo Amigo Egresado 2023!
                                    </p>
                                    <youtube-player videoId="pyyj5-8IQa0" [height]="500" [width]="800">
                                    </youtube-player>
                                </div>

                            </div>
                        </div>


                    </div>

                    <div class="gap"></div>
                    <div class="gap"></div>
                    <div class="gap"></div>
                    <div class="gap"></div>
                </div>
                <div class="conainter-photos2" >
                    <div class="row">

                        <div class="col-12">
                            <div class="card-columns-init">

                                <!-- SORTEO EGRESADO -->
                                <div class="img-fluid mix2 sop1">
                                    <p>
                                        ¡Conoce a Miriam ganadora del 1º lugar en el 1º Sorteo Oportuno!
                                    </p>
                                    <youtube-player videoId="YqAc1X0OeWw" [height]="500" [width]="800">
                                    </youtube-player>
                                </div>

                                <div class="img-fluid mix2 sop1">
                                    <p>
                                        Presentamos a Estefanía ganadora de 1º premio de colaborador del 1º Sorteo Oportuno.
                                    </p>
                                    <youtube-player videoId="mdXeyK7FY8M" [height]="500" [width]="800">
                                    </youtube-player>
                                </div>

                                <div class="img-fluid mix2 sop2">
                                    <p>
                                        ¡Roberto es el ganador del 2º lugar en el 1º Sorteo Oportuno!
                                    </p>
                                    <youtube-player videoId="_TiRqObt_Cc" [height]="500" [width]="800">
                                    </youtube-player>
                                </div>


                                <div class="img-fluid mix2 sop3">
                                    <p>
                                        ¡Conoce a María ganadora del 3º lugar de colaborador en el 1º Sorteo Oportuno!
                                    </p>
                                    <youtube-player videoId="NeBK-9d7k-Y" [height]="500" [width]="800">
                                    </youtube-player>
                                </div>







                            </div>
                        </div>


                    </div>

                    <div class="gap"></div>
                    <div class="gap"></div>
                    <div class="gap"></div>
                    <div class="gap"></div>
                </div>
          
            </div>
        </div>
    </div>
</section>