<section id="stc-colabora"  >
    <div class="container">
        <div class="row">
            <div class="col-lg-12 my-auto" data-aos=" zoom-in-down" data-aos-easing="ease"data-aos-duration="3000" >
                <img src="/assets/images/sorteo2024/col.png" alt="Registrate como colaborador" class="img-fluid">
            </div>
        </div>
    </div>
</section>

<!-- <section id="slide-videos" class="mt-3" >
    <div class="container">
        <div class="row">
            <div class="col-12" data-aos="zoom-in" data-aos-duration="3000">
                <h2 >
                    ¡Conoce a nuestros colaboradores premiados!
                </h2>
                <hr class="line mb-5">
            </div>
            <div class="col-md-12">
                <ngx-slick-carousel class="carousel" #slickModal1="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                        <youtube-player
                        videoId="{{slide.url}}"
                        [height]="500"   
                        [width]="800"  
                        >
                        </youtube-player>
                    
                    </div>
                  </ngx-slick-carousel>
            </div>
        </div>
    </div>
</section> -->

<section class="container-form">
    <div class="container">
        <div class="row">
            <div class="col-md-12" data-aos="fade-up" data-aos-easing="ease"data-aos-duration="3000">
                
                <div class="content-fall">
                    <form #colaboraData="ngForm" (ngSubmit)="validateData(colaboraData)" class="form-all" id="form-egr" autocomplete="off">
                        <div class="form-row  pt-3">
                            <div class="form-group col-lg-4 col-md-12">
                                <label  for="email">Correo electrónico:</label >
  
                                    <input (input)="inputValidateEmail($event)" type="email" class="form-control" id="correo" placeholder="{{colaboraValues.correo }}" correo #correo="ngModel" ngModel   name="correo" [(ngModel)]="colaboraValues.correo"  required autocomplete="new-correo">
                                    <div *ngIf="!colaboraValues.correo || !valid_email " class="alert alert-danger">
                                        Formato incorrecto
                                    </div>
                                </div>

                                <div class="form-group col-lg-4 col-md-12">
                                    <label for="password">Contraseña</label>
                                    <input   (input)="validaPass($event)"   minlength="8"   type="password" class="form-control" id="password" placeholder="* * * * *"  password #password="ngModel" ngModel   name="password" [(ngModel)]="colaboraValues.password" required autocomplete="new-password">
             
                                    
                                    <div *ngIf="!colaboraValues.password " class="alert alert-danger">
                                        Dato requerido
                                    </div>

                            </div>

                            <div class="form-group col-lg-4 col-md-12">
                                <label for="conf-pwd">Confirmar contraseña</label>
                                <input minlength="8" (input)="validaPass($event)" type="password" class="form-control" id="conf-pwd" placeholder="* * * * *" name="conf-pwd" required autocomplete="new-password_12">
                                <div id="verify-pass" class="alert alert-danger">
                                    Contraseñas diferentes
                                </div>
                                <div id="verify-g" class="alert alert-success" role="alert">
                                    Contraseñas iguales
                                </div>
                            </div>
                            <div class="form-group col-md-8 offset-md-4">
                                <div *ngIf="f1  || f2; " class="alert alert-danger">
                                    La contraseña debe tener mínimo 8 caracteres.
                                </div>
                            </div>
                        </div>

                        <div class="form-row pt-3">
                            <div class="col-lg-4 form-group">
                                <label  for="celular">Número celular</label >
                                <div class="row">
    
                                    <div class="form-group col-md-12">
                                        <input  minlength="10" maxlength="10" (input)="inputValidator($event)" type="text" class="form-control"  required id="celular" placeholder="{{direccionValue.telefono }} " required celular ngModel #celular="ngModel"  name="celular" [(ngModel)]="direccionValue.telefono"  autocomplete="new-celular" >
                                        <!-- <label class="pt-2 "for="number-ten">Número a 10 dígitos</label > -->
                                        <div *ngIf="!direccionValue.telefono || celular.invalid  " class="alert alert-danger">
                                            Dato requerido <span  *ngIf="direccionValue.telefono  ">{{ direccionValue.telefono.length }}/10</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 form-group">
                                    <label for="telefono_casa">Número casa</label >
                                    <div class="row">
                                        <!-- <div class="form-group col-md-4">
                                            <input  maxlength="3" (input)="inputValidator($event)" type="text" class="form-control"  id="area_telefono" placeholder="{{colaboraValues.area_telefono }} "  area_telefono ngModel #area_telefono="ngModel" #area_telefono name="area_telefono" [(ngModel)]="colaboraValues.area_telefono">
                                            <label class="pt-2 " for="lada">Lada</label >
                                        </div> -->
                                        <div class="form-group col-md-12">
                                            <input  maxlength="10" (input)="inputValidator($event)" type="text" class="form-control" id="telefono_casa"  placeholder="{{direccionValue.telefono_alternativo }} "  telefono_casa ngModel #telefono_casa="ngModel"  name="telefono_casa" [(ngModel)]="direccionValue.telefono_alternativo"  autocomplete="new-telefono_casa" >
                                            <!-- <label class="pt-2 "for="number-ten">Número de teléfono</label > -->
                                        </div>
                                    </div>
                                </div>
                            </div>



                        <div class="form-row pt-3">
                            <div class="form-group col-lg-4 col-md-12">
                                <label  for="nombre">Nombre </label>
                                <input type="text" class="form-control" id="nombre" name="nombre" nombre ngModel #nombre="ngModel" [(ngModel)]="colaboraValues.nombre" required autocomplete="new-nombre">
                                <div *ngIf="!colaboraValues.nombre" class="alert alert-danger">
                                    No olvides este campo
                                </div>
                            </div>
                            <div class="form-group col-lg-8 col-md-12">
                                <label for="name">Apellidos</label>
                                <input type="text" class="form-control" id="apellido_paterno" placeholder="{{colaboraValues.apellido_paterno }}" apellido_paterno #apellido_paterno="ngModel" ngModel   name="apellido_paterno" [(ngModel)]="colaboraValues.apellido_paterno"  required autocomplete="new-apellido_paterno">
                                <div *ngIf="!colaboraValues.apellido_paterno " class="alert alert-danger">
                                    No olvides este campo
                                </div>
                            </div>

                        </div>

                        <div class="form-row  pt-3">
                            <div class="form-group col-md-12 mb-0">
                                <label class="purple">Dirección de Casa</label >
                            </div>
                        </div>
                        <div class="form-row pt-3">
                            <div class="form-group col-md-6">
                                <label class="pt-2 purple" for="pais">País</label >
                                <select class="form-control"    required pais ngModel id="pais" name="pais" #pais="ngModel"  [(ngModel)]="direccionValue.pais" (change)="capturar()">
                                    <option value="0">Selecciona un país</option>
                                    <option  *ngFor="let item of country" value="{{item}}" >{{ item}}</option>
                                </select>
                                <div *ngIf="!direccionValue.pais || verSeleccion === '0'" class="alert alert-danger">
                                    Dato requerido
                                </div>
                            </div>
                    <ng-container *ngIf="direccionValue.pais === 'México' || direccionValue.pais === 'Mexico'; else ext ">
                            <div class="form-group col-md-4">
                                <label class="pt-2 purple" for="cp">Código Postal</label >
                                <input type="text" class="form-control" required id="cp" placeholder="{{direccionValue.codigo_postal }} "  cp ngModel #cp="ngModel"  name="cp" [(ngModel)]="direccionValue.codigo_postal" autocomplete="new-cp">
                                <!-- <div *ngIf="!colaboraValues.cp " class="alert alert-danger">
                                    Dato requerido
                                </div> -->
                            </div>
                            <div class="form-group col-md-2 my-auto">
                                <a class="btn"  (click)="getCp()" >Buscar</a>
                            </div>

                            <div class="form-group col-md-12">
                                <ng-container *ngIf="dataEmptyCP ">
                                    <div  class="alert alert-danger">
                                        Codigo postal incorrecto
                                    </div>
                                </ng-container>
                            </div>

                            <ng-container *ngIf="selectCP">
                                <div class="form-group col-md-4">
                                    <label class="pt-2  purple" for="estado">Estado </label>
                                    <input type="text" class="form-control" required id="estado" placeholder="{{direccionValue.estado }}" estado ngModel #estado="ngModel"  name="estado" [(ngModel)]="direccionValue.estado " disabled readonly>
                                    <div *ngIf="!direccionValue.estado" class="alert alert-danger">
                                        Dato requerido
                                    </div>
                                </div>

                            <div class="form-group col-md-4">
                                <label class="pt-2 purple" for="municipio">Municipio</label>
                                <input type="text" class="form-control" required id="municipio" placeholder="{{direccionValue.municipio  }} " municipio ngModel #municipio="ngModel"  name="municipio" [(ngModel)]="direccionValue.municipio " disabled readonly>
                                <div *ngIf="!direccionValue.municipio  " class="alert alert-danger">
                                    Dato requerido
                                </div>
                            </div>
                            </ng-container>

                            <ng-container *ngIf="!selectCP">
                                <div class="form-group col-md-4">
                                    <label class="pt-2 purple" for="estado">Estado </label>
                                    <input type="text" class="form-control" required id="estado" placeholder="{{dataCountry[0].estado }}" estado ngModel #estado="ngModel"  name="estado" [(ngModel)]="dataCountry[0].estado" disabled readonly>

                                </div>

                                <div class="form-group col-md-4">
                                    <label class="pt-2 purple" for="municipio">Municipio</label>
                                    <input type="text" class="form-control" required d="municipio" placeholder="{{dataCountry[0].municipio }} " municipio ngModel #municipio="ngModel"  name="municipio" [(ngModel)]="dataCountry[0].municipio" disabled readonly>
                                </div>
                            </ng-container>


                            <div class="form-group col-md-4">
                                <label class="pt-2 purple" for="colonia">Colonia</label>
                                <ng-container *ngIf="isClickCol && selectCP">
                                    <input (click)="isClickInp()" type="text" class="form-control" required id="colonia" placeholder="{{direccionValue.colonia}} " colonia ngModel #colonia="ngModel"  name="colonia" [(ngModel)]="direccionValue.colonia">
                                </ng-container>
                                <ng-container *ngIf="!isClickCol || !selectCP">
                                    <select class="form-control" required colonia ngModel id="colonia" name="colonia" #colonia="ngModel" [(ngModel)]="direccionValue.colonia" (change)="selectCol2()">
                                        <!-- <option value="0" selected>Seleccionar</option> -->
                                       <option  *ngFor="let item of dataCountry" value="{{item.colonia}}" >{{ item.colonia}}</option>
                                    </select>
                                </ng-container>
                                <div *ngIf="!direccionValue.colonia;" class="alert alert-danger">
                                    Dato requerido
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label class="pt-2 purple" for="calle">Calle </label>
                                <input type="text" class="form-control" required id="calle" placeholder="{{direccionValue.calle }} " required calle ngModel #calle="ngModel"  name="calle" [(ngModel)]="direccionValue.calle" autocomplete="new-calle">
                                <div *ngIf="!direccionValue.calle " class="alert alert-danger">
                                    Dato requerido
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label class="pt-2 purple" for="calle">Número exterior </label>
                                <input type="text" class="form-control" id="numero_exterior" placeholder="{{direccionValue.numero_exterior }} "  numero_exterior ngModel #numero_exterior="ngModel"  name="numero_exterior" [(ngModel)]="direccionValue.numero_exterior" autocomplete="new-numero_exterior">

                            </div>

                            <div class="form-group col-md-6">
                                <label class="pt-2 purple" for="calle">Número interior </label>
                                <input type="text" class="form-control" id="numero_interior" placeholder="{{direccionValue.numero_interior }} "  numero_interior ngModel #numero_interior="ngModel"  name="numero_interior" [(ngModel)]="direccionValue.numero_interior" autocomplete="new-numero_interior">
                            </div>

                            </ng-container>

                            <ng-template #ext>
                                <div class="form-group col-md-6">
                                    <label class="pt-2 purple" for="estado">Estado o provincia</label>
                                    <input (input)="inputValidateEst($event)" type="text" class="form-control" required id="estado" placeholder="{{direccionValue.estado }}" estado ngModel #estado="ngModel"  name="estado" [(ngModel)]="direccionValue.estado " >
                                    <div *ngIf="!flagLastName || !direccionValue.estado " class="alert alert-danger">
                                        Dato requerido
                                    </div>
                                </div>

                                <div class="form-group col-md-12">
                                    <label class="pt-2 purple" for="calle">Ingrese dirección</label>
                                    <input type="text" class="form-control" required id="calle" placeholder="{{direccionValue.calle }} " required calle ngModel #calle="ngModel"  name="calle" [(ngModel)]="direccionValue.calle" autocomplete="new-calle">
                                    <div *ngIf="!direccionValue.calle " class="alert alert-danger">
                                        Dato requerido
                                    </div>
                                </div>
                            </ng-template>

                        </div>


                        <div class="form-row pt-3">
                            <div class="form-group col-md-4">
                                <label class="purple" for="parte_de_comunidad">¿Eres parte de la comunidad?</label>
                                <select class="form-control custom-select custom-select-lg " id="parte_de_comunidad" ngModel #parte_de_comunidad="ngModel" parte_de_comunidad  name="parte_de_comunidad" [(ngModel)]="colaboraValues.parte_de_comunidad" required (change)="selectCol()">
                                    <option value="0" selected>Seleccionar</option>
                                    <option  value="Alumno">Alumno</option>
                                    <option  value="Egresado">Egresado</option>
                                    <option  value="Papá alumno" >Papá alumno</option>
                                    <option  value="Papá egresado">Papá egresado</option>
                                    <option  value="Empleado" >Empleado</option>
                                    <option  value="Empleado" >Externo</option>
                                </select>
                                <div *ngIf="!colaboraValues.parte_de_comunidad || seleccionComunidad == '0' " class="alert alert-danger">
                                    No olvides este campo
                                </div>
                            </div>

                            <div class="form-group col-md-4">
                                <label class="purple" for="pkcanal">Canal por donde nos conociste</label>
                                <select class="form-control custom-select custom-select-lg " id="pkcanal" ngModel #pkcanal="ngModel" pkcanal  name="pkcanal" [(ngModel)]="colaboraValues.pkcanal" required (change)="selectCanal()">
                                    <option value="0" selected>Seleccionar</option>
                                    <option *ngFor="let canal of canales"  value='{{ canal.pkcanal }}' > {{ canal.canal }}</option>
                                </select>
                                <div *ngIf="!colaboraValues.pkcanal || seleccionCanal == 0  " class="alert alert-danger">
                                    No olvides este campo
                                </div>
       
                            </div>

                            
                            <ng-container *ngIf="colaboraValues.pkcanal == 14 || colaboraValues.pkcanal ==15;" >
                                    
                                <div class="form-group col-md-4">
                                    <label class="pt-2 purple" for="especifica_canal">Especifique</label>
                                    <input type="text" class="form-control" required id="especifica_canal" placeholder="{{colaboraValues.especifica_canal }} " required calle ngModel #especifica_canal="ngModel"  name="especifica_canal" [(ngModel)]="colaboraValues.especifica_canal" autocomplete="new-calle">
                                    <div *ngIf="!colaboraValues.especifica_canal " class="alert alert-danger">
                                        Dato requerido
                                    </div>
                                </div>
                            </ng-container>
                        </div>



                        <div class="conten-btn text-center">
                            <button type="submit" class="btn-form">Enviar</button>
                        </div>

                        <div class="aviso text-center mt-5">
                            <label >Tus datos están seguros con nosotros. Revisa nuestro 
                                <a  [routerLink]="['/aviso-de-privacidad']" target="_blank">Aviso de privacidad</a>
                            </label>
                        </div>
                </form>
            </div>
        </div>
    </div>
</div>
</section>



<!-- Modal Info -->
<div class="modal fade" id="modalInfo" tabindex="-1" role="dialog" aria-labelledby="modalInfo" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body text-center no-padding">
                <p>Si tus datos son incorrectos, comunícate al siguiente correo electrónico <a class="link" href="mailto:egresados.sorteoan@anahuac.mx">egresados.sorteoan@anahuac.mx</a></p>
            </div>
            <div class="modal-footer justify-content-center border-0">
                <a class="btn  bg-gradient" >Cerrar</a>
            </div>
        </div>
    </div>
</div>

<!-- Modal send-->
<div class="modal fade" id="modal-thank" tabindex="-1" role="dialog" aria-labelledby="modal-thank" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content border-gradient-all">

           
            <div class="modal-body text-center no-padding">
                <ng-container *ngIf="bandera;">
                    <div  class="lds-hourglass"></div>
                  </ng-container>
    
                  <ng-container *ngIf="success;">
                    <p> ¡Gracias, tenemos tus datos! <span class="d-block">en breve nos comunicaremos contigo.</span></p>
                  </ng-container>
               
            </div>
            <ng-container *ngIf="success;">
                <div class="modal-footer text-center border-0 justify-content-center">
                    <a (click) ="fReload();" data-dismiss="modal" class="btn-modal" >Cerrar</a>
                </div>
            </ng-container>
        </div>
    </div>
</div>



<!-- Modal Error -->
<div class="modal fade" id="modalError" tabindex="-1" role="dialog" aria-labelledby="modalError" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body text-center no-padding">
                <p>{{error}}</p>
            </div>
            <div class="modal-footer justify-content-center border-0">
                <a  class="btn-d " data-dismiss="modal" >Cerrar</a>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modalErr" tabindex="-1" role="dialog" aria-labelledby="modalErr" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body text-center no-padding">
                <p>Por favor revisa tu información.</p>
                <div class="text-left">
                    <ng-container *ngIf="colaboraData.invalid;">
                        <p>Datos faltantes</p>
                    </ng-container>
                    
                    <ng-container *ngIf="!this.valid_email;">
                        <p><span style="color:#C22503;">*</span> Email</p>
                    </ng-container>

                    <ng-container *ngIf="!this.validPass || this.f1 || this.f2; ">
                        <p><span style="color:#C22503;">*</span> Password</p>
                    </ng-container>
                    <ng-container *ngIf="!direccionValue.telefono || celular.invalid">
                        <p><span style="color:#C22503;">*</span> Número celular</p>
                    </ng-container>
                    
                    <ng-container *ngIf="!colaboraValues.nombre;">
                        <p><span style="color:#C22503;">*</span> Nombre</p>
                    </ng-container>

                    <ng-container *ngIf="!colaboraValues.apellido_paterno">
                        <p><span style="color:#C22503;">*</span> Apellidos</p>
                    </ng-container>


                    <ng-container *ngIf="!direccionValue.pais || verSeleccion === '0';">
                        <p><span style="color:#C22503;">*</span> País</p>
                    </ng-container>

                    <ng-container *ngIf="!flagLastName;">
                        <p><span style="color:#C22503;">*</span> Estado o provincia</p>
                    </ng-container>


                    <ng-container *ngIf="!this.codigoPostalVal && direccionValue.pais === 'México' || direccionValue.pais === 'Mexico';">
                        <p><span style="color:#C22503;">*</span> Código postal</p>
                    </ng-container>
                   


                    <ng-container *ngIf="!direccionValue.colonia && direccionValue.pais === 'México' || direccionValue.pais === 'Mexico';">
                        <p><span style="color:#C22503;">*</span> Colonia</p>
                    </ng-container>

                    <ng-container *ngIf="!direccionValue.calle;">
                        <p><span style="color:#C22503;">*</span> Calle</p>
                    </ng-container>

                    <ng-container *ngIf="this.seleccionComunidad == '0';">
                        <p><span style="color:#C22503;">*</span> ¿Eres parte de la comunidad? </p>
                    </ng-container>

                    <ng-container *ngIf="this.seleccionCanal == 0;">
                        <p>
                            <span style="color:#C22503;">*</span> 
                            Canal por donde nos conociste
                        </p>
                    </ng-container>
                    
                </div> 
                
            </div>
            <div class="modal-footer text-center d-block">
                <a class="btn-modal " data-dismiss="modal">Cerrar</a>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalSucces" tabindex="-1" role="dialog" aria-labelledby="modalSucces" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-dialog-centered border-gradient-all">

            <div class="modal-body text-center">
                <p>Ya has actualizado tus datos. </p>
            </div>
            <div class="modal-footer justify-content-center border-0">
                <a  (click) ="fReload();" data-dismiss="modal" class="btn-d mb-2" >Cerrar</a>
            </div>
        </div>
    </div>
</div>

