
<app-header>

</app-header> 


<div>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>

<app-footer>

</app-footer>