<section class="stc-legales list-part">
    <div class="container">
        <div class="row">  

            <div class="col-md-12 "  data-aos="zoom-in">
                <div class="container-legal">

                    <div class="card-body ">
                        <h1 class="card-title text-center mt-3">Lista de participantes Primer Sorteo Oportuno 2023</h1>
                        <p>
                            <strong>¡Consulta la lista de participantes aquí! </strong>
                        </p>
                        <p>
                            Encuentra a los afortunados participantes que forman parte de este emocionante sorteo. 
                        </p>
                        <p>
                            ¡Suerte a todos!
                        </p>
                        <div class="text-center mt-4 mb-3">
                            <a href="/assets/pdf/participantes-primer-sorteo-oportuno.pdf" target="_blank" class="btn-stack" >Consultar </a>
                        </div>
                    </div>
                  </div>
            </div>

        </div>
    </div>
</section>

