import { Component, OnInit } from '@angular/core';
import { PasaLavozService } from '../../../services/pasa-lavoz.service';
import { Postal } from '../../../models/postal';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-postal',
  templateUrl: './postal.component.html',
  styleUrls: ['./postal.component.scss']
})
export class PostalComponent implements OnInit {
  dataPos: Postal = new Postal();
  IdPostal = 0;
  dataEmail = false;
  temp;
  send = false;

  id = localStorage.getItem('idColaborador');
  nombre = localStorage.getItem('nombre');
  link;
  count = 0;
  words;
  temp1;
  count2 = 0;
  nPostal = 0;
  postales: any = [];
  namePostalg = "";

  slideConfig = {
    autoplay: true,
    autoplaySpeed: 4000,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    fade: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      },
    ]
  };

  constructor(private readonly _pasaServive: PasaLavozService) {
    if (localStorage.getItem('idColaborador') === null || localStorage.getItem('idColaborador') === "") {
      window.location.href = "/home";
    }

    this._pasaServive.getPostal().subscribe(a => {
      this.postales = a;
      //console.log(this.postales);
    }, err => {
      ////console.log(err);
      if (err.status == 400) {
        this.send = true;
        (<any>$('#errorModal')).modal('show');
      }
    });
  }

  ngOnInit(): void {
    (<any>jQuery('#sendPost')).on('hide.bs.modal', function () {
      window.location.reload();
    });
    //console.log("test");
   

  }

  validateDataAll(form: NgForm) {
    if (form.invalid || this.IdPostal == 0 || !this.dataEmail) {
      (<any>$('#modalCom')).modal('show');
      return;
    }
    this.dataPos.Idpostal = this.IdPostal;
    this.dataPos.IDColaborador = localStorage.getItem('idColaborador');
    this.temp = $("#correos").val();
    this.dataPos.destinatarios = this.temp.split(",");
    //console.log("Envio formulario" +   this.dataPos);
    this.dataPos.NombreColaborador = localStorage.getItem('nombre');
    this.dataPos.medio = "";
    this.dataPos.liga = "";
    //console.log(form);
    //console.log(this.dataPos);

    this._pasaServive.getToken().subscribe(a => {
      //console.log(a);
    }, err => {

      if (err.status == 200) {
        //console.log(err);
        //console.log(err.error.text);

        this._pasaServive.sendPostal(this.dataPos,err.error.text).subscribe(a => {
          ////console.log(a);
        }, err => {
          ////console.log(err);
          if (err.status == 200) {
            this.send = true;
            (<any>$('#sendPost')).modal('show');
          }
        });
      }
    });
    
  }

  imageModal(name: string, pos: number, idPostal1: number) {
    $(".text-image-1").css("display", "none");
    $("#select" + pos).css("display", "block");
    $(".border-image").addClass("no-border");
    $("#border" + pos).addClass("border-postal");
    $("#border" + pos).removeClass("no-border");


    $("#image-modal").attr("src", name);


    this.IdPostal = idPostal1;
    this.namePostalg = name;
    (<any>$('#modal-postal')).modal('show');
  }
  empty(event: any) {
    if ($("#correos").val() === null || $("#correos").val() === "") {
      this.dataEmail = false;
    } else {
      this.dataEmail = true;
    }
  }

  preview(form: NgForm) {
    ////console.log("se ejecuto preview");
    if (form.invalid || this.IdPostal == 0 || !this.dataEmail) {
      (<any>$('#modalCom')).modal('show');
      ////console.log("invalid");
    } else {
      this._pasaServive.getLiga(this.id, this.nombre).subscribe(a => {
        ////console.log(a);
        this.temp = a;
        this.temp = this.temp.split(" ");
        this.link = this.temp[0];
      },
        err => {
        });
      (<any>$('#modalPreview')).modal('show');
    }
  }

  countLetters(event: any) {
    this.temp1 = $('#Titulo').val();
    this.temp1 = this.temp1.split("");
    this.count = this.temp1.length;
  }

  countLetters2(event: any) {
    this.temp1 = $('#cuerpo').val();
    this.temp1 = this.temp1.split("");
    this.count2 = this.temp1.length;
  }

  showMod() {
    (<any>$('#modal-postal')).modal('show');
  }

  fReload() {
    window.location.reload();
  }
}
