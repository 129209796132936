import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RecoveryPass } from '../../../models/recoveryPass';
import { HomeService } from '../../../services/home.service';
import {Md5} from 'ts-md5/dist/md5';
@Component({
  selector: 'app-recuperar-pass',
  templateUrl: './recuperar-pass.component.html',
  styleUrls: ['./recuperar-pass.component.scss']
})
export class RecuperarPassComponent implements OnInit {
  validPass = false;
  token="";
  formPass: RecoveryPass = new RecoveryPass();
  isToken = true;
  errorPass = false;
  successPass = false;
  md5 = new Md5();
  f1 =true;
  f2 =true;
  constructor(private readonly _homeService: HomeService, private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
        if( this.token != undefined){
            ////console.log("token lleno= "  + this.token);
            let tokenJson = '{ "token": "'+ this.token +'" }';
            let tokenJ = JSON.parse(tokenJson);
            ////console.log(tokenJ);
            this._homeService.validateToken(tokenJ).subscribe(a =>{
              ////console.log(a);

            },
            err => {
              ////console.log(err);
              if(err.status !== 200){
                this.isToken = false;
              }else{
                $("#verify-pass").css("display", "none");
                $("#verify-g").css("display", "none");
              }
            });
          }else{
            ////console.log("token vacio= "  + this.token);
          }
      });
  }

  ngOnInit(): void {
  }
  validaPass(event: any){
    let password =  $(".contra").val().toString();
    var confPass =  $("#conf-pwd").val().toString();
    if( $(".contra").val()  !==  $("#conf-pwd").val() ){
      this.errorPass = true;
      this.successPass = false;
      this.validPass = false;
    }else if( $(".contra").val() !== ""  &&   $("#conf-pwd").val() !== ""){
            ////console.log("son iguales");
            this.errorPass = false;
            this.successPass = true;
            this.validPass = true;
    }
    if(password.length < 8){
      this.f1 =true;
    }else{
      this.f1 = false;
    }

    if(confPass.length < 8){
      this.f2 =true;
    }else{
      this.f2 = false;
    }
  }
  validateData(form: NgForm){
    if (form.invalid || this.f1 || this.f2 ) { return; }
    this.formPass.correo = this.token;
    this.formPass.pass = String(this.md5.appendStr(this.formPass.pass).end());
    ////console.log(this.formPass);
    this._homeService.updatePass(this.formPass).subscribe(a =>{
      ////console.log(a);
    },
    err => {
      ////console.log(err);
      if(err.status === 200){
        (<any>$('#loginmodal')).modal('show');
      }
    });
  }
}
