import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Colabora } from '../models/colabora';
import { Registrouni } from 'src/models/registrouni';
@Injectable({
  providedIn: 'root'
})
export class ColaboraFormService {

  constructor(private http: HttpClient) { }
  /**/
  private  apiURL = 'https://serviciosapp.sorteoanahuac.mx/api';
  private  apiURL_2 = 'https://serviciossitioapp.sorteoanahuac.mx/api';
  
  //private  apiURL = 'http://localhost:2393/api';
  //private  apiURL_2 = 'http://localhost:3000/api';

  getEgresadosLink(matricula: any) {
    return this.http.get(`${this.apiURL}/pasalavoz/GetEgresado/` + matricula);
  }

  sendFormColabora(formulario: Colabora, tokenS: string){
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + tokenS
    });
    return this.http.post(`${this.apiURL}/Colaborador/RegistroColaboradorGeneral`, formulario, { headers } );
  }

  validateColabora(formulario: Colabora){
    return this.http.post(`${this.apiURL_2}/formcolabora`, formulario);
  }

  
}
