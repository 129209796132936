import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mainlogin',
  templateUrl: './mainlogin.component.html',
  styleUrls: ['./mainlogin.component.scss']
})
export class MainloginComponent implements OnInit {
  showLogin: boolean = true;
  showRegistration: boolean = false;
  isExpanded: boolean = false;
  showres = false;
  constructor() { 


  }

  ngOnInit(): void {
    if(sessionStorage.getItem('tokken')){
      window.location.href = '/formulario-documentos'
    } 
  }


  showLoginForm() {
    this.showLogin = true;
    this.showRegistration = false;
    this.isExpanded = false;
    this.showres = false;
  }

  showRegistrationForm() {
    this.showLogin = false;
    this.showRegistration = true;
    this.isExpanded = true;
  }

  
  showRegistration2() {
    this.showLogin = false;
    this.showres = true;
  }
  
}






