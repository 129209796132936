<section class="stc-galeria" >
    <div class="container">
        <div class="row ">
            <div class="col-lg-12  d-flex justify-content-center flex-column" data-aos="fade-left"  data-aos-easing="ease"  data-aos-duration="3000">
                <h1>
                    GALERÍA 2022
                </h1>
 
            </div>
        </div>
    </div>
</section>






<section class="container-gallery mb-5">
    <div class="container">
        <div class="row">

            <div class="col-md-12 text-center">
                
                <div class="controls border-gradient-all">
                    <!-- <a type="button" class="control btn-d" data-filter="all"><span>Todo</span></a>    -->
                    <a type="button" class="control btn-d" data-filter=".sm"><span>Sorteo Anáhuac Séptima Edición</span></a>
                </div>
        
                <div class="conainter-photos">
                    <div class="row">

                        <div class="col-12">
                            <div class="card-columns-init"  >   
                                

                                <!-- SORTEO MAGNO --> 
                                <div class="img-fluid mix sm">
                                    <p>
                                        Sorteo Magno Séptima Edición
                                    </p>
                                </div>
                              
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/imagen1.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/imagen2.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/imagen3.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/imagen4.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/imagen5.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/imagen6.jpg" class="img-fluid mix sm " >
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/imagen7.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/imagen8.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/imagen9.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/imagen10.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/imagen11.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/sorteo-magno/imagen12.jpg" class="img-fluid mix sm" >
       
                               
                            

                                <!-- GANADORES --> 
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen1.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen2.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen3.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen4.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen5.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen6.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen7.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen8.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen9.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen10.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen11.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen12.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen13.jpg" class="img-fluid mix sm" >
                                <img src="/assets/images/sorteo2023/galeria/ganadores/imagen14.jpg" class="img-fluid mix sm" >
                                

                                
                            </div>
                        </div>
                        

                    </div>
        
                    <div class="gap"></div>
                    <div class="gap"></div>
                    <div class="gap"></div>
                    <div class="gap"></div>
                </div>
            </div>
        </div>
    </div>
</section>