<section class="stc-legales">
    <div class="container">
        <div class="row">
            <div class="col-md-12" >
                <div class="container-legal"  data-aos="fade-up" data-aos-easing="ease"data-aos-duration="3000">
                    <h1>
                        SORTEO EGRESADOS ANÁHUAC  2023
                    </h1>

                    <p>
                        <strong>Denominación del sorteo: SORTEO EGRESADOS ANÁHUAC  2023</strong>
                    </p>
                    <p>
                        <strong>
                            Permiso otorgado por la Secretaría de Gobernación con el número: 20230059PS00
                        </strong>
                    </p>
                    <p>
                        <strong>Vigencia del permiso: </strong>Del 13 de marzo de 2023 al 30 de junio de 2023. 
                    </p>
                    <p>
                        <strong>Vigencia de la promoción: </strong>Del 13 de marzo de 2023 al 30 de junio de 2023. 
                    </p>
                    <p>
                        <strong>Número de folios electrónicos emitidos: </strong>
                        El número de folios electrónicos que se emitirán, tendrán como máximo de folio, el marcado con el número 999999, por lo que el número de folios electrónicos iniciarán con el número 000001 otorgándose de manera consecutiva y dicha numeración terminará en el folio que coincida con el último egresado que haya registrado y actualizado sus datos hasta las 23:59:59 horas del día 23 de junio del 2023.
                    <p>
                        <strong>Número de premios a otorgar: </strong>1 premio directo al ganador.
                    </p>

                    <p>
                        <strong> Valor del primer premio:</strong> $200,000.00 (doscientos mil pesos 00/100 M.N.) en un cheque certificado menos la retención de impuestos por obtención de premios: el 1% de impuesto federal por $2,000.00 (dos mil pesos 00/100 MN) más el 6% de impuesto estatal por $12,000.00 (Doce mil pesos 00/100 MN).  Valor Neto del Premio: $186,000.00 (Ciento ochenta y seis mil pesos 00/100 MN)
                    </p>
                    <p>
                        <strong>Condición de participación: </strong>
                        Podrán participar, todos los EGRESADOS de la Red de Universidades Anáhuac siempre y cuando sea la primera vez que actualicen sus datos ejecutando los siguientes pasos:
                    </p>
                    <ol type="1">
                        <li>
                            Entrar a la página web del Sorteo Anáhuac www.sorteoanahuac.mx y actualizar sus datos personales en la sección propia para egresados.
                        </li>
                        <li>
                            Después de una validación interna y dando visto bueno al registro de actualización, se generará un folio electrónico de participación.
                        </li>
                        <li>
                            El egresado solamente tendrá un solo folio electrónico de participación independientemente del número de veces que entre a la página web a actualizar su información.
                        </li>
                        <li>
                            Para el caso de aquellos egresados que tengan dos números de matrícula, recibirán en caso de actualizar la información un folio de participación por cada matrícula actualizada.
                        </li>
                        <li>
                            La fecha límite para la actualización de datos es hasta el día 23 de junio del 2023  a las 23:59:59 horas.
                        </li>
                    </ol>

                    <p>
                        Los egresados participantes, podrán verificar en la página web del Sorteo Anáhuac A.C. www.sorteoanahuac.mx, las bases del sorteo desde el inicio de la vigencia. La consulta de los folios,  la podrán hacer a partir del 28 de junio de 2023. Los egresados reciben su folio de participación al actualizar sus datos.
                    </p>

                    <h2>
                        Mecánica del sorteo:
                    </h2>

                    <p>
                        La mecánica del SORTEO EGRESADOS ANÁHUAC 2023 será por formación de números:
                    </p>

                    <ol type="1">
                        <li>
                            Se pondrá a la vista del Inspector que para tal efecto se designe, 1 (una) mesa la cual cuenta con 5 (cinco) ánforas, unidas por un eje horizontal. 
                        </li>
                        <li>
                            En las 4 (cuatro) primeras ánforas, de derecha a izquierda, se colocarán 10 (diez) esferas con la numeración del 0 (cero) al 9 (nueve), representarán las unidades, decenas, centenas y unidades de millar, la 5ª (quinta) ánfora, en caso de ser necesario, representará las decenas de millar, así como las centenas de millar, dependiendo del número de folios participantes se pondrá el número de ánforas y esferas necesarias empezando del 0 y hasta el número que sea necesario. 
                        </li>
                        <li>
                            En cada ánfora (del 1 a la 5), saldrá una esfera formando el número ganador. 
                        </li>
                        <li>
                            Cabe señalar que la formación de los números ganadores se hará de derecha a izquierda y su lectura de izquierda a derecha.
                        </li>
                        <li>
                            En el caso de que el número formado esté fuera de la emisión del número total de folios, el premio volverá a sortearse entre los folios participantes
                        </li>
                        <li>
                            El premio 1 (uno) se sorteará entre los Egresados. El premio se otorgará de manera directa. El cual corresponde al total de premios que se sortearán en el Sorteo Egresados Anáhuac 2023.
                        </li>
                        <li>
                            En caso de que, al momento en que estén corriendo las esferas, alguna se atore, se repetirá el procedimiento. 
                        </li>
                    </ol>

                    <p>
                        <strong>Lugar, fecha y hora del concentrado: </strong> 30 de junio del 2023 a las 11:00 horas, en las instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786
                    </p>
                    <p>
                        <strong>Lugar, fecha y hora del sorteo: </strong> 30 de junio del 2023 a las 11:15 horas en las instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786 
                    </p>

                    <p>
                        <strong>Acceso libre y gratuito al evento del sorteo para todo el público.</strong>
                    </p>

                    <p>
                        <strong>Entrega del premio: </strong> 10 de julio del 2023 a las 10:00 horas en las instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786. Para recibir su premio, el colaborador ganador deberá mostrar una identificación oficial vigente con fotografía (credencial del IFE, credencial del INE o Pasaporte) y el acto será ante el inspector asignado por la Dirección General de Juegos y Sorteos.
                    </p>

                    <p>
                        <strong>Medios publicitarios:</strong>
                    </p>

                    <ul>
                        <li>
                            Redes Sociales del sorteo: 
                            <ol type="a">
                                <li>
                                    FaceBook: 
                                    <a href="https://www.facebook.com/SorteoAnahuac/?locale=es_LA" target="_blank">
                                        https://www.facebook.com/SorteoAnahuac/?locale=es_LA
                                    </a>
                                </li>
                                <li>
                                    Twitter: 
                                    <a href="https://twitter.com/sorteoanahuac?lang=es" target="_blank">
                                        https://twitter.com/sorteoanahuac?lang=es
                                    </a>
                                </li>
                                <li>
                                    Instagram: 
                                    <a href="https://www.instagram.com/sorteoanahuac/?hl=es" target="_blank">
                                        https://www.instagram.com/sorteoanahuac/?hl=es
                                    </a>
                                </li>
                            </ol>
                        </li>

                        <li>
                            Página web: <a href="www.sorteoanahuac.mx" target="_blank">
                                www.sorteoanahuac.mx
                            </a>
                        </li>
                    </ul>

                    <p>
                        <strong>Publicación de Resultados: </strong> Para el sorteo denominado SORTEO EGRESADOS ANÁHUAC 2023. La lista de ganadores, serán publicados (el número ganador, el nombre propio y la ciudad en donde reside el ganador) con base a nuestro aviso de privacidad; la Ley Federal de Juegos y Sorteos y su Reglamento en relación con la Ley Federal de Protección a Datos Personales en Posesión de Particulares en nuestra página web www.sorteoanahuac.mx. El resultado estará disponible a partir del día 01 de julio del 2023. Por otro lado, existirá una publicación de resultados con el nombre completo del ganador y a la vista de todo el público en las instalaciones del Sorteo Anáhuac ubicadas en Av. Universidad Anáhuac número 46, Col. Lomas Anáhuac, C.P. 52786 en Huixquilucan, Estado de México; Piso 7 de la Biblioteca.
                    </p>

                    <p>
                        Por disposición de la Secretaría de Gobernación, se deberá cumplir con los siguientes requisitos: 
                    </p>
                    <ol>
                        <li>
                            La persona beneficiada con el premio,  deberá presentar una  identificación oficial vigente con fotografía. 2) Los premios serán pagados de conformidad con lo establecido en las bases del sorteo de conformidad con lo señalado en el artículo 121 del Reglamento de la Ley Federal de Juegos y Sorteos. Para los premios que causen retención de impuestos, el valor neto del premio señalado incluye el 1% de impuesto federal y el impuesto estatal correspondiente (6% máximo) conforme a lo establecido en las leyes y demás disposiciones legales vigentes. Los ganadores de este tipo de premios recibirán al momento de cobrarlos, el valor neto que corresponda a la resta entre el valor total del premio y las retenciones aplicables. 3) Al asentar sus datos personales reconoce que se le ha informado del aviso de privacidad citado en nuestra página web www.sorteoanahuac.mx. 4) Para cualquier aclaración o información referente a este sorteo o resultados del mismo, comunicarse al teléfono 555328-8096 ext. 2137 o acudir a Av. Universidad Anáhuac número 46, Col. Lomas Anáhuac, C.P. 52786 en Huixquilucan, Estado de México. 5) En caso de queja, acudir a la Dirección General de Juegos y Sorteos de la Secretaría de Gobernación, ubicada en Versalles número 49, 2do. Piso, Col. Juárez, Alcaldía Cuauhtémoc, C.P. 06600, Ciudad de México, o bien comunicarse al teléfono 555209-8800. 6) Responsable del sorteo:  Sorteo Anáhuac A.C. con domicilio en Av. Universidad Anáhuac no. 46 Col. Lomas de Anáhuac, C.P. 52786, Huixquilucan, Estado de México, teléfono: 555328-8096.
                        </li>
                    </ol>



                </div>



                <div class="container-legal"  data-aos="fade-up" data-aos-easing="ease"data-aos-duration="3000">
                    <h1>
                        Sorteo Amigo Egresado 2023
                    </h1>

                    <p>
                        <strong>Denominación del sorteo: Sorteo Amigo Egresado 2023</strong>
                    </p>
                    <p>
                        <strong>
                            Permiso otorgado por la Secretaría de Gobernación con el número: 20230058PS02
                        </strong>
                    </p>
                    <p>
                        <strong>Vigencia del permiso: </strong>Del 09 de marzo de 2023 al 30 de junio de 2023.
                    </p>
                    <p>
                        <strong>Vigencia de la promoción: </strong>Del 09 de marzo de 2023 al 30 de junio de 2023.
                    </p>
                    <p>
                        <strong>Número de folios electrónicos emitidos: </strong>
                        El número de folios electrónicos que se emitirán, tendrán como máximo de folio, el marcado con el número 999999, por lo que el número de folios electrónicos iniciarán con el número 000001 otorgándose de manera consecutiva y dicha numeración terminará en el folio que coincida con el último egresado que haya registrado y actualizado sus datos hasta las 23:59:59 horas del día 23 de junio del 2023.
                    <p>
                        <strong>Número de premios a otorgar: </strong>1 premio directo al ganador.
                    </p>

                    <p>
                        <strong> Valor del primer premio:</strong> $50,000.00 (cincuenta mil pesos 00/100 M.N.) en un cheque nominativo menos la retención de impuestos por obtención de premios: el 1% de impuesto federal por $500.00 (quinientos pesos 00/100 MN) más el 6% de impuesto estatal por $3,000.00 (Tres mil pesos 00/100 MN).  Valor Neto del Premio: $46,500.00 (Cuarenta y seis mil quinientos pesos 00/100 MN)
                    </p>
                    <p>
                        <strong>Condición de participación: </strong>
                        Podrán participar, todos los EGRESADOS de la Red de Universidades Anáhuac siempre y cuando realicen los siguientes pasos:
                    </p>
                    <ol type="1">
                        <li>
                            Entrar a la página web del Sorteo Anáhuac www.sorteoanahuac.mx y actualizar sus datos personales en la sección propia para egresados.
                        </li>
                        <li>
                            Compartir la liga que le llegó vía correo electrónico a sus amigos egresados.
                        </li>
                        <li>
                            El amigo egresado recibe la liga y si actualiza sus datos, el egresado que le compartió la liga recibirá de manera gratuita y automática un folio de participación para el Sorteo Amigo Egresado 2023, este folio se genera de manera automática.
                        </li>
                        <li>
                            Por cada amigo egresado que actualice sus datos en la página web del sorteo el egresado que compartió la liga recibirá un folio de participación de manera automática.
                        </li>
                        <li>
                            La fecha límite para compartir la liga a los amigos egresados y que ellos actualicen sus datos personales es el día 23 de junio del 2023 y hasta las 23:59:59 horas.
                        </li>
                    </ol>

                    <p>
                        Los egresados participantes, podrán verificar en la página web del Sorteo Anáhuac A.C. www.sorteoanahuac.mx, las bases del sorteo desde el inicio de la vigencia. La consulta de los folios la podrán hacer a partir del 28 de junio del 2023. Los egresados reciben su folio electrónico de participación cuando el amigo egresado actualice sus datos.
                    </p>

                    <h2>
                        Mecánica del sorteo:
                    </h2>

                    <p>
                        La mecánica del Sorteo Amigo Egresado 2023 será por formación de números:
                    </p>

                    <ol type="1">
                        <li>
                            Se pondrá a la vista del Inspector que para tal efecto se designe, 1 (una) mesa la cual cuenta con 5 (cinco) ánforas, unidas por un eje horizontal. 
                        </li>
                        <li>
                            En las 4 (cuatro) primeras ánforas, de derecha a izquierda, se colocarán 10 (diez) esferas con la numeración del 0 (cero) al 9 (nueve), representarán las unidades, decenas, centenas y unidades de millar, la 5ª (quinta) ánfora, en caso de ser necesario, representará las decenas de millar, así como las centenas de millar, dependiendo del número de folios participantes se pondrá el número de ánforas y esferas necesarias empezando del 0 y hasta el número que sea necesario. 
                        </li>
                        <li>
                            En cada ánfora (del 1 a la 5), saldrá una esfera formando el número ganador. 
                        </li>
                        <li>
                            Cabe señalar que la formación de los números ganadores se hará de derecha a izquierda y su lectura de izquierda a derecha.
                        </li>
                        <li>
                            En el caso de que el número formado esté fuera de la emisión del número total de folios, el premio volverá a sortearse entre los folios participantes
                        </li>
                        <li>
                            El premio 1 (uno) se sorteará entre los Egresados. El premio se otorgará de manera directa. El cual corresponde al total de premios que se sortearán en el Sorteo Egresados Anáhuac 2023.
                        </li>
                        <li>
                            En caso de que, al momento en que estén corriendo las esferas, alguna se atore, se repetirá el procedimiento. 
                        </li>
                    </ol>

                    <p>
                        <strong>Lugar, fecha y hora del concentrado: </strong> 30 de junio del 2023 a las 11:00 horas, en las instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786
                    </p>
                    <p>
                        <strong>Lugar, fecha y hora del sorteo: </strong> 30 de junio del 2023 a las 11:15 horas en las instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786 
                    </p>

                    <p>
                        <strong>Acceso libre y gratuito al evento del sorteo para todo el público.</strong>
                    </p>

                    <p>
                        <strong>Entrega del premio: </strong> El premio será pagado y entregado de conformidad con lo establecido en las bases de Sorteo, los premios menores a 1,500 (UMA) $155,610.00 (Ciento cincuenta y cinco mil seiscientos diez pesos 00/100 MN). se realizará dentro de los 20 (veinte) días hábiles contados a partir de la celebración del sorteo, en Avenida Universidad Anáhuac N° 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan, Estado de México, piso 7 del edificio de Biblioteca, en horario de lunes a jueves de 10:00 a 18:00 hrs. y los viernes de 10:00 a 14:00 hrs. Para recibir su premio, el colaborador ganador deberá mostrar una identificación oficial vigente con fotografía (credencial del IFE, credencial del INE o Pasaporte).
                    </p>

                    <p>
                        <strong>Medios publicitarios:</strong>
                    </p>

                    <ul>
                        <li>
                            Redes Sociales del sorteo: 
                            <ol type="a">
                                <li>
                                    FaceBook: 
                                    <a href="https://www.facebook.com/SorteoAnahuac/?locale=es_LA" target="_blank">
                                        https://www.facebook.com/SorteoAnahuac/?locale=es_LA
                                    </a>
                                </li>
                                <li>
                                    Twitter: 
                                    <a href="https://twitter.com/sorteoanahuac?lang=es" target="_blank">
                                        https://twitter.com/sorteoanahuac?lang=es
                                    </a>
                                </li>
                                <li>
                                    Instagram: 
                                    <a href="https://www.instagram.com/sorteoanahuac/?hl=es" target="_blank">
                                        https://www.instagram.com/sorteoanahuac/?hl=es
                                    </a>
                                </li>
                            </ol>
                        </li>

                        <li>
                            Página web: <a href="www.sorteoanahuac.mx" target="_blank">
                                www.sorteoanahuac.mx
                            </a>
                        </li>
                    </ul>

                    <p>
                        <strong>Publicación de Resultados: </strong> Para el sorteo denominado Sorteo Amigo Egresado 2023. La lista de ganadores, serán publicados (el número ganador, el nombre propio y la ciudad en donde reside el ganador) con base a nuestro aviso de privacidad; la Ley Federal de Juegos y Sorteos y su Reglamento en relación con la Ley Federal de Protección a Datos Personales en Posesión de Particulares en nuestra página web www.sorteoanahuac.mx. El resultado estará disponible a partir del día 01 de julio del 2023. Por otro lado, existirá una publicación de resultados con el nombre completo del ganador y a la vista de todo el público en las instalaciones del Sorteo Anáhuac.
                    </p>

                    <p>
                        Por disposición de la Secretaría de Gobernación, se deberá cumplir con los siguientes requisitos: 
                    </p>
                    <ol>
                        <li>
                            La persona beneficiada con el premio,  deberán presentar una  identificación oficial vigente con fotografía. 2) Los premios serán pagados de conformidad con lo establecido en las bases del sorteo de conformidad con lo señalado en el artículo 121 del Reglamento de la Ley Federal de Juegos y Sorteos. Para los premios que causen retención de impuestos, el valor neto del premio señalado incluye el 1% de impuesto federal y el impuesto estatal correspondiente (6% máximo) conforme a lo establecido en las leyes y demás disposiciones legales vigentes. Los ganadores de este tipo de premios recibirán al momento de cobrarlos, el valor neto que corresponda a la resta entre el valor total del premio y las retenciones aplicables. 3) Al asentar sus datos personales reconoce que se le ha informado del aviso de privacidad citado en nuestra página web www.sorteoanahuac.mx. 4) Para cualquier aclaración o información referente a este sorteo o resultados del mismo, comunicarse al teléfono 555328-8096 ext. 2137 o acudir a Av. Universidad Anáhuac número 46, Col. Lomas Anáhuac, C.P. 52786 en Huixquilucan, Estado de México. 5) En caso de queja, acudir a la Dirección General de Juegos y Sorteos de la Secretaría de Gobernación, ubicada en Versalles número 49, 2do. Piso, Col. Juárez, Alcaldía Cuauhtémoc, C.P. 06600, Ciudad de México, o bien comunicarse al teléfono 555209-8800. 6) Responsable del sorteo:  Sorteo Anáhuac A.C. con domicilio en Av. Universidad Anáhuac no. 46 Col. Lomas de Anáhuac, C.P. 52786, Huixquilucan, Estado de México, teléfono: 555328-8096.
                        </li>
                    </ol>



                </div>


                <div class="container-legal"  data-aos="fade-up" data-aos-easing="ease"data-aos-duration="3000">
                    <h1>
                        SORTEO EGRESADOS LEALTAD 2023
                    </h1>

                    <p>
                        <strong>Denominación del sorteo: SORTEO EGRESADOS LEALTAD 2023</strong>
                    </p>
                    <p>
                        <strong>
                            Permiso otorgado por la Secretaría de Gobernación con el número: 20230057PS02
                        </strong>
                    </p>
                    <p>
                        <strong>Vigencia del permiso: </strong>Del 13 de marzo de 2023 al 30 de junio de 2023. 
                    </p>
                    <p>
                        <strong>Vigencia de la promoción: </strong>Del 13 de marzo de 2023 al 30 de junio de 2023. 
                    </p>
                    <p>
                        <strong>Número de folios electrónicos emitidos: </strong>
                        El número de folios electrónicos que se emitirán, tendrán como máximo de folio, el marcado con el número 999999, por lo que el número de folios electrónicos iniciarán con el número 000001 otorgándose de manera consecutiva y dicha numeración terminará en el folio que coincida con el último egresado que haya registrado y actualizado sus datos hasta las 23:59:59 horas del día 23 de junio del 2023.
                    <p>
                        <strong>Número de premios a otorgar: </strong>1 premio directo al ganador.
                    </p>

                    <p>
                        <strong> Valor del primer premio:</strong> $200,000.00 (doscientos mil pesos 00/100 M.N.) en un cheque certificado menos la retención de impuestos por obtención de premios: el 1% de impuesto federal por $2,000.00 (dos mil pesos 00/100 MN) más el 6% de impuesto estatal por $12,000.00 (Doce mil pesos 00/100 MN).  Valor Neto del Premio: $186,000.00 (Ciento ochenta y seis mil pesos 00/100 MN)
                    </p>
                    <p>
                        <strong>Condición de participación: </strong>
                        Podrán participar, todos los EGRESADOS de la Red de Universidades Anáhuac que han actualizado sus datos personales en por lo menos en una ocasión a partir de que surgió esta modalidad y que en este año nuevamente realicen este proceso de actualización ejecutando los siguientes pasos:
                    </p>
                    <ol type="1">
                        <li>
                            Después de una validación interna y dando visto bueno al registro de actualización, se generará un folio electrónico de participación.
                        </li>
                        <li>
                            El egresado solamente tendrá un solo folio electrónico de participación independientemente del número de veces que entre a la página web a actualizar su información.
                        </li>
                        <li>
                            Para el caso de aquellos egresados que tengan dos números de matrícula, recibirán en caso de actualizar la información un folio de participación por cada matrícula actualizada.
                        </li>
                        <li>
                            La fecha límite para la actualización de datos es hasta el día 23 de junio del 2023  a las 23:59:59 horas.
                        </li>
                    </ol>

                    <p>
                        Los egresados participantes, podrán verificar en la página web del Sorteo Anáhuac A.C. www.sorteoanahuac.mx, las bases del sorteo desde el inicio de la vigencia. La consulta de los folios,  la podrán hacer a partir del 28 de junio de 2023. Los egresados reciben su folio de participación al actualizar sus datos.
                    </p>

                    <h2>
                        Mecánica del sorteo:
                    </h2>

                    <p>
                        La mecánica del SORTEO EGRESADOS LEALTAD 2023 será por formación de números:
                    </p>

                    <ol type="1">
                        <li>
                            Se pondrá a la vista del Inspector que para tal efecto se designe, 1 (una) mesa la cual cuenta con 5 (cinco) ánforas, unidas por un eje horizontal. 
                        </li>
                        <li>
                            En las 4 (cuatro) primeras ánforas, de derecha a izquierda, se colocarán 10 (diez) esferas con la numeración del 0 (cero) al 9 (nueve), representarán las unidades, decenas, centenas y unidades de millar, la 5ª (quinta) ánfora, en caso de ser necesario, representará las decenas de millar, así como las centenas de millar, dependiendo del número de folios participantes se pondrá el número de ánforas y esferas necesarias empezando del 0 y hasta el número que sea necesario. 
                        </li>
                        <li>
                            En cada ánfora (del 1 a la 5), saldrá una esfera formando el número ganador. 
                        </li>
                        <li>
                            Cabe señalar que la formación de los números ganadores se hará de derecha a izquierda y su lectura de izquierda a derecha.
                        </li>
                        <li>
                            En el caso de que el número formado esté fuera de la emisión del número total de folios, el premio volverá a sortearse entre los folios participantes
                        </li>
                        <li>
                            El premio 1 (uno) se sorteará entre los Egresados. El premio se otorgará de manera directa. El cual corresponde al total de premios que se sortearán en el Sorteo Egresados Anáhuac 2023.
                        </li>
                        <li>
                            En caso de que, al momento en que estén corriendo las esferas, alguna se atore, se repetirá el procedimiento. 
                        </li>
                    </ol>

                    <p>
                        <strong>Lugar, fecha y hora del concentrado: </strong> 30 de junio del 2023 a las 11:00 horas, en las instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786
                    </p>
                    <p>
                        <strong>Lugar, fecha y hora del sorteo: </strong> 30 de junio del 2023 a las 11:15 horas en las instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786 
                    </p>

                    <p>
                        <strong>Acceso libre y gratuito al evento del sorteo para todo el público.</strong>
                    </p>

                    <p>
                        <strong>Entrega del premio: </strong> 10 de julio del 2023 a las 10:00 horas en las instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786. Para recibir su premio, el colaborador ganador deberá mostrar una identificación oficial vigente con fotografía (credencial del IFE, credencial del INE o Pasaporte) y el acto será ante el inspector asignado por la Dirección General de Juegos y Sorteos.
                    </p>

                    <p>
                        <strong>Medios publicitarios:</strong>
                    </p>

                    <ul>
                        <li>
                            Redes Sociales del sorteo: 
                            <ol type="a">
                                <li>
                                    FaceBook: 
                                    <a href="https://www.facebook.com/SorteoAnahuac/?locale=es_LA" target="_blank">
                                        https://www.facebook.com/SorteoAnahuac/?locale=es_LA
                                    </a>
                                </li>
                                <li>
                                    Twitter: 
                                    <a href="https://twitter.com/sorteoanahuac?lang=es" target="_blank">
                                        https://twitter.com/sorteoanahuac?lang=es
                                    </a>
                                </li>
                                <li>
                                    Instagram: 
                                    <a href="https://www.instagram.com/sorteoanahuac/?hl=es" target="_blank">
                                        https://www.instagram.com/sorteoanahuac/?hl=es
                                    </a>
                                </li>
                            </ol>
                        </li>

                        <li>
                            Página web: <a href="www.sorteoanahuac.mx" target="_blank">
                                www.sorteoanahuac.mx
                            </a>
                        </li>
                    </ul>

                    <p>
                        <strong>Publicación de Resultados: </strong> Para el sorteo denominado Sorteo Amigo Egresado 2023. La lista de ganadores, serán publicados (el número ganador, el nombre propio y la ciudad en donde reside el ganador) con base a nuestro aviso de privacidad; la Ley Federal de Juegos y Sorteos y su Reglamento en relación con la Ley Federal de Protección a Datos Personales en Posesión de Particulares en nuestra página web www.sorteoanahuac.mx. El resultado estará disponible a partir del día 01 de julio del 2023. Por otro lado, existirá una publicación de resultados con el nombre completo del ganador y a la vista de todo el público en las instalaciones del Sorteo Anáhuac.
                    </p>

                    <p>
                        Por disposición de la Secretaría de Gobernación, se deberá cumplir con los siguientes requisitos: 
                    </p>
                    <ol>
                        <li>
                            La persona beneficiada con el premio,  deberán presentar una  identificación oficial vigente con fotografía. 2) Los premios serán pagados de conformidad con lo establecido en las bases del sorteo de conformidad con lo señalado en el artículo 121 del Reglamento de la Ley Federal de Juegos y Sorteos. Para los premios que causen retención de impuestos, el valor neto del premio señalado incluye el 1% de impuesto federal y el impuesto estatal correspondiente (6% máximo) conforme a lo establecido en las leyes y demás disposiciones legales vigentes. Los ganadores de este tipo de premios recibirán al momento de cobrarlos, el valor neto que corresponda a la resta entre el valor total del premio y las retenciones aplicables. 3) Al asentar sus datos personales reconoce que se le ha informado del aviso de privacidad citado en nuestra página web www.sorteoanahuac.mx. 4) Para cualquier aclaración o información referente a este sorteo o resultados del mismo, comunicarse al teléfono 555328-8096 ext. 2137 o acudir a Av. Universidad Anáhuac número 46, Col. Lomas Anáhuac, C.P. 52786 en Huixquilucan, Estado de México. 5) En caso de queja, acudir a la Dirección General de Juegos y Sorteos de la Secretaría de Gobernación, ubicada en Versalles número 49, 2do. Piso, Col. Juárez, Alcaldía Cuauhtémoc, C.P. 06600, Ciudad de México, o bien comunicarse al teléfono 555209-8800. 6) Responsable del sorteo:  Sorteo Anáhuac A.C. con domicilio en Av. Universidad Anáhuac no. 46 Col. Lomas de Anáhuac, C.P. 52786, Huixquilucan, Estado de México, teléfono: 555328-8096.
                        </li>
                    </ol>



                </div>
            </div>
        </div>
    </div>
</section>