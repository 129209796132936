<section class="winner-egr">
    <div class="container">
        <div class="row">
            <div class="col-md-7 my-auto">
                <img src="/assets/images/sorteo2024/egresados/img-gan-egr.png" alt="Ganadores egresados"
                    class="img-fluid">
            </div>

            <div class="col-md-5 my-auto">
                <h1>
                    LISTA DE
                    <span class="d-block">
                        GANADORES
                    </span>
                </h1>
            </div>
        </div>
    </div>
</section>

<section class="content-egr">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="box">
                    <div class="head-card d-flex justify-content-center align-items-center text-center">
                        <img src="/assets/images/sorteo2024/egresados/hat1.png" alt="Ganador sorteo egresados anáhuac"
                            class="me-3">
                        <h2 class="mb-0">
                            sorteo egresados anáhuac 2024
                        </h2>
                    </div>
                    <div class="content-card">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th class="col-md-3"><strong>Nombre</strong></th>
                                        <th class="col-md-3"><strong>No. de Premio</strong></th>
                                        <th class="col-md-3"><strong>Valor</strong></th>
                                        <th class="col-md-3"><strong>Folio</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>OSCAR  MANUEL H. C.</td>
                                        <td>1</td>
                                        <td>$200,000.00</td>
                                        <td>001861</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p>
                            <strong>SORTEO EGRESADOS ANÁHUAC 2024</strong>
                        </p>
                        <p>
                            El Sorteo Anáhuac celebró el día viernes 23 de agosto de 2024, a las 10:30 horas, el Sorteo
                            Egresados Anáhuac 2024 en la Prepa Anáhuac México Campus Cumbres con domicilio en Loma del
                            Recuerdo No. 50 , Col. Lomas de Vista Hermosa, Cuajimalpa de Morelos, C.P. 05100 CDMX. con
                            la presencia de la <strong>Lic. María Eugenia Álvarez Cortés</strong>, inspectora de la Dirección General de Juegos y Sorteos, para dar fe de la legalidad del evento.
                        </p>
                        <p>
                            De acuerdo con lo establecido en el Permiso No. <strong>20240096PS00</strong> otorgado por
                            la Dirección General de Juegos y Sorteos a Sorteo Anáhuac, A.C., el resultado del ganador,
                            que se ha hecho acreedor a un premio, ha de ser publicado de forma posterior al evento del
                            sorteo en la página de internet https://www.sorteoanahuac.mx/ y nuestras redes sociales a
                            partir del día 24 de agosto de 2024 y de acuerdo a lo registrado ante la Dirección General
                            de Juegos y Sorteos, solo aparecerá el número de folio ganador, el nombre propio del
                            ganador, iniciales de cada uno de sus apellidos, adicionalmente, se publicará la lista
                            impresa con los datos completos del ganador en las Instalaciones de la Universidad Anáhuac
                            México Campus Norte, ubicada en la Av.Universidad no. 46 Colonia Lomas de Anáhuac, CP. 52786, Huixquilucan Estado de México.
                            Los premios mayores a 1,500 (UMA). $162,855.00 (Ciento Sesenta y dos mil ochocientos
                            cincuenta y cinco pesos 00/100 m.n.), se solicitará la presencia de un inspector de la DGJyS
                            para la entrega de los mismos, conforme al siguiente calendario de fechas: 05 de septiembre
                            de 2024 a las 11:00 horas Entrega del Premio 1 del Sorteo Egresados Anáhuac 2024. En las
                            instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad
                            Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786.
                        </p>
                  


                        <p>
                            El premios será pagado y entregado de conformidad con lo establecido en las bases de Sorteo.
                        </p>
                        <p>
                            Para más información sobre dónde reclamar la entrega del premio, documentos necesarios para
                            recibirlo diríjase a: www.sorteoanahuac.mx, o a la oficina del Sorteo Anáhuac, A.C. ubicada
                            en la calle “Av. Universidad Anáhuac no. 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan,
                            Estado de México o al teléfono: 01(55) 5328-8096 ext 2137.
                        </p>
                        <p>
                            La persona que resulte ganadora deberá presentar una identificación oficial vigente con
                            fotografía (credencial INE, pasaporte), Constancia de situación Fiscal de RFC, comprobante
                            de domicilio (no mayor a tres meses) y CURP; el nombre completo escrito en el folio debe
                            corresponder exactamente con el registro del Sorteo Anáhuac y su identificación oficial.
                        </p>
                        <p>
                            En caso de queja derivada de este sorteo, la autoridad competente es la Dirección General de
                            Juegos y Sorteos de la Secretaría de Gobernación ubicada en la calle de Versalles 49, piso
                            2, Col. Juárez, Del. Cuauhtémoc, C.P. 06600, Ciudad de México o bien comunicarse al
                            teléfono: 01(55) 5209 8800.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="box lealtad">
                    <div class="head-card d-flex justify-content-center align-items-center text-center">
                        <img src="/assets/images/sorteo2024/egresados/hat2.png" alt="Ganador sorteo egresados anáhuac"
                            class="me-3">
                        <h2 class="mb-0">
                            sorteo egresados lealtad 2024
                        </h2>
                    </div>
                    <div class="content-card">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th class="col-md-3"><strong>Nombre</strong></th>
                                        <th class="col-md-3"><strong>No. de Premio</strong></th>
                                        <th class="col-md-3"><strong>Valor</strong></th>
                                        <th class="col-md-3"><strong>Folio</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>OMAR ADRIAN R. O.</td>
                                        <td>1</td>
                                        <td>$200,000.00</td>
                                        <td>001439</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p>
                            <strong>SORTEO EGRESADOS LEALTAD 2024</strong>
                        </p>

                        <p>
                            El Sorteo Anáhuac celebró el día viernes 23 de agosto de 2024, a las 10:40 horas, el Sorteo
                            Egresados Lealtad 2024 en la Prepa Anáhuac México Campus Cumbres con domicilio en Loma del
                            Recuerdo No. 50 , Col. Lomas de Vista Hermosa, Cuajimalpa de Morelos, C.P. 05100 CDMX. con
                            la presencia del <strong>Lic. César Iván Dávila García</strong>,  inspector de la Dirección General de Juegos y Sorteos, para dar fe de la legalidad del evento.
                        </p>

                        <p>
                            De acuerdo con lo establecido en el Permiso No. <strong>20240095PS05</strong> otorgado por
                            la Dirección General de Juegos y Sorteos a Sorteo Anáhuac, A.C., el resultado del ganador,
                            que se ha hecho acreedor a un premio, ha de ser publicado de forma posterior al evento del
                            sorteo en la página de internet https://www.sorteoanahuac.mx/ y nuestras redes sociales a
                            partir del día 24 de agosto de 2024 y de acuerdo a lo registrado ante la Dirección General
                            de Juegos y Sorteos, solo aparecerá el número de folio ganador, el nombre propio del
                            ganador, iniciales de cada uno de sus apellidos, adicionalmente, se publicará la lista
                            impresa con los datos completos del ganador en las Instalaciones de la Universidad Anáhuac
                            México Campus Norte, ubicada en la Av. Universidad no. 46 Colonia Lomas de Anáhuac, CP.
                            52786, Huixquilucan Estado de México.
                        </p>

                        <p>
                            Los premios mayores a 1,500 (UMA). $162,855.00 (Ciento Sesenta y dos mil ochocientos
                            cincuenta y cinco pesos 00/100 m.n.), se solicitará la presencia de un inspector de la DGJyS
                            para la entrega de los mismos, conforme al siguiente calendario de fechas: 05 de septiembre
                            de 2024 a las 11:00 horas Entrega del Premio 1 del Sorteo Egresados Lealtad 2024. En las
                            instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad
                            Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786.
                        </p>

                        <p>
                            El premios será pagado y entregado de conformidad con lo establecido en las bases de Sorteo.
                            Para más información sobre dónde reclamar la entrega del premio, documentos necesarios para
                            recibirlo diríjase a: www.sorteoanahuac.mx, o a la oficina del Sorteo Anáhuac, A.C. ubicada
                            en la calle “Av. Universidad Anáhuac no. 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan,
                            Estado de México o al teléfono: 01(55) 5328-8096 ext 2137.

                        </p>

                        <p>
                            La persona que resulte ganadora deberá presentar una identificación oficial vigente con
                            fotografía (credencial INE, pasaporte), Constancia de situación Fiscal de RFC, comprobante
                            de domicilio (no mayor a tres meses) y CURP; el nombre completo escrito en el folio debe
                            corresponder exactamente con el registro del Sorteo Anáhuac y su identificación oficial.
                        </p>

                        <p>
                            En caso de queja derivada de este sorteo, la autoridad competente es la Dirección General de
                            Juegos y Sorteos de la Secretaría de Gobernación ubicada en la calle de Versalles 49, piso
                            2, Col. Juárez, Del. Cuauhtémoc, C.P. 06600, Ciudad de México o bien comunicarse al
                            teléfono: 01(55) 5209 8800.
                        </p>


                    </div>
                </div>
            </div>


            <div class="col-md-12">
                <div class="box amigo">
                    <div class="head-card d-flex justify-content-center align-items-center text-center">
                        <img src="/assets/images/sorteo2024/egresados/hat3.png" alt="Ganador sorteo egresados anáhuac"
                            class="me-3">
                        <h2 class="mb-0">
                            sorteo amigo egresado 2024
                        </h2>
                    </div>
                    <div class="content-card">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th class="col-md-3"><strong>Nombre</strong></th>
                                        <th class="col-md-3"><strong>No. de Premio</strong></th>
                                        <th class="col-md-3"><strong>Valor</strong></th>
                                        <th class="col-md-3"><strong>Folio</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>MÓNICA O. R.</td>
                                        <td>1</td>
                                        <td>$50,000.00</td>
                                        <td>000235</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p>
                            <strong>
                                SORTEO AMIGO EGRESADO 2024
                            </strong>
                        </p>

                        <p>
                            El Sorteo Anáhuac celebró el día viernes 23 de agosto de 2024, a las 10:50 horas, el Sorteo
                            Amigo Egresado 2024 en la Prepa Anáhuac México Campus Cumbres con domicilio en Loma del
                            Recuerdo No. 50 , Col. Lomas de Vista Hermosa, Cuajimalpa de Morelos, C.P. 05100 CDMX. con
                            la presencia del <strong>Lic. Humberto González Santos</strong>, inspector de la Dirección General de Juegos y Sorteos, para dar fe de la legalidad del evento.
                        </p>

                        <p>
                            De acuerdo con lo establecido en el Permiso No. <strong>20240094PS08</strong> otorgado por
                            la Secretaría de Gobernación a Sorteo Anáhuac, A.C., el resultado del ganador, que se ha
                            hecho acreedor a un premio, ha de ser publicado de forma posterior al evento del sorteo en
                            la página de internet https://www.sorteoanahuac.mx/ y nuestras redes sociales a partir del
                            día 24 de agosto de 2024 y de acuerdo a lo registrado ante la Dirección General de Juegos y
                            Sorteos, solo aparecerá el número de folio ganador, el nombre propio del ganador, iniciales
                            de cada uno de sus apellidos, adicionalmente, se publicará la lista impresa con los datos
                            completos del ganador en las Instalaciones de la Universidad Anáhuac México Campus Norte,
                            ubicada en la Av. Universidad no. 46 Colonia Lomas de Anáhuac, CP. 52786, Huixquilucan
                            Estado de México.
                        </p>

                        <p>
                            El premio será pagado y entregado de conformidad con lo establecido en las bases de Sorteo,
                            los premios menores a 1,500 (UMA), $162,855.00 (Ciento Sesenta y dos mil ochocientos
                            cincuenta y cinco pesos 00/100 m.n.) se realizará dentro de los 20 (veinte) días hábiles
                            contados a partir de la celebración del sorteo, en Avenida Universidad Anáhuac N° 46, Col.
                            Lomas Anáhuac, C.P. 52786, Huixquilucan, Estado de México, piso 6 del edificio de
                            Biblioteca, en horario de lunes a jueves de 10:00 a 18:00 hrs. y los viernes de 10:00 a
                            14:00 hrs.
                            Para más información sobre dónde reclamar la entrega del premio, documentos necesarios para
                            recibirlo diríjase a: www.sorteoanahuac.mx, o a la oficina del Sorteo Anáhuac, A.C. ubicada
                            en la calle “Av. Universidad Anáhuac no. 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan,
                            Estado de México o al teléfono: 01(55) 5328-8096 ext 2137.

                        </p>

                        <p>
                            La persona que resulte ganadora deberá presentar una identificación oficial vigente con
                            fotografía (credencial INE, pasaporte), Constancia de situación Fiscal de RFC, comprobante
                            de domicilio (no mayor a tres meses) y CURP; el nombre completo escrito en el folio debe
                            corresponder exactamente con el registro del Sorteo Anáhuac y su identificación oficial.
                        </p>

                        <p>
                            En caso de queja derivada de este sorteo, la autoridad competente es la Dirección General de
                            Juegos y Sorteos de la Secretaría de Gobernación ubicada en la calle de Versalles 49, piso
                            2, Col. Juárez, Del. Cuauhtémoc, C.P. 06600, Ciudad de México o bien comunicarse al
                            teléfono: 01(55) 5209 8800.
                        </p>


                    </div>
                </div>
            </div>
        </div>
    </div>
</section>