<section class="share">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center my-auto" data-aos="fade-left" data-aos-easing="ease"  data-aos-duration="3000">
                <img src="/assets/images/sorteo2024/pasalavoz/texto-invitacion.png" class="img-fluid">         
            </div>

        
        </div>
    </div>
</section>

<section class="share-cta" data-aos="fade-up" data-aos-easing="ease"  data-aos-duration="3000">
    <div class="container">
        <div class="row ">

            <div class="col-12" style="background-color: #e3e1e1;">
                <p  class="text-center" data-aos="fade-right " data-aos-easing="ease"  data-aos-duration="3000">Link con tu clave para invitar a comprar</p>
            </div>
            <div class="col-md-12 text-center">
              
                <p class="mt-4  text-center"> Invita a tus amigos a comprar un boleto de sorteo Anáhuac, al hacerlo te da la oportunidad de ganar un premio como colaborador. </p>
                <div class="content-btn ">
                    <a href="https://www.facebook.com/sharer/sharer.php?u={{link}}" target="_blank" class="d-inline">
                        <img src="/assets/images/sorteo2024/pasalavoz/facebook.png"class="img-fluid">
                    </a>
              
              
                    <!-- <a href="https://api.whatsapp.com/send?text=Compra%20un%20boleto%20de%20Sorteo%20Anáhuac%20y%20gana%20fabulosos%20premios%0A¡Apoya%20a%20la%20educación!{{link}}" target="_blank" class="d-inline">
                        <img src="/assets/images/sorteo2024/pasalavoz/whatsapp.jpg"class="img-fluid">
                    </a> -->
                    <a href="https://twitter.com/intent/tweet?text=Compra%20un%20boleto%20de%20Sorteo%20Anáhuac%20y%20gana%20fabulosos%20premios%0A¡Apoya%20a%20la%20educación! {{link}}&" target="_blank" class="d-inline">
                        <img src="/assets/images/sorteo2024/pasalavoz/x.png"class="img-fluid">
                    </a>

                    <a href="https://www.linkedin.com/shareArticle?mini=true&url={{link}}" target="_blank" class="d-inline">
                        <img src="/assets/images/sorteo2024/pasalavoz/lk.png"class="img-fluid">
                    </a>

                          
                    <img src="/assets/images/sorteo2024/pasalavoz/instagram.png"class="img-fluid">
          
                    <!-- <img src="/assets/images/sorteo2023/pasa-la-voz/youtube.jpg"class="img-fluid">
                    <img src="/assets/images/sorteo2023/pasa-la-voz/tiktok.jpg"class="img-fluid">
                        -->

             

                    
                </div>

                <div class="container-link">
                    <p class="mt-4 mb-4 pb-0 copy">Puedes copiar este link para compartirlo donde quieras.</p>
                    <input id="link" value="{{link}}" readonly>
                    <div class="content">
                        <a (click)="clickbtn()" class="btn-stack"> Copiar link</a>
                    </div>
                    <div *ngIf="select" class="alert alert-success mt-4 animate__animated  animate__fadeInUpBig" role="alert" >
                        Texto copiado.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


