import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-login',
  templateUrl: './menu-login.component.html',
  styleUrls: ['./menu-login.component.scss']
})
export class MenuLoginComponent implements OnInit {

  constructor() { 
    if(localStorage.getItem('idColaborador') === null || localStorage.getItem('idColaborador') === ""){
      window.location.href = "/home";
    }
  }

  ngOnInit(): void {
    (<any>$('#loginmodal')).modal('hide');
  }

}
