import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Colabora } from 'src/models/colabora';



@Injectable({
  providedIn: 'root'
})
export class HomeServiceService  {
  // local
  //apiURL = 'http://localhost:2393/';
  
  // test
  apiURL = 'https://serviciosapp.sorteoanahuac.mx';

  constructor(private http: HttpClient) {
  }

   // Uses http.get() to load data from a single API endpoint
   getBoletoTablero() {
    const body = {};
    return this.http.post(`${this.apiURL}/api/TombolaBoleto`,body);
   }
    getValidaBoleto(folio) {
      return this.http.get(`${this.apiURL}/api/BoletosWeb/ValidaBoleto/${folio}`);
    }
    postBoletoConditioned(obj) {
      return this.http.get(`${this.apiURL}/api/BoletosWeb/Seleccion`);
    }
    getLuckyBol(obj) {
      return this.http.get(`${this.apiURL}/api/BoletosWeb/GetLuckyBol_Perm`);
    }
    setLuckyBol(obj) { // Boletos enviados a Back para registrar como perpetuos
      return this.http.get(`${this.apiURL}/api/BoletosWeb/Registro_BoletoPerpetuo`);
    }
    getPopState() {  // Estado de Pop según DB
      return this.http.get(`${this.apiURL}/api/GetPopState`);
    }

    sendColabora(formulario: Colabora, tokenS: string){
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + tokenS
      });
      return this.http.post(`${this.apiURL}/api/Colaborador/RegistroColaboradorGeneral`, formulario, { headers } );
    }

}
