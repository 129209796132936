import { Direccion_colabora } from './direccion_colabora';

export class Colabora{
    correo: string;
    password:string;
    nombre: string;
    apellido_paterno: string;
    apellido_materno:string;
    area_celular:string;
    celular:string;
    telefono_casa:string;
    area_telefono: string;
    pais:string;
    cp:string;
    estado:string;
    municipio:string;
    colonia:string;
    calle:string;
    direccion ?: Direccion_colabora;
    parte_de_comunidad: string;
    pkcanal: number;
    especifica_canal: string;

}
