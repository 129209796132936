<section class="stc-legales">
    <div class="container">
        <div class="row">
            <div class="col-lg-8  offset-lg-2 my-auto">
                <h1>
                    TÉRMINOS Y CONDICIONES SORTEO ANÁHUAC 9ª EDICIÓN
                </h1>
            </div>
        </div>
    </div>
</section>


<section class="stc-legal">
    <div class="container">
        <div class="row">
            <div class="container-legal" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="3000">

                <p>
                    El presente documento constituye los términos y condiciones de uso y privacidad de los medios
                    electrónicos del SORTEO ANÁHUAC, incluyendo su página web, aplicación (App o App Sorteo Anáhuac),
                    Boleto Digital o cualquier otro medio electrónico que se ponga a disposición del Usuario, (en
                    adelante “Medios Electrónicos”). En caso de no estar de acuerdo con ellos, el Usuario deberá
                    abstenerse de acceder o utilizar la aplicación. Sorteo Anáhuac, A.C., se reserva el derecho de
                    modificar discrecionalmente el contenido de este documento y los Medios Electrónicos en cualquier
                    momento, sin necesidad de previo aviso.
                </p>
                <p>
                    La descarga, el acceso y/o utilización de los Medios Electrónicos por parte de cualquier persona, le
                    atribuye la calidad de Usuario lo cual implica su adhesión lisa y llana a los presentes términos,
                    condiciones de uso y privacidad.
                </p>
                <p>
                    Los Medios Electrónicos son propiedad de Sorteo Anáhuac A.C., cuyo domicilio se encuentra en Avenida
                    Anáhuac número 46, piso 7 Edificio de la Biblioteca, colonia Lomas Anáhuac, Huixquilucan, Estado de
                    México, código postal 52786 y cuyo Registro Federal de Contribuyentes es SAN1501298V6.
                </p>

                <p>
                    Las acciones de emisión de boletos digitales, así como cualquier actividad relacionada con el Sorteo
                    Anáhuac Novena Edición, está plenamente autorizada por la Dirección de Juegos y Sorteos dependiente
                    de la Secretaria de Gobernación mediante el permiso número:
                    <strong>20240048PS07</strong>
                </p>

                <h2>
                    Definiciones
                </h2>

                <p>
                    Para efectos de los presentes términos y condiciones de uso y privacidad se entenderá por:
                </p>

                <ol type="I">
                    <li>
                        Sorteo Anáhuac. Asociación Civil, permisionaria de la Secretaría de Gobernación, para operar y llevar acabo sorteos universitarios, que beneficien a la educación del país, misma que es propietaria de los Medios Electrónicos y de todos los contenidos, marcas, logos, imágenes gráficas, diseños, dibujos, programas, desarrollos informáticos, y cualquier otro elemento que se encuentre en la propia app o en cualquier otro soporte ya sea físico o digital.
                    </li>
                    <li>
                        Este Documento. Los presentes Términos y Condiciones.
                    </li>
                    <li>
                        Medios Electrónicos. Página web, App o App Sorteo Anáhuac, Boleto Digital o cualquier otro medio electrónico que se ponga a disposición del Usuario.
                    </li>
                    <li>
                        App o App Sorteo Anáhuac. Programa informático que contienen un conjunto de datos, información, servicios, opciones y acciones, que se encuentra disponible principalmente para dispositivos móviles.
                    </li>

                    <li>
                        Página web. Es un documento de información electrónica capaz de contener texto, sonido, video, programas, enlaces, imágenes adaptada para la World Wide Web (WWW) y que puede ser accedida mediante un navegador web.
                    </li>
                    <li>
                        Dispositivo Móvil.  aparato electrónico de tamaño compacto o pequeño, con capacidades de procesamiento, con conexión permanente o intermitente a una red y con memoria limitada. Por ejemplo, celulares inteligentes o tabletas electrónicas. 
                    </li>
                    <li>
                        Sistema Operativo. Conjunto de órdenes y programas que controlan los procesos básicos de una computadora, dispositivo móvil o algunos aparatos electrónicos que permiten el funcionamiento de otros programas.
                    </li>
                    <li>
                        Usuario. Colaborador (persona física) del Sorteo Anáhuac que por medio de una cuenta de usuario y utilizando un dispositivo móvil, descargó y solicitó el acceso a los Medios Electrónicos para la venta de los boletos digitales, o cualquiera otra de las acciones que puedan ejecutarse dentro de los mismos.
                    </li>
                    <li>
                        Cuenta de Usuario. Sistema de identificación que permite el acceso exclusivo al usuario de los Medios Electrónicos.
                    </li>
                    <li>
                        Contraseña. Conjunto y/o suma de signos alfanuméricos de exclusivo conocimiento del Usuario, que permiten su asociación con una cuenta de usuario, y que mediante el debido proceso de identificación y autenticación le permite acceder a los Medios Electrónicos.
                    </li>
                    <li>
                        Comunidad. Alumnos, ex alumnos, padres, profesores y personal administrativo, así como cualquier otra persona que tenga relación directa con cualquiera de las instituciones que conforman la Red de Universidades Anáhuac. 
                    </li>
                    <li>
                        Boleto Digital. Registro electrónico autorizado que acredita al portador o titular el derecho de participar en un sorteo y garantiza sus derechos; 
                    </li>
                    <li>
                        Sorteo. Actividad en la que los poseedores o titulares de un boleto o Boleto Digital, obtienen el derecho a participar, en un procedimiento conforme al cual se determina al azar uno o varios ganadores de un premio;
                    </li>

                </ol>

                <h2>Condiciones de uso</h2>
                <p>
                    Sorteo Anáhuac autoriza a los usuarios para que, a través de los Medios Electrónicos, previa identificación, y autenticación como usuario, puedan acceder a los datos, información, servicios, opciones y acciones que se tienen en funcionamiento. 
                </p>
                <p>
                    La descarga y el acceso a los Medios Electrónicos es gratuito, pero restringido a los Usuarios.
                </p>
                <p>
                    El acceso a los Medios Electrónicos se logrará única y exclusivamente cuando el Usuario se identifique y autentique mediante la utilización de la cuenta, que previamente le fue asignada. Dada la naturaleza de la aplicación, es necesario que el Usuario cuente con un dispositivo móvil que este habilitado técnicamente para operar los Medios Electrónicos. 
                </p>
                <p>
                    Los medios Electrónicos, están disponibles a través de las tiendas electrónicas de los Sistemas Operativos disponibles, en cualquier versión de estos y en la página web.
                </p>
                <p>
                    Lograda la identificación y autenticación del Usuario, este podrá tener acceso y efectuar las siguientes operaciones: 
                </p>

                <ul>
                    <li>
                        Consulta de saldos.
                    </li>
                    <li>
                        Acceso a plataforma para realizar pagos.
                    </li>
                    <li>
                        Consulta de información general del Sorteo Anáhuac.
                    </li>
                    <li>
                        Consulta de las cuentas bancarías para depósitos del Sorteo Anáhuac.
                    </li>
                    <li>
                        Consulta del Calendario de Actividades propias del Sorteo Anáhuac. 
                    </li>
                </ul>

                <p>
                    Adicionalmente algunos de los usuarios podrán acceder a la función “Venta de Boletos Digitales”, con la cual tendrán derecho a la asignación de una cantidad limitada de boletos digitales, mismos que, una vez llenada la información correspondiente podrán enviarlo por medio de correo electrónico al adquirente o comprador, por lo que el pago correspondiente se deberá realizar en efectivo o bien mediante depósito a la cuenta bancaria y referencia que se encuentran señaladas dentro de los medios Electrónicos. La opción de pago en línea ya está habilitada por lo que el Usuario podrá realizar también su pago mediante su tarjeta de crédito o débito o incluso en efectivo en banco pudiendo imprimir su ficha de pago.

                </p>

                <p>
                    Para la emisión de un Boleto Digital, es necesario que el Usuario complete manualmente cada uno de los campos del formulario requeridos por los Medios Electrónicos, evitando dejar algún campo en blanco. Una vez concluido el formulario, el usuario y el comprador deberán corroborar que la información sea completa y correcta, ya que, una vez enviado el formulario, emitido el Boleto Digital, y efectuado el pago, NO HAY POSIBILIDAD DE MODIFICACION O CAMBIO ALGUNO. Por lo que, se deberá tener sumo cuidado en su llenado. 
                </p>

                <p>
                    En caso de que el Usuario se haya equivocado en alguno de los campos del formulario, deberá acudir, en compañía del titular del Boleto Digital, ante la representación social del domicilio que les corresponda y redactar una carta en la que manifieste esta situación. Dicha situación deberá ser informada al Sorteo Anáhuac a la brevedad posible y darlo a conocer a la Dirección General de Juegos y Sorteos. Asimismo, un tanto de la carta redactada, deberá ser conservada por el titular en compañía del Boleto Digital original, ya que en caso de ser ganador de algún premio ambos documentos deberán ser presentados, en caso contrario NO SE ENTREGARÁ EL PREMIO SORTEADO. 
                </p>

                <p>
                    El Boleto Digital consiste en un algoritmo lógico-matemático único, irrepetible e inalterable de imposible falsificación, el cual se valida por medio de un código QR. La impresión del Boleto Digital o la presentación del mismo en cualquier medio digital, óptico o cualquier otra tecnología que permita su lectura constituyen un boleto original, lo que le da derecho de participar en el sorteo y reclamar cualquier premio que le corresponda. 
                </p>

                <p>
                    La información e instrucciones que el usuario transmita o comunique a Sorteo Anáhuac, mediante la utilización de los Medios Electrónicos, tendrán pleno valor probatorio y fuerza legal para acreditar la operación realizada. 
                </p>

                <p>
                    El Usuario para todos los efectos legales a que haya lugar, expresamente reconoce y acepta el carácter personal e intransferible de la contraseña de acceso a los Medios Electrónicos, así como su confidencialidad, por lo anterior, expresamente reconoce que cualquier acción, orden o información que se envíe por medio de la app se considerará realizada por él y legalmente hecha por lo que tendrá pleno valor probatorio. 
                </p>


                <h2>
                    Del Usuario
                </h2>

                <p>
                    El Usuario tiene la obligación de leer y aceptar el presente documento antes de usar todos los servicios, información y acciones que se encuentran dentro de los medios Electrónicos, ya que la utilización de todos o parte de sus contenidos y/o servicios constituye la lisa y llana aceptación de los presentes términos y condiciones.
                </p>

                <p>
                    Será obligación del Usuario, verificar que la información que ingreso en cualquier registro o formulario de los Medios Electrónicos, sea completa y verídica. Asimismo, una vez que haya concluido su “estancia” en los Medios Electrónicos, deberá pulsar el botón de “Cerrar Sesión”, con la intención de cerrar los Medios Electrónicos y con ello evitar cualquier mal uso o acceso no autorizado, ya que, en caso de no hacerlo, el Usuario responderá por cualquier mal uso que se dé dentro de la misma. 
                </p>

                <p>
                    El Usuario es el único responsable y conocedor de la cuenta de Usuario y contraseña con la que se identifica y autentica en los Medios Electrónicos. En tal virtud, el usuario reconoce que dicha información quedará bajo su exclusiva custodia, control y cuidado, reconociendo también el carácter personal, intransferible y confidencial de éstos, deslindando de cualquier responsabilidad al Sorteo Anáhuac, A.C. o a cualquiera de sus colaboradores por el mal uso que pudiera hacer de los mismos, por lo que será de su estricta responsabilidad cualquier quebranto que pudiera sufrir como consecuencia del uso indebido de las mismas.
                </p>

                <p>
                    La responsabilidad del Usuario sobre el uso de la aplicación cesará en el momento que Sorteo Anáhuac, sea notificado del extravío de la cuenta y/o contraseña de acceso, o bien cuando el Usuario considere que ha habido una vulneración a la seguridad de los Medios Electrónicos, en estos casos se deberá dar aviso de inmediato a Sorteo Anáhuac a través de la oficina de la universidad que corresponda o bien a los teléfonos o direcciones que parecen en la siguientes lista: dante.cortes@anahuac.mx Tel. (01) (55) 53288096 ext. 2137, es de recalcarse que el aviso o notificación no eximirá al Usuario de los malos manejos o usos que se hayan dado previamente en la aplicación, puesto que el aviso al que se hace alusión en este párrafo solo surtirá sus efectos para actos futuros. 
                </p>
                <p>
                    El Usuario se abstendrá de realizar cualquier mal uso, conducta o actividad ilícita con y en los Medios Electrónicos, dado que Sorteo Anáhuac, de ninguna manera y ninguna circunstancia será responsable sobre lo realizado en los perfiles de Usuario o lo que estos provoquen en la manipulación de la misma. Bajo esta tesitura, el Usuario se compromete a sacar en paz y salvo a Sorteo Anáhuac de cualquier acción o juicio que se intente en su contra ya sea de carácter, penal, civil, administrativo o laboral, así mismo se obliga a reembolsar cualquier cantidad que Sorteo Anáhuac tenga que desembolsar por estos conceptos 
                </p>
                <p>
                    Así mismo, el Usuario no podrá utilizar los Medios Electrónicos para transmitir, almacenar, divulgar promover o distribuir datos o contenidos que sean portadores de virus o cualquier otro código informático, archivos o programas diseñados para interrumpir, destruir o perjudicar el funcionamiento de cualquier programa o equipo informático o de telecomunicaciones.
                </p>

                <p>
                    <strong>
                        Por último, el usuario, no podrá ofrecer boletos para su venta directa a menores de edad. En el caso de que algún comprador, requiera poner como propietario a un menor de edad, el padre, madre o tutor, será el responsable de hacer el registro en el boleto como tenedor a éste. La venta de boletos del Sorteo Anáhuac será sólo para personas mayores de edad de acuerdo a las leyes de nuestro país.
                    </strong>
                </p>

                <h2>
                    Responsabilidad de Sorteo Anáhuac
                </h2>

                <p>
                    Sorteo Anáhuac A.C.  se deslinda de toda responsabilidad civil, penal, administrativa o de cualquier índole que pudiese surgir como consecuencia del mal uso de las acciones y/o del contenido de los Medios Electrónicos.
                </p>
                <p>
                    Sorteo Anáhuac, solo será responsable de aquella información que sea válidamente enviada, así como del registro de los boletos digitales que sean adquiridos mediante la utilización de los Medios Electrónicos, hasta en tanto estos se encuentren válidamente enviados y pagados.
                </p>
                <p>
                    Asimismo, Sorteo Anáhuac, será responsable por todas aquellas conductas o acciones que se generen mediante la utilización de los Medios Electrónicos, única y exclusivamente a partir del momento en el que el Usuario notifique del extravió y/o de la vulneración de la seguridad de la cuenta, sin que esto pueda tener ningún efecto retroactivo. 
                </p>
                
                <p>
                    Por último, Sorteo Anáhuac, se reserva el derecho de cancelar cualquier cuenta de Usuario que a juicio del propio Sorteo Anáhuac lleve un mal manejo, en el entendido que cualquier acción que implique un gravamen deberá ser cubierto por el Usuario. En el caso en el que se llegasen a cancelar algún o algunos de los boletos digitales, derivado de un mal manejo de los Medios Electrónicos, el Usuario deberá responder frente a los adquirentes de los mismos. 
                </p>

                <h2>
                    Cancelación del servicio
                </h2>

                <p>
                    Una vez que el Usuario haya hecho uso de los Medios Electrónicos y se haya identificado y autenticado se entenderá como un servicio dado de alta y podrá cancelarlo en cualquier momento, con el simple hecho de borrar los Medios Electrónicos de su equipo y a no utilizarlos en ninguna otra situación. 
                </p>

                <p>
                    Empero de lo anterior si el usuario ha solicitado vender boletos digitales, NO PODRÁ cancelar por ningún motivo o circunstancia el servicio y este será vinculante hasta el momento en que el sorteo haya terminado legalmente. En el entendido que, a pesar de una negativa para continuar con el servicio, deberá responder por el pago de la totalidad del boletaje digital que se le haya asignado.
                </p>

                <h2>
                    Privacidad y Protección de Datos Personales
                </h2>

                <p>
                    Sorteo Anáhuac, A.C., con domicilio en Avenida Anáhuac número 46, Piso 7 Edificio de la Biblioteca, colonia Lomas Anáhuac, Huixquilucan, Estado de México, código postal 52786 es responsable de la protección y tratamiento de todos los datos personales que se proporcionen con motivo de la interacción del usuario con la app.
                </p>

                <p>
                    Si desea ejercer cualquiera de sus Derechos ARCO o conocer los términos bajos los cuales será tratada y protegida su información le invitamos a conocer nuestro aviso de privacidad integral, el cual está disponible en cualquiera de nuestras oficinas, en el sitio web www.sorteoanahuac.mx o bien solicitarlo a través del correo electrónico sorteoanahuac@anahuac.mx, mismo que se tiene por aquí reproducido como si se insertase a la letra. 
                </p>

                <p>
                    Hacemos de su conocimiento que Sorteo Anáhuac, A.C.  está comprometido con la salvaguarda de la cualquier información de carácter personal que poseamos, en este sentido, nos ocupamos de mantener su información protegida conforme a los más altos estándares de seguridad, física, administrativa y tecnológica y su tratamiento se realizará en pleno respeto y apego a los principios señalados en nuestro Aviso de Privacidad y en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, su reglamento, y en la demás normatividad aplicable. 
                </p>

                <h2>
                    Confidencialidad
                </h2>

                <p>
                    Sorteo Anáhuac, se obliga a mantener confidencial de toda la información que reciba del usuario mediante la interacción con la aplicación. Esta responsabilidad solo es aplicable por cuanto hace a la interacción que se tenga con la aplicación. 
                </p>

                <p>
                    Dado que la cuenta de Usuario es proporcionada por un agente externo a la relación que aquí se describe, el Usuario deberá revisar las condiciones de confidencialidad y seguridad de la información con este. 
                </p>

                <p>
                    Sorteo Anáhuac, A.C., ni ninguno de sus representantes o colaboradores solicitan información confidencial de la cuenta, por lo que el Usuario no deberá proporcionarla por ningún medio. En este tenor, Sorteo Anáhuac no tiene acceso de ninguna manera a las cuentas de los usuarios o a las contraseñas que lo autentifiquen, por lo que en caso de extravío, cambio o recuperación de la misma deberá contactar al proveedor de la cuenta. 
                </p>

                <h2>Propiedad Intelectual</h2>

                <p>
                    Los Medios Electrónicos y todo su contenido es propiedad de Sorteo Anáhuac A.C., y se encuentra protegido de conformidad con lo establecido por la Ley Federal del Derecho de Autor. Sorteo Anáhuac, tiene derechos sobre signos distintivos y avisos comerciales, sean derivados e inherentes de los mismos, salvo que exista un señalamiento particular en contrario dentro del mismo; y se encuentran protegidos por las leyes mexicanas aplicables, por lo que no podrán ser copiados, distribuidos, reutilizados, publicados, vendidos o utilizados en forma alguna, por ningún medio ya sea físico, electrónico, digital, o magnéticos,  sin el consentimiento previo, expreso y por escrito de Sorteo Anáhuac A.C. Cualquier contravención a lo anterior o uso indebido representa una violación de derechos de autor y/o de propiedad intelectual. 
                </p>

                <h2>Atención al Usuario</h2>

                <p>
                    En todo lo no previsto en este documento el usuario podrá revisar el manual de usuario que se encuentra disponible mediante formato PDF, en la siguiente dirección electrónica www.sorteoanahuac.mx 

                    Para la resolución de consultas, atención de dudas y sugerencias relacionadas con los Medios Electrónicos, el usuario deberá llamar al servicio telefónico (55) 53-28-80-96, ext. 2138, enviar un correo electrónico a la cuenta sorteoanahuac@anahuac.mx, o bien acudir personalmente a la oficina de Sorteo Anáhuac de la Universidad correspondiente. 

                </p>

                <p>
                    
                    Asimismo, si el Usuario tiene problemas para identificar o autenticarse ante los medios Electrónicos, el Usuario deberá contactar a los enlaces operativos del Sorteo en cada una de las Universidades  o enviar un correo electrónico al área de soporte, datos que se encuentran por universidad en la siguiente dirección  dante.cortes@anahuac.mx Tel. (01) (55) 53288096 ext. 2137.

                </p>

                <h2>
                    Notificaciones
                </h2>

                <p>
                    Todas las notificaciones, requerimientos, peticiones y otras comunicaciones que el Usuario desee efectuar al Sorteo Anáhuac A.C., deberán realizarse por escrito y se entenderá como legalmente hecha aquella que se reciba en las oficinas centrales de Sorteo Anáhuac, o bien en alguna de las oficinas que se encuentre en cualquiera de las universidades. El hecho de recibir el escrito no significa que Sorteo Anáhuac procederá conforme a lo solicitado.  
                </p>

                <h2>
                    Legislación aplicable y jurisdicción
                </h2>
                <p>
                    El presente documento regula la conducta entre Sorteo Anáhuac, A.C. y el Usuario, con relación al uso de los Medios Electrónicos y todo su contenido, se interpretará a la letra y en caso de discrepancia se interpretará de conformidad con las leyes aplicables para el Estado de México. 
                </p>  
                
                <p>
                    En caso de conflicto entre Sorteo Anáhuac, A.C. y el Usuario, serán competentes para conocer del asunto los juzgados y tribunales del Estado de México. 
                </p>

                <h2>
                    Tarifas y Pagos
                </h2>


                <ol type="I">
                    <li>
                        Costo. Cada Boleto Digital tiene un costo unitario de $ 450.00 (Cuatrocientos cincuenta pesos
                        00/100 M.N.)
                    </li>
                    <li>
                        Costos y calendario de pagos:
                        <div class="over">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>USUARIO</th>
                                        <th>BOLETO</th>
                                        <th>TALONARIO (11 BOLETOS)</th>
                                        <th>BOLETO DE CORTESÍA</th>
                                        <th>TALONARIO COMPLETO VENDIDO</th>
                                        <th>FECHAS DE PAGO REINGRESO</th>
                                        <th>NUEVO INGRESO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row"> BECARIO RUA </td>
                                        <td> $450.00 </td>
                                        <td> $4,950.00 </td>
                                        <td> $450.00 </td>
                                        <td> $4,950.00 </td>
                                        <td>15 may 2024 y
                                            06-sep-24
                                        </td>
                                        <td> 18-oct-24 </td>
                                    </tr>
                                    <tr>
                                        <td scope="row"> VOLUNTARIO </td>
                                        <td> $450.00 </td>
                                        <td> $4,950.00 </td>
                                        <td> $450.00 </td>
                                        <td> $4,950.00 </td>
                                        <td> 21-nov-24 </td>
                                        <td> N/A </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>

                    <li>
                        Opciones de pagos:
                        <ol type="A">
                            <li>
                                Devoluciones
                                <p>
                                    Cuando procediera la devolución de un pago, éste se deberá solicitar en las oficinas
                                    de Sorteo Anáhuac, dentro del año fiscal correspondiente. En caso de exceso de pago
                                    por un importe equivalente a $450.00 no se hará devolución y a cambio se entregará
                                    un boleto vigente del mismo valor.
                                </p>
                            </li>
                            <li>
                                Pago en Banco:
                                <p>
                                    Durante el inicio de la vigencia y promoción del permiso para la venta de boletos
                                    del sorteo, los Usuarios tendrán disponibles los Boletos Digitales del Sorteo en los
                                    Medios Electrónicos, y para el pago de los boletos, tendrán disponible la plataforma
                                    de pagos de igual manera.
                                </p>
                                <p>
                                    1. Pago referenciado.
                                </p>
                                <p>
                                    2. Transferencia bancaria.
                                </p>
                            </li>

                            <p>
                                Las ’Referencias de pago’ deberán descargase en los Medios Electrónicos. 
                            </p>
                            <p>
                                El hecho de que el Banco acepte el pago no implica que éste haya quedado realizado correctamente. La sociedad Sorteo Anáhuac, A.C. puede solicitar la aclaración de los pagos. Es responsabilidad del colaborador conservar los comprobantes de pago para realizar cualquier aclaración.
                            </p>
                        </ol>
                    </li>

                </ol>

                <h2>1. Tarjeta de crédito o débito: </h2>
                <p>
                    -Pago con cualquier tipo de tarjeta de crédito o débito: se podrá pagar los Boletos Físicos, directamente en las oficinas de Sorteo Anáhuac de cada una de la Universidades de la Red Anáhuac, en caso de algún cobro duplicado se solicita la cancelación del movimiento el mismo día.
                </p>
                <p>
                    -Pago con cualquier tipo de tarjeta de crédito o débito: se podrá pagar los Boletos Digitales, directamente en las oficinas de Sorteo Anáhuac de cada una de la Universidades de la Red Anáhuac, en caso de algún cobro duplicado se solicita la cancelación del movimiento el mismo día.
                </p>
                <p>
                    -Pago con cualquier tipo de tarjeta de crédito o débito: se podrá pagar los Boletos Digitales, directamente en los Medios Electrónicos, en la modalidad de pago en línea a través de los usuarios.
                </p>
                <p>
                    -Pago en línea página web: se podrán pagar los Boletos Digitales, en el portal oficial de Sorteo Anáhuac, con la opción de pago Visa, MasterCard, AMEX o cualquier medio habilitado autorizado por el propio sorteo en su página web.
                </p>

                <h2>
                    2. Con cheque:
                </h2>
                <p>
                    Los pagos en ventanilla bancaria se hacen mediante cheque nominativo expedido a favor de “Sorteo Anáhuac A.C.” anotando la referencia bancaria en la parte de atrás. No se reciben pagos en efectivo.
                </p>

                <h2>
                    2.1. Cheques devueltos
                </h2>

                <p>
                    Los cheques se reciben salvo buen cobro. Por lo anterior, el pago de Boletos Digitales, se considerará pagadas si el Banco no rechaza el cobro del cheque. Conforme al artículo 193 de la Ley General de Títulos y Operaciones de Crédito, Sorteo Anáhuac y el colaborador acuerdan que la indemnización por cheque devuelto será del 20% del valor del cheque, misma que el librador pagará directamente a Sorteo.
                    El monto del cheque devuelto y la indemnización indicada en el párrafo anterior deberán ser pagadas con algún otro medio de pago. No se volverá a aceptar otro cheque para saldarlas. 
                </p>

                <h2>
                    3. Transferencia bancaria:
                </h2>
                <p>
                    Los pagos hechos mediante transferencia bancaria desde el portal del banco del Usuario, deberán tener la cuenta clabe de 18 dígitos (Santander 014180655049267903), y poner referencia de Sorteo Anáhuac en el campo concepto. 
                </p>
                <h2>
                    XI. Notas importantes:
                </h2>
                <p>
                    1.	El cobro mediante los Medios Electrónicos es responsabilidad del Usuario. <br>
                    2.	Sorteo Anáhuac A.C. NO tiene ni mantiene ninguna relación ni de marcas registradas ni de cualquier otro tipo similar con Apple Inc.

                </p>
                <p>Última actualización de este documento <strong>07 DE MARZO DE 2024.</strong></p>
            </div>
        </div>
    </div>
</section>