import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dates',
  templateUrl: './dates.component.html',
  styleUrls: ['./dates.component.scss']
})
export class DatesComponent implements OnInit {
  link;
  linkShare;
  select = false;
  session = false;
  mensaje="";
  mensaje2="";
  title = 'Fechas importantes del Sorteo Anáhuac 2023 - 8ª Edición';
  description = 'Conoce las fechas más importantes del Sorteo Anáhuac 8ª Edición y que no te debes perder.';
  constructor(private titleService: Title, private metaTagService: Meta) { 
    if(localStorage.getItem('idColaborador') === null || localStorage.getItem('idColaborador') === ""){
		}else{
			this.session = true;
		}
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'description', content: 'Conoce las fechas más importantes del Sorteo Anáhuac 8ª Edición y que no te debes perder. '}
    );

    this.metaTagService.updateTag(
      { property: 'keywords', content: ''}
    );

    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title}
    );

    this.metaTagService.updateTag(
      { property: 'og:description', content: 'Conoce las fechas más importantes del Sorteo Anáhuac 8ª Edición y que no te debes perder. ' }
    );

    this.metaTagService.updateTag(
    { property: 'og:url', content: 'https://www.sorteoanahuac.mx/fechas-importantes' }
    );

    this.metaTagService.updateTag(
      { property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/bg-dates.jpg' }
    );
      
    this.metaTagService.updateTag(
      { name: 'twitter:title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: 'Conoce las fechas más importantes del Sorteo Anáhuac 8ª Edición y que no te debes perder. '}
    );
  }

  sharedLink(mensaje:string){
    this.linkShare = document.location.href;
    this.link = document.location.href;
    this.link = this.link.replace("#", '%23');
    this.mensaje = mensaje;
    this.mensaje2 = mensaje.replace(/ /g, "%20");
  }

  clickbtn() {
    $("#link").select();
    document.execCommand("copy");
    ////console.log("texto copiado");
    this.select = true;
   
  }
  more() {
    window.location.href = "/comparte-contenido";
  }

}
