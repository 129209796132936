<section class="stc-psw">
    <div class="container">
        <div class="row ">
            <div class="col-12">
                <ng-container *ngIf="isToken; else errorToken;">
                    <h2 class="text-center mb-5">Ingresa tu nueva contraseña.</h2>
                    <form #dataPass="ngForm" novalidate (ngSubmit)="validateData(dataPass)">
                        <div class="form-group">
                            <label for="name">Password</label>
                            <input minlength="8" (input)="validaPass($event)" type="password" class="form-control contra" id="pass" placeholder="* * * * *" ngModel #pass="ngModel" pass  name="pass" [(ngModel)]="formPass.pass" required>
                            <div *ngIf="!formPass.pass " class="alert alert-danger">
                                Dato requerido
                            </div>

                            <label class="mt-3 " for="name ">Confirmar Password</label>
                            <input minlength="8" (input)="validaPass($event)" type="password" class="form-control" id="conf-pwd" placeholder="* * * * *" name="conf-pwd" required>
                            <div *ngIf="errorPass" class="alert alert-danger  ">
                                Contraseñas diferentes
                            </div>
                            <div *ngIf="successPass ==true; " class="alert alert-success " role="alert">
                                Contraseñas iguales
                            </div>
                        </div>
                        <div class="form-group col-12" style="padding: 0 !important;">
                            <div *ngIf="f1  || f2; " class="alert alert-danger">
                                La contraseña debe tener mínimo 8 caracteres.
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn-stack border-0 mt-4">Enviar</button>
                        </div>
                    </form>
                </ng-container>
                <ng-template #errorToken>
                    <h2 class="text-center mb-5">Token Invalido</h2>
                </ng-template>
            </div>
        </div>
    </div>
</section>