import { Component, OnInit } from '@angular/core';
// import { GalleryService } from 'src/services/gallery.service';
import { Title, Meta } from '@angular/platform-browser';
import mixitup from 'mixitup';
@Component({
  selector: 'app-galerianueva',
  templateUrl: './galerianueva.component.html',
  styleUrls: ['./galerianueva.component.scss']
})
export class GalerianuevaComponent implements OnInit {
  title = 'Ganadores del Sorteo Anáhuac 2023 - 8ª Edición ';
  description = 'Toda la información sobre los ganadores del Sorteo Anáhuac 2023 - 8ª Edición. ¡Muchas felicidades y gracias por participar!';

  btn_2023 = true;
  btn_2024 = true;
  btn_show_2023 = false;
  btn_show_2024 = false;
  constructor( private titleService: Title, private metaTagService: Meta) {
   }

  ngOnInit(): void {
    $('.content-2023').css('display', 'none');
    $('.content-2024').css('display', 'none');
    $('.conainter-photos').css('display', 'none');
    $('.conainter-photos2').css('display', 'none');
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'description', content: this.description}
    );

    this.metaTagService.updateTag(
      { property: 'keywords', content: ''}
    );

    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title}
    );

    this.metaTagService.updateTag(
      { property: 'og:description', content: this.description }
    );

    this.metaTagService.updateTag(
    { property: 'og:url', content: 'https://www.sorteoanahuac.mx/ganadores' }
    );

    this.metaTagService.updateTag(
      { property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/gallery-ph.jpg' }
    );
      
    this.metaTagService.updateTag(
      { name: 'twitter:title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: this.description}
    );

    let mixer = mixitup('.conainter-photos', {
      selectors: {
          target: '.mix'
      },
      animation: {
          duration: 300
      }
     });
     let mixer2 = mixitup('.conainter-photos2', {
      selectors: {
          target: '.mix2'
      },
      animation: {
          duration: 300
      }
     });

  }


  showGalery(galery:number) {
    if(galery == 1 ) {
      $('.content-2023').css('display', 'block');
      $('.btn1').addClass('active');

      $('.content-2024').css('display', 'none');
      $('.btn2').removeClass('active');

      $('.conainter-photos').css('display', 'block');
      $('.conainter-photos2').css('display', 'none');

      
    }else {
      $('.content-2023').css('display', 'none');
      $('.btn1').removeClass('active');

      $('.content-2024').css('display', 'block');
      $('.btn2').addClass('active');

      $('.conainter-photos').css('display', 'none');
      $('.conainter-photos2').css('display', 'block');
    }
  }

}
