import { Component, OnInit } from '@angular/core';
import { Colabora } from 'src/models/colabora';
import { Direccion_colabora } from 'src/models/direccion_colabora';
import { NgForm } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { ColaboraFormService } from 'src/services/colabora-form.service';
import { Md5 } from 'ts-md5/dist/md5';
import { Title, Meta } from '@angular/platform-browser';
import { FormEgrService } from 'src/services/form-egr.service';
import { PasaLavozService } from 'src/services/pasa-lavoz.service';
@Component({
  selector: 'app-colabora-form',
  templateUrl: './colabora-form.component.html',
  styleUrls: ['./colabora-form.component.scss']
})
export class ColaboraFormComponent implements OnInit {
  name: string;
  email: string;
  tel: string;
  valid_email = false;
  country: any = [];
  dataCountry: any = [];
  dataEmptyCP = false;
  selectCP = true;
  isClickCol = true;
  validPass = false;
  idEgresado = "";
  dataStudent: any = [];
  colaboraValues: Colabora = new Colabora();
  direccionValue: Direccion_colabora = new Direccion_colabora();
  emailV = "";
  md5 = new Md5();
  error = "";
  f1 = true;
  f2 = true;
  verSeleccion: string = '0';
  seleccionComunidad: string = '0';
  seleccionCol: string = '0';
  validate_flag: any = [];
  success = false;
  bandera = true;
  messgge = "";
  flagGlobal = false;
  codigoPostalVal;
  title = 'Registro para colaboradores | Sorteo Anáhuac 2023 - 8ª Edición';
  description = '¡Tu apoyo cuenta! Regístrate como colaborador y obtén la posibilidad de ganar increíbles premios. Llena el formulario de colaborador aquí y participa.';
  flagLastName = false;
  canales: any = [];
  seleccionCanal = 0;


  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          lidesToScroll: 1,
        }
      },
    ]
  };
  /*video */
  slides = [
    { url: 'McSjABAEhL8' },
    { url: 'iVWFsxjrbg8' },
  ];
  constructor(private readonly _pasaServive: PasaLavozService, private readonly _formService: FormEgrService, private readonly _formColabora: ColaboraFormService, private route: ActivatedRoute, private titleService: Title, private metaTagService: Meta) {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title }
    );
    this.metaTagService.updateTag(
      { name: 'description', content: this.description }
    );

    this.metaTagService.updateTag(
      { property: 'keywords', content: 'Registro colaboradores' }
    );

    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title }
    );

    this.metaTagService.updateTag(
      { property: 'og:description', content: this.description }
    );

    this.metaTagService.updateTag(
      { property: 'og:url', content: 'https://www.sorteoanahuac.mx/formulario-registro-colaboradores' }
    );

    this.metaTagService.updateTag(
      { property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/premio2.jpg' }
    );

    this.metaTagService.updateTag(
      { name: 'twitter:title', content: this.title }
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: this.description }
    );



    this.colaboraValues.parte_de_comunidad = '0';
    this.colaboraValues.pkcanal = 0;

    this.direccionValue.pais = '0';
    // this.direccionValue.colonia = '0';
    this.route.queryParams.subscribe(params => {
      this.idEgresado = params['idEgresado'];
      if (this.idEgresado === undefined) {
        this.idEgresado = "";
        ////console.log("id vacio= "  + this.idEgresado);
      }
      ////console.log("id = "  + this.idEgresado);
    });
    //obtener paises
    this._formService.getCountry().subscribe(a => {
      this.country = a;
      //console.log(this.country)

    });

    //obtener caneles
    this._formService.getCanales().subscribe(a => {
      this.canales = a;
      //console.log(this.canales)
      //console.log(this.canales[0].pkcanal +  this.canales[0].canal)
    });


    //obtener datos de egresafo
    if (this.idEgresado !== "") {
      this._formColabora.getEgresadosLink(this.idEgresado.toString()).subscribe(a => {
        this.dataStudent = a;
        this.colaboraValues = this.dataStudent;
        ////console.log( this.colaboraValues);
        this.direccionValue = this.dataStudent.direccion;
        ////console.log("dirreccion value = " + this.direccionValue.telefono);
        this.valid_email = true;
      },
        err => {
          ////console.log(err);
          // this.error = err.error.Message;
          // (<any>$('#modalError')).modal('show');
        });
    }
  }



  ngOnInit(): void {

    /*videos*/
    (<any>$('#modalVideo')).modal('show');
    (<any>jQuery('#modalVideo')).on('hide.bs.modal', function () {
      jQuery(".modal-content").find('iframe').remove();
    });


    (<any>jQuery('#modal-thank-2')).on('hide.bs.modal', function () {
      window.location.href = "/home";
    });
    //validacion correo
    var email2 = "";
    $("#verify-pass").css("display", "none");
    $("#verify-g").css("display", "none");
    $("#email").on('paste', function (e) {
      e.preventDefault();
      alert('Favor de escribir su correo');
    })

    $("#close-thank").click(function () {
      location.reload();
    });




  }

  inputValidateEst(event: any) {

    if (event.target.value.length > 0 && this.direccionValue.pais !== 'México' || this.direccionValue.pais !== 'Mexico') {
      this.flagLastName = true;
    } else {
      this.flagLastName = false;
    }
  }



  inputValidator(event: any) {
    //////console.log(event.target.value);
    const pattern = /^[0-9]*$/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/, "");
      // invalid character, prevent input
    }
  }

  inputValidateEmail(event: any) {
    ////console.log(event.target.value); 

    // ////console.log("aqui email" + email);
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,10})+$/;
    //let inputChar = String.fromCharCode(event.charCode)

    if (!pattern.test(event.target.value)) {
      // invalid character, prevent input
      this.valid_email = false;
    } else {
      this.valid_email = true;
    }
  }


  getCp() {
    var valor = $('#cp').val();
    this.codigoPostalVal = valor;
    this._formService.getCpostal(valor.toString()).subscribe(a => {
      ////console.log("codigo postal");
      ////console.log(a);
      this.dataCountry = a;
      if (this.dataCountry.length < 1) {
        this.dataEmptyCP = true;
      } else {
        this.dataEmptyCP = false;
      }
      //this.isCp =1;
      this.selectCP = false;
    },
      err => {
        ////console.log(err);
        //this.errorCP = true;
      });
  }

  //saber si dio click sobre el input de colonia 
  isClickInp() {
    this.isClickCol = false;
    this.getCp();
  }

  //validar pass

  validaPass(event: any) {
    let password = $("#password").val().toString();
    var confPass = $("#conf-pwd").val().toString();

    if ($("#password").val() != $("#conf-pwd").val()) {
      $("#verify-pass").css("display", "block");
      $("#verify-g").css("display", "none");
      this.validPass = false;
    } else if ($("#password").val() != "" && $("#conf-pwd").val() != "") {
      ////console.log("son iguales");
      $("#verify-pass").css("display", "none");
      $("#verify-g").css("display", "block");
      this.validPass = true;
    }
    if (password.length < 8) {
      this.f1 = true;
    } else {
      this.f1 = false;
    }

    if (confPass.length < 8) {
      this.f2 = true;
    } else {
      this.f2 = false;
    }
  }

  validateData(form: NgForm) {
    if (this.flagGlobal) { (<any>$('#modalSucces')).modal('show'); return; }
    if (form.invalid || !this.valid_email || !this.validPass || this.f1 || this.f2 || this.verSeleccion == "0" || this.seleccionComunidad == "0" || this.seleccionCanal == 0) { (<any>$('#modalErr')).modal('show'); return; }

    if (this.selectCP == false) {
      this.direccionValue.estado = this.dataCountry[0].estado;
      this.direccionValue.municipio = this.dataCountry[0].municipio;
    }



    this.colaboraValues.password = String(this.md5.appendStr(this.colaboraValues.password).end());
    if (this.direccionValue.pais === 'México' || this.direccionValue.pais === 'Mexico') {
      ////console.log("SI ES igual a mexico");
    } else {
      ////console.log("NO ES igual a mexico");
      this.direccionValue.codigo_postal = "000000";
      this.direccionValue.colonia = "Extranjero";
      this.direccionValue.municipio = "Extranjero";
    }


    this.colaboraValues.apellido_materno = "";

    if (this.colaboraValues.pkcanal != 14 && this.colaboraValues.pkcanal != 15) {
      this.colaboraValues.especifica_canal = "";
    }


    this.colaboraValues.direccion = this.direccionValue;

    this._formColabora.validateColabora(this.colaboraValues).subscribe(a => {
      this.validate_flag = a;
    });
    this.colaboraValues.pkcanal = Number(this.colaboraValues.pkcanal);
    console.log(this.colaboraValues);

    (<any>$('#modal-thank')).modal('show');

    this._pasaServive.getToken().subscribe(a => {
      console.log(a);
    }, err => {

      if (err.status == 200) {
        console.log(err.error.text);
        this._formColabora.sendFormColabora(this.colaboraValues, err.error.text).subscribe(a => {
        },
          err => {
            console.log(err);
            this.success = false;
            this.bandera = true;
            this.flagGlobal = false;
            if (this.idEgresado !== "") {
              this.error = err.error;
              this.colaboraValues.password = "";
            } else {
              this.error = err.error.text;
              this.colaboraValues.password = "";
            }
            if (err.status === 409) {
              this.error = "Ya existe un correo registrado";
              (<any>$('#modal-thank')).modal('hide');
              (<any>$('#modalError')).modal('show');
            }
            if (err.status === 200) {
              this.error = "Registro Exitoso.";
              this.success = true;
              this.bandera = false;
              this.flagGlobal = true;
              (<any>$('#modal-thank')).modal('show');
              window.location.href = '/formulario-registro-colaboradores-gracias';
            }

          });

      }
    });

  }

  fReload() {
    window.location.href = "/home";
  }

  capturar() {
    this.verSeleccion = this.direccionValue.pais;
    if (this.direccionValue.pais === 'México' || this.direccionValue.pais === 'Mexico') {
      this.direccionValue.codigo_postal = "";
      this.direccionValue.colonia = "";
      this.direccionValue.estado = "";
      this.direccionValue.municipio = "";
      this.flagLastName = true;
    } else {

      this.direccionValue.codigo_postal = "000000";
      this.direccionValue.colonia = "Extranjero";
      this.direccionValue.municipio = "Extranjero";
      this.flagLastName = false;
    }
  }
  selectCol() {
    this.seleccionComunidad = this.colaboraValues.parte_de_comunidad;
  }

  selectCol2() {
    this.seleccionCol = this.colaboraValues.colonia;
  }

  selectCanal() {
    this.seleccionCanal = this.colaboraValues.pkcanal;
  }
}
