
<section id="info">
    <div class="container-fluid">

            <div class="row">
                <div class="col-md-12">
                    <h5>AVISO SOBRE EL USO DE COOKIES</h5>
                    <p>
                        En Sorteo Anáhuac, valoramos tu privacidad. Utilizamos cookies. para mejorar tu experiencia de navegación, personalizar contenido y analizar nuestro tráfico web. Aquí puedes encontrar más información sobre las cookies que utilizamos y cómo gestionar tus preferencias Consulta nuestro aviso de privacidad.
                    </p>

                    <div class="row">
                        <div class="col-md-6 text-md-right text-center mt-3 mb-3">
                            <a (click)="acceptInfo()" class="btn-stack">Aceptar</a>
                        </div>
                        <div class="col-md-6 text-md-left text-center mt-3 mb-3">
                            <a (click)="closeModal()" class="btn-stack">Rechazar</a>
                        </div>
                    </div>
                </div>
            </div>

    </div>
</section>
<div class="btn-call">
    <a href="https://api.whatsapp.com/send?phone=+525531332409&text=hola!%20Sorteo"  target="_blank">
        <div class="row">
            <div class="col-4 text-right p-0 my-auto order-md-1 order-2">
                <img src="/assets/images/sorteo2023/btn-whats.png" class="img-fluid" >
            </div>
            <div class="col-8 p-0 my-auto order-md-2  order-1">
                <span>Contáctanos <span class="d-block">por whatsapp</span></span>
            </div>
        </div>
    </a>
</div>


<div class="btn-call btn-go-buy">
    <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/compra-en-linea-boletos-sorteo-anahuac{{utmUrl}}" target="_blank">
        
        <img src="/assets/images/sorteo2023/btn-buy-red-responsive.png" class="d-md-none d-block" >
        <img src="/assets/images/sorteo2023/btn-buy-red.png" class="d-md-block d-none" >
    </a> 
</div>

<!-- <section class="campus">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h5 class="text-center">
                    nuestra comunidad
                </h5>
            </div>
            
            <div class="col-md-3 text-center my-auto">
                <img src="/assets/images/sorteo2023/redes.png" class="img-fluid" alt="Red de Universidades Anáhuac">
            </div>
           
            <div class="col-md-3 text-right my-auto">
               <img src="/assets/images/sorteo2023/red.png" class="img-fluid" alt="Red de Colegios Semper Altius">
            </div>

            <div class="col-md-3 text-center my-auto">
                <img src="/assets/images/sorteo2023/anahuac.png" class="img-fluid" alt="Prepa Anáhuac">
            </div>

            <div class="col-md-3 text-center my-auto">
                <img src="/assets/images/sorteo2023/ufv.png" class="img-fluid" alt="UFV">
            </div>
            <div class="col-md-3 text-center my-auto">
                <img src="/assets/images/sorteo2023/iada.png" class="img-fluid" alt="IADA">
            </div>

        </div>
    </div>
</section> -->

<section class="campus">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h5 class="text-center">
                    nuestra comunidad
                </h5>
            </div>
        </div>
        <div class="row text-center d-flex justify-content-center align-items-center">
            <div class="image-container">
                <a href=" https://www.anahuac.mx/" target="_blank">
                    <img src="/assets/images/sorteo2023/redes.png" class="img-fluid img-same-dimensions" alt="Red de Universidades Anáhuac">
                </a>
            </div>
            <div class="image-container">
                <a href="https://www.semperaltius.edu.mx/">
                    <img src="/assets/images/sorteo2023/red.png" class="img-fluid img-same-dimensions" alt="Red de Colegios Semper Altius">
                </a> 
            </div>
            <div class="image-container">
                <a href=" https://www.prepaanahuac.mx/" target="_blank">
                    <img src="/assets/images/sorteo2023/anahuac.png" class="img-fluid img-same-dimensions" alt="Prepa Anáhuac">
                </a>
            </div>
            <div class="image-container p-0">
                <a href="https://www.ufv.es/" target="_blank">
                    <img src="/assets/images/sorteo2023/ufv.png" class="img-fluid img-same-dimensions" alt="UFV">
                </a>
            </div>
            <div class="image-container p-0">
                <a href=" https://www.anahuac.mx/mexico/IADA/" target="_blank">
                    <img src="/assets/images/sorteo2023/iada.png" class="img-fluid img-same-dimensions" alt="IADA">
                </a>
            </div>
        </div>
    </div>
</section>





    

<footer id="main-footer">
    <div class="container">
        <div class="row">

            <div class="col-xl-5 col-lg-6 my-auto">
                <a [routerLink]="['/home']">
                    <img src="assets/images/sorteo2024/logo2024_vf.png" class="logo-footer img-fluid" alt="Sorteo Anahuac 2024">
                </a>

                <ul>
                    <li>
                        <a class="d-block" [routerLink]="['/reglamento-becario']" > 
                            Reglamento del Sorteo Anáhuac para Becarios Colaboradores
                        </a>
                    </li>
                    <li>
                        <a class="d-block" [routerLink]="['/aviso-de-privacidad']">
                            Aviso de privacidad
                        </a>
                    </li>
                    <li>
                        <a class="d-block" [routerLink]="['/terminos-y-condiciones']">
                            Términos
                            y Condiciones
                        </a>
                    </li>
                    <li>
                        <a class="d-block" [routerLink]="['/carta-de-confidencialidad']">
                            Carta de Confidencialidad
                        </a>
                    </li>
                </ul>

            </div>

            <!-- <div class="col-xl-3 col-lg-6 text-md-center  sto">
                <h5>
                    SORTEOS
                </h5>
                <div class="main-sorteo" style="background-color: #faaf3a;">
                    <a [routerLink]="['/ganadores-sorteo-egresados']">
                        <p class="m-0 p-0">
                            Sorteo de
                            egresados
                        </p>
                    </a>
                </div>
                <div class="main-sorteo" style="background-color: #8560d2;">
                  <a [routerLink]="['/sorteo-oportuno']">
                    <p class="m-0 p-0">
                        Sorteos
                        oportunos
                      </p> 
                  </a>
                </div>
                <div class="main-sorteo" style="background-color: #ff4700;">
                   <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/compra-en-linea-boletos-sorteo-anahuac{{utmUrl}}" target="_blank">
                        <p class="m-0 p-0">
                            Sorteo
                            Magno
                        </p>
                   </a>
                </div>

            </div> -->

            <div class="col-xl-4 col-lg-6 my-auto">
                <h5>
                    Contacto
                </h5>
                <h4>
                    ¿En qué te podemos ayudar hoy?
                </h4>
                <a href="/contacto" class=" d-block text-decoration-underline" target="_blank" >
                    <span style="border-bottom: 2px solid #333;">Haz clic aquí para ponerte en contacto</span>
                </a>
                <!-- <a href="https://api.whatsapp.com/send?phone=525540525533&text=Hola"  target="_blank" class="whats d-block" target="_blank">
                    <i class="fa fa-whatsapp" aria-hidden="true" style="color: #fc5705;font-size: 22px;margin-right: 5px;"></i> 55 4052 5533  
                </a> -->

                <a href="mailto:sorteoanahuac@anahuac.mx" class=" d-block" target="_blank">
                    <i class="fa fa-envelope" aria-hidden="true" style="color: #fc5705;font-size: 20px;margin-right: 5px;"></i> sorteoanahuac@anahuac.mx
                </a>
                <!-- <a href="mailto:sorteoanahuac@anahuac.mx" class=" d-block" target="_blank">
                    <i class="fa fa-envelope" aria-hidden="true" style="color: #fc5705;font-size: 20px;margin-right: 5px;"></i> sorteo.ganadores@anahuac.mx
                </a> -->
                <p>
                    <strong>Recuerda</strong> que para participar en nuestros Sorteos debes ser mayor de 18 años.
                </p>
            </div>

            <div class="col-xl-3 col-lg-6 my-auto redes text-md-center">
                <h5>
                    síguenos
                </h5>
                <a href="https://www.facebook.com/SorteoAnahuac" target="_blank">
                    <img  src="assets/images/sorteo2023/facebook.png" alt="Facebook" class="img-fluid">
                </a>
 
                <a href="https://www.instagram.com/sorteoanahuac/" target="_blank">
                    <img src="assets/images/sorteo2023/instagram.png" alt="Instagram" class="img-fluid">
                </a>

                <a href="https://twitter.com/sorteoanahuac" target="_blank">
                    <img src="assets/images/sorteo2024/x.png" alt="twitter" class="img-fluid">
                </a>

                <a href="https://www.youtube.com/@sorteoanahuac_oficial" target="_blank">
                    <img src="assets/images/sorteo2023/youtube.png" alt="youtube" class="img-fluid">
                </a>

                <a href="https://www.linkedin.com/company/sorteoanahuac/" target="_blank">
                    <img src="assets/images/sorteo2023/linkedin.png" alt="linkedin" class="img-fluid">
                </a>

                <a href="https://www.tiktok.com/@sorteoanahuac_oficial" target="_blank">
                    <img src="assets/images/sorteo2023/tiktok.png" alt="tiktok" class="img-fluid">
                </a>

            </div>
<!--             
            <div class="col-xl-2 col-md-12 my-auto text-md-center ">
                <h5 class=" mt-4 mb-4">
                    NUESTRA COMUNIDAD
                </h5>
          
                <img  src="assets/images/sorteo2023/serua.png" alt="SERUA" class="img-fluid d-block mx-lg-auto">
                <img src="assets/images/sorteo2023/prepas.png" alt="Prepas Anáhuac" class="img-fluid d-block mx-lg-auto m-4">
                <img  src="assets/images/sorteo2023/red-colegios.png" alt="Red de colegios" class="img-fluid d-block mx-lg-auto">

            </div> -->
            <div class="col-md-12 text-md-center my-auto legals">
                <p >
                    Sorteo Anáhuac A.C. R.F.C.:SAN1501298V6. Av. Universidad Anáhuac #46 Col. Lomas Anáhuac, Huixquilucan, Estado de México C.P.52786. <br>
                    Permiso SEGOB: 20240048PS07 Precio del boleto: $450 


                </p>
            </div>

          
        </div>
    </div>



</footer>

<section class="derechos">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <p class="m-0 p-0">
                    ©SorteoAnáhuac A.C. Algunos derechos reservados.
                </p>
            </div>
        </div>
    </div>
</section>
