import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-convenio',
  templateUrl: './convenio.component.html',
  styleUrls: ['./convenio.component.scss']
})
export class ConvenioComponent implements OnInit {
  title = 'Carta de confidencialidad del Sorteo Anáhuac Octava Edición';
  description = 'Lee los detalles de la carta de confidencialidad del Sorteo Anáhuac Octava Edición.';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    	this.titleService.setTitle(this.title);
		this.metaTagService.updateTag(
			{ name: 'title', content: this.title}
		);
		this.metaTagService.updateTag(
			{ name: 'description', content:  this.description}
		);

		this.metaTagService.updateTag(
				{ property: 'keywords', content: 'Nosotros'}
			);

			this.metaTagService.updateTag(
				{ property: 'og:title', content: this.title}
			);

		this.metaTagService.updateTag(
				{ property: 'og:description', content: this.description}
			);

		this.metaTagService.updateTag(
		{ property: 'og:url', content: 'https://www.sorteoanahuac.mx/nosotros' }
		);

		this.metaTagService.updateTag(
			{ property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/banner2_about.png' }
		  );
		  
		this.metaTagService.updateTag(
			{ name: 'twitter:title', content: this.title}
		);
		this.metaTagService.updateTag(
			{ name: 'twitter:description', content: this.description}
		);

    
  }

}
