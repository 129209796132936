<section class="tickets">
    <div class="container">
        <div class="row">
            <div class="col-md-12 my-auto text-center" data-aos="fade-left" data-aos-easing="ease"  data-aos-duration="3000">
                <h1> Consulta quién te ha comprado</h1>
            </div>
        </div>
    </div>
</section>


<section class="form-tickets" data-aos="fade-up" data-aos-easing="ease"  data-aos-duration="3000">
    <div class="container">
        <div class="row cta-form">
            <div class="col-md-12">
                <form #boletosData="ngForm" novalidate (ngSubmit)="validateData(boletosData)">

                    <div class="form-row  pt-3">
                        <div class="form-group  col-md-3">
                            <label>Fecha  inicio</label>
                            <input type="date" class="form-control" id="Fecha_ini" placeholder="{{boletoCol.Fecha_ini }}" #Fecha_ini="ngModel" ngModel Fecha_ini  name="Fecha_ini" [(ngModel)]="boletoCol.Fecha_ini" required>
                            <div *ngIf="Fecha_ini.invalid &&  boletosData.submitted" class="alert alert-danger">
                                Dato requerido
                            </div>
                        </div>

                        <div class="form-group  col-md-3">
                            <label>Fecha final</label>
                            <input type="date" class="form-control" id="Fecha_fin" placeholder="{{boletoCol.Fecha_fin }}" #Fecha_fin="ngModel" ngModel Fecha_fin  name="Fecha_fin" [(ngModel)]="boletoCol.Fecha_fin" required>
                            <div *ngIf="Fecha_fin.invalid &&  boletosData.submitted" class="alert alert-danger">
                                Dato requerido
                            </div>
                        </div>

                        <div class="form-group  col-md-3 text-center my-auto">
                            <button  type="submit" class="btn-d  mt-3 mb-2 l mr-2">Buscar</button><br>
                            <!-- <a style="color:#fff;" class="btn mb-2 btn-send-all mr-2" (click)="exportexcel()">Exportar a excel</a> -->
                            <a style="color:#fff;" class="btn-d mb-2 mr-2 " (click)="viewAll();">Ver todo</a>
                        </div>

                        <div class="form-group  col-md-3 text-center">
                            <label>Mostrar</label>
                            <select id="cant" (change)="onEditClick($event.target.value)">
                                <option>Seleccione cantidad a mostrar</option>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                                <option value="2000">2000</option>
                            </select>
                        </div>
                    </div>

                </form>
            </div>

            <div class="col-md-12">
                <div class="table-responsive">
                    <table id="excel-table" class="table">
                        <thead>
                            <tr>
                                <th scope="col">Edición de Sorteo</th>
                                <th scope="col">No. Boleto</th>
                                <th scope="col">Fecha_Venta</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Apellido_P</th>
                                <th scope="col">Apellido_M</th>
                                <th scope="col">Correo</th>
                                <th scope="col">Estatus_Boleto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="isData;">
                                <tr *ngFor="let data of dataBoletos">
                                    <td>{{ data.Sorteo }}</td>
                                    <td>{{ data.Boleto }}</td>
                                    <td>{{ data.Fecha_venta }}</td>
                                    <td>{{ data.Nombre }}</td>
                                    <td>{{ data.APaterno }}</td>
                                    <td>{{ data.AMaterno }}</td>
                                    <td>{{ data.Correo }}</td>
                                    <td>{{ data.Estado }}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <div *ngIf="erroData" class="alert alert-danger text-center">
                        {{errorInfo}}
                    </div>
                </div>
            </div>
        </div>


    </div>
</section>