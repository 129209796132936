import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import mixitup from 'mixitup';

@Component({
  selector: 'app-premiocolabora',
  templateUrl: './premiocolabora.component.html',
  styleUrls: ['./premiocolabora.component.scss']
})
export class PremiocolaboraComponent implements OnInit {
  title = 'Premios para colaboradores | Sorteo Anáhuac 2023 - 8ª Edición';
  description = 'Descubre los exclusivos premios para colaboradores del Sorteo Anáhuac 8ª Edición, ¡colabora con nosotros, ayuda y gana!';
  constructor(private titleService: Title, private metaTagService: Meta) { }
  utmUrl =  "";
  // awards = 
	// [

	// 	{
	// 		src: "/assets/images/sorteo2023/premio-colaborador1.jpg", alt: "Primer premio",  title: '$500,000.00', place: '1° Premio' , id: ''
	// 	},

  //   {
	// 		src: "/assets/images/sorteo2023/premio-colaborador2.jpg", alt: "Segundo premio",  title: '$400,000.00', place: '2° PREMIO' , id: ''
	// 	},

  //   {
	// 		src: "/assets/images/sorteo2023/premio-colaborador3.jpg", alt: "Tercer premio",  title: '$300,000.00', place: '3° Premio' , id: ''
	// 	},
  //   {
	// 		src: "/assets/images/sorteo2023/premio-colaborador4.jpg", alt: "Cuarto premio",  title: '$200,000.00', place: '4° Premio' , id: ''
	// 	},
  //   {
	// 		src: "/assets/images/sorteo2023/premio-colaborador5.jpg", alt: "Primer 5-9",  title: '$100,000.00', place: '5° al 9° Premio' , id: 'premios_cta2'
	// 	},

  //   {
	// 		src: "/assets/images/sorteo2023/premio-colaborador6.jpg", alt: "Primer 10-24",  title: '$60,000.00', place: '10° al 24° Premio' , id: ''
	// 	},

  //   {
	// 		src: "/assets/images/sorteo2023/premio-colaborador7.jpg", alt: "Primer 25-34",  title: '$15,000.00', place: '25° AL 34° PREMIO' , id: ''
	// 	},

  //   {
	// 		src: "/assets/images/sorteo2023/premio-colaborador8.jpg", alt: "Primer 35-44",  title: '$10,000.00', place: '35° AL 44° PREMIO' , id: ''
	// 	},

  //   {
	// 		src: "/assets/images/sorteo2023/premio-colaborador9.jpg", alt: "Primer 45-54",  title: '$6,000.00', place: '45° AL 54° PREMIO' , id: ''
	// 	},

  //   {
	// 		src: "/assets/images/sorteo2023/premio-colaborador10.jpg", alt: "Primer 55-64",  title: '$4,000.00', place: '55° AL 64° PREMIO' , id: ''
	// 	},

  //   {
	// 		src: "/assets/images/sorteo2023/premio-colaborador11.jpg", alt: "Primer 65-433",  title: '$2,000.00', place: '65° AL 433° PREMIO' , id: ''
	// 	},

		 
	
	// ];

  ngOnInit(): void {
     /*utm */
     let utmTem= localStorage.getItem('urlUtm');
     if(utmTem)this.utmUrl =utmTem;
     /* fin utm */
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'description', content: this.description }
    );

    this.metaTagService.updateTag(
      { property: 'keywords', content: ''}
    );

    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title}
    );

    this.metaTagService.updateTag(
      { property: 'og:description', content: this.description  }
    );

    this.metaTagService.updateTag(
    { property: 'og:url', content: 'https://www.sorteoanahuac.mx/premios-para-colaboradores' }
    );

    this.metaTagService.updateTag(
      { property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/banner-pc1.jpg' }
    );
      
    this.metaTagService.updateTag(
      { name: 'twitter:title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: this.description }
    );

    let mixer = mixitup('.conainter-photos', {
      selectors: {
          target: '.mix'
      },
      animation: {
          duration: 300
      }
     });

  }

  scrollPage(name) {
    var posicion = $(name).offset().top;
    $("html, body").animate({
        scrollTop: posicion
    }, 2000); 
  }

}
