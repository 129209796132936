<section class="stc-legales">
    <div class="container">
        <div class="row">
            <div class="col-md-12" >
                <div class="container-legal"  data-aos="fade-up" data-aos-easing="ease"data-aos-duration="3000">
                    <h1>
                        MECÁNICA de sorteos oportunos
                    </h1>

                    <h2>
                        MECÁNICA DEL SORTEO PARA CADA UNO DE LOS SORTEOS ACUMULADOS ESPECIALES PARA LOS COMPRADORES Y COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN
                    </h2>

                    <p>
                        La mecánica para el Primer, Segundo y Tercer Sorteo Acumulado Especial para Compradores y Colaboradores Oportunos del SORTEO ANÁHUAC OCTAVA EDICIÓN será por formación de números de conformidad a lo siguiente: 
                    </p>

                    <p>
                        Se presentará 1 (una) mesa que tiene 5 (cinco) ánforas unidas por un eje horizontal de izquierda a derecha. En 4 (cuatro) de las 5 (cinco) ánforas, se colocarán 10 (diez) esferas con la numeración del 0 (cero) al 9 (nueve). Estas 4 (cuatro) primeras ánforas representan las unidades, decenas, centenas y unidades de millar.  El ánfora 5 (cinco), que representa las decenas de millar, contendrá 37 (treinta y siete) esferas con numeración del 0 (cero) al 36 (treinta y seis). En donde de cada ánfora saldrá una esfera formando el número ganador. Cabe señalar que la formación de los números ganadores se hará de derecha a izquierda y su lectura de izquierda a derecha. Los premios se irán otorgando en orden de mayor a menor valor, comenzando con el premio número 1 (uno) y terminando en el premio 3 (tres). Lo cual corresponde al total de premios que se sortearán en cada Sorteo Acumulado 
                        Especial para Compradores y Colaboradores Oportunos de la Octava Edición. Debido al número de premios, se irán obteniendo de la mesa los números premiados, previo anuncio del premio correspondiente para cada número de boleto ganador. En caso de que, al momento en que estén corriendo las esferas, alguna se atore, se repetirá el procedimiento. Si se generara más de una vez el mismo número ganador, la persona recibirá el premio de mayor valor al cual se haya hecho acreedor, toda vez que posteriormente se generarán números para premios de menor valor. Adicionalmente se otorgará un premio a cada colaborador que haya vendido el boleto ganador.
                    </p>

                    <p>
                        Por el sistema de control con el que contamos, de manera inmediata, podemos determinar si el número de boleto digital o físico que se ha formado es vendido (y por lo tanto ganador) o no vendido.
                    </p>

                    <p>
                        Si el boleto es vendido (físico o digital), en ese momento se procederá a dar a conocer el nombre del ganador.
                    </p>

                    <p>
                        Si el boleto es no vendido (físico o digital) en ese momento se procederá a mencionar que el número de boleto es no vendido y se procederá a formar y extraer un nuevo número de boleto y así sucesivamente hasta que haya un ganador.
                    </p>

                    <p>
                        Con respecto a los comprobantes de los boletos ganadores:
                    </p>

                    <p>
                        Para el caso de los boletos físicos, se tomarán los talones (contraparte del boleto) de los ganadores que se encuentran en la sala de operaciones como los comprobantes y se les sacará una copia simple que llevará la rúbrica del inspector asignado mismos que se mostrarán a la entrega del premio. El talón original quedará en resguardo del Sorteo Anáhuac A.C. ya que ese mismo talón, participará en los otros sorteos y el sorteo principal denominado Sorteo Anáhuac Octava Edición. 
                    </p>

                    <p>
                        Para el caso de los boletos digitales se imprimirá en una impresora de oficina, el boleto digital que consta de dos hojas (una con los datos del comprador-ganador) y otra con el boleto ganador. La primera hoja es la que se le dará a la autoridad como comprobante y que llevará la rúbrica del inspector asignado mismos que se mostrarán a la entrega del premio. 
                    </p>

                    <p>
                        Si ganara más de una vez un mismo número, la persona recibirá el premio de mayor valor al cual se haya hecho acreedor, volviendo a sortear el premio de menor valor.
                    </p>

                    <p>
                        Al finalizar el evento y al elaborar las actas correspondientes, el Inspector designado por la Dirección General de Juegos y Sorteos, anexará a dichas actas copia simple de los talones físicos que en su caso hayan sido ganadores, copia simple de la impresión (realizada en impresora de oficina) de la primera hoja del boleto digital que funge como talón.
                    </p>

                </div>
            </div>
        </div>
    </div>
</section>