<section class="info-sorteo-opt">
    <div class="container">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-center flex-column text-center" data-aos="zoom-in" data-aos-duration="3000">
                <img src="/assets/images/sorteo2024/sorteo-opt.png" alt="Sorteo Oportuno" class="img-fluid">
                <h1>
                    sorteos oportunos
                </h1>

            </div>
        </div>
    </div>
</section>


<section class="about-sorteo-opt" data-aos="fade-up"
data-aos-duration="3000">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                    <h2 >
                        1 BOLETO TE DA HASTA 4
                       <span class="d-md-block">
                        OPORTUNIDADES DE GANAR EN NUESTROS SORTEOS
                       </span>
                    </h2>
                    
                    <!-- <p class="subtitle">
                        ¡aquí te explicamos como!
                    </p> -->

                    <p>
                        Cuando compras boletos de <strong>Sorteo Anáhuac automáticamente</strong> cada uno de ellos estará participando en nuestros Sorteos Oportunos.
                        
                        Solo tienes que comprarlo antes de las fechas limite para participar en cada uno de ellos y podrás ganar hasta $250,000 pesos

                        <strong>¡Recuerda que si eres colaborador también ganas!</strong>
                    </p>

                    <p>
                    </p>

 
                    <!-- <a class="btn-stack mt-4 btn-sto" href="https://sorteoanahuaccompra.sorteoanahuac.mx/compra-en-linea-boletos-sorteo-anahuac{{utmUrl}}" target="_blank">
                        Comprar aquí
                    </a> -->
            </div>
        </div>
    </div>
</section>

<section class="days-opt" data-aos="fade-up"
data-aos-duration="3000">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-4 ">
                <img src="/assets/images/sorteo2023/opt1.jpg" class="img-fluid" alt="Primer Sorteo Oportuno">
                <p style="color: #8560d2">
                    COMPRA ANTES DEL 19 DE MAYO DEL 2023
                </p>
            </div> -->

            <!-- <div class="col-md-6 text-center"> 
                <img src="/assets/images/sorteo2023/opt2.jpg" class="img-fluid" alt="Primer Sorteo Oportuno">
                <p style="color: #0085ff">
                    COMPRA ANTES DEL 04 DE AGOSTO DEL 2023
                </p>
            </div> -->

            <!-- <div class="col-md-12 text-center">
                <img src="/assets/images/sorteo2023/opt3.jpg" class="img-fluid" alt="Primer Sorteo Oportuno">
                <p style="color: #ff9d00">
                    COMPRA ANTES DEL 06 DE OCTUBRE DEL 2023
                </p>
            </div> -->

            <div class="col-md-12 text-center">
                <h3>Conoce Nuestros Sorteos</h3>
                <p>¡Participa  y súmate! </p>
            </div>

            <div class="col-lg-3 offset-lg-2">
                <div class="card w-100" >
                    <div class="image-text-box">
                        <div class="row">
                            <div class="col-md-4 my-auto ">
                                <img src="/assets/images/sorteo2024/icon-so1.png" alt="Primer Sorteo Oportuno" class="img-fluid">
                            </div>
                            <div class="col-md-8  my-auto pl-lg-0">
                                <span>Sorteo <span class="d-md-block">oportuno</span></span>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        <small>Primer premio</small>
                        <p>
                            CHEQUE CERTIFICADO DE
                            <span class="d-block">
                                $250,000.00
                            </span>
                        </p>
                        <div class="text-center">
                            <a [routerLink]="['/sorteo-oportuno']" class="btn-card">
                                CONOCE LOS PREMIOS
                            </a>
                        </div>

                        <small class="d-block">24 de mayo 2024</small>
                        <p>
                            11:15 hrs. <br>
                            Prepa Anáhuac México
                        </p>
                        <small class="d-block">
                            Campus Oxford
                        </small>
                        <div class="text-center">
                            <a [routerLink]="['/sorteo-oportuno']"  class="btn-card">
                                PARTICIPA
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-3">
                <div class="card card-a2 w-100" >
                    <div class="image-text-box">
                        <div class="row">
                            <div class="col-md-4 my-auto ">
                                <img src="/assets/images/sorteo2024/icon-so2.png" alt="Primer Sorteo Oportuno" class="img-fluid">
                            </div>
                            <div class="col-md-8  my-auto pl-lg-0">
                                <span>Sorteo <span class="d-md-block">oportuno</span></span>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        <small>Primer premio</small>
                        <p>
                            CHEQUE CERTIFICADO DE
                            <span class="d-block">
                                $250,000.00
                            </span>
                        </p>
                        <div class="text-center">
                            <a [routerLink]="['/sorteo-oportuno']"  class="btn-card">
                                CONOCE LOS PREMIOS
                            </a>
                        </div>

                        <small class="d-block">23 de agosto 2024</small>
                        <p>
                            11:15 hrs. <br>
                            Prepa Anáhuac México
                        </p>
                        <small class="d-block">
                            Campus Cumbres
                        </small>
                        <div class="text-center">
                            <a [routerLink]="['/sorteo-oportuno']"  class="btn-card">
                                PARTICIPA
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="col-lg-3">
                <div class="card card-a3 w-100" >
                    <div class="image-text-box">
                        <div class="row">
                            <div class="col-md-4 my-auto ">
                                <img src="/assets/images/sorteo2024/icon-so3.png" alt="Primer Sorteo Oportuno" class="img-fluid">
                            </div>
                            <div class="col-md-8  my-auto pl-lg-0">
                                <span>Sorteo <span class="d-md-block">oportuno</span></span>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        <small>Primer premio</small>
                        <p>
                            CHEQUE CERTIFICADO DE
                            <span class="d-block">
                                $250,000.00
                            </span>
                        </p>
                        <div class="text-center">
                            <a [routerLink]="['/sorteo-oportuno']"  class="btn-card">
                                CONOCE LOS PREMIOS
                            </a>
                        </div>

                        <small class="d-block">18 de octubre 2024</small>
                        <p>
                            11:15 hrs. <br>
                            Cumbres International
                        </p>
                        <small class="d-block">
                            School Lomas
                        </small>
                        <div class="text-center">
                            <a [routerLink]="['/sorteo-oportuno']"  class="btn-card">
                                PARTICIPA
                            </a>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>



<section class="award-opt" data-aos="fade-up"
data-aos-duration="3000">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h3>
                    premios
                </h3>
            </div>

            <div class="col-md-6 bg-award  text-center">
                
                <div class="line">
                    <h4>
                        comprador
                    </h4>
                    
                    <p>
                        1o Premio
                    </p>
                    <span >
                        $250,000.00
                    </span>
    
                    <p>
                        2o Premio
                    </p>
                    <span >
                        $150,000.00
                    </span>
    
                    <p>
                        3o Premio
                    </p>
                    <span >
                        $100,000.00
                    </span>

                    <p>
                        4o Premio
                    </p>
                    <span >
                        $50,000.00
                    </span>

                    <p>
                        5o Premio
                    </p>
                    <span >
                        $25,000.00
                    </span>
                </div>
  
            </div>

            <div class="col-md-6 bg-award text-center border-0">
                
                <div class="line">
                    <h4>
                        colaborador
                    </h4>
                    
                    <p>
                        1o Premio
                    </p>
                    <span >
                        $100,000.00
                    </span>
    
                    <p>
                        2o Premio
                    </p>
                    <span >
                        $50,000.00
                    </span>
    
                    <p>
                        3o Premio
                    </p>
                    <span >
                        $25,000.00
                    </span>

                    
                    <p>
                        4o Premio
                    </p>
                    <span >
                        $15,000.00
                    </span>

                    
                    <p>
                        5o Premio
                    </p>
                    <span >
                        $5,000.00
                    </span>
                </div>
  
            </div>

        </div>
    </div>
</section>





  
 <!-- <section class="form-oportuno " data-aos="fade-up"
     data-aos-duration="3000">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h2 class="pt-5 mt-5">
                    CONSULTA TU BOLETO
                </h2>
            </div>
            <div class="col-md-12">
                <div class="content-form">
                    <div class="content-fall">
                        <form class="form-all mt-4 "  #dataContact="ngForm" (ngSubmit)="sendDataContact(dataContact)">
                            <div class="row">
                                <div class="col-md-6">
                                    <p>Folio</p>
                                    <input type="text" class="form-control" folio ngModel id="folio" name="folio" #folio="ngModel" [(ngModel)]="dataSorteo.folio"  [ngClass]="{'is-invalid': folio.errors && (folio.touched || folio.dirty), 'is-valid': folio.valid}" placeholder="*Ingresa tu folio:" required  pattern="[0-9]+">
                                    <div  class="text-left invalid-feedback mb-2">
                                        <span>Solo se aceptan números</span>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <p>Correo</p>
                                    <input type="email" [ngClass]="{'is-invalid': correo.errors && (correo.touched || correo.dirty), 'is-valid':correo.valid}"  class="form-control" correo ngModel id="correo" name="correo" #correo="ngModel" [(ngModel)]="dataSorteo.correo" placeholder="*Ingrese su correo" required  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" maxlength="100">        
                                    <div class="text-left invalid-feedback mb-2">
                                        <span>Formato incorrecto</span>
                                    </div> 
                                </div>

                                <div class="col-md-12 mt-4 text-center">
                                    <button type="submit" class="btn-stack border-0">Enviar</button>
                                 </div>
                            </div>
                        </form>

                        <div class="row mt-5">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table id="excel-table" class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Folio</th>
                                                <th scope="col">Correo</th>
                                                <th scope="col">Fecha de compra</th>
                                                <th scope="col">Sorteo oportuno 1</th>
                                                <th scope="col">Sorteo oportuno 2</th>
                                                <th scope="col">Sorteo oportuno 3</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                           
                                            <ng-container *ngIf="isData && !progress">
                                                <tr >
                                                    <td>{{ dataAll.nombre }}</td>
                                                    <td>{{ dataAll.folio }}</td>
                                                    <td>{{ dataAll.correo }}</td>
                                                    <td>{{ dataAll.dtCompra }}</td>

                                                    <ng-container *ngIf="primerSorteo;else opt0;">
                                                        <td style="background-color: #82cdb6;">
                                                            Participando
                                                            <a (click)="generatePDF(1);" class="btn-d">Generar PDF</a>
                                                        </td>
                                                       
                                                    </ng-container>
                                                    <ng-template #opt0> <td style="background-color: #f7877a;">No valido</td> </ng-template>
                                                    
                                                    <ng-container *ngIf="segundoSorteo;else opt1;">
                                                        <td style="background-color: #82cdb6;">
                                                            Participando
                                                            <a (click)="generatePDF(2);" class="btn-d" >Generar PDF</a>
                                                        </td>
                                                        
                                                    </ng-container>
                                                    <ng-template #opt1> <td  style="background-color: #f7877a;">No valido</td> </ng-template>

                                                    <ng-container *ngIf="tercerSorteo;else opt2;">
                                                        <td style="background-color: #82cdb6;">
                                                            Participando
                                                            <a (click)="generatePDF(3);" class="btn-d" >Generar PDF</a>
                                                        </td>
                                                       
                                                    </ng-container>
                                                    <ng-template #opt2> <td  style="background-color: #f7877a;">No valido</td> </ng-template>
                                                    
                                                   
                                                </tr>                                      
                                            </ng-container>

                                            
                                        </tbody>
                                        
                                    </table>
                                    <div class="text-center">
                                        <ng-container *ngIf="progress">
                                            <div class="spinner-border text-primary mt-2 mb-2" role="status" style="color: #f35f44 !important;">
                                              <span class="sr-only">Loading...</span>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div *ngIf="errorData" class="alert alert-danger" role="alert">
                                        No existen datos.
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        


    </div>
</section>   -->