import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Boletocolab } from '../models/boletocolab';
import { Postal } from '../models/postal';
import { Contact } from '../models/contact';

@Injectable({
  providedIn: 'root'
})
export class PasaLavozService {
  //PROD



  //LOCAL
  //private apiURL = 'http://localhost:2393/api';
  //private ts = 'GCSaTPT6rUlfbDAriV5i4kIoS0w0d3oCXAh6v';

  
    //TEST
  private  apiURL = 'https://serviciosapp.sorteoanahuac.mx/api';

  //private ts = 'TDBHNjNuczNDdVIxVDNUQCNLM05CVFIqMjI0JTUzYzgzVA==';

  //PROD
  private ts = 'dDBrM25EM1MzZ1U3SWRARDlAclFTM3ZpQzAyJCUjQTlJcyRlIzNQNXIwZFU1NTFPbmMyMXNUM200YXM=';


  constructor(private http: HttpClient) { }

  getToken() {
    // const headers = new HttpHeaders({
    //   'Authorization': 'Bearer ' + this.ts
    // });
    const body = {
      "correo": "emmanuel.sonda.batun@gmail.com",
      "tipo_usuario": "0",
      "tipo_seguridad": this.ts
    };
    return this.http.post(`${this.apiURL}/Seguridad/Token`, body);
  }

  getLiga(idColab: string, nombre: string) {
    return this.http.get(`${this.apiURL}/pasalavoz/GeneraLiga/${idColab}/${nombre}`);
  }

  getBoletosColab(boletoCol: Boletocolab) {
    //console.log(boletoCol);
    return this.http.post(`${this.apiURL}/PasaLaVoz/RegistroVentas`, boletoCol);
  }

  sendPostal(postal: Postal, tokenS: string) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + tokenS
    });
    return this.http.post(`${this.apiURL}/pasalavoz/EnviarPostal`, postal, { headers });
  }

  getPostal() {
    return this.http.get(`${this.apiURL}/PasaLaVoz/Postales`);
  }

  sendConact(contact: Contact, tokenS: string) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + tokenS
    });
    return this.http.post(`${this.apiURL}/pasalavoz/CorreoContacto`, contact, { headers });
  }
}
