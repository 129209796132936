<section class="sorteo-egresados-anahuac amigo">
    <div class="container">
        <div class="row">

            <div class="col-md-4 my-auto">
                <img src="/assets/images/sorteo2024/egresados/img-left1.png" class="img-fluid">
            </div>

            <div class="col-md-4 my-auto text-center" data-aos="zoom-in">
                <h1>
                    SORTEO AMIGO

 
                    <span class="paragraph d-block">
                      EGRESADO 2024
                    </span>
                </h1>
            </div>

            <div class="col-md-4 my-auto">
                <img src="/assets/images/sorteo2024/egresados/img-right3.png" class="img-fluid">
            </div>

        </div>
    </div>
</section>


<section class="stc-winners-opt amigo">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h2>
                    LISTA OFICIAL DE PARTICIPANTES
                    <span class="d-block">
                        SORTEO  AMIGO EGRESADO 2024
                    </span>
                </h2>
            </div>
            <div class="col-md-12">
                <div class="text-center" style="overflow-x: auto;">
                    <label for="search" class="mt-5" style="text-align: left !important; width: 100%; font-weight: 600; font-size: 18px;">Buscar:</label>
                    <input id="search" type="text" class="form-control mb-3" placeholder="Ingresa matrícula, nombre o folio"
                        [(ngModel)]="searchText" (input)="filterData()" />
                    <table class="table table-striped">
                        <thead class="text-center">
                            <!-- <tr class="text-center">
                        <th class="border-none" colspan="10">Participantes Nuevos</th>
                      </tr> -->
                            <tr class="text-center cols">
                                <th scope="col"><span>FOLIO</span></th>
                                <th scope="col"><span>Nombre</span></th>
                                <th scope="col"><span>Número de Expediente</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center"
                                *ngFor="let participante of filteredData | paginate: { itemsPerPage: 21, currentPage: p }">
                                <td>{{ participante.folio_participacion }}</td>
                                <td>{{  getFirstName(participante.nombre_pila) }}</td>
                                <td>{{ participante.matricula }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente">
                    </pagination-controls>
                </div>
            </div>


            <!-- Repite lo mismo para las otras dos tablas -->


           
            <div class="col-12 mt-5 pt-0 footer-egr">
                <p class="font-bold">
                    SORTEO AMIGO EGRESADO 2024
                </p>
                <p>
                    Los premios serán pagados y entregados de conformidad con lo establecido en las bases de Sorteo, <strong>
                        los premios menores a 1,500 (UMA). $162,855.00 (Ciento Sesenta y dos mil ochocientos cincuenta y cinco pesos 00/100 m.n.), 
                    </strong>se realizará dentro de los 20 (veinte) días hábiles contados a partir de la celebración del sorteo, en Avenida Universidad Anáhuac N° 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan, Estado de México, piso 7 del edificio de Biblioteca, en horario de lunes a jueves de 10:00 a 18:00 hrs. y los viernes de 10:00 a 14:00 hrs. 
                </p>

                <p>
                    Para más información sobre dónde reclamar la entrega de los premios, documentos necesarios para recibirlos diríjase a: www.sorteoanahuac.mx, o a la oficina del Sorteo Anáhuac, A.C. ubicada en la calle “Av. Universidad Anáhuac no. 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan, Estado de México o al teléfono: 01(55) 5328-8096 ext 2137.
                </p>

                <p>
                    Las personas que resulten ganadoras deberán presentar una identificación oficial vigente con fotografía (credencial INE, pasaporte), comprobante de domicilio (no mayor a tres meses), CURP y constancia actualizada de su RFC.
                </p>
                <p>
                    En caso de queja derivada de este sorteo, la autoridad competente es la Dirección General de Juegos y Sorteos de la Secretaría de Gobernación ubicada en la calle de Versalles 49, piso 2, Col. Juárez, Del. Cuauhtémoc, C.P. 06600, Ciudad de México o bien comunicarse al teléfono: 01(55) 5209 8800.
                </p>

                <p>
                    <strong>PERMISO SEGOB 20240094PS08.</strong>
                </p>


            </div>


        </div>
    </div>
</section>