import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-premioprinc',
	templateUrl: './premioprinc.component.html',
	styleUrls: ['./premioprinc.component.scss']
})
export class PremioprincComponent implements OnInit {
	link;
	linkShare;
	select = false;
	session = false;
	mensaje = "";
	mensaje2 = "";
	title2 = 'Premios del Sorteo Anáhuac 2023 - 8ª Edición. !Participa y gana!';
	description = ''
	utmUrl = "";
	cancun = [
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(1).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(2).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(3).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(4).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(5).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(6).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(7).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(8).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(9).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(10).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(11).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(12).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(13).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(14).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(15).jpg"
		},
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(16).jpg"
		}
		,
		{
			src: "/assets/images/sorteo2024/cancun/cancun-(17).jpg"
		}
	];
	

	mexico = [
		{
			src: "/assets/images/sorteo2024/mexico/mexico-(1).jpg"
		},
		{
			src: "/assets/images/sorteo2024/mexico/mexico-(2).jpg"
		},
		{
			src: "/assets/images/sorteo2024/mexico/mexico-(3).jpg"
		},
		{
			src: "/assets/images/sorteo2024/mexico/mexico-(4).jpg"
		},
		{
			src: "/assets/images/sorteo2024/mexico/mexico-(5).jpg"
		},
		{
			src: "/assets/images/sorteo2024/mexico/mexico-(6).jpg"
		},
		{
			src: "/assets/images/sorteo2024/mexico/mexico-(7).jpg"
		},
		{
			src: "/assets/images/sorteo2024/mexico/mexico-(8).jpg"
		},
		{
			src: "/assets/images/sorteo2024/mexico/mexico-(9).jpg"
		}
	];
	

	merida =
		[

			{
				src: "/assets/images/sorteo2024/merida/merida-(1).jpg"
			},
			{
				src: "/assets/images/sorteo2024/merida/merida-(2).jpg"
			},
			{
				src: "/assets/images/sorteo2024/merida/merida-(3).jpg"
			},
			{
				src: "/assets/images/sorteo2024/merida/merida-(4).jpg"
			},
			{
				src: "/assets/images/sorteo2024/merida/merida-(5).jpg"
			},
			{
				src: "/assets/images/sorteo2024/merida/merida-(6).jpg"
			},
			{
				src: "/assets/images/sorteo2024/merida/merida-(7).jpg"
			},
			{
				src: "/assets/images/sorteo2024/merida/merida-(8).jpg"
			},
			{
				src: "/assets/images/sorteo2024/merida/merida-(9).jpg"
			},


		];


	imageSources = [
		{ src: "/assets/images/sorteo2024/otros1.jpg", videoId: "BRste7e9Ozw" },
		{ src: "/assets/images/sorteo2024/otros2.jpg", videoId: "BRste7e9Ozw" },
		{ src: "/assets/images/sorteo2024/otros3.jpg", videoId: "BRste7e9Ozw" },
		{ src: "/assets/images/sorteo2024/otros4.jpg", videoId: "BRste7e9Ozw" },
		{ src: "/assets/images/sorteo2024/otros5.jpg", videoId: "BRste7e9Ozw" },
		{ src: "/assets/images/sorteo2024/otros6.jpg", videoId: "BRste7e9Ozw" },
		{ src: "/assets/images/sorteo2024/otros7.jpg", videoId: "BRste7e9Ozw" },
		{ src: "/assets/images/sorteo2024/otros8.jpg", videoId: "BRste7e9Ozw" }
	];



	


	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		dots: false,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 4000,

	};

	//   slides = [
	//     { url: 'YXLDYVFfwYE' },
	//     { url: 'A4Go8T6xMYY' },
	// 	{ url: 'HNMXpe6JBOY' },
	// 	{ url: 'yCspgwNYKcU' },
	// 	{ url: '6_wD21NiBgI' },
	// 	{ url: '9cxFEfdkDpc' },
	// 	{ url: '8M6nx47G3_E' },
	// 	{ url: '-EgtWPf9tOU' },
	// 	{ url: 'ZjmBydmrUVg' },
	// 	{ url: 'Tf67Vz6HurA' },
	// 	{ url: 'FPN7VIjT4J8' },
	//     // Agrega más elementos según tus necesidades
	//   ];

	isHovering: boolean = false;
	player: any;
	activeButton: string = '';
	isPremio1Active = false;
	isPremio2Active = false;
	isPremio3Active = false;
	isPremio4Active = false;


	constructor(private router: Router, private titleService: Title, private metaTagService: Meta, private renderer: Renderer2, private elementRef: ElementRef) {

	}


	
    ngAfterViewInit(): void {
        const containers = this.elementRef.nativeElement.querySelectorAll('.video-container');

        containers.forEach(container => {
            const iframe = container.querySelector('.video-iframe') as HTMLIFrameElement;
            const thumbnail = container.querySelector('.video-thumbnail') as HTMLImageElement;

            this.renderer.listen(thumbnail, 'mouseenter', () => this.reproducirVideo(iframe, thumbnail));
            this.renderer.listen(thumbnail, 'mouseleave', () => this.detenerVideo(iframe, thumbnail));
        });
    }

    reproducirVideo(iframe: HTMLIFrameElement, thumbnail: HTMLImageElement): void {
        const originalSrc = iframe.src;
        iframe.src = originalSrc + '?autoplay=1';
        thumbnail.style.opacity = '0';
    }

    detenerVideo(iframe: HTMLIFrameElement, thumbnail: HTMLImageElement): void {
        const originalSrc = iframe.src.split('?')[0];
        iframe.src = originalSrc;
        thumbnail.style.opacity = '1';
    }

	

	@HostListener('window:scroll', ['$event'])
	onScroll(event: any): void {
		// Obtenemos el elemento premio1 por su ID
		const premio1Element = document.getElementById('premio1');

		if (premio1Element) {
			const rect = premio1Element.getBoundingClientRect();
			// Verificamos si el elemento premio1 está en el viewport
			if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
				this.isPremio1Active = true;
			} else {
				this.isPremio1Active = false;
			}
		}

		const premio2Element = document.getElementById('premio2');

		if (premio2Element) {
			const rect = premio2Element.getBoundingClientRect();
			// Verificamos si el elemento premio1 está en el viewport
			if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
				this.isPremio2Active = true;
			} else {
				this.isPremio2Active = false;
			}
		}

		const premio3Element = document.getElementById('premio3');

		if (premio3Element) {
			const rect = premio3Element.getBoundingClientRect();
			// Verificamos si el elemento premio1 está en el viewport
			if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
				this.isPremio3Active = true;
			} else {
				this.isPremio3Active = false;
			}
		}

		const premio4Element = document.getElementById('otros');

		if (premio4Element) {
			const rect = premio4Element.getBoundingClientRect();
			// Verificamos si el elemento premio1 está en el viewport
			if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
				this.isPremio4Active = true;
			} else {
				this.isPremio4Active = false;
			}
		}
	}

	ngOnInit(): void {
		//(<any>$('#modalVideoPremios')).modal('show');
		/* videos */




		/*utm */
		let utmTem = localStorage.getItem('urlUtm');
		if (utmTem) this.utmUrl = utmTem;
		/* fin utm */
		this.titleService.setTitle(this.title2);
		this.metaTagService.updateTag(
			{ name: 'title', content: this.title2 }
		);
		this.metaTagService.updateTag(
			{ name: 'description', content: this.description }
		);

		this.metaTagService.updateTag(
			{ property: 'keywords', content: '' }
		);

		this.metaTagService.updateTag(
			{ property: 'og:title', content: this.title2 }
		);

		this.metaTagService.updateTag(
			{ property: 'og:description', content: this.description }
		);

		this.metaTagService.updateTag(
			{ property: 'og:url', content: 'https://www.sorteoanahuac.mx/premios-sorteo-anahuac' }
		);

		this.metaTagService.updateTag(
			{ property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/award1/primer-lugar.jpg' }
		);

		this.metaTagService.updateTag(
			{ name: 'twitter:title', content: this.title2 }
		);
		this.metaTagService.updateTag(
			{ name: 'twitter:description', content: this.description }
		);
	}


	scrollPage(name) {
		var posicion = $(name).offset().top;
		$("html, body").animate({
			scrollTop: posicion
		}, 2000);
	}


}


