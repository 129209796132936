<section class="sorteo-egresados-anahuac amigo">
    <div class="container">
        <div class="row">

            <div class="col-md-7 my-auto text-center">
                <h1 style="font-size: 52px;">


                    <span class="paragraph d-block"
                        style=" background-color: #f8ae00;  font-size: 52px;  margin: auto;">
                        GANADORES SORTEO
                        EGRESADOS Y SEGUNDO SORTEO OPORTUNO
                    </span>
                </h1>
            </div>



            <div class="col-md-5 my-auto">
                <img src="/assets/images/sorteo2024/egresados/img-right-so2.png" class="img-fluid">
            </div>

        </div>
    </div>
</section>


<section class="stc-legales ganadores-so2">
    <div class="container">
        <div class="row">

            <div class="col-md-6 " data-aos="zoom-in">
                <div class="card W-100">
                    <div class="card-body">
                        <h3 class="card-title text-center mt-3">Lista de ganadores Sorteo Egresados 2024</h3>
                        <p>
                            <strong>¡Consulta la lista de ganadores aquí!</strong>
                        </p>
                        <p>
                            Encuentra a los afortunados gandores de este emocionante sorteo.
                        </p>

                        <div class="text-center mt-4 mb-3">
                            <a routerLink="/lista-ganadores-sorteo-egresados-2024" 
                                class="btn-stack">Consultar
                            </a>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-md-6 " data-aos="zoom-in">
                <div class="card W-100">
                    <div class="card-body">
                        <h3 class="card-title text-center mt-3">Lista de ganadores 2° Sorteo Oportuno 2024</h3>
                        <p>
                            <strong>¡Consulta la lista de ganadores aquí!</strong>
                        </p>
                        <p>
                            Encuentra a los afortunados gandores de este emocionante sorteo.
                        </p>

                        <div class="text-center mt-4 mb-3">
                            <a routerLink="/lista-ganadores-segundo-sorteo-oportuno-2024" 
                                class="btn-stack">Consultar
                            </a>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</section>