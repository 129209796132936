import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { GaleriaComponent } from './components/galeria/galeria.component';
import { PremiocolaboraComponent } from './components/premiocolabora/premiocolabora.component';
import { PremioprincComponent } from './components/premioprinc/premioprinc.component';
import { RemanentesComponent } from './components/remanentes/remanentes.component';
//NO BORRAR
import { FormComponent } from './components/form/form.component';
import { ColaboraFormComponent } from './components/colabora-form/colabora-form.component';
import { ContactComponent } from './components/contact/contact.component';
import { ReglamentobecarioComponent } from './components/reglamentobecario/reglamentobecario.component';
import { PrivacidadComponent } from './components/privacidad/privacidad.component';
import { TerminosComponent } from './components/terminos/terminos.component';
import { ConvenioComponent } from './components/convenio/convenio.component';
import { BasesorteoComponent } from './components/basesorteo/basesorteo.component';
import { MecanicaComponent } from './components/mecanica/mecanica.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { DatesComponent } from './components/dates/dates.component';
import { RecuperarPassComponent } from './components/recuperar-pass/recuperar-pass.component';
import { MenuLoginComponent } from './components/menu-login/menu-login.component';
import { PostalComponent } from './components/postal/postal.component';
import { ComparteComponent } from './components/comparte/comparte.component';
import { ComparteTextComponent } from './components/comparte-text/comparte-text.component';
import { ConsultaBoletosComponent } from './components/consulta-boletos/consulta-boletos.component';
import { BlogComponent } from './components/blog/blog.component';
import { VentamesessininteresesComponent } from './components/ventamesessinintereses/ventamesessinintereses.component';
import { BasesoportunosComponent } from './components/basesoportunos/basesoportunos.component';
import { ParticiparSorteoOportunoComponent } from './components/participar-sorteo-oportuno/participar-sorteo-oportuno.component';

import { ThanksColaboradorComponent } from './components/thanks-colaborador/thanks-colaborador.component';
import { ThanksEgresadoComponent } from './components/thanks-egresado/thanks-egresado.component';

import { FormwinnersComponent } from './components/formwinners/formwinners.component';
import { LoginComponent } from './components/login/login.component';
import { FormularioDocumentosComponent } from './components/formulario-documentos/formulario-documentos.component';


// import { PuntosdeventaComponent } from './components/puntosdeventa/puntosdeventa.component';

import { ListaegresadosComponent } from './components/listaegresados/listaegresados.component';
import { GanadoressorteosoportunosComponent } from './components/ganadoressorteosoportunos/ganadoressorteosoportunos.component';
//import { GanadoresPrincipalesComponent } from './components/ganadores-principales/ganadores-principales.component';
import { ParticipantessorteoptComponent } from './components/participantessorteopt/participantessorteopt.component';
import { BasesegresadosComponent } from './components/basesegresados/basesegresados.component';
import { PuntosdeventaComponent } from './components/puntosdeventa/puntosdeventa.component';
import { Participantesso2Component } from './components/participantesso2/participantesso2.component';
import { MainloginComponent } from './components/mainlogin/mainlogin.component';
import { DocumentosComponent } from './components/documentos/documentos.component';
import { GalerianuevaComponent } from './components/galerianueva/galerianueva.component';
import { ParticipantesEgresadosComponent } from './components/participantes-egresados/participantes-egresados.component';
import { ParticipantesLealtadComponent } from './components/participantes-lealtad/participantes-lealtad.component';
import { ParticipantesAmigoComponent } from './components/participantes-amigo/participantes-amigo.component';
import { ParticipantesSONComponent } from './components/participantes-son/participantes-son.component';
import { Ganadorso2Component } from './components/ganadorso2/ganadorso2.component';
import { GanadorresegresadosComponent } from './components/ganadorresegresados/ganadorresegresados.component';
import { GanadorSo3Component } from './components/ganador-so3/ganador-so3.component';



const routes: Routes = [
    { path: 'home', component:HomeComponent},
    { path: 'sorteo-egresado-universidad-anahuac', component: FormComponent },
    { path: 'premios-sorteo-anahuac', component: PremioprincComponent },
    { path: 'premios-para-colaboradores', component: PremiocolaboraComponent },
    //{ path: 'blog', component: BlogComponent },
    { path: 'contacto', component: ContactComponent },
    { path: 'preguntas-frecuentes', component: FaqsComponent },
    { path: 'fechas-importantes', component: DatesComponent },
    { path: 'formulario-registro-colaboradores', component: ColaboraFormComponent },
    { path: 'nosotros', component: NosotrosComponent},
    { path: 'remanentes', component: RemanentesComponent },
    { path: 'mecanica', component: MecanicaComponent },
    // { path: 'galeria', component: GaleriaComponent },
    { path: 'galeria', component: GalerianuevaComponent },
    
    //legales
    { path: 'terminos-y-condiciones', component: TerminosComponent },
    { path: 'reglamento-becario', component: ReglamentobecarioComponent },
    { path: 'aviso-de-privacidad', component: PrivacidadComponent },

    { path: 'carta-de-confidencialidad', component: ConvenioComponent },
    
    { path: 'bases-del-sorteo', component: BasesorteoComponent },
    //{ path: 'bases-de-sorteos-oportunos', component: BasesoportunosComponent },

    { path: 'venta-meses-sin-intereses', component: VentamesessininteresesComponent },
    
    /*PASA LA VOZ */
    { path: 'recuperar-contrasena', component: RecuperarPassComponent },
    { path: 'menu-login', component: MenuLoginComponent },
    { path: 'postal', component: PostalComponent },
    { path: 'comparte', component: ComparteComponent },
    { path: 'comparte-contenido', component: ComparteTextComponent },
    { path: 'consulta-boletos', component: ConsultaBoletosComponent },

    { path: 'sorteo-oportuno', component: ParticiparSorteoOportunoComponent },
    
    //{ path: 'sorteo-egresado-universidad-anahuac-gracias', component: ThanksEgresadoComponent },
    { path: 'formulario-registro-colaboradores-gracias', component: ThanksColaboradorComponent },
    /* FIN SORTEO */
    /*
    { path: 'formulario-ganadores', component:  FormwinnersComponent },
    { path: 'login', component:  LoginComponent },
    
    { path: 'documentos-ganadores', component:  DocumentosComponent },
    { path: 'formulario-documentos', component:  FormularioDocumentosComponent },
    { path: 'login-ganadores', component:  MainloginComponent },
    
    */

   
    
    //{ path: 'lista-participantes-sorteo-egresados', component: ListaegresadosComponent },
  
    //{ path: 'bases-del-sorteo-egresados', component: BasesegresadosComponent },
    
    
    // { path: 'lista-participantes-tercer-sorteo-oportuno', component: Participantesso2Component },
    // { path: 'lista-participantes-sorteo-egresados-anahuac-2024', component: ParticipantesEgresadosComponent },
    // { path: 'lista-participantes-sorteo-egresados-lealtad-2024', component: ParticipantesLealtadComponent },
    // { path: 'lista-participantes-sorteo-egresados-amigo-referido-2024', component: ParticipantesAmigoComponent },
    { path: 'lista-ganadores-sorteo-egresados-2024', component: GanadorresegresadosComponent },

    // { path: 'ganadores-sorteos', component:  PuntosdeventaComponent },
   
    //{ path: 'lista-participantes-primer-sorteo-oportuno', component: ParticipantessorteoptComponent },
    { path: 'lista-ganadores-primer-sorteo-oportuno-2024', component: GanadoressorteosoportunosComponent },
    { path: 'lista-ganadores-segundo-sorteo-oportuno-2024', component: Ganadorso2Component },

    { path: 'lista-participantes-tercer-sorteo-oportuno-2024', component: ParticipantesSONComponent },
    { path: 'lista-ganadores-tercer-sorteo-oportuno-2024', component: GanadorSo3Component },




    {path :'**', redirectTo:'home',  pathMatch: 'full'},
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

  link = "";
  link2 : any;
  url = "https://www.sorteoanahuac.mx";  
  param_url : any;
  flag = false;
  constructor(private router: Router){
    this.link = window.location.href;

    //separar por ? comprobamos si tiene variables con esto, pero solo obtenemos en path osea /nostros?utm=test
    this.link.includes("#") ? this.flag = true :  this.flag = false;
    if(this.flag){
      this.link = this.link.replace("/#/", '/');
      this.link2 = this.link.split("?");
      //una vez hecho recogemos la primera quedando /nosotros, este pueden ser comparado y al final de las urls añadimos la varible en caso de no tener no pasa nada
      //console.log(this.link);
      //console.log(this.link2);
      this.param_url = this.link2[1];
  
      if(this.param_url)this.param_url = this.param_url.split("=");
      //console.log("dsadas");
      //console.log(this.link2[0]);
      switch(this.link2[0]) { 
        case this.url+"/": { 
            this.router.navigate(['/home']);
            break; 
        } 

        case this.url+"/home": { 
            this.router.navigate(['/home']);
            break; 
        } 
  
        case this.url+"/colabora-formulario": { 
            this.router.navigate(['/formulario-registro-colaboradores']);
            break; 
        }
  
        case this.url+"/nosotros": { 
            this.router.navigate(['/nosotros'] );   
            break; 
        }
  
        case this.url+"/premio-principal": { 
            this.router.navigate(['/premios-sorteo-anahuac'] );   
            break; 
        }
  
        case this.url+"/primer-lugar": { 
            this.router.navigate(['/premio-primer-lugar-depto-cancun'] );   
            break; 
        }
  
        case this.url+"/segundo-lugar": { 
            this.router.navigate(['/premio-segundo-lugar-depto-edomex'] );   
            break; 
        }
  
        case this.url+"/tercer-luga": { 
            this.router.navigate(['/premio-tercer-lugar-1-millon-pesos'] );   
            break; 
        }
  
        case this.url+"/lugar-4-5": { 
            this.router.navigate(['/premios-4o-5o-lugar-bmw-x1'] );   
            break; 
        }
  
        case this.url+"/lugar-6-12": { 
            this.router.navigate(['/premios-6o-12o-lugar-500-mil-pesos'] );   
            break; 
        }
  
        case this.url+"/lugar-13-17": { 
            this.router.navigate(['/premios-13o-17o-lugar-mini-cooper-chili'] );   
            break; 
        }
  
        case this.url+"/lugar-18-22": { 
            this.router.navigate(['/premios-18o-22o-lugar-kia-forte'] );   
            break; 
        }
  
        case this.url+"/lugar-23-37": { 
            this.router.navigate(['/premios-23o-37o-lugar-kia-rio'] );   
            break; 
        }
  
        case this.url+"/lugar-38-49": { 
            this.router.navigate(['/premios-38o-49o-lugar-50-mil-pesos'] );   
            break; 
        }
  
        case this.url+"/macbook": { 
            this.router.navigate(['/premios-50o-64o-lugar-macbook-air-13'] );   
            break; 
        }
  
        case this.url+"/iphone-xi": { 
            this.router.navigate(['/premios-65o-79o-lugar-iphone-12'] );   
            break; 
        }
  
        case this.url+"/apple-watch": { 
            this.router.navigate(['/premios-80o-94o-lugar-apple-watch-s6'] );   
            break; 
        }
  
        case this.url+"/ipad": { 
            this.router.navigate(['/premios-95o-109o-lugar-ipad-8-generacion'] );   
            break; 
        }
  
        case this.url+"/cheque-5000": { 
            this.router.navigate(['/premios-110o-200o-lugar-5-mil-pesos'] );   
            break; 
        }
  
        case this.url+"/egresado": { 
            this.router.navigate(['/sorteo-egresado-universidad-anahuac'] );   
            break; 
        }
  
        case this.url+"/formulario": {
            if(this.link2[1]){
              this.router.navigate(['/formulario'], { queryParams: { IDEgresado: this.param_url[1] } });
            }else{
              this.router.navigate(['/formulario']);
            } 
            break; 
        }
  
        case this.url+"/premio-colabora": { 
            this.router.navigate(['/premios-para-colaboradores'] );   
            break; 
        }
  
        case this.url+"/premio-1-a-2": { 
            this.router.navigate(['/premios-colaboradores-1o-2o-lugar'] );   
            break; 
        }
  
        case this.url+"/premio-3": { 
            this.router.navigate(['/premios-colaboradores-3er-lugar'] );   
            break; 
        }
  
        case this.url+"/premio-4-a-5": { 
            this.router.navigate(['/premios-colaboradores-4o-5o-lugar'] );   
            break; 
        }
  
        case this.url+"/premio-6-a-12": { 
            this.router.navigate(['/premios-colaboradores-6o-12o-lugar'] );   
            break; 
        }
  
        case this.url+"/premio-13-a-17": { 
            this.router.navigate(['/premios-colaboradores-13o-17o-lugar'] );   
            break; 
        }
  
        case this.url+"/premio-18-a-22": { 
            this.router.navigate(['/premios-colaboradores-18o-22o-lugar'] );   
            break; 
        }
  
        case this.url+"/premio-23-a-37": { 
            this.router.navigate(['/premios-colaboradores-23o-37o-lugar'] );   
            break; 
        }
  
        case this.url+"/premio-38-a-49": { 
            this.router.navigate(['/premios-colaboradores-38o-49o-lugar'] );   
            break; 
        }
  
        case this.url+"/premio-50-a-64": { 
            this.router.navigate(['/premios-colaboradores-50o-64o-lugar'] );   
            break; 
        }
  
        case this.url+"/premio-65-a-79": { 
            this.router.navigate(['/premios-colaboradores-65o-79o-lugar'] );   
            break; 
        }
  
        case this.url+"/premio-80-a-94": { 
            this.router.navigate(['/premios-colaboradores-80o-94o-lugar'] );   
            break; 
        }
  
        case this.url+"/premio-95-a-109": { 
            this.router.navigate(['/premios-colaboradores-95o-109o-lugar'] );   
            break; 
        }
  
        case this.url+"/premio-110-a-200": { 
            this.router.navigate(['/premios-colaboradores-110o-200o-lugar'] );   
            break; 
        }
  
        case this.url+"/remanentes": { 
            this.router.navigate(['/remanentes'] );   
            break; 
        }
  
        case this.url+"/mecanica": { 
            this.router.navigate(['/mecanica'] );   
            break; 
        }
  
        case this.url+"/bases-del-sorteo": { 
            this.router.navigate(['/bases-del-sorteo'] );   
            break; 
        }
  
        case this.url+"/ganadores": { 
            this.router.navigate(['/ganadores'] );   
            break; 
        }
  
        case this.url+"/noticias": { 
            this.router.navigate(['/noticias'] );   
            break; 
        }
  
        case this.url+"/fechas-importantes": { 
            this.router.navigate(['/fechas-importantes'] );   
            break; 
        }
  
        case this.url+"/preguntas-frecuentes": { 
            this.router.navigate(['/preguntas-frecuentes'] );   
            break; 
        }
  
  
        case this.url+"/contacto": { 
            this.router.navigate(['/contacto'] );   
            break; 
        }
  
        // case this.url+"/blog": { 
        //     this.router.navigate(['/blog'] );   
        //     break; 
        // }
  
        // case this.url+"/blog/1": { 
        //   //console.log("ds");
        //   this.router.navigate(['/informacion-basica-de-sorteo'] );   
        //   break; 
        // }
  
        case this.url+"/reglamento-becario": { 
            this.router.navigate(['/reglamento-becario'] );   
            break; 
        }
  
        case this.url+"/comunicado-oficial-31-enero-2021": { 
            this.router.navigate(['/comunicado-oficial-31-enero-2021'] );   
            break; 
        }
  
        case this.url+"/aviso-de-privacidad": { 
            this.router.navigate(['/aviso-de-privacidad'] );   
            break; 
        }
  
        case this.url+"/terminos-y-condiciones": { 
            this.router.navigate(['/terminos-y-condiciones'] );   
            break; 
        }
  
        case this.url+"/carta-de-confidencialidad": { 
            this.router.navigate(['/carta-de-confidencialidad'] );   
            break; 
        }
  
        case this.url+"/recuperar-contrasena": { 
            if(this.link2[1]){
              this.router.navigate(['/recuperar-contrasena'], { queryParams: { token: this.param_url[1] } });
            }else{
              this.router.navigate(['/recuperar-contrasena']);
            } 
            break; 
        }
  
        case this.url+"/menu-login": { 
            this.router.navigate(['/menu-login'] );   
            break; 
        }
  
        case this.url+"/postal": { 
            this.router.navigate(['/postal'] );   
            break; 
        }
  
        case this.url+"/comparte": { 
            this.router.navigate(['/comparte'] );   
            break; 
        }
  
        case this.url+"/comparte-contenido": { 
            this.router.navigate(['/comparte-contenido'] );   
            break; 
        }
  
        case this.url+"/consulta-boletos": { 
            this.router.navigate(['/consulta-boletos'] );   
            break; 
        }
  
        
  
        // default: { 
        //    //statements; 
        //    break; 
        // } 
     } 
    }
    
  }



}
