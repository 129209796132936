<section class="main-login">
    <div class="container-fluid h-100">
        <div class="row h-100 align-items-center">
            <div class="col-lg-9 d-flex flex-column align-items-center justify-content-center p-0 img-gnd">
                <img src="assets/images/sorteo2023/hero-awards.png" class="img-fluid">
                <img src="assets/images/sorteo2023/logo-negro.png" class="img-fluid"  style="margin-top: -30px; margin-bottom: 30px;"> 
                <p>
                    SORPRENDIENDO A 866 GANADORES
                </p>
            </div>
            
            
            
            <div class="col-lg-3 d-flex flex-column align-items-center" data-aos="fade-up"  data-aos-duration="700" style="z-index: 9;">
                <div class="row text-center">
                    <div class="col-12">
                        <a class="btn-responsive d-lg-none d-block" [ngClass]="{ 'active': showLogin }" (click)="showLoginForm()" >Iniciar sesión</a>

                    </div>
                    <div class="col-12">
                        <a class="btn-responsive d-lg-none d-block" (click)="showRegistration2()" [ngClass]="{ 'active': !showLogin }" >Registro</a>
                    </div>
                </div>
                <app-login *ngIf="showLogin"></app-login>
                <app-formwinners *ngIf="showres"></app-formwinners>

            </div>

        </div>
    </div>
    <div class="responsive-form d-flex align-items-center justify-content-center d-lg-block d-none"
        [ngClass]="{ 'expanded': isExpanded }">
        <a class="btn-float d-lg-block d-none" [ngClass]="{ 'active': !isExpanded }"(click)="showLoginForm()">Iniciar sesión</a>
        <a class="btn-float d-lg-block d-none" [ngClass]="{ 'active': isExpanded }" (click)="showRegistrationForm()">Registro</a>
        <app-formwinners  *ngIf="showRegistration"></app-formwinners>

    </div>
</section>
