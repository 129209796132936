<section class="sorteo-egresados-anahuac so3">
    <div class="container">
        <div class="row">

            <div class="col-md-4 my-auto">
                <img src="/assets/images/sorteo2024/egresados/img-left-so3vf.png" class="img-fluid">
            </div>

            <div class="col-md-4 my-auto text-center" data-aos="zoom-in">
                <h1 style="font-size: 52px;">
                    CONOCE


                    <span class="paragraph d-block" style="font-size: 50px;">
                        A NUESTROS

                    </span>

                    <span class="text d-block">
                        GANADORES
                    </span>
                </h1>

                <p>
                    3° SORTEO OPORTUNO
                </p>
            </div>

            <div class="col-md-4 my-auto">
                <img src="/assets/images/sorteo2024/egresados/mg-right-so3v.png" class="img-fluid">
            </div>

        </div>
    </div>
</section>

<section class="stc-winners-opt so3">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h2 style="background-color: #ff9b00;">
                    LISTA OFICIAL DE GANADORES
                    <span class="d-block">
                        3° SORTEO OPORTUNO 2024
                    </span>
                </h2>
            </div>
            <div class="col-md-12 ">
                <div class=" text-center" style="overflow-x: auto;">

                    <table class="table table-striped ">

                        <thead class="text-center">
                            <tr class="text-center">
                                <th class="border-none" colspan="4">Compradores</th>
                                <th class="border-none" colspan="3">Colaboradores</th>
                            </tr>
                            <tr class="text-center cols">
                                <th scope="col"><span>No. DE PREMIO</span></th>
                                <th scope="col"><span>nombre</span></th>
                                <th scope="col"><span>VALOR</span></th>
                                <th scope="col"><span>NÚMERO DE BOLETO</span></th>
                                <th scope="col"><span>NOMBRE</span></th>
                                <th scope="col"><span>VALOR</span></th>
                                <th scope="col"><span>NÚMERO DE BOLETO </span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center first">
                                <td>1</td>
                                <td>Guillermo Alejandro N. B.</td>
                                <td> $250,000.00 </td>
                                <td>093714</td>
                                <td>Danysha Montserrath N. K.</td>
                                <td>$100,000.00</td>
                                <td>POR VENDER EL BOLETO 093714</td>
                            </tr>
                            <tr class="text-center second">
                                <td>2</td>
                                <td>Elizabeth del Carmen Z. A. </td>
                                <td> $150,000.00</td>
                                <td>146768</td>
                                <td>Elizabeth del Carmen Z. A.</td>
                                <td>$50,000.00</td>
                                <td>POR VENDER EL BOLETO 146768</td>
                            </tr>

                            <tr class="text-center first">
                                <td>3</td>
                                <td>Nissim J. Z.</td>
                                <td>$100,000.00</td>
                                <td>306518</td>
                                <td>Michell L. M.</td>
                                <td>$25,000.00</td>
                                <td>POR VENDER EL BOLETO 306518</td>
                            </tr>

                            <tr class="text-center second">
                                <td>4</td>
                                <td>Angelica Rubi M. C.</td>
                                <td>$50,000.00</td>
                                <td>251853</td>
                                <td>Noemi C. N.</td>
                                <td>$15,000.00</td>
                                <td>POR VENDER EL BOLETO 251853</td>
                            </tr>

                            <tr class="text-center first">
                                <td>5</td>
                                <td>Ariadna A. A.</td>
                                <td>$25,000.00</td>
                                <td>133240</td>
                                <td>Luis Rene A. A.</td>
                                <td>$5,000.00</td>
                                <td>POR VENDER EL BOLETO 133240</td>
                            </tr>


                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-12 footer-egr">
                <p class="font-bold">
                    SORTEO ANÁHUAC NOVENA EDICIÓN
                </p>
               <p>
                El Sorteo Anáhuac celebró el día viernes 18 de octubre de 2024, a las 11:15 horas, el  <strong> Tercer Sorteo Acumulado Especial para Compradores y Colaboradores Oportunos del Sorteo Anáhuac Novena Edición en la  Prepa Anáhuac Cumbres Lomas</strong> con domicilio en Rosedal 50, Lomas de Chapultepec, Miguel Hidalgo, C.P. 11000 Ciudad de México, con la presencia del C. César Iván Dávila García, Inspector de la Dirección General de Juegos y Sorteos, para dar fe de la legalidad del evento.
                De acuerdo con lo establecido en el Permiso No. <strong>20240048PS07</strong> otorgado por la Secretaría de Gobernación a Sorteo Anáhuac, A.C., los resultados de los ganadores, así como los nombres de los colaboradores que se han hecho acreedores a un premio por haber vendido los boletos del premio uno al cinco, han de ser publicados de forma posterior al evento del sorteo en la página de internet https://www.sorteoanahuac.mx/ y nuestras redes sociales a partir del día 19 de octubre de 2024 y de acuerdo a lo registrado ante SEGOB, solo aparecerá los números de los boletos ganadores, el nombre propio de los ganadores con sus iniciales, y la ciudad de residencia de cada un o de ellos, adicionalmente, se publicará la lista impresa con los datos completos de los ganadores en las Instalaciones de la Universidad Anáhuac México Campus Norte, ubicada en la Av. Universidad no. 46 Colonia Lomas de Anáhuac, CP. 52786, Huixquilucan Estado de México.
               </p>

               <p>
                Los premios mayores a 1,500 (UMA). $162,855.00 (Ciento Sesenta y dos mil ochocientos cincuenta y cinco pesos 00/100 m.n.), se solicitará la presencia de un inspector de la DGJyS para la entrega de los mismos, conforme al siguiente calendario de fechas: 31 de octubre de 2024 a las 12:00 horas Entrega del Premio 1 de Comprador del Tercer Sorteo Acumulado Especial de Compradores y Colaboradores Oportunos del SORTEO ANAHUAC NOVENA EDICIÓN. En las instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786.
               </p>

               <p>
                Los premios serán pagados y entregados de conformidad con lo establecido en las bases de Sorteo, los premios menores a 1,500 (UMA), $162,855.00 (Ciento Sesenta y dos mil ochocientos cincuenta y cinco pesos 00/100 m.n.) se realizará dentro de los 20 (veinte) días hábiles contados a partir de la celebración del sorteo, en Avenida Universidad Anáhuac N° 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan, Estado de México, piso 6 del edificio de Biblioteca, en horario de lunes a jueves de 10:00 a 18:00 hrs. y los viernes de 10:00 a 14:00 hrs. 
               </p>

               <p>
                Para más información sobre dónde reclamar la entrega de los premios, documentos necesarios para recibirlos diríjase a: www.sorteoanahuac.mx, o a la oficina del Sorteo Anáhuac, A.C. ubicada en la calle “Av. Universidad Anáhuac no. 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan, Estado de México o al teléfono: 01(55) 5328-8096 ext 2137.
Las personas que resulten ganadoras deberán presentar una identificación oficial vigente con fotografía (credencial INE, pasaporte), Constancia de situación Fiscal de RFC, comprobante de domicilio (no mayor a tres meses) y CURP; el nombre completo escrito en el boleto debe corresponder exactamente con el del talón o con el registro en la APP del Sorteo Anáhuac y su identificación oficial.
En caso de queja derivada de este sorteo, la autoridad competente es la Dirección General de Juegos y Sorteos de la Secretaría de Gobernación ubicada en la calle de Versalles 49, piso 2, Col. Juárez, Del. Cuauhtémoc, C.P. 06600, Ciudad de México o bien comunicarse al teléfono: 01(55) 5209 8800.

               </p>

            </div>

        </div>
    </div>
</section>