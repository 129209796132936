import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reglamentobecario',
  templateUrl: './reglamentobecario.component.html',
  styleUrls: ['./reglamentobecario.component.scss']
})
export class ReglamentobecarioComponent implements OnInit {
  title = 'Reglamento del Sorteo Anáhuac para Becados y Colaboradores';
  description = 'Conoce aquí todos los detalles del reglamento el Sorteo Anáhuac 2023 para becados y colaboradores. ';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    	this.titleService.setTitle(this.title);
		this.metaTagService.updateTag(
			{ name: 'title', content: this.title}
		);
		this.metaTagService.updateTag(
			{ name: 'description', content:  this.description}
		);

		this.metaTagService.updateTag(
				{ property: 'keywords', content: 'Nosotros'}
			);

			this.metaTagService.updateTag(
				{ property: 'og:title', content: this.title}
			);

		this.metaTagService.updateTag(
				{ property: 'og:description', content: this.description}
			);

		this.metaTagService.updateTag(
		{ property: 'og:url', content: 'https://www.sorteoanahuac.mx/nosotros' }
		);

		this.metaTagService.updateTag(
			{ property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/banner2_about.png' }
		  );
		  
		this.metaTagService.updateTag(
			{ name: 'twitter:title', content: this.title}
		);
		this.metaTagService.updateTag(
			{ name: 'twitter:description', content: this.description}
		);

    
  }

}
