
export class GanadoresSorteo {
    nombre: string;
    apellido_paterno: string;
    apellido_materno: string;
    edad: number;
    estado: string;
    municipio: string;
    pais: string;
    cp: string;
    colonia: string;
    calleNumero: string;
    rfc: string;
    folio_boleto: string;
    tipo_ganador: string;
    correo: string;
    password: string;
    curp: string;
   
}