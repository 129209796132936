import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {
  title = 'Nosotros | Sorteo Anáhuac 2023 - 8ª Edición';
  description = 'Al comprar un boleto para el Sorteo Anáhuac apoyas la formación de jóvenes con educación de excelencia. Conócenos y descubre nuestros objetivos.';

  slides = 
	[

		{
			place: "Transparecnia",  img: '/assets/images/sorteo2023/valor1.png' 
		},
		{
			place: "Integridad", img: '/assets/images/sorteo2023/valor2.png' 
		},
		{
			place: "Austeridad",  img: '/assets/images/sorteo2023/valor3.png' 
		},
		{
			place: "Compromiso",  img: '/assets/images/sorteo2023/valor4.png' 
		},
		{
			place: "honestidad",  img: '/assets/images/sorteo2023/valor5.png' 
		},
		 
	
	];
	slideConfig2 = {
		autoplay: true,
		autoplaySpeed: 4000,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		arrows: true,
		fade: true,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				arrows: false,
				dots: true
			  }
			},
		  ]
	};

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    	this.titleService.setTitle(this.title);
		this.metaTagService.updateTag(
			{ name: 'title', content: this.title}
		);
		this.metaTagService.updateTag(
			{ name: 'description', content: this.description }
		);

		this.metaTagService.updateTag(
				{ property: 'keywords', content: 'Nosotros'}
			);

			this.metaTagService.updateTag(
				{ property: 'og:title', content: this.title}
			);

		this.metaTagService.updateTag(
				{ property: 'og:description', content: this.description  }
			);

		this.metaTagService.updateTag(
		{ property: 'og:url', content: 'https://www.sorteoanahuac.mx/nosotros' }
		);

		this.metaTagService.updateTag(
			{ property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/banner2_about.png' }
		  );
		  
		this.metaTagService.updateTag(
			{ name: 'twitter:title', content: this.title}
		);
		this.metaTagService.updateTag(
			{ name: 'twitter:description', content: this.description  }
		);

    
  }



}
