import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private http: HttpClient) { }


  getImages() {

    return this.http.get('http://phpstack-258137-1183389.cloudwaysapps.com/api/gallery/');
  }


}


