<section class="stc-legales">
    <div class="container">
        <div class="row">
            <div class="col-md-12 my-auto" style="padding-top: 100px;">
                <h1>
                    Bases del SORTEO ANÁHUAC NOVENA EDICIÓN
                </h1>
            </div>
        </div>
    </div>
</section>


<section class="stc-legal">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="container-legal" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="3000">
                   
                    <p>
                        Permiso otorgado por la Secretaría de Gobernación con el número <strong>20240048PS07</strong>
                    </p>
                    <p>
                        <strong>Denominación del sorteo:</strong> SORTEO ANÁHUAC NOVENA  EDICIÓN.
                    </p>
                    <p>
                        <strong> Número de boletos emitidos:</strong> 370,000 boletos con folio del 000,000 al 369,999 de los cuales: boletos físicos son 20,000 y boletos digitales son 350,000.
                    </p>
                    <p>
                        <strong>Vigencia del permiso:</strong> del 26 de marzo de 2024 al 21 de noviembre de 2024.
                    </p>

                    <p>
                        <strong>Vigencia de la promoción:</strong>: del 26 de marzo de 2024 al 21 de noviembre de 2024.
                    </p>

                    <p>
                        <strong>Número de premios a otorgar:</strong> Para el sorteo principal 433 premios a comprador y 433 premios a colaborador; para cada uno de los sorteos acumulados 5 para comprador y 5 para colaborador 
                    </p>

                    <p>
                        <strong> Valor del primer premio del sorteo principal:</strong> $10,042,535.00 (Diez millones cuarenta y dos mil quinientos treinta y cinco pesos 00/100 m.n.). Departamento ubicado en Avenida Antonio Enriquez Savignac, Torre Aire Int: UPE 2-30 Condominio Residencial Brezza Lote 4C-4 Manzana 01 localidad Cancún Supermanzana 4-A, Municipio Benito Juárez, Cancún, Quintana Roo, México. Incluye gastos de escrituración.
                        Valor nominal del boleto: 450.00 Pesos. (Cuatrocientos cincuenta pesos 00/100 m.n.)
                        
                    </p>

                    <p>
                        <strong>Valor nominal del boleto: </strong> 450.00 Pesos. (Cuatrocientos cincuenta pesos 00/100
                        m.n.)
                    </p>

                    <p>
                        <strong>Valor total de la emisión: </strong> $166,500,000.00 (Ciento sesenta i seis millones quinientos mil pesos 00/100 m.n.)
                    </p>

                    <p>
                        <strong>Condición de participación: </strong> Todas las personas que compren un boleto físico y/o digital con valor unitario de $450.00 (Cuatrocientos cincuenta pesos 00/100 m.n.) podrán participar en los tres Sorteos Acumulados Especiales para Compradores y Colaboradores Oportunos y en el SORTEO ANÁHUAC NOVENA EDICIÓN. La venta de boletos físicos y boletos digitales será a través de nuestros colaboradores. La venta del boleto digital será por medio de nuestra APP oficial denominada SORTEO ANÁHUAC misma que está disponible para los sistemas operativos IOS y ANDROID. Los boletos también se podrán adquirir en la página oficial de internet de Sorteo Anáhuac A.C. www.sorteoanahuac.mx, donde además se podrá consultar las bases del sorteo y la cantidad de boletos emitidos.
                    </p>

                    <p>
                        De los Sorteos Acumulados Especiales para Compradores y Colaboradores Oportunos de la Novena Edición: Con el mismo número de boleto comprado, participas en el primero, segundo y tercer sorteo acumulado Especial para Compradores y Colaboradores Opotunos de la Novena Edición con base a las fechas de corte establecidas para cada uno, en caso de resultar ganador en alguno de los sorteos mencionados, no es restricción para seguir participando en los sorteos subsecuentes y el principal. 
                    </p>

                    <p>
                        La fechas de corte para cada Sorteo Acumulado serán:
                        Para el Primer Sorteo Acumulado Especial para Compradores y Colaboradores Oportunos del SORTEO ANÁHUAC NOVENA EDICIÓN la fecha límite será hasta el día 20 de mayo de 2024 a las 23:59:59 horas del mismo día. A partir del día 22 de mayo de 2024, también se podrán consultar los folios participantes que son iguales al boleto que pagaron y compraron con el nombre del dueño del boleto de acuerdo a la fecha límite.
                        
                    </p>

                    <p>
                        Para el Segundo Sorteo Acumulado Especial para Compradores y Colaboradores Oportunos del SORTEO ANÁHUAC NOVENA EDICIÓN la fecha límite será hasta el día 19 de agosto del 2024 a las 23:59:59 horas del mismo día. A partir del día 22 de agosto de 2024, también se podrán consultar los folios participantes que son iguales al boleto que pagaron y compraron con el nombre del dueño del boleto de acuerdo a la fecha límite.
                    </p>
                    <p>
                        Para el Tercer Sorteo Acumulado Especial para Compradores y Colaboradores Oportunos del SORTEO ANÁHUAC NOVENA EDICIÓN será hasta el día 14 de octubre del 2024 a las 23:59:59 horas del mismo día. A partir del día 21 de octubre de 2024, también se podrán consultar los folios participantes que son iguales al boleto que pagaron y compraron con el nombre del dueño del boleto de acuerdo a la fecha límite.
                    </p>

                    <h2>
                        Lugar, fecha y hora de los concentrados:
                    </h2>

                    <p>
                        Para cada uno de los sorteos acumulados, se llevará a cabo en la fecha, hora y lugar señalado en
                        el siguiente cuadro:
                    </p>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">24 de mayo de 2024</td>
                                    <td>11:00 horas</td>
                                    <td>En las instalaciones de la Prepa Anáhuac México Campus Oxford con domicilio en Torre Prepa Anáhuac, Av. de las Torres 131, Torres de Potrero, Álvaro Obregón, 01780 Álvaro Obregón, CDMX
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">23 de agosto de 2024</td>
                                    <td>11:00 horas</td>
                                    <td>En las instalaciones de la Prepa Anáhuac México Campus Cumbres con domicilio en Loma del Recuerdo 50, Lomas de Vista Hermosa, Cuajimalpa de Morelos, 05100 Ciudad de México, CDMX</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">18 de octubre de 2024</td>
                                    <td>11:00 horas</td>
                                    <td>En las instalaciones de la Prepa Anáhuac Cumbres Lomas con domicilio en Rosedal 50, Lomas de Chapultepec, Miguel Hidalgo, 11000 Ciudad de México, CDMX</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p>
                        Para el sorteo principal, se llevará a cabo en la fecha, hora y lugar señalado en el siguiente
                        cuadro:
                    </p>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">21 de noviembre del 2024</td>
                                    <td>16:00 horas</td>
                                    <td>En las instalaciones del IEST Anáhuac con domicilio en Av. Dr. Burton E. Grossman 501 Poniente-SECTOR 1, Tampico Altamira, C.P. 89605 Miramar, Tamaulipas</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h2>
                        Lugar, fecha y hora de los sorteos:
                    </h2>

                    <p>
                        Para cada uno de los sorteos acumulados, se llevará a cabo en la fecha, hora y lugar señalado en
                        el siguiente cuadro:
                    </p>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">24 de mayo de 2024</td>
                                    <td>11:15 horas</td>
                                    <td>En las instalaciones de la Prepa Anáhuac México Campus Oxford con domicilio en Torre Prepa Anáhuac, Av. de las Torres 131, Torres de Potrero, Álvaro Obregón, 01780 Álvaro Obregón, CDMX
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">23 de agosto de 2024</td>
                                    <td>11:15 horas</td>
                                    <td>En las instalaciones de la Prepa Anáhuac México Campus Cumbres con domicilio en Loma del Recuerdo 50, Lomas de Vista Hermosa, Cuajimalpa de Morelos, 05100 Ciudad de México, CDMX</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">18 de octubre de 2024</td>
                                    <td>11:15 horas</td>
                                    <td>En las instalaciones de la Prepa Anáhuac Cumbres Lomas con domicilio en Rosedal 50, Lomas de Chapultepec, Miguel Hidalgo, 11000 Ciudad de México, CDMX  </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p>
                        Para el sorteo principal, se llevará a cabo en la fecha, hora y lugar señalado en el siguiente
                        cuadro:
                    </p>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">21 de noviembre de 2024</td>
                                    <td>17:00 horas</td>
                                    <td>En las instalaciones del IEST Anáhuac con domicilio en Av. Dr. Burton E. Grossman 501 Poniente-SECTOR 1, Tampico Altamira, C.P. 89605 Miramar, Tamaulipas</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h2>
                        La entrada es gratuita y abierta a todo público para cada uno de los eventos
                    </h2>

                    <h2>
                        Mecánica del sorteo:
                    </h2>

                    <p>
                        Se realizará por formación de números. Se pondrá a la vista del Inspector que para tal efecto se designe, 3 (tres) mesas las cuales cuentan cada una de ellas con 5 (cinco) ánforas, unidas por un eje horizontal, en las 4 (cuatro) primeras ánforas, de derecha a izquierda, se colocaran 10 (diez) esferas con la numeración del 0 (cero) al 
                        9 (nueve), representaran las unidades, decenas, centenas y unidades de millar, la 5ª (quinta) ánfora, representará las decenas de millar así como las centenas de millar, ya que contendrá 37 (treinta y siete) esferas con numeración del 0 (cero) al 36 (Treinta y seis). La primera mesa con las 5 ánforas iniciará su movimiento giratorio para obtener el número ganador y una vez que se obtenga dicho número, se leerá de izquierda a derecha; de manera casi simultánea, la segunda mesa con 5 ánforas, realizará el mismo movimiento para obtener el número ganador del premio siguiente e igualmente se leerá de izquierda a derecha; de manera casi simultánea, la tercera mesa con 5 ánforas, realizará el mismo movimiento para obtener el número ganador del premio siguiente e igualmente se leerá de izquierda a derecha, las mesas irán sincronizando sus movimientos para formar cada uno de los números ganadores uno por uno de manera consecutiva hasta llegar al número de premio 64. 
                        
                    </p>

                    <p>
                        Los premios del 65 al 433 (369 premios) serán entregados con base a la numeración que coincida
                        con las tres últimas cifras (unidades, decenas y centenas) que formaron el número del primer
                        premio, es decir, serán ganadores por número de terminación teniendo en cuenta los siguientes
                        casos:
                    </p>

                    <ul>
                        <li>
                            El primer premio será declarado como tal por el inspector asignado por la Dirección General de Juegos y Sorteos siempre y cuando, el boleto sea un boleto vendido (físico o digital) y generar así la lista de los boletos ganadores por terminación.
                        </li>
                        <li>
                            Para el caso de que, al sortearse el primer premio, resultara ser un boleto NO VENDIDO, (físico o digital) en ese momento se informará al auditorio presente, que el número de boleto es no vendido y se procederá a formar y extraer un nuevo número de boleto en beneficio de los participantes del sorteo hasta tener un boleto vendido ganador y generar así la lista de los boletos ganadores por terminación.
                        </li>
                        <li>
                            Si el boleto ganador es no concentrado, (físico)al momento del Sorteo, pero se tuviera la certeza de que el mismo fue “vendido” por así aparecer en la base de datos, este será considerado como ganador y se generaría la lista de los ganadores por terminación. Ahora bien, si durante el desarrollo del Sorteo, no se lograra definir el estatus del mismo, se procederá a sacar un número de RESERVA en la misma mesa en que se generó el número de boleto no concentrado. Este número quedará asentado en el acta del sorteo y se procederá a generar la lista de los ganadores por terminación.
                        </li>
                    </ul>

                    <p>
                        Por ejemplo: si el número ganador del primer premio del SORTEO ANÁHUAC NOVENA EDICIÓN fuera el 369,999 y este fuera un boleto vendido, todos los boletos de toda la emisión que terminen en 999 (número formado por las unidades, decenas y centenas), serán acreedores al premio por terminación. Para este caso, se le entregará una lista de los números ganadores al inspector asignado por la DGJyS de la Secretaría de Gobernación. El número de ganadores por terminación es con base a la emisión de boletos que se maneja. Cabe mencionar que si en el rango del premio 2 al premio 64 existiera algún número de boleto vendido ganador que coincida con las tres últimas cifras de terminación (unidades, decenas y centenas) del primer premio, por excepción, se le otorgarán los dos premios, es decir el premio que recibe al ser sorteado, más el premio que corresponde por terminación.
                        Para el caso de que existieran dentro de los 369 boletos ganadores números participantes NO VENDIDOS, los premios que correspondan a estos boletos, se resortearán a través de la la mecánica de formación de números, hasta determinar un ganador. 
                        Para el caso de que una terminación fuera un boleto NO VENDIDO y al ser resorteado cayera en un número que ya obtuvo un premio del 1 al 64, la persona recibirá el premio de mayor valor al cual se haya hecho acreedor, volviendo a sortear el premio de menor valor hasta tener un número de boleto ganador.
                        
                    </p>

                    <p>
                        Por el sistema de control con el que contamos, de manera inmediata podemos determinar si el número de boleto que se ha formado es ganador, no vendido, es no concentrado o si tiene alguna incidencia.
                    </p>

                    <ol>
                        <li>
                            1.	Si el boleto es vendido (físico o digital), en ese momento se procederá a mencionar al auditorio presente en el lugar, que el número de boleto es vendido y se dará a conocer el nombre del ganador y de la ciudad de residencia.
                        </li>
                        <li>
                            2.	Si el boleto es no vendido (físico o digital) en ese momento se procederá a mencionar al auditorio presente, que el número de boleto es no vendido y se procederá a formar y extraer un nuevo número de boleto en beneficio de los participantes del sorteo. Esta formación de número será en la misma mesa donde se está extrayendo las esferas que formarán el número de boleto.
                        </li>
                        <li>
                            3.	Si el boleto es no concentrado (físico), al momento del Sorteo, pero se tuviera la certeza de que el mismo fue “vendido” por así aparecer en la base de datos, este será considerado como ganador; ahora bien, si durante el desarrollo del Sorteo no se lograra definir el estatus del mismo, se procederá a sacar un número de reserva en la misma mesa que se generó el número de boleto no concentrado y en caso de que el boleto no concentrado no haya sido vendido o distribuido, el premio será asignado al número de reserva.
                        </li>
                        <li>
                            4.	Para el caso de que el número del boleto ganador fuera reportado como boleto (físico) con incidencia, se revisará que el acta levantada ante el Ministerio Público por parte del colaborador contenga el número de boleto ganador y se le entregará el premio al dueño del boleto.
                        </li>
      
                    </ol>

                    <p>
                        Entre cada movimiento de las mesas:
                        Si el boleto ganador es digital, inmediatamente a la vista del inspector asignado por la Dirección General de Juegos y Sorteos, de ahora en adelante denominada: DGJYS aparecerá en una pantalla los datos personales del ganador y con la anuencia del mismo inspector se anunciará al auditorio asistente el nombre del ganador. Al final del sorteo se imprimirá en una impresora de oficina el boleto digital o en el momento que designe la autoridad, y se certificará mediante rúbrica del inspector asignado dicha impresión.
                       


                    </p>

                    <p>
                        En caso de que el número ganador cayera en un boleto ganador físico, se procederá a buscar el talón físico digitalizado en el archivo PDF del concentrado, una vez localizado, se procederá a su impresión en una impresora de oficina para que sea validado por el inspector designado. 

                        Tanto para el boleto físico como para el boleto digital, este procedimiento se repetirá tantas veces como sea necesario hasta que se hayan formado los 64 (sesenta y cuatro) números ganadores, otorgándose los premios, en orden de mayor valor a menor valor.
                        En caso de que, al momento en que estén corriendo las esferas, alguna se atore o no se complete la formación del número, se solicitará la autorización del inspector asignado por parte de la DGJYS para regresar las esferas y volver a formar el número ganador. Si una esfera se cae, se colocará en el color que le corresponda ya que el número si se formó. Este proceso se repetirá tantas veces ocurra lo descrito. 

                    </p>

                    <p>
                        Con respecto a los comprobantes de los boletos ganadores:
                    </p>
                    <ul>
                        <li>
                            Para el caso de los boletos físicos, se tomarán los talones (contraparte del boleto) de los
                            ganadores que se encuentran en la sala de operaciones.
                        </li>
                        <li>
                            Para el caso de los boletos digitales se imprimirá en una impresora de oficina, el boleto digital que llega por correo electrónico al comprador-ganador, que consta de dos hojas (una con los datos del comprador-ganador) y otra con el boleto ganador. Estos comprobantes son los que el inspector designado por la autoridad validará y firmará como los boletos ganadores mismos que podrán ser anunciados al público asistente al evento.
                        </li>
                    </ul>

                    <p>
                        Si ganara más de una vez un mismo número, la persona recibirá el premio de mayor valor al cual
                        se haya hecho acreedor, volviendo a sortear el premio de menor valor.
                    </p>

                    <p>
                        Entrega de premios:  Los premios serán pagados y entregados de conformidad con lo establecido en las bases de Sorteo, los premios menores a 1,500 (UMA) $162,855.00 (Ciento Sesenta y dos mil ochocientos cincuenta y cinco pesos 00/100 m.n.), se realizará dentro de los 20 (veinte) días hábiles contados a partir de la celebración del sorteo, en Avenida Universidad Anáhuac N° 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan, Estado de México, piso 7 del edificio de Biblioteca, en horario de lunes a jueves de 10:00 a 18:00 horas. y los viernes de 10:00 a 14:00 horas. 
                    </p>

                    <p>
                        Los premios mayores a 1,500 (UMA) $162,855.00 (Ciento Sesenta y dos mil ochocientos cincuenta y cinco pesos 00/100 m.n.), se solicitará la presencia de un inspector de la DGJyS para la entrega de los mismos, conforme al siguiente calendario de fechas:
                    </p>

                    <p>
                        Para cada uno de los sorteos acumulados, se llevará a cabo en la fecha, hora y lugar señalado en el siguiente cuadro:
                    </p>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">  07 de junio de 2024</td>
                                    <td>12:00 horas</td>
                                    <td>Entrega del Premio 1 de Compradores del Primer Sorteo Acumulado Especial de Compradores y Colaboradores Oportunos del SORTEO ANÁHUAC NOVENA EDICIÓN. Instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row"> 05 de septiembre de 2024</td>
                                    <td>12:00 horas</td>
                                    <td>Entrega del Premio 1 de Compradores del Segundo Sorteo Acumulado Especial de Compradores y Colaboradores Oportunos del SORTEO ANÁHUAC NOVENA EDICIÓN. Instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">  31 de octubre de 2024</td>
                                    <td>12:00 horas</td>
                                    <td>Entrega del Premio 1 de Compradores del Tercer Sorteo Acumulado Especial de Compradores y Colaboradores Oportunos del SORTEO ANÁHUAC NOVENA EDICIÓN. Instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p>
                        Para el sorteo principal, se llevará a cabo en la fecha, hora y lugar señalado en el siguiente
                        cuadro:
                    </p>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row"> 10 DE DICIEMBRE DE 2024</td>
                                    <td>11:00 horas</td>
                                    <td>ENTREGA DE LOS PREMIOS 4° AL 24° DIRECTOS Y DEL 1° AL 4° DE COLABORADOR, EN LAS INSTALACIONES DE LA UNIVERSIDAD ANÁHUAC MÉXICO CAMPUS NORTE CON DOMICILIO EN ACVENIDA UNIVERSIDAD ANÁHUAC NO. 46 COL. LOMAS DE ANÁHUAC C.P.52786 EN HUIXQUILUCAN, ESTADO DE MÉXICO, MÉXICO   </td>
                                </tr>

                                <tr>
                                    <td scope="row"> 11 DE DICIEMBRE DE 2024</td>
                                    <td>11:00 horas</td>
                                    <td>ENTREGA DEL PREMIO 2: Boulevard Bosque Real lote 16-A manzana V Número 180 Depto. 2703 Col. Bosque Real. Torre B Conjunto hab. Bosque Real Five C.P. 52774 Huixquilucan, Estado de México.</td>
                                </tr>
                                <tr>
                                    <td scope="row"> 13 DE DICIEMBRE DE 2024</td>
                                    <td>11:00 horas</td>
                                    <td>ENTREGA DEL PREMIO 3: Condominio El Origen  Lote 16 localidad Komchen, Mérida, Yucatán, México C.P. 97302 </td>
                                </tr>
                                <tr>
                                    <td scope="row"> 14 DE DICIEMBRE DE 2024</td>
                                    <td>11:00 horas</td>
                                    <td>ENTREGA DEL PREMIO 1:  Avenida Antonio Enriquez Savignac, Torre Aire Int: UPE 2-30 Condominio Residencial Brezza Lote 4C-4 Manzana 01 localidad Cancún Supermanzana 4-A, Municipio Benito Juárez, Cancún, Quintana Roo, México. 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h2>
                        Los impuestos, derechos y gastos de entrega de los premios a los ganadores estarán a cargo del
                        Sorteo Anáhuac A.C.
                    </h2>
                    <h2>
                        Publicación de Resultados:
                    </h2>

                    <ol type="1">
                        <li>
                            Para el Primer Sorteo Acumulado Especial de Compradores y Colaboradores Oportunos del SORTEO ANÁHUAC NOVENA EDICIÓN. La lista de ganadores, serán publicados (el número ganador, el nombre propio y la ciudad en donde reside el ganador) con base a nuestro aviso de privacidad; la Ley Federal de Juegos y Sorteos y su Reglamento en relación con la Ley Federal de Protección a Datos Personales en Posesión de Particulares en nuestra página web www.sorteoanahuac.mx. El resultado estará disponible a partir del día 25 de mayo del 2024. . El domicilio en donde se publicará la lista completa de ganadores con todos los datos del ganador, será en las instalaciones de la Universidad Anáhuac México Campus Norte ubicada en Avenida Universidad no. 46, Col. Lomas Anáhuac, Huixquilucan, Estado de México, C.P. 52786
                        </li>
                        <li>
                            Para el Segundo Sorteo Acumulado Especial de Compradores y Colaboradores Oportunos del SORTEO ANÁHUAC NOVENA EDICIÓN. La lista de ganadores, serán publicados (el número ganador, el nombre propio y la ciudad en donde reside el ganador) con base a nuestro aviso de privacidad; la Ley Federal de Juegos y Sorteos y su Reglamento en relación con la Ley Federal de Protección a Datos Personales en Posesión de Particulares en nuestra página web www.sorteoanahuac.mx. El resultado estará disponible a partir del día 24 de agosto de 2024. El domicilio en donde se publicará la lista completa de ganadores con todos los datos del ganador, será en las instalaciones de la Universidad Anáhuac México Campus Norte ubicada en Avenida Universidad no. 46, Col. Lomas Anáhuac, Huixquilucan, Estado de México, C.P. 52786
                        </li>
                        <li>
                            Para el Tercer Sorteo Acumulado Especial de Compradores y Colaboradores Oportunos del SORTEO ANÁHUAC NOVENA EDICIÓN. La lista de ganadores, serán publicados (el número ganador, el nombre propio y la ciudad en donde reside el ganador) con base a nuestro aviso de privacidad; la Ley Federal de Juegos y Sorteos y su Reglamento en relación con la Ley Federal de Protección a Datos Personales en Posesión de Particulares en nuestra página web www.sorteoanahuac.mx. El resultado estará disponible a partir del día 19 de octubre de 2024. El domicilio en donde se publicará la lista completa de ganadores con todos los datos del ganador, será en las instalaciones de la Universidad Anáhuac México Campus Norte ubicada en Avenida Universidad no. 46, Col. Lomas Anáhuac, Huixquilucan, Estado de México, C.P. 52786


                        </li>
                        <li>
                            Para el Sorteo Principal denominado SORTEO ANÁHUAC NOVENA EDICIÓN, la lista de ganadores, serán publicados (el número ganador, el nombre propio y la ciudad en donde reside el ganador) con base a nuestro aviso de privacidad; la Ley Federal de Juegos y Sorteos y su Reglamento en relación con la Ley Federal de Protección a Datos Personales en Posesión de Particulares en nuestra página web www.sorteoanahuac.mx. El resultado estará disponible a partir del día 23 de noviembre de 2024. El domicilio en donde se publicará la lista completa de ganadores con todos los datos del ganador, será en las instalaciones de la Universidad Anáhuac México Campus Norte ubicada en Avenida Universidad no. 46, Col. Lomas Anáhuac, Huixquilucan, Estado de México, C.P. 52786.
                        </li>
                    </ol>

                    <p>
                        Por disposición de la Secretaría de Gobernación, se deberá cumplir con los siguientes requisitos: 
                        Boleto físico: 1) Conserve su comprobante porque sin él no podrá reclamar el premio, el derecho para reclamar el premio se extingue a los 20 días hábiles a partir de la fecha del sorteo. 2) El comprobante no debe presentar enmendaduras, raspaduras o alteración alguna, en caso contrario el poseedor no tendrá derecho a reclamar el premio. 3) En caso de robo o extravío del boleto o comprobante de participación, se deberá presentar la denuncia correspondiente ante el Ministerio Público. 4) Las personas beneficiadas deberán presentar el boleto ganador y una identificación oficial vigente con fotografía. 
                        Boleto digital: 1) Las personas beneficiadas deberán presentar el boleto ganador mediante su dispositivo móvil (mismo que recibió por correo electrónico y que caduca a los 20 días hábiles) y mostrar una  identificación oficial vigente con fotografía.
                        Boletos físicos y digitales: 1) Al asentar sus datos reconoce que se le ha informado del aviso de privacidad citado en nuestra página web www.sorteoanahuac.mx. 2) Para cualquier aclaración o información referente a este sorteo o resultados del mismo, comunicarse al teléfono 55 5328-8096 ext. 2137 o acudir a Av. Universidad Anáhuac número 46, Col. Lomas Anáhuac, C.P. 52786 en Huixquilucan, Estado de México. 3) En caso de queja, acudir a la Dirección General de Juegos y Sorteos de la Secretaría de Gobernación, ubicada en Versalles número 49, 2do. Piso, Col. Juárez, Alcaldía Cuauhtémoc, C.P. 06600, Ciudad de México, o bien comunicarse al teléfono 55 5209-8800. 4) Responsable del sorteo:  Sorteo Anáhuac A.C. con domicilio en Av. Universidad Anáhuac no. 46 Col. Lomas de Anáhuac, C.P. 52786, Huixquilucan, Estado de México, teléfono: 55 5328-8096.
                        
                    </p>

       

                </div>
            </div>
        </div>
    </div>
</section>