
<section class="banner-share-text">
    <div class="container">
        <div class="row">
            <div class="col-md-5 text-center my-auto" data-aos="fade-left" data-aos-easing="ease"  data-aos-duration="3000">
                <img src="/assets/images/sorteo2024/pasalavoz/girl.png" class="img-fluid">         
            </div>

            <div class="col-md-7 text-center my-auto" data-aos="fade-left" data-aos-easing="ease"  data-aos-duration="3000">
                <img src="/assets/images/sorteo2024/pasalavoz/texto-comparte.png" class="img-fluid">         
            </div>
        </div>
    </div>
</section>


<section class="share-text" data-aos="fade-up" data-aos-easing="ease"  data-aos-duration="3000">
    <div class="container">
        <div class="row" >
            <div class="col-12">
                <p class="text">
                    ¡Ahora podrás compartir contenido de Sorteo Anáhuac con tus amigos y contactos!
                </p>
            </div>

            <div class="col-lg-4 container-text">
                <h2>
                    <span>1. </span>  INGRESA AL siTIO WEB
                </h2>
                <p>
                    Asegúrate de ingresar al sitio de Sorteo Anáhuac con tu correo electrónico y contraseña para hacer uso de esta opción.
                </p>
            </div>

            <div class="col-lg-4 container-text">
                <h2>
                    <span>2. </span> Identifica el símbolo
                    de compartir 
                </h2>
                <p>
                Identifica este símbolo en cualquier sección del sitio para compartir información con tus contactos en redes sociales como Facebook, Instagram, WhastApp, Twitter.
                </p>
            </div>

            <div class="col-lg-4 container-text">
                <h2>
                    <span>3. </span> Corrobora tus datos
                </h2>
                <p>
                    Al dar clic en el símbolo, revisa que tu nombre de colaborador aparezca como: Compartir como (tu nombre de colaborador) con amigos.
                </p>
            </div>

            <div class="col-lg-4 offset-lg-4 container-text">
                <h2>
                    <span>4.</span> Opciones que puedes compartir
                </h2>
                <p>
                     Las opciones que puedes compartir son:
                </p>
                <ul>
                    <li>Premios principales </li>
                    <li>Sección de compradores oportunos </li>
                    <li>Destino de fondos </li>
                    <li>Ganadores </li>
                    <li>Noticias </li>
                    <li>Fechas importantes </li>
                </ul>
            </div>

        </div>
    </div>
</section>