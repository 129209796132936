<div class="card cascading-right banner-login border-0 " id="login_main">
  <div class="card-body p-0" >


    <h2 class="fw-bold mb-3" data-aos="fade-up"  data-aos-duration="700">INICIO DE SESIÓN</h2>
    <form #colaboraData="ngForm" (ngSubmit)="validateData(colaboraData)" class="form-all" id="form-egr" autocomplete="off" >

      <!-- Email input -->


      <div class="form-group mb-4" data-aos="fade-up"  data-aos-duration="900">
        <label class="mb-2 text-left">Correo</label>
        <input type="email"
          [ngClass]="{'is-invalid': correo.errors && (correo.touched || correo.dirty), 'is-valid':correo.valid }"
          class="form-control" correo ngModel id="correo" name="correo" #correo="ngModel" [(ngModel)]="dataLogin.correo"
          placeholder="*Ingrese su correo" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" maxlength="100">
        <div class="text-left invalid-feedback mb-2">
          <span>Formato incorrecto</span>
        </div>
      </div>

      <!-- Email input -->
      <div class="form-group mb-4" data-aos="fade-up"  data-aos-duration="1100">
        <label class="text-left">Contraseña</label>
        <input type="password"
          [ngClass]="{'is-invalid': password.errors && (password.touched || password.dirty), 'is-valid':password.valid }"
          class="form-control" password ngModel id="password" name="password" #password="ngModel"
          [(ngModel)]="dataLogin.password" placeholder="*Ingrese su contraseña" required maxlength="100">
        <div class="text-left invalid-feedback mb-2">
          <span>Formato incorrecto</span>
        </div>
      </div>

      <div class="form-group mb-4" data-aos="fade-up"  data-aos-duration="1300">
        <label class="text-left">Tipo de ganador </label>
        <select class="form-control" required tipo_ganador ngModel id="tipo_ganador" name="tipo_ganador"
          #tipo_ganador="ngModel" [(ngModel)]="dataLogin.tipo_ganador"
          [ngClass]="{'is-invalid': tipo_ganador.errors && (tipo_ganador.touched || tipo_ganador.dirty ) , 'is-valid':tipo_ganador.valid }"
          (change)="changeVal(1)">
          <option value="0">Comprador</option>
          <option value="1">Colaborador</option>
        </select>

        <div class="text-left invalid-feedback mb-2">
          <span>Selecciona un tipo de ganador</span>
        </div>

      </div>

      <button type="submit" class=" mt-4 border-0" data-aos="fade-up"  data-aos-duration="1500">
        Enviar
      </button>
    </form>

    <div class="content-alert  text-center mt-5" *ngIf="message">
      <div class="alert alert-danger" role="alert">
        {{message}}
      </div>
    </div>

  </div>
</div>