import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RetrieveEmail } from '../models/retrieveEmail';
import { RecoveryPass } from '../models/recoveryPass';
import { Login } from '../models/login';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  private  apiURL = 'https://serviciosapp.sorteoanahuac.mx/api';
  //private  apiURL = 'http://localhost:2393/api';

  recoveryMail(email: RetrieveEmail, tokenS: string) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + tokenS
    });
    return this.http.post(`${this.apiURL}/PasaLaVoz/RecuperaPwd/` , email, {headers});
  }
  validateToken(token : any){
    return this.http.post(`${this.apiURL}/PasaLaVoz/Validatoken/` , token);
  }

  updatePass(formPass: RecoveryPass){
    return this.http.post(`${this.apiURL}/PasaLaVoz/ActualizaPwd` , formPass);
  }

  login(dataLogin : Login, tokenS: string){
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + tokenS
    });
    return this.http.post(`${this.apiURL}/pasalavoz/login` , dataLogin, { headers });
  }
}
