<section class="rgl">
    <div class="container">
        <div class="row">

            <div class="col-12">
                <div class="hero-legal" data-aos="zoom-in-down">
                    <h1 class="text-center" style="font-size: 48px;">Se realizó con gran éxito <span>el Sorteo Anáhuac 6ª Edición</span></h1> 
                </div>
            </div>

            <div class="col-lg-12">
                <div class="content-text">
                   <ul class=" p-0">
                       <li>
                            <p class="f-bold ">Con un total de 200 números ganadores. </p>
                       </li>
                   </ul>
                    <p> 
                        Oaxaca, Oax. 18 de noviembre de 2021.- El Sorteo Anáhuac, uno de los sorteos  universitarios más importantes de México, celebró su sexta edición teniendo como sede  para su realización la Universidad Anáhuac Oaxaca.  
                    </p>
                    <p>
                        El Sorteo Anáhuac 6ª Edición contó con una gran participación de la comunidad  universitaria, colaboradores y amigos de esta casa de estudios, los fondos recaudados  se destinarán para que un gran número de alumnos continúen con sus estudios. 
                    </p>
                    <p>
                        José Luis Arenas Delgado, Director de Sorteo Anáhuac comenta: <em>“Es para nosotros un  orgullo llegar a esta instancia con la satisfacción de cumplir los objetivos y con el  agradecimiento para todos nuestros colaboradores. Aún nos resta una parte  importante del Sorteo Anáhuac, la entrega de premios y estamos a la espera de cumplir  el sueño de quienes resultaron ganadores”. </em> 
                    </p>
                    <p>
                        <em>“Este año contamos con importantes novedades a destacar para todos los que apoyan  este evento que beneficia a miles de estudiantes para continuar con sus estudios”. Añadió J. L. Delgado. </em>
                    </p>
                    <p>
                        Para facilitar la selección y compra de boletos en la sección de “Compra”, se contó  como ya es costumbre con el Botón de la fortuna, que genera números de boletos  aleatorios al momento de accionar el botón de la máquina, para después agregarlo al carrito y finalizar la compra simplificando el proceso de seleccionar número por número. 
                    </p>
                    <p>
                        Otra de las ventajas de esta edición fueron los boletos digitales haciendo factible  comprar desde cualquier lugar a través de www.sorteoanahuac.mx con un proceso que  facilitó la transacción para finalizar con el envío de boletos por correo electrónico. 
                    </p>

                    <p>
                        También los premios del Sorteo Anáhuac incluyeron novedades interesantes este año: Los departamentos, el 1er. lugar en Cancún con un valor aproximado de $9,605,228.01,  mientras el 2do. lugar, un departamento de lujo en el Estado de México con valor  $7,854,932.06. Y algo muy alentador también, un premio en efectivo para el tercer lugar  del Sorteo que se llevará un cheque certificado por $1,000,000 de pesos. Adicionalmente del 6° al 12° tienen premio de $500,000 pesos. 
                    </p>
                    <p>
                        Y la lista sigue, con increíbles premios como el cuarto y quinto lugar, un BMW X1  modelo 2021 con un valor de $675,000 y del 13° al 17° un Mini Cooper Chili, del 18° al  22° un Kia Forte y del 23° al 37° un Kia Río, así que, para los fanáticos de los coches,  también hay premios.
                    </p>
                    <p>
                        Son un total de 200 números ganadores, los premios se asignaron paulatinamente en el siguiente orden: de mayor a menor. También se reconoce a los colaboradores que  hayan vendido un boleto ganador. 
                    </p>
                    <p>
                        Es posible conocer los resultados del Sorteo Anáhuac a través del sitio web  www.sorteoanahuac.mx y también serán publicados en el periódico de circulación  nacional El Heraldo de México, los días sábado 20 y domingo 21 de noviembre de 2021.
                    </p>
                    <p>
                        Para más información sobre dónde reclamar la entrega de los premios, documentos  necesarios para recibirlos o conocer las características generales de los doscientos  premios del Sorteo Anáhuac Sexta Edición y de los doscientos premios a colaborador,  diríjase a: www.sorteoanahuac.mx, o a la oficina del Sorteo Anáhuac, A.C. ubicada en  la calle “Av. Universidad Anáhuac no. 46, Col. Lomas Anáhuac, C.P. 52786,  Huixquilucan, Estado de México o al teléfono: 01(55) 5328-8096 ext 2137. 
                    </p>
                    <p>
                        Las personas que resulten ganadoras deberán presentar el boleto original o en su caso  su boleto digital, acompañado de una identificación oficial vigente con fotografía  (credencial INE o pasaporte), comprobante de domicilio (no mayor a tres meses) y  CURP; el nombre completo escrito en el boleto debe corresponder exactamente con el  del talón o con el registro en la APP del Sorteo Anáhuac y su identificación oficial. 
                    </p>
                    <p>
                        En caso de queja derivada de este sorteo, la autoridad competente es la Dirección  General Adjunta de Juegos y Sorteos de la Secretaría de Gobernación ubicada en la  calle de Versalles 49, piso 2, Col. Juárez, Del. Cuauhtémoc, C.P. 06600, Ciudad de  México o bien comunicarse al teléfono: 01(55) 5209 8800.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>