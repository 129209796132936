<section class="sorteo-egresados-anahuac lealtad">
    <div class="container">
        <div class="row">

            <div class="col-md-4 my-auto">
                <img src="/assets/images/sorteo2024/egresados/img-left1.png" class="img-fluid">
            </div>

            <div class="col-md-4 my-auto text-center" data-aos="zoom-in">
                <h1>
                    SORTEO
                    <span class="text d-block">
                        EGRESADOS
                    </span>
 
                    <span class="paragraph d-block">
                      LEALTAD 2024
                    </span>
                </h1>
            </div>

            <div class="col-md-4 my-auto">
                <img src="/assets/images/sorteo2024/egresados/img-right2.png" class="img-fluid">
            </div>

        </div>
    </div>
</section>


<section class="stc-winners-opt lealtad">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h2>
                    LISTA OFICIAL DE PARTICIPANTES
                    <span class="d-block">
                        SORTEO EGRESADOS LEALTAD 2024
                    </span>
                </h2>
            </div>
            <div class="col-md-12">
                <div class="text-center" style="overflow-x: auto;">
                    <label for="search" class="mt-5" style="text-align: left !important; width: 100%; font-weight: 600; font-size: 18px;">Buscar:</label>
                    <input id="search" type="text" class="form-control mb-3" placeholder="Ingresa matrícula, nombre o folio"
                        [(ngModel)]="searchText" (input)="filterData()" />
                    <table class="table table-striped">
                        <thead class="text-center">
                            <!-- <tr class="text-center">
                        <th class="border-none" colspan="10">Participantes Nuevos</th>
                      </tr> -->
                            <tr class="text-center cols">
                                <th scope="col"><span>FOLIO</span></th>
                                <th scope="col"><span>Nombre</span></th>
                                <th scope="col"><span>Número de Expediente</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center"
                                *ngFor="let participante of filteredData | paginate: { itemsPerPage: 21, currentPage: p }">
                                <td>{{ participante.folio_recurrente }}</td>
                                <td>{{  getFirstName(participante.nombre_pila) }}</td>
                                <td>{{ participante.matricula }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente">
                    </pagination-controls>
                </div>
            </div>


            <!-- Repite lo mismo para las otras dos tablas -->


            <div class="col-12 mt-5 pt-0 footer-egr">
                <p class="font-bold">
                    SORTEO EGRESADOS LEALTAD 2024
                </p>
                <p>
                    Los premios mayores a <strong>1,500 (UMA) $162,855.00 (Ciento Sesenta y dos mil ochocientos cincuenta y cinco pesos 00/100 m.n.)., </strong>se solicitará la presencia de un inspector de la DGJS para la entrega de los mismos, conforme al siguiente calendario de fechas:  <strong>: 05 de septiembre de 2024 a las 11:00 horas Entrega del Premio 1 del Sorteo Egresados Lealtad 2024. </strong> En las instalaciones de la Universidad Anáhuac México Campus Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de México. C.P. 52786.
                </p>

                <p>
                    Para más información sobre dónde reclamar la entrega de los premios, documentos necesarios para recibirlos diríjase a: www.sorteoanahuac.mx, o a la oficina del Sorteo Anáhuac, A.C. ubicada en la calle “Av. Universidad Anáhuac no. 46, Col. Lomas Anáhuac, C.P. 52786, Huixquilucan, Estado de México o al teléfono: 01(55) 5328-8096 ext 2137.
                </p>

                <p>
                    Las personas que resulten ganadoras deberán presentar una identificación oficial vigente con fotografía (credencial INE, pasaporte), comprobante de domicilio (no mayor a tres meses), CURP y constancia actualizada de su RFC.
                </p>
                <p>
                    En caso de queja derivada de este sorteo, la autoridad competente es la Dirección General de Juegos y Sorteos de la Secretaría de Gobernación ubicada en la calle de Versalles 49, piso 2, Col. Juárez, Del. Cuauhtémoc, C.P. 06600, Ciudad de México o bien comunicarse al teléfono: 01(55) 5209 8800.
                </p>

                <p>
                    <strong>PERMISO SEGOB 20240095PS05.</strong>
                </p>


            </div>

        </div>
    </div>
</section>