<form class="form-all form-win" #dataWinner="ngForm" (ngSubmit)="sendData(dataWinner)" autocomplete="off"
    id="form_register" data-aos="fade-up" data-aos-duration="700" >
    <div class="row">

        <div class="col-12 text-center show">
            <h3>
                Favor de hacer scroll hacia abajo para ver el botón enviar
            </h3>
        </div>

        <div class="form-group col-md-4">
            <label class="pt-2">Nombre</label>
            <input type="text"
                [ngClass]="{'is-invalid': nombre.errors && (nombre.touched || nombre.dirty), 'is-valid':nombre.valid }"
                class="form-control" nombre ngModel id="nombre" name="nombre" #nombre="ngModel"
                [(ngModel)]="dataSorteo.nombre" placeholder="*Ingrese su nombre" required
                pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+" minlength="3" maxlength="100">
            <div class="text-left invalid-feedback mb-2">
                <span>Solo se aceptan letras</span>
            </div>
        </div>

        <div class="form-group col-md-4">
            <label class="pt-2">Apellido paterno</label>
            <input type="text"
                [ngClass]="{'is-invalid': apellido_paterno.errors && (apellido_paterno.touched || apellido_paterno.dirty), 'is-valid':apellido_paterno.valid }"
                class="form-control" apellido_paterno ngModel id="apellido_paterno" name="apellido_paterno"
                #apellido_paterno="ngModel" [(ngModel)]="dataSorteo.apellido_paterno"
                placeholder="*Ingrese su apellido paterno" required pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+" minlength="3"
                maxlength="100">
            <div class="text-left invalid-feedback mb-2">
                <span>Solo se aceptan letras</span>
            </div>
        </div>

        <div class="form-group col-md-4">
            <label class="pt-2">Apellido materno</label>
            <input type="text"
                [ngClass]="{'is-invalid': apellido_materno.errors && (apellido_materno.touched || apellido_materno.dirty), 'is-valid':apellido_materno.valid }"
                class="form-control" apellido_materno ngModel id="apellido_materno" name="apellido_materno"
                #apellido_materno="ngModel" [(ngModel)]="dataSorteo.apellido_materno"
                placeholder="*Ingrese su apellido materno" required pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+" minlength="3"
                maxlength="100">
            <div class="text-left invalid-feedback mb-2">
                <span>Solo se aceptan letras</span>
            </div>
        </div>


        <div class="form-group col-md-4">
            <label class="pt-2">Edad</label>
            <input type="text" class="form-control" edad ngModel id="edad" name="edad" #edad="ngModel"
                [(ngModel)]="dataSorteo.edad"
                [ngClass]="{'is-invalid': edad.errors && (edad.touched || edad.dirty), 'is-valid': edad.valid}"
                placeholder="*Ingresa tu edad" required pattern="[0-9]+" minlength="1" maxlength="3">
            <div class="text-left invalid-feedback mb-2">
                <span>Solo se aceptan números</span>
            </div>
        </div>

        <div class="form-group col-md-4">
            <label class="pt-2">CURP</label>
            <input type="text" class="form-control" curp ngModel id="curp" name="curp" #curp="ngModel"
                [(ngModel)]="dataSorteo.curp"
                [ngClass]="{'is-invalid': curp.errors && (curp.touched || curp.dirty), 'is-valid': curp.valid}"
                placeholder="*Ingresa tu curp" required minlength="18" maxlength="18">
            <div class="text-left invalid-feedback mb-2">
                <span>Formato incorrecto</span>
            </div>
        </div>

        <div class="form-group col-md-4">
            <label class="pt-2" for="pais">Pais</label>
            <select class="form-control" required pais ngModel id="pais" name="pais" #pais="ngModel"
                [(ngModel)]="dataSorteo.pais"
                [ngClass]="{'is-invalid': pais.errors && (pais.touched || pais.dirty), 'is-valid':pais.valid }"
                (change)="changeVal(1)">
                <option value="0" selected>Seleccionar</option>
                <option selected *ngFor="let item of country" value="{{item}}">{{ item}}</option>
            </select>

            <div class="text-left invalid-feedback mb-2">
                <span>Selecciona una país</span>
            </div>
        </div>

        <!-- ---------------------------------------------------MEXICO ------------------------------------------------------->
        <ng-container *ngIf="extrajero == 'México'">
            <div class="form-group col-md-4">
                <label class="pt-2 " for="cp">Código Postal</label>
                <input type="text" class="form-control" required id="cp" placeholder="00000" cp ngModel #cp="ngModel"
                    name="cp" [(ngModel)]="dataSorteo.cp" autocomplete="new-cp" maxlength="10" pattern="[0-9]+"
                    maxlength="5">
                <div class="text-left invalid-feedback mb-2">
                    <span>Código postal incorrecto</span>
                </div>
                <div class="mt-3 text-right">
                    <a class="btn-stack " (click)="getCp()">Buscar</a>
                </div>
            </div>

            <div class="form-group col-md-4">
                <label class="pt-2 " for="estado">Estado </label>
                <input type="text" class="form-control" required id="estado" placeholder="" estado ngModel
                    #estado="ngModel" name="estado" [(ngModel)]="dataSorteo.estado" autocomplete="new-estado" readonly>
                <div class="text-left invalid-feedback mb-2">
                    <span>Dato incorrecto</span>
                </div>
            </div>

            <div class="form-group col-md-4">
                <label class="pt-2 " for="municipio">Municipio</label>
                <input type="text" class="form-control" required id="municipio" placeholder="" municipio ngModel
                    #municipio="ngModel" name="municipio" [(ngModel)]="dataSorteo.municipio"
                    autocomplete="new-municipio" readonly>
                <div class="text-left invalid-feedback mb-2">
                    <span>Dato incorrecto</span>
                </div>
            </div>

            <div class="form-group col-md-4">
                <label class="pt-2" for="colonia">Colonia</label>
                <select class="form-control" required colonia ngModel id="colonia" name="colonia" #colonia="ngModel"
                    [(ngModel)]="dataSorteo.colonia"
                    [ngClass]="{'is-invalid': colonia.errors && (colonia.touched || colonia.dirty), 'is-valid':colonia.valid }"
                    (change)="changeVal(2)">
                    <!-- <option value="0" selected>Seleccionar</option> -->
                    <option *ngFor="let item of dataCountry" value="{{item.colonia}}">{{ item.colonia}}</option>
                </select>

                <div class="text-left invalid-feedback mb-2">
                    <span>Selecciona una colonia</span>
                </div>
            </div>




        </ng-container>

        <!-- --------------------------------------------------- END MEXICO ------------------------------------------------------->
        <div class="form-group col-md-4">
            <label class="pt-2" *ngIf="extrajero == 'México'">Calle y número </label>
            <label class="pt-2" *ngIf="extrajero != 'México'"> Dirección</label>
            <input type="text"
                [ngClass]="{'is-invalid': calleNumero.errors && (calleNumero.touched || calleNumero.dirty), 'is-valid':calleNumero.valid }"
                class="form-control" calleNumero ngModel id="calleNumero" name="calleNumero" #calleNumero="ngModel"
                [(ngModel)]="dataSorteo.calleNumero" placeholder="*Ingrese su calle" required maxlength="100">
            <div class="text-left invalid-feedback mb-2">
                <span>Dato incorrecto</span>
            </div>
        </div>

        <div class="form-group col-md-4">
            <label class="pt-2">RFC <span *ngIf="extrajero != 'México'"> o equivalante en tu país</span></label>
            <input type="text"
                [ngClass]="{'is-invalid': rfc.errors && (rfc.touched || rfc.dirty), 'is-valid':rfc.valid }"
                class="form-control" rfc ngModel id="rfc" name="rfc" #rfc="ngModel" [(ngModel)]="dataSorteo.rfc"
                placeholder="*Ingrese su rfc" required minlength="12" maxlength="13">
            <div class="text-left invalid-feedback mb-2">
                <span>Complete este dato</span>
            </div>
        </div>

        <div class="form-group col-md-4">
            <label class="pt-2">Folio del boleto </label>
            <input type="text"
                [ngClass]="{'is-invalid': folio_boleto.errors && (folio_boleto.touched || folio_boleto.dirty), 'is-valid':folio_boleto.valid }"
                class="form-control" folio_boleto ngModel id="folio_boleto" name="folio_boleto" #folio_boleto="ngModel"
                [(ngModel)]="dataSorteo.folio_boleto" placeholder="*Ingrese el folio ganador" required pattern="[0-9]+"
                minlength="6" maxlength="6">
            <div class="text-left invalid-feedback mb-2">
                <span>Solo se aceptan números</span>
            </div>
        </div>

        <div class="form-group col-md-4">
            <label class="pt-2">Tipo de ganador </label>
            <select class="form-control" required tipo_ganador ngModel id="tipo_ganador" name="tipo_ganador"
                #tipo_ganador="ngModel" [(ngModel)]="dataSorteo.tipo_ganador"
                [ngClass]="{'is-invalid': tipo_ganador.errors && (tipo_ganador.touched || tipo_ganador.dirty ) , 'is-valid':tipo_ganador.valid }"
                (change)="changeVal(3)">
                <option value="0">Comprador</option>
                <option value="1">Colaborador</option>
            </select>

            <div class="text-left invalid-feedback mb-2">
                <span>Selecciona un tipo de ganador</span>
            </div>

        </div>

        <div class="form-group col-md-4">
            <label class="pt-2">Correo</label>
            <input type="email"
                [ngClass]="{'is-invalid': correo.errors && (correo.touched || correo.dirty), 'is-valid':correo.valid}"
                class="form-control" correo ngModel id="correo" name="correo" #correo="ngModel"
                [(ngModel)]="dataSorteo.correo" placeholder="*Ingrese su correo" required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"   maxlength="100">
            <div class="text-left invalid-feedback mb-2">
                <span>Formato incorrecto</span>
            </div>
        </div>


        <div class="form-group col-md-4">
            <label class="pt-2">Contraseña </label>
            <input type="password"
                [ngClass]="{'is-invalid': password.errors && (password.touched || password.dirty), 'is-valid':password.valid }"
                class="form-control" password ngModel id="password" name="password" #password="ngModel"
                [(ngModel)]="dataSorteo.password" placeholder="*Ingrese contraseña" required minlength="8"
                maxlength="100" (input)="validaPass($event)">
            <div class="text-left invalid-feedback mb-2">
                <span>La contraseña debe tener por lo menos 8 caracteres</span>
            </div>
        </div>

        <div class="form-group col-md-4">
            <label class="pt-2">Valida contraseña </label>
            <input type="password" id="validaPass" name="validaPass" class="form-control" minlength="8" maxlength="100"
                placeholder="*Reingrese contraseña" (input)="validaPass($event)">
            <div class="text-left invalid-feedback mb-2">
                <span>Contraseñas distintas</span>
            </div>
        </div>



        <div class="col-md-12 mt-4 text-center">
            <button type="submit" class="mb-2">Enviar</button>
        </div>
    </div>
</form>







<!-- Modal send-->
<div class="modal-container">
    <div class="modal fade" id="modal-registro" tabindex="-1" role="dialog" aria-labelledby="modal-registro"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content p-3">


                <div class="modal-body text-center">
                    <ng-container *ngIf="flag;">
                        <div class="spinner-border text-primary mt-2 mb-2" role="status"
                            style="color: #f35f44 !important;">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="success;">
                        <p> {{ mensaje }} </p>
                    </ng-container>

                </div>
                <div class="modal-footer text-center border-0 justify-content-center">
                    <ng-container *ngIf="success && errorG != 200 ;">
                        <a data-dismiss="modal" class="btn-stack  ">Cerrar</a>
                    </ng-container>
                    <ng-container *ngIf="success && errorG == 200 ;">
                        <a (click)="reloadCurrentPage();" data-dismiss="modal" class="btn-stack  ">Iniciar Sesión</a>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal error-->
<div class="modal fade" id="modal-error" tabindex="-1" role="dialog" aria-labelledby="modal-error" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-3">
            <div class="modal-body text-center">
                <p>
                    Faltan datos por llenar, los datos faltantes aparecen en color rojo como el siguiente ejemplo:
                </p>
                <div class="row">
                    <div class="form-group col-md-12">
                        <label class="text-left">Nombre </label>
                        <input type="text" class="form-control is-invalid" readonly>
                        <div class="text-left invalid-feedback mb-2">
                            <span>Dato faltante</span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer text-center border-0 justify-content-center">
                <a data-dismiss="modal" class="btn-stack ">Cerrar</a>
            </div>
        </div>
    </div>
</div>


<div class="modal-container">

    <!-- Modal slide-->
    <div class="modal fade pr-0" id="modal-slide" tabindex="-1" role="dialog">
        <div class="modal-dialog  modal-dialog-centered" role="document">
            <div class="modal-content py-4 px-2">


                <div class="modal-body text-center">


                    <div id="carouselExampleControls" class="carousel slide" data-interval="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <h3>IMPORTANTE</h3>
                                <p> El <strong>nombre</strong> que ingreses debe coincidir exactamente a como aparece en
                                    tu
                                    credencial de elector. </p>
                                <img class="d-block w-100" src="/assets/images/sorteo2022/form/credencial.gif"
                                    alt="First slide">
                                <a class="btn-stack  mt-5" href="#carouselExampleControls" role="button"
                                    data-slide="next">
                                    Siguiente
                                </a>
                            </div>
                            <div class="carousel-item">
                                <h3>IMPORTANTE</h3>
                                <p> Cualquier duda que tengas con un dato no dudes en contactarnos al
                                    <a class="contact-t" href="tel:5553288096">(55) 5328 8096 ext. 7474
                                    </a> o nuestro correo
                                    <a class="contact-t" href="mailto:sorteoanahuac@anahuac.mx"
                                        target="_blank">sorteoanahuac@anahuac.mx
                                    </a>.
                                </p>
                                <img class="d-block w-100" src="/assets/images/sorteo2022/form/formulario.png"
                                    alt="Second slide">
                                <a data-dismiss="modal" class="btn-stack mt-3">He leído lo anterior</a>
                            </div>

                        </div>


                    </div>
                </div>
                <div class="modal-footer text-center border-0 justify-content-center">

                </div>
            </div>
        </div>
    </div>


</div>