import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  title = 'Preguntas frecuentes | Sorteo Anáhuac 8ª Edición';
  description = '¿Tienes algunas dudas? Consulta aquí las preguntas más frecuentes del Sorteo Anáhuac 8ª Edición.';

  faqs = 
	[

		{
			title: "¿Cuántos boletos tengo que vender si tengo beca Santander?", 
      description:'Tienes que vender 1 talonario lo que es igual a 11 boletos.',
      id: 'faq1',
      collapse: 'collapse1',
      idCollapse: 'data-target="#collapse1"'
		},
    
  ];


  constructor(private titleService: Title, private metaTagService: Meta) { }

  ngOnInit(): void {
  
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'description', content: this.description}
    );

    this.metaTagService.updateTag(
      { property: 'keywords', content: ''}
    );

    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title}
    );

    this.metaTagService.updateTag(
      { property: 'og:description', content:  this.description }
    );

    this.metaTagService.updateTag(
    { property: 'og:url', content: 'https://www.sorteoanahuac.mx/preguntas-frecuentes' }
    );

    this.metaTagService.updateTag(
      { property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/bg-btn.jpg' }
    );
      
    this.metaTagService.updateTag(
      { name: 'twitter:title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content:  this.description}
    );

    $(document).ready(function() {

      $('.collapse').on('shown.bs.collapse', function () {
          $(this).prev().addClass('active-collap');
      });

      $('.collapse').on('hidden.bs.collapse', function () {
          $(this).prev().removeClass('active-collap');
      });

    });
    
  }



}
