import { Component, OnInit } from '@angular/core';
import { GalleryService } from 'src/services/gallery.service';
import { Title, Meta } from '@angular/platform-browser';
import mixitup from 'mixitup';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.scss']
})
export class GaleriaComponent implements OnInit {
  title = 'Ganadores del Sorteo Anáhuac 2023 - 8ª Edición ';
  description = 'Toda la información sobre los ganadores del Sorteo Anáhuac 2023 - 8ª Edición. ¡Muchas felicidades y gracias por participar!';
  constructor(private _galleryService: GalleryService, private titleService: Title, private metaTagService: Meta) {
   }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'description', content: this.description}
    );

    this.metaTagService.updateTag(
      { property: 'keywords', content: ''}
    );

    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title}
    );

    this.metaTagService.updateTag(
      { property: 'og:description', content: this.description }
    );

    this.metaTagService.updateTag(
    { property: 'og:url', content: 'https://www.sorteoanahuac.mx/ganadores' }
    );

    this.metaTagService.updateTag(
      { property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/gallery-ph.jpg' }
    );
      
    this.metaTagService.updateTag(
      { name: 'twitter:title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: this.description}
    );

    let mixer = mixitup('.conainter-photos', {
      selectors: {
          target: '.mix'
      },
      animation: {
          duration: 300
      }
     });

  }


}
