<section id="stc-dates">
    <div class="container">
        <div class="row" data-aos="fade-down" data-aos-easing="ease"  data-aos-duration="2000">
            <div class="col-md-12 d-flex justify-content-center flex-column text-center" >
                <img src="/assets/images/sorteo2024/fechas-importantes.png" alt="" class="img-fluid">
            </div>
        </div>
    </div>
</section>


<section class="stc-days" data-aos="zoom-in" data-aos-easing="ease"  data-aos-duration="2000">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="row">
                    <div class="col-12">
                        <h5 class="">Alumnos de reingreso</h5>
                    </div>
                    <div class="col-md-7">
                        <p class="day">
                            Primer corte de pago
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                            15 de mayo
                        </p>
                    </div>

                    <div class="col-md-7">
                        <p class="day">
                            Fecha límite de pago
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                            28 de agosto
                        </p>
                    </div>

                    <div class="col-md-7">
                        <p class="day">
                            Sorteo Magno
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                            21 de noviembre
                        </p>
                    </div>

                    <div class="col-12">
                        <h5 class="">Alumnos a egresar en Mayo</h5>
                    </div>
                    <div class="col-md-7">
                        <p class="day">
                            Fecha límite de pago
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                            15 de mayo
                        </p>
                    </div>

                    <div class="col-12">
                        <h5 class="">Alumnos nuevo ingreso</h5>
                    </div>
                    <div class="col-md-7">
                        <p class="day">
                            Primer corte de pago
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                            7 de octubre
                        </p>
                    </div>
                    <div class="col-md-7">
                        <p class="day">
                            Fecha límite
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                            15 de octubre
                        </p>
                    </div>

                    <div class="col-12">
                        <h5 class="">Comunidad Universitaria Voluntaria</h5>
                    </div>
                    <div class="col-md-7">
                        <p class="day">
                            Fecha límite de pago
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                            7 de octubre
                        </p>
                    </div>


                    <div class="col-lg-9">
                        <h5>
                            Fechas de Sorteos Oportunos
                        </h5>
                    </div>

                    <div class="col-md-7">
                        <p class="day">
                            1º Sorteo Oportuno <br>
                            Fecha limite de compra
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                           20 de mayo
                        </p>
                    </div>

                    <div class="col-md-7">
                        <p class="day">
                            1º Sorteo Oportuno <br>
                            Fecha del sorteo
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                           24 de mayo
                        </p>
                    </div>


                    <div class="col-md-7">
                        <p class="day">
                           2º Sorteo Oportuno <br>
                           Fecha limite de compra
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                            19 de agosto
                        </p>
                    </div>

                    <div class="col-md-7">
                        <p class="day">
                            2º Sorteo Oportuno <br>
                            Fecha del sorteo
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                           23 de agosto
                        </p>
                    </div>

                    <div class="col-md-7">
                        <p class="day">
                            3º Sorteo Oportuno <br>
                            Fecha limite de compra
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                            14 de octubre
                        </p>
                    </div>

                    <div class="col-md-7">
                        <p class="day">
                            3º Sorteo Oportuno <br>
                           Fecha del sorteo
                        </p>
                    </div>
                    <div class="col-md-5 my-auto">
                        <p>
                          18 de octubre
                        </p>
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>