import { Component, OnInit } from '@angular/core';
import { PasaLavozService } from '../../../services/pasa-lavoz.service';
import { Boletocolab } from '../../../models/boletocolab';
import { NgForm } from '@angular/forms';
import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-consulta-boletos',
  templateUrl: './consulta-boletos.component.html',
  styleUrls: ['./consulta-boletos.component.scss']
})
export class ConsultaBoletosComponent implements OnInit {
  boletoCol: Boletocolab = new Boletocolab();
  boletoColCan: Boletocolab = new Boletocolab();
  reiniciarData: Boletocolab = new Boletocolab();
  fileName= 'Boletos-Vendidos.xlsx';
  dataBoletos:any = [];
  erroData = false;
  errorInfo = "";
  isData =true;
  constructor(private readonly _pasaServive: PasaLavozService) {
    if(localStorage.getItem('idColaborador') === null || localStorage.getItem('idColaborador') === ""){
      window.location.href = "/home";
    }
  }

  ngOnInit(): void {
    
    this.boletoColCan.IDColaborador = localStorage.getItem('idColaborador');
    this.boletoColCan.cantidad = 2000;
    this._pasaServive.getBoletosColab(this.boletoColCan).subscribe( a => {
      this.erroData = false;
      this.dataBoletos = a;
      ////console.log(a);
    },
    err => {
      ////console.log(err);
      if(err.status === 404){
        this.erroData = true;
        this.errorInfo = err.error;
        this.isData = false;
      }
      if(err.status === 200){
        this.isData = true;
      }
    });

  }
  validateData(form: NgForm){
    if (form.invalid ) { return; }
    this.boletoCol.IDColaborador = localStorage.getItem('idColaborador');
    //console.log(this.boletoCol);
    $('#cant').prop('selectedIndex',0);
    this._pasaServive.getBoletosColab(this.boletoCol).subscribe( a => {
      this.erroData = false;
      this.dataBoletos = a;
      //console.log(a);
    },
    err => {
      ////console.log(err);
      if(err.status === 404){
        this.erroData = true;
        this.errorInfo = err.error;
        this.isData = false;
      }
      if(err.status === 200){
        this.isData = true;
      }
    });

  }

  exportexcel(): void 
    {
       /* table id is passed over here */   
      let element = document.getElementById('excel-table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }

    onEditClick(valueInp: any) {
      ////console.log('skill name', valueInp);
      ////console.log();
      if($("#Fecha_ini").val() != "" && $("#Fecha_fin").val() != "" && $("#Fecha_ini").val() != null && $("#Fecha_fin").val() != null ){
          this.boletoColCan.IDColaborador = localStorage.getItem('idColaborador');
          this.boletoColCan.Fecha_ini = $("#Fecha_ini").val().toString();
          this.boletoColCan.Fecha_fin = $("#Fecha_fin").val().toString();
          this.boletoColCan.cantidad = Number(valueInp);
      }else{
          this.boletoColCan.IDColaborador = localStorage.getItem('idColaborador');
          this.boletoColCan.cantidad = Number(valueInp);
      }

      this._pasaServive.getBoletosColab(this.boletoColCan).subscribe( a => {
        this.erroData = false;
        this.dataBoletos = a;
        //console.log(a);
      },
      err => {
        ////console.log(err);
        if(err.status === 404){
          this.erroData = true;
          this.errorInfo = err.error;
          this.isData = false;
        }
        if(err.status === 200){
          this.isData = true;
        }
      });
    }

    viewAll(){
      $('#cant').prop('selectedIndex',0);
      $('#Fecha_fin').val("");
      $('#Fecha_ini').val("");

      this.reiniciarData.IDColaborador = localStorage.getItem('idColaborador');
      this.reiniciarData.cantidad = 2000;
      this._pasaServive.getBoletosColab(this.reiniciarData).subscribe( a => {
        this.erroData = false;
        this.dataBoletos = a;
        ////console.log(a);
        this.isData = true;
      },
      err => {
        ////console.log(err);
        if(err.status === 404){
          this.erroData = true;
          this.errorInfo = err.error;
          this.isData = false;
        }
        if(err.status === 200){
          this.isData = true;
        }
      });
    }
}
