import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SorteoOportuno } from 'src/models/sorteoOportuno';
import { FormEgrService } from 'src/services/form-egr.service';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Meta, Title } from '@angular/platform-browser';
import { PasaLavozService } from 'src/services/pasa-lavoz.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-participar-sorteo-oportuno',
  templateUrl: './participar-sorteo-oportuno.component.html',
  styleUrls: ['./participar-sorteo-oportuno.component.scss']
})
export class ParticiparSorteoOportunoComponent implements OnInit {
  dataSorteo: SorteoOportuno = new SorteoOportuno();
  isData = false;
  dataAll: any = [];
  errorData = false;
  primerSorteo = false;
  segundoSorteo = false;
  tercerSorteo = false;
  title = 'Sorteos Oportunos del Sorteo Anáhuac 2023 - 8ª Edición';
  description = 'Con los Sorteos Oportunos del Sorteo Anáhuac 1 boleto te da hasta 4 oportunidades de ganar. Encuentra toda la información aquí.';
  utmUrl = "";
  constructor(private readonly _pasaServive: PasaLavozService, private readonly _formService: FormEgrService, private titleService: Title, private metaTagService: Meta) { }
  progress = false;
  ngOnInit(): void {
    /*utm */
    let utmTem = localStorage.getItem('urlUtm');
    if (utmTem) this.utmUrl = utmTem;
    /* fin utm */
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title }
    );
    this.metaTagService.updateTag(
      { name: 'description', content: this.description }
    );

    this.metaTagService.updateTag(
      { property: 'keywords', content: 'Nosotros' }
    );

    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title }
    );

    this.metaTagService.updateTag(
      { property: 'og:description', content: this.description }
    );

    this.metaTagService.updateTag(
      { property: 'og:url', content: 'https://www.sorteoanahuac.mx/nosotros' }
    );

    this.metaTagService.updateTag(
      { property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/banner2_about.png' }
    );

    this.metaTagService.updateTag(
      { name: 'twitter:title', content: this.title }
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: this.description }
    );
  }
  //DESCOMENTAR FUNCION
  sendDataContact(form: NgForm) {

    if (form.invalid) {
      $("input.ng-invalid, textarea.ng-invalid").addClass("is-invalid");
      return;
    }
    this.progress = true;
    this.dataSorteo.folio = this.dataSorteo.folio;
    this._pasaServive.getToken().subscribe(a => {
      console.log(a);
    }, err => {

      if (err.status == 200) {

        this._formService.sendSorteoOpt(this.dataSorteo, err.error.text).subscribe(a => {

          this.isData = true;
          this.dataAll = a;
          //   this.dataAll = {
          //     "ltsSorteosParticipantes": [ {"id":1},{"id":2},{"id":3},],
          //     "dtCompra": "2022-02-04",
          //     "correo": "qwe@qwe.com",
          //     "nombre": "prueba2 prueba2",
          //     "folio": "331901"
          // }
          //console.log(this.dataAll);

          // this.dataAll =  {
          //     "ltsSorteosParticipantes": [
          //         {
          //             "nombre": "Primer Sorteo Acumulado Especial para Compradores y Colaboradores Oportunos del Sorteo Anahuac Septima Edicion"
          //         },
          //         {
          //             "nombre": "Segundo Sorteo Acumulado Especial para Compradores y Colaboradores Oportunos del Sorteo Anahuac Septima Edicion"
          //         },
          //         {
          //             "nombre": "Tercer Sorteo Acumulado Especial para Compradores y Colaboradores Oportunos del Sorteo Anahuac Septima Edicion"
          //         }
          //     ],
          //     "dtCompra": "2022-06-01",
          //     "correo": "anacehe@outlook.com",
          //     "nombre": "ANASARY CERON HERANDEZ",
          //     "folio": "323440"
          // };

          this.dataAll.ltsSorteosParticipantes.find(element => {
            let tem = element["nombre"].split(" ");
            //console.log(tem[0]);
            if (tem[0] === "Primer") this.primerSorteo = true;
            if (tem[0] === "Segundo") this.segundoSorteo = true;
            if (tem[0] === "Tercer") this.tercerSorteo = true;
          });

          // console.log(this.primerSorteo);
          // console.log(this.segundoSorteo);
          // console.log(this.tercerSorteo);

          this.errorData = false;
          this.progress = false;
        },
          err => {
            this.errorData = true;
            this.progress = false;
          });

      }
    });

  }

  async generatePDF(index) {

    const pdfDefinition: any = {
      content: [
        {
          image: await this.getBase64ImageFromURL(
            `/assets/images/soteo-oportuno/sorteo-oportuno-${index}.jpg`
          ),
          width: 500,
          height: 260,
        },


        {
          table: {
            body: [

              [

                {
                  stack: [
                    {
                      image: await this.getBase64ImageFromURL(
                        "/assets/images/soteo-oportuno/cintillo2.jpg"
                      ),
                      width: 270,
                      height: 25,
                    }
                  ]

                },
                [
                  {
                    text: `Folio de participación:  ${this.dataAll.folio}  `,
                    style: 'header'
                  },
                ],

              ]
            ]
          },
          layout: 'noBorders'
        },

        // {
        //   image: await this.getBase64ImageFromURL(
        //     "/assets/images/soteo-oportuno/footer.jpg"
        //   ),
        //   width: 500,
        //   height: 215,
        //   margin:[0,10,0,0]
        // },
        // {

        //   table: {
        //     widths: [ '70%',    '30%'],
        //     heights: 40,
        //     body: [

        //       [
        //         {
        //           stack: [

        //               {
        //                 text: `NOMBRE DEL COMPRADOR: ${this.dataAll.nombre}  `,
        //                 style: 'textpadding'
        //               }

        //           ]
        //         },
        //         [
        //           {
        //             text: `SORTEO OPORTUO`,
        //             style: 'textpadding'
        //           },
        //         ],

        //       ]
        //     ]
        //   },
        //   style: 'footer',
        //   layout: 'noBorders'


        // },
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'right',
          width: 40,
          margin: [0, 10, 0, 0]
        },

        footer: {
          fontSize: 10,
          bold: true,
          color: '#010101',
          fillColor: '#b5b2a9',
          margin: [0, 10, 0, 10]
        },

        textpadding: {
          margin: [20, 20, 0, 20]
        }
      },


    }
    pdfMake.createPdf(pdfDefinition).print();
    //pdfMake.createPdf(pdfDefinition).open();
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }
}
