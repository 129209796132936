<section class="thanks">
    <div class="container">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-center flex-column" data-aos="fade-top" data-aos-easing="ease"  data-aos-duration="3000">
                <h1>
                    ¡GRACIAS POR TU INTERÉS!
                </h1>
            </div>
        </div>
    </div>
</section>

<section class="stc-text">
    <div class="container">
        <div class="row" data-aos="fade-left" data-aos-easing="ease"  data-aos-duration="3000">
           
            <div class="col-md-4" >
                <p>
                    ¿Quieres ganar grandes premios con la compra de <strong>un solo boleto? </strong>
                    Compra 1 boleto por solo $450 pesos y participa en nuestros 3 sorteos.
                </p>
                <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/" target="_blank" class="btn-stack"> Da clic aquí </a>
            </div>

            <div class="col-md-4" >

                <p>
                    ¿Por qué conformarse con <strong>participar en solo sorteo</strong> cuando puedes ganar aún <strong>más</strong> ? Comparte con tus amigos y gana la oportunidad de entrar en nuestro Sorteo Amigo Egresado

                </p>
                <a href="" class="btn-stack"> Da clic aquí </a>

            </div>

            <div class="col-md-4" >
                <p>
                    <strong> ¡Más de 400 premios</strong> te esperan en nuestro Sorteo Magno! 
                    ¡Participa ya y gana apoyando la educación!                    
                </p>
                <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/" target="_blank" class="btn-stack">Da clic aquí </a>
            </div>

            <div class="col-md-12 mt-md-5" *ngIf="this.folio">
                <p>
                    Su folio de participación es
                    <span>{{this.folio}}.</span>
                    mismo que llegará al correo que proporcionó, es importante revisar en la bandeja de Spam.
                </p>
                <p>
                    <strong class=" f-bold">  
                        NOTA: este correo puede tardar hasta 24 horas en llegar, a partir de este correo, podrán empezar a compartir una liga con sus conocidos egresados para participar en el “Sorteo Amigo Egresado”.
                    </strong>  
                </p>
                <p>
                    <strong>
                        ¡Mucha suerte!
                    </strong>
                </p>
            </div>
        </div>
    </div>
</section>